// Action types
export const START_BULK_UPLOAD = 'START_BULK_UPLOAD';
export const BULK_UPLOAD_SUCCESS = 'BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_FAILURE = 'BULK_UPLOAD_FAILURE';
export const RESET_BULK_UPLOAD_STATE = 'RESET_BULK_UPLOAD_STATE'

// Action creators
export const startBulkUploadItemsNBundles = (payloads, length, reset, edit, economy, method) => ({
  type: START_BULK_UPLOAD,
  payloads,
  length,
  reset,
  edit,
  economy,
  method
});

export const apiBulkUploadSuccess = (payloads, totalLength, payloadLength) => ({
  type: BULK_UPLOAD_SUCCESS,
  payloads,
  totalLength,
  payloadLength
});

export const apiBulkUploadFailure = (error, totalLength, payloadLength) => ({
  type: BULK_UPLOAD_FAILURE,
  error,
  totalLength,
  payloadLength
});

export const endBulkUploadCall = () => ({
    type: RESET_BULK_UPLOAD_STATE
});
