import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import {ReactComponent as ExcelSvg} from '../../../../../media/images/placeholders/SpreadSheetPlaceholder.svg'
import styles from './fileValidation.module.sass'
import Icon from "../../../../Icon";
import Switch from "../../../../Switch";
import FileUpload from "../../../../File/Others";
import ShowErrorTooltip from '../../../../CustomTooltips/ErrorsTooltip'

const FileValidation = ({className, type, onFileChange, files, fileErrors, toggle}) => {

    const onTrash = (type) => {
        if(type === 'main'){
            files.setItemFile(null)
            fileErrors.setItemFileErrors(null)
        }else if(type === 'pricing'){
            files.setPricingFile(null)
            fileErrors.setPricingFileErrors(null)
        }else if(type === 'lock'){
            files.setLockByFile(null)
            fileErrors.setLockByFileError(null)
        }
        document.getElementById('import').value = null;
    }

    return(
        <>
            <div className={styles.fileInformation}>
               <div className={styles.container}>

                    <div className={styles.fileUpload}>
                        <FileUpload placeHolder='Click or drop file' multiple={true} onChange={onFileChange} type={type} selection={toggle}/>
                    </div>

                    <div className={styles.filePreview}>
                        <div className={styles.group}>
                            {files.itemFile !== null &&
                                <>
                                    <div className={styles.files}>
                                        <div className={styles.fileIcon}>
                                            <ExcelSvg />
                                        </div>
                                        <div className={styles.fileInfo}>
                                            <div className={styles.fileName}>{files.itemFile.name}</div>
                                            <div className={styles.fileDetail}><span className={styles.format}>{files.itemFile.name.split('.').pop()}</span> <span className={styles.seprator}>|</span> <span className={styles.fileSize}>{(files.itemFile.size / (1024 * 1024)).toFixed(2)} Mb</span></div>
                                        </div>
                                        <div className={styles.actionIcons}>
                                            <div className={styles.icons} onClick={() => onTrash('main')}><Icon className={styles.icon} name='trash' size='14'/></div>
                                        </div>
                                    </div>
                                </>
                            }
                            {type !== 'Currency' && files.pricingFile !== null &&
                                <div className={styles.files}>
                                    <div className={styles.fileIcon}>
                                        <ExcelSvg />
                                    </div>
                                    <div className={styles.fileInfo}>
                                        <div className={styles.fileName}>{files.pricingFile.name}</div>
                                        <div className={styles.fileDetail}><span className={styles.format}>{files.pricingFile.name.split('.').pop()}</span> <span className={styles.seprator}>|</span> <span className={styles.fileSize}>{(files.pricingFile.size / (1024 * 1024)).toFixed(2)} Mb</span></div>
                                    </div>
                                    <div className={styles.actionIcons}>
                                        <div className={styles.icons} onClick={() => onTrash('pricing')}><Icon className={styles.icon} name='trash' size='14'/></div>
                                    </div>
                                </div>
                            }
                        </div>
                        
                            <div className={styles.errors}>
                                <div className={styles.fileError}>
                                    {fileErrors.itemFileError?.length > 0 && 
                                        <div className={styles.issues}><ShowErrorTooltip position='normal' errors={fileErrors.itemFileError}>Please Upload Valid File.</ShowErrorTooltip></div>
                                    }
                                </div>
                                {type !== 'Currency' &&
                                    <div className={styles.fileError}>
                                        {fileErrors.pricingFileErrors?.length > 0 && 
                                            <div className={styles.issues}><ShowErrorTooltip position='normal' errors={fileErrors.pricingFileErrors}>Please Upload Valid File.</ShowErrorTooltip></div>
                                        }
                                    </div>
                                }
                            </div>
                        
                            {type !== 'Currency' &&
                                <>
                                    <div className={styles.group}>
                                        {files.lockByFile !== null &&
                                            <div className={styles.files}>
                                                <div className={styles.fileIcon}>
                                                    <ExcelSvg />
                                                </div>
                                                <div className={styles.fileInfo}>
                                                    <div className={styles.fileName}>{files.lockByFile.name}</div>
                                                    <div className={styles.fileDetail}><span className={styles.format}>{files.lockByFile.name.split('.').pop()}</span> <span className={styles.seprator}>|</span> <span className={styles.fileSize}>{(files.lockByFile.size / (1024 * 1024)).toFixed(2)} Mb</span></div>
                                                </div>
                                                <div className={styles.actionIcons}>
                                                    <div className={styles.icons} onClick={() => onTrash('lock')}><Icon className={styles.icon} name='trash' size='14'/></div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className={styles.errors}>
                                        <div className={styles.fileError}>
                                            {fileErrors.lockByFileError?.length > 0 && 
                                                <div className={styles.issues}><ShowErrorTooltip position='normal' errors={fileErrors.lockByFileError}>Please Upload Valid File.</ShowErrorTooltip></div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                    </div>

               </div>
            </div>
        </>
    )
}

export default FileValidation