import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";

const Table = ({ items, title }) => {

  const desiredLength = 10;
  items = items.concat(Array(desiredLength - items.length).fill({})).slice(0, desiredLength)

  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Linked Accounts</div>
          <div className={styles.col}>Created At</div>
          <div className={styles.col}>Last Login</div>
          <div className={styles.col}></div>
        </div>
        {items && items.map((x, index) => (
          <Row item={x} key={index} index={index} value={selectedFilters.includes(x.id)} onChange={() => handleChange(x.id)} />))}
      </div>
    </div>
  );
};

export default Table;
