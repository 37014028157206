import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getFirestore,
} from "firebase/firestore"; // Import Firestore functions
import app from "../../firebase"; // Import your Firestore instance
import Card from "../../components/Card";
import cn from "classnames";
import styles from "./activityFeed.css";
import Icon from "../../components/Icon";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";

const ActivityFeed = () => {
  const [activities, setActivities] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // Stores the last document for pagination
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const db = getFirestore(app);
  const { appid } = useParams();

  // Fetch activities initially and when page changes
  useEffect(() => {
    fetchActivities(currentPage);
  }, [currentPage]);

  // Function to fetch activities based on page number
  const fetchActivities = async (pageNumber) => {
    setLoading(true);
    try {
      let q = query(
        collection(db, "activityFeed"),
        where("activity_feed", "==", true),
        where("app_id", "==", appid),
        orderBy("timestamp", "desc"),
        limit(itemsPerPage)
      );

      if (pageNumber > 1) {
        // Get lastVisible document from the previous page to handle pagination
        const lastSnapshot = await getLastDocumentForPage(pageNumber - 1);
        if (lastSnapshot) {
          q = query(
            collection(db, "activityFeed"),
            where("activity_feed", "==", true),
            where("app_id", "==", appid),
            orderBy("timestamp", "desc"),
            startAfter(lastSnapshot),
            limit(itemsPerPage)
          );
        }
      }

      const querySnapshot = await getDocs(q);
      const newActivities = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setActivities(newActivities);

      // Calculate total pages (assuming we do not have a count function available from Firestore)
      if (pageNumber === 1 && querySnapshot.docs.length > 0) {
        const allQuery = await getDocs(
          query(
            collection(db, "activityFeed"),
            where("activity_feed", "==", true),
            where("app_id", "==", appid)
          )
        );
        setTotalPages(Math.ceil(allQuery.docs.length / itemsPerPage));
      }

      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching activities: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to get the last visible document for pagination
  const getLastDocumentForPage = async (page) => {
    const q = query(
      collection(db, "activityFeed"),
      where("activity_feed", "==", true),
      where("app_id", "==", appid),
      orderBy("timestamp", "desc"),
      limit(page * itemsPerPage)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs[querySnapshot.docs.length - 1];
  };

  // Handle page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Card
        className={cn(styles.card)}
        title="Activity Feed"
        classTitle="title-blue"
        head={
          <div
            style={{
              padding: "12px",
              borderRadius: "12px",
              marginLeft: "auto",
              border: "2px solid #efefef",
            }}
          >
            <Icon name="filter" fill="#6f767e" size="24" />
          </div>
        }
      >
        <div className="activity-table" style={{ marginTop: "12px" }}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Activity</th>
                <th>Date/Member</th>
              </tr>
            </thead>
            <tbody>
              {activities?.length > 0 ? (
                activities.map((activity, index) => (
                  <tr
                    key={activity.id}
                    style={index % 2 !== 0 ? { background: "#f4f4f4" } : {}}
                  >
                    <td style={{ alignContent: "center" }}>
                      <span style={{ margin: "0 12px" }}>
                        <Icon
                          name={
                            activity?.action_type === "UPDATE"
                              ? "edit"
                              : activity?.action_type === "ARCHIVE"
                              ? "trash"
                              : activity?.action_type === "CREATE"
                              ? "file-add"
                              : "activity"
                          }
                          size="24"
                        />
                      </span>
                    </td>
                    <td>{activity.description}</td>
                    <td>
                      {new Date(activity.timestamp).toLocaleString()} <br />
                      <span
                        style={{ color: "#777", fontWeight: "500" }}
                      >
                        {activity.performed_by}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td
                    style={{
                      color: "#777",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    No Activity Available
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.pagination} style={{ marginTop: "20px", justifyItems:"center"}}>
          <Stack spacing={2}>
            <Pagination
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    "&.Mui-selected": {
                      color: "rgb(255, 255, 255)",
                      fontWeight: "bold",
                      border: "1px solid rgb(42, 133, 255)",
                      background: "rgba(42, 133, 255)",
                    },
                    "&.MuiPaginationItem-page.Mui-selected:hover": {
                      color: "rgb(255, 255, 255)",
                      fontWeight: "bold",
                      border: "1px solid rgb(42, 133, 255)",
                      background: "rgba(42, 133, 255)",
                    },
                  }}
                />
              )}
              siblingCount={1}
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </Card>
      {/* {loading && <p>Loading more activities...</p>} */}
    </>
  );
};

export default ActivityFeed;
