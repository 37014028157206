import React, { useContext, useEffect, useState } from 'react'
import bcrypt from 'bcryptjs'
import styles from './login.module.sass'
import TextInput from '../../../components/TextInput'
import Radio from '../../../components/Radio'
import {ReactComponent as GoogleSVG} from '../../../media/images/icons/google.svg'
import Icon from '../../../components/Icon'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../../redux/action/authenticate/authenticationAction'
import { ToastContainer } from 'react-toastify';
import { useNavigate, Link, redirect, useLocation } from 'react-router-dom'
import './style.css'
import Spectre from '../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as SpecterLogo} from '../../../media/images/Specter_Logo.svg'
import {ReactComponent as HalfCircle} from '../../../media/images/half_ellipse.svg'
import {ReactComponent as Cross} from '../../../media/images/hollow_crossed.svg'
import {ReactComponent as FullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../media/images/filled_cross.svg'
import FilledCrossed from '../../../media/images/fill_cross.png'
import {ReactComponent as HollowCross} from '../../../media/images/x_fill.svg'
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import Lottie from 'lottie-react'
import { jwtDecode } from 'jwt-decode'
import axios from "axios";
import GlobalStateContext from '../../../components/Context/GlobalStates/GlobalStateContext'

const SignIn = ({showLoader, setShowLoader, setLoaderType}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const globalStates = useContext(GlobalStateContext)

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

    const style = {
        width : 560,
        height : 608,
    }
    const [errors, setErrors] = useState({passwordErr : '',email:''})
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showModal, setShowModal] = useState(false)

    const onFullName = (e) => {
        setFullName(e.target.value)
    }

    const onEmail = (e) => {
        setEmail(e.target.value)
    }

    const onPassword = (e) => {
        setPassword(e.target.value)
    }

    function validateEmailAddress(emailAddress) {
        let atSymbol = emailAddress.indexOf("@");
        let dotSymbol = emailAddress.lastIndexOf(".");
        let spaceSymbol = emailAddress.indexOf(" ");
    
        if ((atSymbol != -1) &&
            (atSymbol != 0) &&
            (dotSymbol != -1) &&
            (dotSymbol != 0) &&
            (dotSymbol > atSymbol + 1) &&
            (emailAddress.length > dotSymbol + 1) &&
            (spaceSymbol == -1)) {
            return true;
        } else {
            return false;
        }
    }
      
    const validation = (data) => {
        const error = {}
        let isValid = true
        if(data.password == null || data.password == ''){
            error.passwordErr = 'Password is required'
            isValid = false
        }
        
        if(!validateEmailAddress(email)){
            error.email = 'Please enter a valid email address.'
            isValid = false
        }
        if(data.email == null || data.email == ''){
            error.email = 'Email is required'
            isValid = false
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const signIn = () => {
        let user = {
            email : email,
            password : password,
        }
        if(validation(user)){
            dispatch(loginUser(user, navigate, setShowLoader, setLoaderType, showModal, globalStates.setShowQuickGuideModal))
        }
    }

    const signInWithGoogle = async (response) => {
        const decoded = jwtDecode(response.credential)
        let user = {
            email: decoded.email,
            password: null,
            googleId: decoded.sub
        }
        dispatch(loginUser(user, navigate, setShowLoader, setLoaderType, showModal, globalStates.setShowQuickGuideModal))
    }

    const onError = (error) => {
        console.log(error, "Error")
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            signIn()
        }
    }

    const getPlanCode = () => {
        console.log(location)
        const searchParams = new URLSearchParams(location.search)
        const planCodeParam = searchParams.get('hostedpage_id')
        if(planCodeParam){
            setShowModal(true)
            localStorage.setItem('showModal', JSON.parse(true))
        }
    }

    useEffect(() => {
        getPlanCode()
    }, [location])

    return (
    <>
        <ToastContainer />
        <div className={styles.signUp}>
            <div className={styles.signUp_container_outer}>
                <div className={styles.logo}>
                    <SpecterLogo />
                </div>
                <div className={styles.signUp_container_inner}>
                    <div className={styles.Branding}>
                        <HalfCircle className={styles.halfCircle}/>
                        <Cross className={styles.crossFilled}/>
                        <FullCircle className={styles.fullCircle}/>
                        <FullHollowCircle className={styles.hollowCircle}/>
                        <div className={styles.animation}>
                            <Lottie className={styles.lottieAnimation} animationData={Spectre} loop={true} autoPlay={true} style={style} />
                        </div>
                    </div>
                    <div className={styles.form}>
                        <HollowCross className={styles.hollowCross}/>
                        <div className={styles.signInForm}>
                            <img src={FilledCrossed} className={styles.filledCross} />
                            {/* <FilledCross className={styles.filledCross}/> */}
                            <SmallerFullCircle className={styles.fullCircle}/>
                            <div className={styles.signInFormContainer}>
                                <div className={styles.formTitle}>
                                    <p className={styles.title}>Dive back into the Action<span className={styles.blueText}>!</span></p>
                                    <p className={styles.content}>Log in to your account.</p>
                                </div>

                                <div className={styles.formFiedls}>
                                    <TextInput position="bottom" errorMessage={errors.email}  className={styles.field} type="email" placeHolder="Email" currency={<Icon name="mail" size="20" />} onChange={(e)=>{onEmail(e)}} fieldName={email}/>
                                </div>

                                <div className={styles.formFiedls}>
                                    <TextInput position="bottom" errorMessage={errors.passwordErr}  className={styles.field} type="password" placeHolder="Password" currency={<Icon name="lock" size="20" />} onKeyPress={handleKeyPress} onChange={(e)=>{onPassword(e)}} fieldName={password}/>
                                </div>


                                <div className={styles.signInButton}>
                                    <button className={styles.Save} onClick={signIn}>Continue</button>
                                </div>

                                <div className={styles.forgotPassword}>
                                    <Link className={styles.links} to='/forgot-password'>Forgot Password?</Link>
                                </div>

                                <div className={styles.logInOption}>
                                    <div className={styles.sep}></div>
                                    <div className={styles.option}>or</div>
                                    <div className={styles.sep}></div>
                                </div>

                                <GoogleOAuthProvider clientId={CLIENT_ID}>
                                    <GoogleLogin width='380' height='48' onSuccess={signInWithGoogle} onError={onError} />
                                </GoogleOAuthProvider>
                                
                                {/* <div className={styles.signInGoogle} onClick={login}>
                                    <div className={styles.icon}><GoogleSVG /></div>
                                    <div className={styles.text}>Google</div>
                                </div> */}

                                <div className={styles.message}>
                                    <span>Don't have an account? <Link to='/sign-up' className={styles.links}><span>Sign up</span></Link></span>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    </>
  )
}

export default SignIn