import React, { useEffect, useState } from "react";
import cn from 'classnames'
import Card from "../../../../components/Card";
import styles from './payment.module.sass'
import { getAllPaymentAction, updateCardDetailsAction } from "../../../../redux/action/organisation/Billing/billingAction";
import { useDispatch, useSelector } from "react-redux";
import {ReactComponent as Paypal} from '../../../../media/images/icons/paypal.svg'
import {ReactComponent as Visa} from '../../../../media/images/icons/visa.svg'
import { fa } from "@faker-js/faker";
import Loader from "../../../../components/Loaders/LoaderProgress";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../../routes/routes";

const Payment = ({className, payments, items}) => {

    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const [paymentOptions, setPaymentOptions] = useState([])

    const onChangePayment = (id) => {
        setPaymentOptions(option => option && option.map(x => x.cardId === id ? {...x, status: !x.status} : {...x, status: false}))
    }

    const configurePayment = () => {
        if(payments && payments?.length > 0){
            setPaymentOptions(payments && payments.map((x, i) => ({...x, id: i, status: (i === 0 ? true : false)})))
        }
    }

    useEffect(() => {
        configurePayment()
    }, [payments])

    const onUpdatePaymentMethod = () => {
        let subscriptionId = {externalSubscriptionId: items?.subscriptionId}
        dispatch(updateCardDetailsAction(subscriptionId, Navigate))
    }

    return (
        <>
            <Card className={styles.card} classCardHead={styles.head} title={'Payment Method'} classTitle={cn("title-green", styles.title)}
                head={
                    <>
                        <div className={styles.address}>
                            <Link to={routes.OrganisationProfile.path}>Change Address</Link>
                        </div>
                    </>
                }
            >
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <div className={styles.paymentList}>
                            {paymentOptions && paymentOptions.map(x => (
                                <>
                                    <div className={cn(styles.payment, {[styles.active] : x.status})} onClick={() => onChangePayment(x.cardId)}>
                                        <div className={styles.logo}>
                                            {x.cardType === 'visa' && <><Visa /></>}
                                            {x.cardType === 'paypal' && <><Paypal /></>}
                                        </div>
                                        <div className={styles.cardDetail}>
                                            <div className={styles.cardNumber}>
                                                {"**** **** **** " + x.lastFourDigits}
                                            </div>
                                            <div className={styles.date}>
                                                <span>Exp. {x.expiryMonth}/{(x.expiryYear).toString().slice(-2)}</span>
                                            </div>
                                        </div>
                                        <div className={styles.radio}><div className={styles.select}></div></div>
                                    </div>
                                </>
                            ))}
                        </div>
                        {paymentOptions && paymentOptions.filter(x => x.status)?.length > 0 &&
                            <div className={styles.action}>
                                <button className={cn(styles.button, 'button-white-small')} onClick={onUpdatePaymentMethod}>Update Payment Method</button>
                            </div>
                        }
                    </div>
                </div>
            </Card>
        </>
    )
}

export default Payment