import React, { useEffect, useState } from "react";
import styles from './addOns.module.sass'
import classNames from "classnames";
import cn from 'classnames'
import Icon from "../../../../components/Icon";
import Checkbox from "../../../../components/Checkbox";
import { buyAddOnsAction, buyNewPlanAction, getAddOnsAction, getPlansAction, getPriceBookAction, verifyBillingAction } from "../../../../redux/action/organisation/Billing/billingAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loaders/LoaderProgress";
import BillingHeader from "../BillingHeader";

const AddOn = ({className, active, plans, priceBook, currencyType, selected}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Location = useLocation()
    const [addOn, setAddOn] = useState([])
    const [currency, setCurrency] = useState(currencyType || null)
    const [priceId, setPriceId] = useState(selected || null)

    const {id} = useParams()

    const onAddOns = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, active: !e.active, quantity: 1} : {...e}))
    }

    const onAdd = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: e.quantity + 1} : {...e, quantity: e.quantity}))
    }

    const onRemove = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: (e.quantity !== 1 ? e.quantity - 1 : 1)} : {...e, quantity: e.quantity}))
    }

    const savePlan = () => {
        if(Location.pathname.includes('/add-on')){
            let addonsDetails = {
                subscriptionId: id,
                type: "one-time",
                addonsDetails: addOn && addOn.filter(x => x.active).map(y => ({addonCode: y.addonCode, quantity: y.quantity, price: y.priceBrackets[0]?.price, addonDescription: 'x'}))
            }
            dispatch(buyAddOnsAction(addonsDetails))
        }else{
            let customerId = JSON.parse(sessionStorage.getItem('customerId'))
            let gstTreatment = JSON.parse(sessionStorage.getItem('gstTreatment'))
            let isGST = JSON.parse(sessionStorage.getItem('isGST'))
            const plan = {
                gstTreatment: gstTreatment,
                customerId: customerId,
                planDetails : plans && plans.filter(e => e.selected).map(item => ({ planCode: item.planCode, quantity: 1 }))[0],
                addonsDetails : addOn && addOn.filter(e => e.active).map(item => ({ addonCode: item.addonCode, quantity: item.quantity }))
            }
            dispatch(verifyBillingAction(plan, navigate, isGST))
        }
    }

    const getAllAddOns = () => {
        dispatch(getAddOnsAction())
    }

    const configureAddOns = () => {
        if(active){
            let updatedAddOns = addOns && addOns.map(x => {
                const matchObj = price?.pricebook_items && price?.pricebook_items.find(y => x.addonCode === y.product_code)
                return{
                    ...x,
                    price: matchObj ? matchObj.pricebook_rate : x.priceBrackets[0]?.price
                }
            })
            setAddOn(updatedAddOns && updatedAddOns.map(e => ({...e, quantity: 0, active: false})))
        }else{
            let updatedAddOns = addOns && addOns.map(x => {
                const matchObj = priceBook?.pricebook_items && priceBook?.pricebook_items.find(y => x.addonCode === y.product_code)
                return{
                    ...x,
                    price: matchObj ? matchObj.pricebook_rate : x.priceBrackets[0]?.price
                }
            })
            setAddOn(updatedAddOns && updatedAddOns.map(e => ({...e, quantity: 0, active: false})))
        }
    }

    let {addOns, isLoadingAddOns, price} = useSelector(state =>  state.billing)

    let {orgProfile} = useSelector(state => state.organisation)

    const getAllPriceBook = () => {
        let priceBook = {}
        dispatch(getPriceBookAction(priceBook))
    }

    const getSelectedPriceBook = () => {
        let priceBook = {
            id: priceId
        }
        dispatch(getPriceBookAction(priceBook))
    }

    const findSelectedPriceBook = () => {
        if(price && price !== null && Array.isArray(price)){
            if(orgProfile && orgProfile.location && orgProfile.location !== null){
                if(orgProfile.location === 'India'){
                    let priceBookId = price && price.filter(x => x.name === 'India').map(y => y.pricebook_id)[0]
                    setPriceId(priceBookId)
                    setCurrency('Rupee')
                }else{
                    let priceBookId = price && price.filter(x => x.name === 'Outside India').map(y => y.pricebook_id)[0]
                    setPriceId(priceBookId)
                    setCurrency('Dollar')
                }
            }
        }
    }

    useEffect(() => {
        if(active){
            getAllPriceBook()
        }
    }, [])

    useEffect(() => {
        if(active &&priceId !== null){
            getSelectedPriceBook()
        }
    }, [priceId])

    useEffect(() => {
        if(active){
            findSelectedPriceBook()
        }
    }, [price])

    useEffect(() => {
        getAllAddOns()
    }, [])

    useEffect(() => {
        configureAddOns()
    }, [addOns, price, priceBook, priceId])

    if(active && isLoadingAddOns){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    return(
        <>
            <div className={cn(styles.container, {[styles.active] : active})}>
                <div className={styles.billings}>
                    <div className={styles.addOns}>
                        {(active || (plans?.length > 0 && plans && plans.filter(x => x.selected && x.active)[0]?.planCode === 'Plan_04')) &&
                            <>
                                <div className={styles.title}>Add-ons</div>

                                {/* <div className={styles.specialOffer}>
                                    <div className={styles.offer}>
                                        <div className={styles.content}>
                                            <div className={styles.heading}>{addOn && addOn.filter(x => x.name === 'TaskFlow Engine Automation Overage')[0]?.name} <span className={styles.pill}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{addOn && addOn.filter(x => x.name === 'TaskFlow Engine Automation Overage')[0]?.price}</span></div>
                                            <div className={styles.description}>1 million TaskFlow Engine Automation</div>
                                        </div>
                                        <div className={styles.action}>
                                            <button className={cn(styles.button, 'button-medium')} onClick={toggleAddOn}>{toggle ? 'Deactivate' : 'Activate'}</button>
                                        </div>
                                    </div>
                                </div> */}

                                <div className={styles.addOnsList}>
                                    {addOn && addOn.map(e => (
                                        <div className={cn(styles.addOn)}>
                                            <div className={styles.addOnDetail}>
                                                <div className={styles.detail}>
                                                    <div className={styles.name}>{e.name}</div>
                                                    <div className={styles.description}>{e.description}</div>
                                                </div>
                                                <div className={styles.checkbox}>
                                                    <Checkbox size='small' value={e.active} onChange={() => {onAddOns(e.addonCode)}}/>
                                                </div>
                                            </div>
                                            <div className={styles.addOnPurchase}>
                                                <div className={styles.price}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{e.price}</div>
                                                {e.active &&
                                                    <div className={styles.quantity}>
                                                        <div className={styles.label}>Qty</div>
                                                        <div className={styles.amount}>{e.quantity}</div>
                                                        <div className={styles.operation}>
                                                            <div className={styles.add} onClick={() => onAdd(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                            <div className={styles.remove} onClick={() => onRemove(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                        <div className={styles.procced}>
                            {(active || !(plans && plans.map(x => x.current && x.selected)[0])) &&
                                <button className={cn(styles.button, 'button-small')} onClick={savePlan}>Procced</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddOn