import React, { useContext, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ConfirmNavigationModal from "../ConfirmNavigationModal";
import Modal from "../Modals/ModalComponent";
import Icon from "../Icon";
import User from "./User";
import { useEffect } from "react";
import Theme from "../Theme";
import { ReactComponent as LeftArrow } from "../../media/images/leftArrow.svg";
import AccessWarningHeader from "../AccessWarningHeader";
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";
import NotificationFeed from "./Notification"
import ActivityFeed from "./Activity"
import { routes } from "../../routes/routes";
import { ReactComponent as  Specter} from "../../media/images/Spectre.svg";
import { ReactComponent as  SpecterText} from "../../media/images/Specter_Text.svg";
import NavHeader from "../NavHeader";
import SecondaryHeader from './Navigation'
import QuickAction from "./QuickAction";

const Header = ({className, title, wide, onOpen, onClose, type, appName, setAppName, loaderType, setLoaderType, showLoader, setShowLoader, isChanged, setIsChanged}) => {

  const {appid} = useParams()

  const [visible, setVisible] = useState(false);
  const [mobile, setMobile] = useState()
  const [visibleModal, setVisibleModal] = useState(false);
  const [lnk, setLnk] = useState()

  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  useEffect(()=>{
    setMobile(isMobile)
  }, [])

  const navigation = [
    {
      title: "Currencies",
      url: `/currency/${appid}`,
    },
    {
      title: "Items",
      url: `/item/${appid}`,
    },
    {
      title: "Bundles",
      url: `/bundle/${appid}`,
    },
    {
      title: "Stores",
      url: `/store/${appid}`,
    }
  ]

  const progressionNavigation = [
    {
      title: "Progression Markers",
      url: `/progression-marker/${appid}`,
    },
    {
      title: "Progression Systems",
      url: `/progression-system/${appid}`,
    },
  ]

  const engageNavigation = [
    {
      title: "Tasks",
      url: `/task/${appid}`,
    },
    {
      title: "Missions",
      url: `/mission/${appid}`,
    },
    {
      title: "Step Series",
      url: `/step-series/${appid}`,
    }
  ]

  const competitionNavigation = [
    {
      title: 'Tournaments',
      url : `/competition-tournament/${appid}`
    },
    {
      title : 'Instant-Battles',
      url : `/competition-instant-battle/${appid}`
    }
  ]
  
  const appSettingsNavigation = [
    {
      title : 'App Information',
      url : `/app-information/${appid}`
    },
    {
      title: "Media",
      url: `/app-media/${appid}`,
    },
    {
      title : 'Members',
      url : `/app-members/${appid}`
    },
    {
      title : 'API',
      url : `/app-api/${appid}`
    }
  ]

  const LiveOpsNavigation = [
    {
      title : 'Competitions',
      url : `/competition-schedule/${appid}`
    },
    {
      title : 'Calendar',
      url : `/calendar/${appid}`
    }
  ]

  const ProfileSettingsNavigation = [
    {
      title : 'Organization',
      url : `/settings-organization-profile`
    },
    {
      title : 'Member',
      url : `/settings-member-profile`
    }
  ]

  const Navigate = useNavigate();
  const { pathname } = useLocation();

  const NavigateTo = (e) => {
      if(e.target.value == 1){
        Navigate('/currency')
      }else if(e.target.value == 2){
        Navigate('/item')
      }else if(e.target.value == 3){
        Navigate('/bundle')
      }else if(e.target.value == 4){
        Navigate('/reward-set')
      }else{
        Navigate('/')
      }
  }

  const NavTo = (x) => {
    if (isChanged==true) {
      setLnk(x)
      setVisibleModal(true);
    } else {
      Navigate(x)
    }
  }

  const onCls = () => {
    setVisibleModal(false)
    setLnk()
  }

  const onProceed = () => {
    setVisibleModal(false)
    Navigate(lnk)
    setIsChanged(false)
  }

  const onBack = () => {
    if(appid){
      Navigate(`/app-overview/${appid}`)
    }else{
      Navigate(routes.Dashboard.path)
    }
  }

  const loaderConfigure = (loaderType) => {
    setLoaderType(loaderType)
    setShowLoader(true)
    setTimeout(() => {
        setShowLoader(false)
    }, 4000)
  }

  const resetEnvironment = () => {
    localStorage.setItem('environment', 'Development')
    loaderConfigure(5)
    onClose()
  }

  return (
    <>
      <header className={cn(styles.header, className, {[styles.wide]: wide})}>
        <div className={styles.orgLogo} onClick={resetEnvironment}>
          <Link className={styles.logo} to="/home" onClick={onClose}>
            <SpecterText className={styles.pic}/>
          </Link>
        </div>
        <div className={styles.mainHeader}>
          <div className={styles.headContent}>
            <div className={styles.control} >
              <QuickAction className={styles.quickAction} />
            </div>
            
            <div className={styles.control} onClick={() => setVisible(false)}>
              <NotificationFeed className={styles.user} />
            </div>
            {(window.location.pathname.includes(`/${appid}`))?
              <>
              <div className={styles.control} onClick={() => setVisible(false)} style={{marginLeft:"0px"}}>
                <ActivityFeed className={styles.user} />
              </div>
              </>
            :
            ''
            }
            {(window.location.pathname.includes('/app-information/')|| window.location.pathname.includes('/app-media/') || window.location.pathname.includes('/activityFeed/')|| window.location.pathname.includes('/app-members/')||window.location.pathname.includes('/app-documents/')|| window.location.pathname.includes('/app-api/'))?
              <>
                <button className={styles.headHelp} onClick={()=>NavTo(`/app-overview/${appid}`)}>
                  <Icon name={"arrow-left"} size={18}/>
                </button>
              </>
            :(window.location.pathname.includes(`/${appid}`)&&!(window.location.pathname.includes('/app-information/')|| window.location.pathname.includes('/app-media/')|| window.location.pathname.includes('/activityFeed/')||window.location.pathname.includes('/app-documents/')||window.location.pathname.includes('/app-members/')|| window.location.pathname.includes('/app-api/')))?
              <>
                <button className={styles.headHelp} onClick={()=>NavTo(`/app-information/${appid}`)}>
                  <Icon name={"setting"} size={16}/>
                </button>
              </>
            :
            ''
            }
            
            <div className={styles.headContent}>
              <div className={styles.control} onClick={() => setVisible(false)}>
                <User className={styles.user} />
              </div>
            </div>
          </div>
        </div>
        <Modal visible={visibleModal} onClose={() => onCls()}>
          <ConfirmNavigationModal onProceed={()=>onProceed()} onClose={() => onCls()}/>
        </Modal>
      </header>
      <NavHeader type={type} wide={wide} loaderType={loaderType} setLoaderType={setLoaderType} appName={appName} setAppName={setAppName} showLoader={showLoader} setShowLoader={setShowLoader}/>
      {(window.location.pathname.includes('/create') || window.location.pathname.includes('/edit') || window.location.pathname.includes('/view') || window.location.pathname.includes('/app-overview') || window.location.pathname.includes('/terminal') || window.location.pathname.includes('/env-information')) &&
        <SecondaryHeader title={title} />
      }
      {/* {window.location.pathname.includes('/view') &&
        <AccessWarningHeader />
      } */}
    </>
  );
};

export default Header;
