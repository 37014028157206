import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
//import Control from "./Control/index";
import { useParams } from "react-router-dom";
import {ReactComponent as Add} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import Items from '../../../../../media/images/placeholders/Items.png'
import Currencies from '../../../../../media/images/placeholders/Currency.png'
import Bundles from '../../../../../media/images/placeholders/Bundles.png'
import PMS from '../../../../../media/images/placeholders/Progression_Marker.png'
import { useEffect } from "react";
import CurrencyTooltip from '../../../../CustomTooltips/CurrencyTooltip'
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({tableFor,fullList,type,amountKey, item, items, index,value, setValue, setData,categoryIndex }) => {
    
    const {appid} = useParams()
    
    const addContent = (item, index) => {
        let activeType = type=="Item"?'items':type=="Currency"?'currencies':type=="Bundles"?'bundles':type=="Progression Marker"?'progressionMarkers':''
        setValue((prev)=>{
            let rnks =[...prev]
            let rnkRewards=prev[categoryIndex]?.rewardDetails?.[activeType]
            let newContent = {...item}
            newContent.status=true
            newContent.rewardStatus='add'
            newContent.archive=false
            if (amountKey) {
                newContent[amountKey]=null
            } else {
                newContent.amount=null
            }
            let newRewards=[]
            newRewards = [...rnkRewards, newContent];
            rnks[categoryIndex].rewardDetails[activeType]=newRewards
            return rnks
        })
    }

    const RemoveContent = (item, id) => {
        let activeType = type=="Item"?'items':type=="Currency"?'currencies':type=="Bundles"?'bundles':type=="Progression Marker"?'progressionMarkers':''
            setValue((prev)=>{
                let rnks =[...prev]
                let rnkRewards=prev[categoryIndex].rewardDetails?.[activeType]
                let remainingRewards = rnkRewards && rnkRewards.map(e => {
                    if(e.uuid && e.uuid === id){
                        return{...e, status: false, archive: true, rewardStatus: 'remove'}
                    }else if(!e.uuid && e.id === id){
                        return{...e, status: false, archive: true, rewardStatus: 'remove'}
                    }else{
                        return{...e}
                    }
                })
                rnks[categoryIndex].rewardDetails[activeType]=remainingRewards
                return rnks
            })
    }

    const onInputChange = (e,id) => {
        let activeType = type=="Item"?'items':type=="Currency"?'currencies':type=="Bundles"?'bundles':type=="Progression Marker"?'progressionMarkers':''
        setValue((prev)=>{
            let rnks =[...prev]
            let rnkRewards= prev[categoryIndex]?.rewardDetails?.[activeType]
            let updatedRewards = rnkRewards && rnkRewards.map(content => {
                if(content.uuid){
                    if (content.uuid === id) {
                        if (amountKey) {
                            if (content.hasOwnProperty(amountKey)) {
                                console.log(parseInt(content[amountKey]) , parseInt(e.target.value))
                                content[amountKey] = parseInt(e.target.value)
                                content.rewardStatus = (content?.rewardStatus === 'add' ? 'add' : 'update')
                                return content;
                            } else {
                                return { ...content, [amountKey]: parseInt(e.target.value), rewardStatus: (content?.rewardStatus === 'add' ? 'add' : 'update')};
                            }
                            
                        } else {
                            if (content.hasOwnProperty('amount')) {
                                content.amount = parseInt(e.target.value)
                                content.rewardStatus = (content?.rewardStatus === 'add' ? 'add' : 'update')
                                return content;
                            } else {
                                return { ...content, amount: parseInt(e.target.value), rewardStatus: (content?.rewardStatus === 'add' ? 'add' :'update')};
                            }
                            
                        }
                    }
                    return content;
                }else if(!content.uuid){
                    if (content.id === id) {
                        if (amountKey) {
                            if (content.hasOwnProperty(amountKey)) {
                                content[amountKey] = parseInt(e.target.value)
                                console.log(parseInt(content[amountKey]) , parseInt(e.target.value))
                                content.rewardStatus = (content?.rewardStatus === 'add' ? 'add' : 'update')
                                return content;
                            } else {
                                return { ...content, [amountKey]: parseInt(e.target.value), rewardStatus: (content?.rewardStatus === 'add' ? 'add' : 'update')};
                            } 
                        } else {
                            if (content.hasOwnProperty('amount')) {
                                content.amount= parseInt(e.target.value)
                                content.rewardStatus = (content?.rewardStatus === 'add' ? 'add' : 'update' )
                                return content;
                            } else {
                                return { ...content, amount: parseInt(e.target.value), rewardStatus: (content?.rewardStatus === 'add' ? 'add' : 'update')};
                            } 
                        }
                    }
                    return content;
                }
            });
            rnks[categoryIndex].rewardDetails[activeType] = updatedRewards
            return rnks
        })
    }

    useEffect(()=>{

    }, [item])

    return (
        <>
            <div className={styles.row}>
                {/* {show? */}
                {item.status !== false ?
                    <div className={styles.col}><Remove className={styles.addIcon} onClick={()=>{RemoveContent(item, item.id)}}/></div>
                    :
                    <div className={styles.col}><Add className={styles.addIcon} onClick={()=>{addContent(item, index)}}/></div>
                }
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.modalPreview}>
                            <img src={item.iconUrl != null ? item.iconUrl : (item.iconUrl === null && type === 'Item') ? Items : (item.iconUrl === null && type === 'Currency') ? Currencies : (item.iconUrl === null && type === 'Bundles') ? Bundles : (item.iconUrl === null && type === 'Progression Marker') ? PMS : null} alt="Item" />
                        </div>
                    </div>
                </div>
                <div className={styles.col}><div className={styles.modalItemName}>{item.name}</div><div className={styles.modalItemId}>{item.itemId}</div></div>
                {type=='Currency'&&
                    <div className={styles.col}>
                        <div className={item.type == 'real' ? styles.real : styles.virtual }>{item.type == 'real' ? "RMG" : item.type == 'virtual' ? "Virtual" : ""}</div>
                    </div>
                }
                <div className={styles.col}><div className={styles.rewardsInput}><input disabled={item.status == false ? true : false} key={item.id} className={styles.amount} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={items[index].status == false ? '' :amountKey? items[index][amountKey]:items[index].amount} onChange={(e)=>{onInputChange(e, item.id)}}/></div></div>
            </div>
        </>
    );
};

export default Row;
