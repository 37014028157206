import React, {useState, useEffect} from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../../components/Radio'
import styles from './inventory.module.sass'
import CollectionTabelLayout from '../../../../../components/EngageComponents/PlayersComponent/CollectionTable'
import IneventoryTableLayout from '../../../../../components/EngageComponents/PlayersComponent/IneventoryTable'
import moment from 'moment'
import { useParams } from 'react-router-dom';
import { getPlayerDetailAction } from '../../../../../redux/action/engage/Players/PlayersAction';
import { useDispatch, useSelector } from 'react-redux';
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import InventoryFilter from './InventoryFilter'
import cn from 'classnames'

const valueTabs = ["Summary", "Raw Inventory"]

const Inventory = ({className, access, activeIndex}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)

    const getPlayerDetails = async() => {
        let playerDetail = {
            entities : {
                type : (active == 0 ? 'all' : active == 1 ? 'items' : 'bundles'),
                collectionId : itemId,
                value : (value == 0 ? 'inventory-summary' : 'inventory-raw'),
                offset: ((page-1)*limit),
                limit: limit
            },
            projectId : appid,
            ids : [id]
        }
        await dispatch(getPlayerDetailAction(playerDetail, activeIndex))
    }

    
    const handlePageChange = (event, value)  => {
        setPage(value)
    }

    const playerInventoryCollection = useSelector((state) => {
        return state.players.inventoryCollection
    })

    const playerInventoryHistory = useSelector((state) => {
        return state.players.inventoryHistory
    })

    const playerInventoryOverview = useSelector((state) => {
        return state.players.inventoryOverview
    })

    let totalCount = useSelector((state) => {
        return state.players.totalInventoryCount
    })

    const [isCalled, setIsCalled] = useState(false)

    const configureCollection = () => {
        if(playerInventoryCollection?.length > 0){
            setItemOption(playerInventoryCollection && playerInventoryCollection.map((e) => ({...e, id: e.collectionId , name: e.collectionId})))
            setItem(playerInventoryCollection[0].collectionId)
            setItemId(playerInventoryCollection[0].collectionId)
            setIsCalled(true)
        }
    }

    useEffect(() => {
        if(!isCalled){
            configureCollection()
        }
    }, [playerInventoryCollection])

    const [value, setValue] = useState(0)
    const [itemOptions, setItemOption] = useState()
    const [item, setItem] = useState()
    const [itemId, setItemId] = useState('')
    const tabs = ["All", "Items", "Bundles"]
    const [active, setActive] = useState(0);

    
    useEffect(()=>{
        getPlayerDetails()
    }, [activeIndex, page, itemId, active, value])

    return (
        <>
            <Card title="Overview" classTitle="title-purple">
                <div className={styles.overview}>
                    <div className={styles.groups}>
                        <TextInput className={styles.field} value={playerInventoryOverview && playerInventoryOverview.length > 0 ? playerInventoryOverview[0].totalSize : '-'} disabled label='Total Size' name="totalSize" type="text" />
                    </div>

                    <div className={styles.groups}>
                        <TextInput className={styles.field} value={playerInventoryOverview && playerInventoryOverview.length > 0 ? playerInventoryOverview[0].numberOfCollections : '-'} disabled label='No of Collections' name='collection' type='text'/>
                        <TextInput className={styles.field} value={playerInventoryOverview && playerInventoryOverview.length > 0 && playerInventoryOverview[0].latestUpdate != null ? moment(playerInventoryOverview[0].latestUpdate).format('DD-MM-YYYY hh:mm A') : '-'} disabled label='Last Updated' name='lastUpdated' type='text' />
                    </div>    
                </div>
            </Card>
            <Card title="Collections" classTitle="title-red">
                {playerInventoryCollection && playerInventoryCollection.length > 0 ?
                    <CollectionTabelLayout items={playerInventoryCollection} />
                : <div className={styles.warning}>No Data Available</div>}
            </Card>
            <Card title="Inventory Content" classTitle="title-purple" head={
                <>
                    <InventoryFilter setPage={setPage} tabs={tabs} active={active} setActive={setActive} options={itemOptions} value={item} setValue={setItem} setId={setItemId} />
                </>
            }>
                <div className={styles.tableWrapper}>
                    <div className={styles.group}>
                        <div className={styles.toggle}>
                            {valueTabs && valueTabs.map((x, index) => (
                                <button className={cn(styles.link, { [styles.active]: index === value,})} onClick={() => {setValue(index); setPage(1)}} key={index}>
                                <p>{x}</p>
                                </button>
                            ))}
                        </div>
                        {playerInventoryHistory.length > 0 ?
                           <>
                                <IneventoryTableLayout type={value} items={playerInventoryHistory} />
                                <div className={styles.pagination}>
                                    <Stack spacing={2}>
                                        <Pagination renderItem={(item) => (
                                            <PaginationItem
                                                {...item}
                                                sx={{
                                                    '&.MuiPaginationItem-root': {
                                                      minWidth: '28px',
                                                      height: '28px',
                                                      fontSize: '12px'
                                                    },
                                                    '&.Mui-selected': {
                                                      color: 'rgb(255, 255, 255)',
                                                      fontWeight : 'bold',
                                                      border : '1px solid rgb(42, 133, 255)',
                                                      background : 'rgba(42, 133, 255)'
                                                    },
                                                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                      color: 'rgb(255, 255, 255)',
                                                      fontWeight : 'bold',
                                                      border : '1px solid rgb(42, 133, 255)',
                                                      background : 'rgba(42, 133, 255)'
                                                    }
                                                }}
                                            />
                                        )} page={page} siblingCount={1} count={Math.ceil(totalCount/limit)} color="primary" onChange={handlePageChange} />
                                    </Stack>
                                </div>
                           </>
                        : <div className={styles.warning}>No Data Available</div>}
                    </div>
                </div>
            </Card>
        </>
    )
}

export default Inventory