import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./multiselect.module.sass";
import Tooltip from "../../../../../../../components/Tooltip";
import Icon from "../../../../../../../components/Icon";

const Dropdown = ({className, disabled, classDropdownHead, classDropdownLabel, classCheckboxTick, value, setValue, error, placeholder, options, label, tooltip, small, upBody}) => {

  const [visible, setVisible] = useState(false);

  const handleClick = (selectedOption) => {
    const val=[...value]
    if(val.some(e => e.name === selectedOption.name)){
      val.map((e)=>{
        if (e.name === selectedOption.name) {
          if (e.archive !== undefined) {
            if (e.archive == false) {
              e.archive = true
              // e.lockedLevel = null
              setValue(val)
            } else {
              e.archive = false
              setValue(val)
            }
          }else {
            setValue(value.filter((values) => values.name !== selectedOption.name));
          }
        }
      })
    }else{
      setValue([...value, selectedOption]);
      if(error){
        error(false)
      }
    }
  };
  // setValue(value.filter((values) => values.name !== selectedOption.name));
  const removeOption = (selectedOption) => {
    const val=[...value]
    if(val.some(e => e.name === selectedOption.name)){
      val.map((e)=>{
        if (e.name === selectedOption.name) {
          if (e.archive!==undefined) {
            e.archive=true
            // e.lockedLevel=null
            setValue(val);
          } else {
            setValue(value.filter((values) => values.name !== selectedOption.name));
          }
        }
      })
      setValue(val);
    }else{
      setValue([...value, selectedOption]);
    }
  }



  <div>
    {value && value.length > 0 ? (
        value.some(el => !el.archive) ? (
            value.map((el, index) => 
                !el.archive ? <div key={index}>{el.name}</div> : null
            )
        ) : (
            <span className={styles.placeholder}>Select Item</span>
        )
    ) : (
        <span className={styles.placeholder}>No Items</span>
    )}
</div>



  return (
    <div className={styles.main}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
        </div>
      )}
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.dropdown, className,{ [styles.small]: small },{ [styles.active]: visible},{[styles.disabled] : disabled})}>
        <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(true)}>
          <div className={styles.selection}>
          {value && value.length > 0 ? (
              value.some(el => !el.archive) ? (
                <>
                {value.filter(el => !el.archive).slice(0, 2).map((el, index) => (
                    <span className={styles.pills}>{el.name}<span className={styles.remove} onClick={()=>{removeOption(el)}}><Icon name='close' size='12' /></span></span>
                ))}
                {value.filter(el => !el.archive).length > 2 && (
                    <span>{value.length > 2 ? <span>&nbsp;&#43;&nbsp;{value.length-2}</span>  : ''}</span>
                )}
            </>
              ) : (
                <span className={styles.placeholder}>{placeholder}</span>
                )
            ) : (
              <span className={styles.placeholder}>{placeholder}</span>
            )}
          </div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody }, {[styles.disabled] : disabled})}>
          {options.map((x, index) => (
            <div className={cn(styles.option)} key={index}>
                <label className={cn(styles.checkbox)} onChange={() => handleClick(x, index)} checked={value.some(e => e.name === x.name && !e.archive)}>
                    <input className={styles.input} type="checkbox" checked={value.some(e => e.name === x.name && !e.archive)}/>
                    <span className={styles.inner}>
                        <span className={cn(styles.tick, classCheckboxTick)}></span>
                        <span className={styles.optionLabel}>{x.name}</span>
                    </span>
                </label>
            </div>
          ))}
        </div>
      </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
