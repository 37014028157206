import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import {ReactComponent as ExcelSvg} from '../../../../../media/images/placeholders/SpreadSheetPlaceholder.svg'
import styles from './fileUploadSelection.module.sass'
import Icon from "../../../../Icon";
import Switch from "../../../../Switch";

const FileUploadSelection = ({className, type, toggle, setToggle, onNext}) => {

    const handleToggle = (key) => {
        setToggle(prevState => ({...prevState, [key]: !prevState[key]}))
    }

    return(
        <>
            <div className={styles.fileInformation}>
                <div className={cn(styles.container, className, {[styles.type]: (type === 'Currency')})}>
                    <div className={styles.notice}>
                        {type !== 'Currency' &&
                            <>
                                <div className={styles.note}>
                                    <p>Dria is able to turn any files into vector embeddings for you to make them understandable by Als. In that manner, while uploading CVs we recommend you to consider the nature of vector embeddings to contribute in a more efficient way.</p>
                                </div>
                                
                                <div className={styles.fileSelection}>
                                    <div className={styles.title}>Files to be Uploaded</div>
                                    <div className={styles.files}>
                                        <div className={styles.selection}><div>{type}</div> <Switch value={toggle.main} /></div>
                                        <div className={styles.selection}><div>Pricing</div> <Switch value={toggle.pricing} onChange={() => handleToggle('pricing')}/></div>
                                        <div className={styles.selection}><div>Lock By</div><Switch value={toggle.lockBy} onChange={() => handleToggle('lockBy')}/></div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className={styles.typeSelection}>
                            <div className={styles.title}>Append/Override</div>
                            <div className={styles.group}>
                                <div className={styles.files}>
                                    <div className={styles.title}>Append</div>
                                    <div className={styles.info}>
                                        <ul className={styles.list}>
                                            <li className={styles.pointers}>Dria is able to turn any files into vector embeddings for you to make them understandable by Als. In that manner, while uploading CVs we recommend you to consider the nature of vector embeddings to contribute in a more efficient way.</li>
                                            <li className={styles.pointers}>Dria is able to turn any files into vector embeddings for you to make them understandable by Als. In that manner, while uploading CVs we recommend you to consider the nature of vector embeddings to contribute in a more efficient way.</li>
                                        </ul>
                                    </div>
                                    {/* <div className={styles.options}>
                                        <div className={styles.button} onClick={() => onNext('Append')}>Append</div>
                                    </div> */}
                                </div>
                                <div className={styles.files}>
                                    <div className={styles.title}>Override</div>
                                    <div className={styles.info}>
                                        <ul className={styles.list}>
                                            <li className={styles.pointers}>Dria is able to turn any files into vector embeddings for you to make them understandable by Als. In that manner, while uploading CVs we recommend you to consider the nature of vector embeddings to contribute in a more efficient way.</li>
                                            <li className={styles.pointers}>Dria is able to turn any files into vector embeddings for you to make them understandable by Als. In that manner, while uploading CVs we recommend you to consider the nature of vector embeddings to contribute in a more efficient way.</li>
                                        </ul>
                                    </div>
                                    {/* <div className={styles.options}>
                                        <div className={styles.button} onClick={() => onNext('Override')}>Override</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.toggle}>
                        <div className={styles.options}>
                            <div className={styles.button} onClick={() => onNext('Append')}>Append</div>
                        </div>
                        <div className={styles.options}>
                            <div className={styles.button} onClick={() => onNext('Override')}>Override</div>
                        </div>
                    </div>
               </div>
            </div>
        </>
    )
}

export default FileUploadSelection