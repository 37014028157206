import React, { useState, useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./multiselect.module.sass";
import Tooltip from "../../Tooltip";
import CustomTooltip from "../../CustomTooltips/ReusableTooltip";
import Icon from "../../Icon";
import Tooltips from "../../Tooltip";

const Dropdown = ({ className, disabled, id, errorMessage, classDropdownHead, classDropdownLabel, classCheckboxTick, value, setValue, placeholder, options, label, tooltip, place, small, upBody }) => {

  const [visible, setVisible] = useState(false);
  const [filterText, setFilterText] = useState('');

  const handleClick = (selectedOption) => {
    if (value.some(e => e.name === selectedOption.name)) {
      setValue(value.filter((values) => values.name !== selectedOption.name));
    } else {
      setValue([...value, selectedOption]);
    }
    setFilterText('');
  };

  const removeOption = (selectedOption) => {
    if (value.some(e => e.name === selectedOption.name)) {
      setValue(value.filter((values) => values !== selectedOption));
    } else {
      setValue([...value, selectedOption]);
    }
  }

  const onDropDownClick = (event) => {
    setVisible(true)
      if(document.getElementById('inputFocus')){
          document.getElementById('inputFocus').focus()
      }
  }

  const filteredOptions = options&& options.filter(option =>
    option.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const inputStyle = {
    background: "#FCFCFC",
    color: "#6F767E", 
    height: "30px",
    fontSize: "12px",
    fontWeight: "inherit",
    width: "inherit",
  };

  return (
    <div className={styles.main} id={id}>
      {label && (
        <div className={styles.groupLabel}>
          <div className={cn(styles.label, classDropdownLabel)}>
            {label}
            {tooltip && (
              <Tooltips
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place={place ? place : "right"}
              />
            )}
          </div>
          <div className={styles.error}>
            {(value && value.length === 0) && errorMessage}
          </div>
        </div>
      )}
      <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(''); }}>
        <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
          <div className={(errorMessage && value && value.length === 0) ? cn(styles.head, styles.head_error, classDropdownHead) : cn(styles.head, classDropdownHead)} onClick={() => onDropDownClick()}>
            <div className={styles.selection}>
              {value.length > 0 ?
                <>
                  <div className={styles.valueContainer}>
                    {value && value.slice(0, 3).map((e, i) => {
                      return (
                        <span className={styles.pills} key={i}>{e.name}<span className={styles.remove} onClick={!disabled ? () => { removeOption(e) } : null}><Icon name='close' size='12' /></span></span>
                      )
                    })}
                  </div>
                  <CustomTooltip text="Genre" data={value}><span className={styles.tooltipValues}>{value.length > 3 ? <span>&nbsp;&#43;&nbsp;{value.length - 3}</span> : ''}</span></CustomTooltip>
                </>
                : <input
                  id="inputFocus"
                  type="text"
                  style={{ ...inputStyle, marginTop: "4px",width:"100%" }}
                  placeholder={placeholder}
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              }
              {value.length > 0 && (
                <input
                  id="inputFocus"
                  type="text"
                  style={{ ...inputStyle, marginLeft: "10px" }}
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              )}
            </div>
          </div>
          {!disabled && visible && (
            <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
              {filteredOptions.map && filteredOptions.map((x, index) => (
                <div className={cn(styles.option)} key={index}>
                  <label className={cn(styles.checkbox, className)} onChange={() => handleClick(x, index)} checked={value.some(e => e.name === x.name)}>
                    <input className={styles.input} type="checkbox" checked={value.some(e => e.name === x.name)} onChange={() => handleClick(x, index)}/>
                    <span className={styles.inner}>
                      <span className={cn(styles.tick, classCheckboxTick)}></span>
                      <span className={styles.optionLabel}>{x.name}</span>
                    </span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
