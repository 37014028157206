import React, { useEffect } from "react";
import styles from './billing.module.sass'
import Plans from "./Plans";
import BillingHistory from "./BillingHistory";
import BillingCycle from "./BillingCycle";
import Payment from "./Payments";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loaders/LoaderProgress";
import { getAllPaymentAction, getInvoiceAction, getMyPlanAction } from "../../../redux/action/organisation/Billing/billingAction";

const Pricing = () => {

    const dispatch = useDispatch()

    const getMyPlan = () => {
        let customerId = sessionStorage.getItem('customerId')
        if(customerId !== null && customerId !== undefined){
            let userDetail = {
                externalCustomerId: JSON.parse(customerId)
            }
            dispatch(getMyPlanAction(userDetail))
        }
    }

    const getAllPayments = () => {
        let customerId = sessionStorage.getItem('customerId')
        if(customerId !== null && customerId !== undefined){
            let customer = {
                customerId: JSON.parse(customerId)
            }
            dispatch(getAllPaymentAction(customer))
        }
    }

    const getAllBillingHistory = () => {
        let customerId = sessionStorage.getItem('customerId')
        if(customerId !== undefined){
            let customer = {
                customerId: JSON.parse(customerId)
            }
            dispatch(getInvoiceAction(customer))
        }
    }

    let {myPlan, payment, invoices, isLoadingPlans, isLoadingPayments, isLoadingInvocies} = useSelector((state) => state.billing)

    useEffect(() => {
        getMyPlan()
        getAllPayments()
        getAllBillingHistory()
    }, [])

    if(isLoadingPlans || isLoadingPayments || isLoadingInvocies){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    return(
        <>
            <div className={styles.wrapper}>
            <Plans className={styles.card} myPlan={myPlan}/>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Payment payments={payment} items={myPlan}/>
                </div>
                <div className={styles.col}>
                    <BillingCycle items={myPlan}/>
                </div>
            </div>
            <BillingHistory className={styles.card} items={invoices}/>
            </div>
        </>
    )

}

export default Pricing