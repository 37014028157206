import React, { useEffect, useState } from "react";
import styles from './billing.module.sass'
import { ReactComponent as  Cube} from "../../../../media/images/icons/cube.svg";
import { ReactComponent as  Star} from "../../../../media/images/icons/Star.svg";
import { ReactComponent as  Crown} from "../../../../media/images/icons/crown.svg";
import { ReactComponent as  Thunder} from "../../../../media/images/icons/thunder.svg";
import classNames from "classnames";
import cn from 'classnames'
import Icon from "../../../../components/Icon";
import Checkbox from "../../../../components/Checkbox";
import AddOn from "../AddOns";
import { useDispatch, useSelector } from "react-redux";
import { getPlansAction, getPriceBookAction } from "../../../../redux/action/organisation/Billing/billingAction";
import {ReactComponent as Circle} from '../../../../media/images/smaller_circle.svg'
import {ReactComponent as OutlineCircle} from '../../../../media/images/full_hollow_circle.svg'
import Cross from '../../../../media/images/fill_cross.png'
import {ReactComponent as OutlineCross} from '../../../../media/images/hollow_crossed.svg'
import {ReactComponent as HalfCircle} from '../../../../media/images/half_ellipse.svg'
import {ReactComponent as FullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../../media/images/filled_cross.svg'
import Loader from "../../../../components/Loaders/LoaderProgress";
import BillingHeader from "../BillingHeader";

const Billing = ({className}) => {

    const dispatch = useDispatch()

    const [currentIndex, setCurrentIndex] = useState(0)
    const itemPerView = 3
    const [selectedPlan, setSelectedPlan] = useState('')
    const [allPlans, setAllPlans] = useState([])
    const [addOn, setAddOn] = useState([])
    const [currency, setCurrency] = useState(null)
    const [priceId, setPriceId] = useState(null)

    const onPlanChange = (id) => {
        setAllPlans(plans => plans && plans.map(e => e.planCode === id ? {...e, selected: true, active: true} : {...e, selected: false, active: false}))
    }

    const getSelectedPlan = () => {
        let planCode = sessionStorage.getItem('plan_code')
        setSelectedPlan(planCode && planCode !== undefined ? JSON.parse(planCode) : null)
    }

    const getAllPlans = () => {
        dispatch(getPlansAction())
    }

    const getAllPriceBook = () => {
        let priceBook = {}
        dispatch(getPriceBookAction(priceBook))
    }

    const getSelectedPriceBook = () => {
        let priceBook = {
            id: priceId
        }
        dispatch(getPriceBookAction(priceBook))
    }

    const configurePlans = () => {
        if(plans?.length > 0 && price && price?.pricebook_id && priceId !== null){
            let activePlanCode = sessionStorage.getItem('active_plan_code')
            let currentPlan = null
            if(activePlanCode !== null && activePlanCode !== undefined){
                currentPlan = plans && plans.filter(x => x.planCode === JSON.parse(activePlanCode)).map(x => ({price: x.recurringPrice, code: x.planCode}))[0]
            }
            let updatedPlans = plans && plans.map(x => {
                const matchObj = price?.pricebook_items && price?.pricebook_items.find(y => x.planCode === y.product_code)
                return{
                    ...x,
                    recurringPrice: matchObj ? matchObj.pricebook_rate : x.recurringPrice
                }
            })
            let currentPrice = updatedPlans && updatedPlans.map(x => {
                if(currentPlan && currentPlan.code === x.planCode){
                    return x.recurringPrice
                }
                return null
            }).filter(x => x !== null)[0]
            let plansList = updatedPlans && updatedPlans.map(e => ({...e, description: (e.description ? e.description : 'Best for Indie Developer'), period: e.intervalUnit, features: (e.featureDetails && e.featureDetails.features && e.featureDetails.features.map(x => x.name)), current: ((currentPlan !== null && currentPlan?.code !== null && currentPlan?.code === e.planCode) ? true : false), active: false, selected: (e.planCode === selectedPlan ? true : false)}))
            // setAllPlans(plansList && plansList.filter(x => x.recurringPrice >= (currentPlan !== null && currentPlan?.price !== null ? currentPlan?.price : 0)).sort((a, b) => a.recurringPrice - b.recurringPrice))
            setAllPlans(plansList && plansList.filter(x => x.recurringPrice >= (currentPlan !== null && currentPrice !== null ? currentPrice : 0)).sort((a, b) => a.recurringPrice - b.recurringPrice))
        }
    }

    const findSelectedPriceBook = () => {
        let country = sessionStorage.getItem('location')
        if(price && price !== null && Array.isArray(price)){
            if(JSON.parse(country) === 'India'){
                let priceBookId = price && price.filter(x => x.name === 'India').map(y => y.pricebook_id)[0]
                setPriceId(priceBookId)
                setCurrency('Rupee')
            }else{
                let priceBookId = price && price.filter(x => x.name === 'Outside India').map(y => y.pricebook_id)[0]
                setPriceId(priceBookId)
                setCurrency('Dollar')
            }
        }
    }

    let {isLoadingPlans, plans, price} = useSelector(state => state.billing)

    let {orgProfile} = useSelector(state => state.organisation)

    useEffect(() => {
        getAllPlans()
        getSelectedPlan()
        getAllPriceBook()
    }, [])

    useEffect(() => {
        configurePlans()
    }, [plans, selectedPlan, priceId])

    useEffect(() => {
        findSelectedPriceBook()
    }, [price])

    useEffect(() => {
        if(priceId !== null){
            getSelectedPriceBook()
        }
    }, [priceId])

    const onNext = () => {
        if(currentIndex < allPlans?.length - itemPerView){
            setCurrentIndex(currentIndex + 1)
        }
    }

    const onPrev = () => {
        if(currentIndex > 0){
            setCurrentIndex(currentIndex - 1)
        }
    }

    if(isLoadingPlans){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    return(
        <>
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <OutlineCross className={styles.crossLeftMiddle}/>
                    <FullCircle className={styles.fullCircle}/>
                    <OutlineCross className={styles.crossRightTop}/>
                    <OutlineCross className={styles.crossRightBottom}/>
                    <FullCircle className={styles.circleBottom}/>
                    <OutlineCircle className={styles.bottomOutline} />
                    <div className={styles.intercept}>
                        <Circle className={styles.overlapCircle} />
                        <OutlineCircle className={styles.overlapOutlineCircle} />
                    </div>
                    <img src={Cross} width={22} height={22} className={styles.leftFilledCross}/>
                    <img src={Cross} width={22} height={22} className={styles.rightTopFilledCross}/>
                    <img src={Cross} width={22} height={22} className={styles.rightBottomFilledCross}/>
                </div>
                <div className={styles.container}>
                    <div className={styles.billings}>
                        <div className={styles.title}>Plans</div>
                        <div className={styles.billingContainer}>
                            <div className={styles.plans}>
                                {allPlans && allPlans.slice(currentIndex, currentIndex + itemPerView).map((e, index) => {
                                    return(
                                        <>
                                            <div key={index} className={cn(styles.planContainer, classNames, {[styles.active] : e.selected})} onClick={() => {onPlanChange(e.planCode)}}>
                                                <div className={styles.planWrapper}>
                                                    <div className={styles.planDetails}>
                                                        <div className={styles.planName}>
                                                            <div className={styles.identifier}>{e.name === 'Starter Plan' ? <Crown /> : <Thunder />}</div>
                                                            <div className={styles.name}>{e.name}</div>
                                                        </div>
                                                        <div className={styles.planDescription}>
                                                            {e.description}
                                                        </div>
                                                        <div className={styles.planPricing}>
                                                            <span className={styles.price}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{e.recurringPrice}</span><span className={styles.period}> / {e.period}</span>
                                                        </div>
                                                        <div className={styles.features}>
                                                            <div className={styles.title}>Feature</div>
                                                            <div className={styles.feature}>
                                                                {e.features && e.features.map(x => {
                                                                    return(
                                                                        <>
                                                                            <div className={styles.points}>
                                                                                <div className={styles.cube}>
                                                                                    <Cube />
                                                                                </div>
                                                                                <div className={styles.detail}>{x}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                            <div className={styles.link}>See all Features</div>
                                                        </div>
                                                        <div className={styles.action}>
                                                            <div className={styles.save}>{e.current ? 'Current Plan' : e.selected ? 'Selected Plan' : 'Choose Plan'}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            {allPlans?.length > 3 &&
                                <div className={styles.control}>
                                    <button className={styles.prev} onClick={onPrev} disabled={currentIndex === 0}>&lt;</button>
                                    <button className={styles.next} onClick={onNext} disabled={currentIndex >= allPlans.length - itemPerView}>&gt;</button>
                                </div>
                            }
                        </div>
                        <AddOn active={false} plans={allPlans} currencyType={currency} priceBook={price} selected={priceId}/>
                    </div>
                </div>  
            </div>
        </>
    )
}

export default Billing