import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../../components/Modals/ModalComponent";
import Icon from "../../../../../../../components/Icon";
import {ReactComponent as Arrow} from '../../../../../../../media/images/icons/Union.svg'
import CompetitionFilterMoadl from "../../../../../../../components/Filters/Engage/Competitions"
import Sort from '../../../../../../../components/Sort'

const Control = ({className, setPage, value, setValue, data, sort, setSort, sortIndex, setSortIndex, selected, setSelected, filters, action, type, width}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <div className={cn(styles.control, className)}>
        <div className={cn(styles.buttons, className, { [styles.active]: ((filters.source.length + filters.priceType.length + filters.status.length + filters.instanceStatus.length > 0) || filters.isPrizeConfigured !== null || filters.isEntryPriced !== null || (filters.maxEntryAllowed !== null && filters.maxEntryAllowed !== '') || (filters.maxAttemptAllowed !== null && filters.maxAttemptAllowed !== '') || filters.startDate !== '' || filters.endDate !== '' || (filters.recurrance !== null && filters.recurrance !== '') || filters.recurranceIntervalId !== null)})} onClick={() => {setVisibleModal(true)}}>
          <Icon name='filter' size='20' className={styles.icon} />
          <span className={styles.buttonText}>Filter  {((filters.source.length + filters.priceType.length + filters.status.length + filters.instanceStatus.length > 0) || filters.isPrizeConfigured !== null || filters.isEntryPriced !== null || (filters.maxEntryAllowed !== null && filters.maxEntryAllowed !== '') || (filters.maxAttemptAllowed !== null && filters.maxAttemptAllowed !== '') || filters.startDate !== '' || filters.endDate !== '' || filters.recurranceIntervalId !== null) && <span className={styles.countPill}>{filters.source.length + filters.priceType.length + filters.status.length  + filters.instanceStatus.length + (filters.isPrizeConfigured !== null ? 1 : 0) + (filters.isEntryPriced !== null ? 1 : 0) + ((filters.maxEntryAllowed !== null && filters.maxEntryAllowed !== '') ? 1 : 0) + ((filters.maxAttemptAllowed !== null && filters.maxAttemptAllowed !== '') ? 1 : 0) + (filters.startDate !== '' ? 1 : 0) + (filters.endDate !== '' ? 1 : 0) + (filters.recurranceIntervalId !== null ? 1 : 0)}</span>}</span>
        </div>
        {/* <Sort setPage={setPage} value={value} setValue={setValue} data={data} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selected} setSelected={setSelected} sort={sort} setSort={setSort}>
          <div className={cn(styles.buttons, className, { [styles.active]: (value?.name !== null)})}>
            <span className={styles.sortIcon}>
              <Arrow className={cn(styles.up, { [styles.active]: (value?.value !== null && value?.value === 0)})}/>
              <Arrow className={cn(styles.down, { [styles.active]: (value?.value !== null && value?.value === 1)})}/>
            </span>
            <span className={styles.buttonText}>
              {value?.name ? (value?.name === 'updated_at' ? 'Updated At' : value?.name === 'created_at' ? 'Created At' : value?.name == 'name' ? 'Mission Name' : '') : 'Sort'}
            </span>
          </div>
        </Sort> */}
      </div>
      <Modal heading={true} width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <CompetitionFilterMoadl type={type} action={action} filters={filters} onClose={() => setVisibleModal(false)} />
      </Modal>
    </>
  );
};

export default Control;
