import React, {useState} from 'react'
import cn from 'classnames'
import styles from './getCommunity.module.sass'
import { Link, useParams } from 'react-router-dom'
import addIcon from '../../../media/images/icons/light.png'
import Toast from '../../../components/Toast'
import { ToastContainer, toast } from 'react-toastify'
import './toast.css'
import 'react-toastify/dist/ReactToastify.css';
import EnvironmentSwitchTooltip from '../../../components/CustomTooltips/EnvironmentSwitchTooltip'
import { BASE_URL } from '../../../resources/APIEndpoints'
import Skeleton from '../../../components/Skeleton/TableSkeleton'
import Card from '../../../components/Card'
import Modal from '../../../components/Modals/ModalComponent'
import Loader from '../../../media/lottieAnimation/Specter_Animation.json'
import Lottie from 'lottie-react'

const GetCommunity = ({className}) => {

    const {appid} = useParams()

    const [visibleModal, setVisibleModal] = useState(false);

    const showToast = () => {
        toast.success(<Toast type='Success' messages={null}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#DEF1E5'}})
        toast.error(<Toast type='Error' messages={null}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#FF6A55'}})
        toast.warning(<Toast type='Warning' messages={null}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#FFD88D'}})
        toast.info(<Toast type='Info' messages={null}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#B1E5FC'}})

        toast.success(<Toast type='Success' messages={null}/>, {position : 'top-left', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#00D59C'}})
        toast.error(<Toast type='Error' messages={null}/>, {position : 'top-left', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#FF6A55'}})
        toast.warning(<Toast type='Warning' messages={null}/>, {position : 'top-left', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#FFD944'}})
        toast.info(<Toast type='Info' messages={null}/>, {position : 'top-left', icon: false, hideProgressBar : true, autoClose: 15000, style : {background : '#3188FB'}})
    }

    const [environmentOption, setEnvironmentOption] = useState([{id : 1, name : 'Production', url: BASE_URL},{id : 3, name : 'Staging', url: BASE_URL},{id : 2, name : 'Development', url: BASE_URL}])
    const [environment, setEnvironment] = useState()

    const style = {
      width : '160px',
      height : '160px'
  }

    const showLoader = () => {
     setVisibleModal(true)
     setTimeout(() => {
      setVisibleModal(false)
     }, 9000)
    }
  
   return (
    <>
        <ToastContainer />
        <div className={styles.wrapper}>

          <div className={styles.test}>
            <Link className={cn("button", styles.button)} onClick={showToast}>Show Toast</Link>
          </div>

          <div className={styles.test}>
            <EnvironmentSwitchTooltip type='detail' appName={`League of Legends`} data={environmentOption}>
              <Link className={cn("button", styles.button)}>Show Tooltip</Link>
            </EnvironmentSwitchTooltip>
          </div>

          <div className={styles.test}>
            <Link className={cn("button", styles.button)} onClick={showLoader}>Show Loader</Link>
          </div>
        </div>


        <Card>
          <Skeleton rows={1} column={6} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'circular']} colWidth={[36, 110, 110, 110, 110, 40]} colHeight={[36, 15, 25, 25, 25, 40]} />
        </Card>

        <Modal width='fullWidth' className={styles.fullWidth} visible={visibleModal}>
          <Lottie className={styles.loader} animationData={Loader} loop={true} autoPlay={true} style={style} />
        </Modal>
    </>
  )
}

export default GetCommunity