import {GET_PLAYERS, GET_PLAYER_TASK, GET_PLAYERS_REWARDS, GET_PLAYERS_WALLET, GET_PLAYER_DATA, GET_PLAYER_INVENTORY, GET_PLAYER_MATCHES, GET_PLAYER_PROFILE, GET_PLAYER_PROGRESSION, CREATE_CUSTOM_PLAYER, CREATE_PLAYER_PAYOUT, GRANT_PM, EDIT_PLAYERS_PROFILE, FILTER_PLAYERS_SUCCESS, GET_PLAYERS_SUCCESS} from "../../../actionTypes/actionTypes";
import PlayerServices from '../../../../services/engageServices/Players/PlayerServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";

export const getAllPlayersAction = (body) => {
    return async (dispatch) => {
        dispatch(getPlayers(null, null, GET_PLAYERS))
        await PlayerServices.getPlayers(body)
        .then(res=>{
            dispatch(getPlayers(res.data.data.userDetails, res.data.data.totalCount, GET_PLAYERS_SUCCESS))
        }).catch(err=>{
            console.log(err)
        })
    }
}

export const filterPlayerAction = (body) => {
    return async (dispatch) => {
        await PlayerServices.getPlayers(body)
        .then(res=>{
            dispatch(filterPlayer(res.data.data.userDetails, res.data.data.totalCount, FILTER_PLAYERS_SUCCESS))
        }).catch(err=>{
            console.log(err)
        })
    }
}

export const getPlayerDetailAction = (body, type) => {
    return async (dispatch) => {
        await PlayerServices.getPlayerDetails(body)
        .then(res=>{
            if(type == 0){
                dispatch(getPlayerDetails(res.data.data, null, GET_PLAYER_PROFILE))
            }else if(type == 1){
                dispatch(getPlayerDetails(res.data.data, null, GET_PLAYER_DATA))
            }else if(type == 2){
                dispatch(getPlayerDetails(res.data.data, res.data.data.totalUserInventories, GET_PLAYER_INVENTORY))
            }else if(type == 3){
                dispatch(getPlayerDetails(res.data.data, res.data.data.totalWalletTransactions, GET_PLAYERS_WALLET))
            }else if(type == 4){
                dispatch(getPlayerDetails(res.data.data, res.data.data.totalProgressionHistory, GET_PLAYER_PROGRESSION))
            }else if(type == 6){
                dispatch(getPlayerDetails(res.data.data, res.data.data.totalRewards, GET_PLAYERS_REWARDS))
            }else if(type == 7){
                dispatch(getPlayerDetails(res.data.data, res.data.data.totalMatches, GET_PLAYER_MATCHES))
            }else if(type == 5){
                dispatch(getPlayerDetails(res.data.data, res.data.data.totalTasks, GET_PLAYER_TASK))
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}

export const editPlayerProfileAction = (body, appid, id, setEditForm, index) => {
    return async (dispatch) => {
        await PlayerServices.editPlayerProfile(body)
        .then(res=>{
            if(setEditForm){
                setEditForm(false)
            }
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let getData = {
                entities: {
                    value : 'profile',
                    offset : 0,
                    limit : 100
                },
                projectId : appid,
                ids : [id],
            }
            setTimeout(()=>{
                dispatch(getPlayerDetailAction(getData, index))
            }, 1000)
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
            throw error;
        })
    }
}

export const createCustomSignupAction = (body, Navigate, appid) => {
    return async (dispatch) => {
        await PlayerServices.createCustomPlayer(body)
        .then(res=>{
            let getPlayerData = {
                offset: 0,
                limit: 10,
                projectId : appid
            }
            dispatch(getAllPlayersAction(getPlayerData))
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                Navigate(`/player-details/${appid}/${res.data.data.user.id}`)
            }, 700)
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
            throw error;
        })
    }
}

export const createUserPayoutAction = (body, appid, id, index) => {
    return async (dispatch) => {
        try {
            const res = await PlayerServices.createPayout(body);

            // Dispatching any further actions if the payout was successful
            toast.success(
                <Toast type="Success" messages={res.data.message} />,
                { position: 'top-right', icon: false, hideProgressBar: true, autoClose: 2000, style: { background: '#DEF1E5' } }
            );

            let getData = {
                entities: {
                    value: 'wallets',
                    offset: 0,
                    limit: 100,
                },
                projectId: appid,
                ids: [id],
            };
            dispatch(getPlayerDetailAction(getData, 3));

            return res; // Return the response so it can be used by the caller
        } catch (error) {
            // Show error toasts for each error message
            if (error.response?.data?.errors) {
                error.response.data.errors.forEach((err) => {
                    toast.error(
                        <Toast type="Error" messages={err.message} />,
                        { position: 'top-right', icon: false, hideProgressBar: true, autoClose: 2000, style: { background: '#FAE8E7' } }
                    );
                });
            }

            throw error; // Re-throw the error so it can be caught by the caller
        }
    };
};


export const grantPMAction = (body, appid, id) => {
    return async (dispatch) => {
        try {
            const res = await PlayerServices.grantPM(body);
            
            // Show success toast
            toast.success(
                <Toast type='Success' messages={res.data.message} />,
                { position: 'top-right', icon: false, hideProgressBar: true, autoClose: 2000, style: { background: '#DEF1E5' } }
            );

            // Update player detail action
            let getData = {
                entities: {
                    value: 'progression',
                    offset: 0,
                    limit: 100
                },
                projectId: appid,
                ids: [id],
            };
            dispatch(getPlayerDetailAction(getData, 4));
            
            return res; // Return the response so it can be used by the caller
        } catch (error) {
            // Show error toasts
            if (error.response?.data?.errors) {
                error.response.data.errors.forEach((err) => {
                    toast.error(
                        <Toast type='Error' messages={err.message} />,
                        { position: 'top-right', icon: false, hideProgressBar: true, autoClose: 2000, style: { background: '#FAE8E7' } }
                    );
                });
            }

            // Re-throw the error so it can be caught by the calling function
            throw error;
        }
    };
};


// export const resetPlayerPasswordAction = (body) => {
//     return async (dispatch) => {
//         await PlayerServices.resetPlayerPassword(body)
//         .then(res => {
//             toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
//         }).catch(error => {
//             error.response.data.errors && error.response.data.errors.map((err,i)=>{
//                 toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
//             })
//         })
//     }
// }
export const resetPlayerPasswordAction = (body) => {
    return async (dispatch) => {
        try {
            const res = await PlayerServices.resetPlayerPassword(body);

            // Display success toast message
            toast.success(
                <Toast type="Success" messages={res.data.message} />,
                {
                    position: "top-right",
                    icon: false,
                    hideProgressBar: true,
                    autoClose: 2000,
                    style: { background: "#DEF1E5" },
                }
            );

            return res; // Return the response to be used by the caller, if needed
        } catch (error) {
            // Show error toasts for each error message
            if (error.response?.data?.errors) {
                error.response.data.errors.forEach((err) => {
                    toast.error(
                        <Toast type="Error" messages={err.message} />,
                        {
                            position: "top-right",
                            icon: false,
                            hideProgressBar: true,
                            autoClose: 2000,
                            style: { background: "#FAE8E7" },
                        }
                    );
                });
            }

            throw error; // Re-throw the error so it can be caught by the caller
        }
    };
};

export const getPlayers = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getPlayerDetails = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const editPlayerProfile = (data) => {
    return {
        type : EDIT_PLAYERS_PROFILE,
        payload : data
    }
}

export const createCustomPlayer = (data) => {
    return {
        type : CREATE_CUSTOM_PLAYER,
        payload : data
    }
}

export const createPayout = (data) => {
    return {
        type : CREATE_PLAYER_PAYOUT,
        payload : data
    }
}

export const grantPM = (data) => {
    return {
        type : GRANT_PM,
        payload :data
    }
}

export const filterPlayer = (data, count, type) => {
    return {
        type : type,
        payload :data
    }
}