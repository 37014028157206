import React, {useState, useEffect, Children, useContext} from 'react';
import {Route, Redirect, Navigate, useParams, useLocation} from 'react-router-dom';
import propTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {getLoggedInUser} from '../redux/action/authenticate/authenticationAction'
import Page from '../components/Page';
import GlobalStateContext from '../components/Context/GlobalStates/GlobalStateContext';
import Forbidden from '../screens/Error/Forbidden';

const PrivateRoute = ({ children }) => {

    const GlobalStates = useContext(GlobalStateContext)
    const {appid} = useParams()
    const location = useLocation()
    const [appControl, setAppControl] = useState()
    const [userType, setUserType] = useState()

    let user = useSelector((state)=>{ 
        return state.auth.user
    })

    let authUser = useSelector((state)=>{ 
        return state.auth.isLoggedIn
    })

    useEffect(() => {
        GlobalStates.setAccess(true)
    }, [location])

    const userConfig = user.memberAccessConfig && user.memberAccessConfig.filter((obj) => (obj.projectId === appid))

    return (authUser && user.type == 'admin') ? children : 
    (authUser && user.type == 'invited_member' && (appid == undefined && userConfig?.length == 0)) ? children :
    (authUser && user.type == 'invited_member' && (appid && userConfig?.length > 0)) ? children :
    (authUser && user.type == 'invited_member' && (appid && userConfig?.length == 0)) ? <Forbidden /> : 
    <Navigate to="/" />;

}

PrivateRoute.propTypes={
    auth:propTypes.object.isRequired,
}

const mapStateToProps=state=>({
    auth:state.auth
})

export default connect(mapStateToProps)(PrivateRoute);