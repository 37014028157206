import {FILTER_GAMES_SUCCESS, GET_GAMES, GET_GAMES_SUCCESS, GET_GAMES_ERROR, ADD_GAMES, ADD_GAMES_SUCCESS, ADD_GAMES_ERROR, EDIT_GAMES_SUCCESS, EDIT_GAMES_ERROR, GET_ALL_GAMES, GET_ALL_GAMES_SUCCESS, GET_ALL_GAMES_ERROR, FILTER_GAMES_ERROR} from '../../../actionTypes/actionTypes'

const initialState = {
    games : [],
    isLoading : false,
    isFiltered: false,
    error : null,
    totalCount : null,
    gameList : [],
    isGameListFetched : false,
    isGameListError : false
};

export const gameReducer = (state=initialState, action)=>{
    switch(action.type){

        case GET_GAMES : {
            return{
                ...state,
                isLoading: true
            }
        }

        case GET_GAMES_SUCCESS : {
            return {
                ...state,
                games : action.payload,
                isLoading : false,
                isFiltered: false,
                totalCount : action.totalCount,
                errors: null
            }
        }

        case GET_GAMES_ERROR : {
            return {
                ...state,
               isLoading : false,
               error : action.payload,
            }
        }

        case GET_ALL_GAMES : {
            return {
                ...state,
                isGameListFetched : false,
                isGameListError : null,
            }
        }

        case GET_ALL_GAMES_SUCCESS : {
            return {
                ...state,
                gameList : action.payload,
                isGameListFetched : true,
                isGameListError : null,
            }
        }

        case GET_ALL_GAMES_ERROR : {
            return {
                ...state,
                isGameListFetched : false,
                isGameListError : action.payload,
            }
        }

        case FILTER_GAMES_SUCCESS : {
            return {
                ...state,
                games : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_GAMES_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case ADD_GAMES_SUCCESS : {
            return {
                ...state,
                games : [...state.games, action.payload],
            }
        }

        case ADD_GAMES_ERROR : {
            return {
                ...state,
               error : action.payload,
            }
        }

        case EDIT_GAMES_SUCCESS : {
            const updatedGames = state.games && state.games.map((games) =>
                games.id === action.payload.id ? action.payload : games
            );
            return {
                ...state,
                games : updatedGames
            }
        }

        case EDIT_GAMES_ERROR : {
            return {
                ...state,
               error : action.payload,
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   