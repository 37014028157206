import React from 'react'
import styles from './emptyState.module.sass'
import { Link } from 'react-router-dom'
import cn from "classnames";
import { Skeleton } from '@mui/material'

const SkeletonEmptyState = ({route, type, apps, setAppName, setShowLoader}) => {

  return (
    <>
      <div className={styles.emptyStateContainer}>
        <div className={styles.emptyStateContainerInner}>
          <div className={styles.emptyStateSvg}>
            <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={300} height={300}/>
          </div>

            <div className={styles.emptyStateContent}>
                <div className={styles.heading}><Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={300} height={28}/></div>
                <div className={styles.content}><Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={400} height={20}/></div>
            </div>
            <div className={styles.emptyStateButtonGroup}>
              <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={160} height={40}/>
            </div>
        </div>
      </div>
    </>
  )
}

export default SkeletonEmptyState