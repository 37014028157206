import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"
import Modal from "../../../Modals/ModalComponent";
import EditMemberPermissionModal from "../../../AppSettingComponent/MemberComponent/EditMemberPermissionModal";

const MemberPermissionDropdown = ({className, disabled, to, id, type, classLabel, classDropdownHead, classDropdownLabel, value, setValue, permission, setPermission, options, label, small, upBody, tooltip}) => {

    const [visible, setVisible] = useState(false);
    const [visiblePermission, setVisiblePermission] = useState(false)
    const [modalValue, setModalValue] = useState(value)
    const [modalId, setModalId] = useState(permission)

    const handleClick = (value) => {
        if(type == 'table'){
            setModalValue(value.name)
            setModalId(value.id)
            setVisiblePermission(true)
        }else{
            setValue(value.name)
            setPermission(value.id)
            setVisible(false)
        }
    };

      
    return (
        <div className={type == 'modal' ? styles.dropdownModal : type == 'table' ? styles.tableDropdown : styles.groupDropdown}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: (visible && !disabled)})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                <div className={styles.selection}>{value}</div>
                </div>
                {!disabled &&
                    <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                        {options && options.map((x, index) => (
                            <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                                {x.name}
                            </div>
                        ))}
                    </div>
                }
            </div>
            </OutsideClickHandler>
            <Modal visible={visiblePermission} onClose={()=>{setVisiblePermission(false)}}>
                <EditMemberPermissionModal to={to} id={id} modalValue={modalValue} modalId={modalId} setPermission={setValue} setPermissionId={setPermission} onClose={()=>{setVisiblePermission(false)}} />
            </Modal>
        </div>
    )
}

export default MemberPermissionDropdown