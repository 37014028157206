import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import Control from "./Control";
import cn from "classnames";

const Row = ({className, item, key, index }) => {


    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div>{item.invoiceNumber}</div>
                    <div className={styles.email}>{item.email ? item.email : '-'}</div>
                </div>
                <div className={styles.col}>{item.currencySymbol ? item.currencySymbol : ''} {item.total}</div>
                <div className={styles.col}>{item.invoiceDate}</div>
                <div className={styles.col}>
                    <div className={cn(styles.pills, {[styles.active] : (item.status === 'paid')}, {[styles.pending] : (item.status === 'Pending'),}, {[styles.failed] : (item.status === 'Failed')})}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</div>
                </div>
                <div className={styles.col}>
                    <Control className={styles.control} item={item}/>
                </div>
            </div>
        </>
    );
};

export default Row;
