import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import TextArea from '../../../../../../components/TextArea'
import CommonDropdown from '../../../../../../components/MultiselectDropDown/CommonDropdown'
import Radio from '../../../../../../components/Radio';
import Tooltip from "../../../../../../components/Tooltip";
import styles from './stepSeriesInfo.module.sass'
import Control from '../Control';
import Schedule from '../Schedule';
import StepSeriesTableLayout from '../../../../../../components/EngageComponents/AchievementComponent/StepSeriesComponent/TableComponent'
import { getAllCurrencyAction } from '../../../../../../redux/action/builds/economy/currency/currencyAction';
import { getAllItemAction } from '../../../../../../redux/action/builds/economy/items/itemAction';
import { getAllBundlesAction } from '../../../../../../redux/action/builds/economy/bundles/bundleAction';
import { getAllProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import SS from '../../../../../../media/images/placeholders/Step_Series.png'
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as AddRuleIcon} from '../../../../../../media/images/icons/Rule_Add_SVG.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import { getAllProgressionSystemAction } from '../../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction';
import { createStepSeriesAction } from '../../../../../../redux/action/engage/Achievement/StepSeries/StepSeriesAction';
import Toast from '../../../../../../components/Toast';
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../../components/MetaInput';
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
import { BASE_URL, GET_PROGRESSION_SYSTEM } from '../../../../../../resources/APIEndpoints';
import customAxios from '../../../../../../utils/interceptor';
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
// Static Data


const StepSeriesInfo = ({className,onClose, setIsChanged}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
    const ref = useRef()

    // StepSeriesInfo Informations

    const placeholder = SS
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [disable, setDisable] =  useState(false)
    const [iconUrl, setIconUrl] = useState()
    const [name, setName] = useState('')
    const [stepSeriesId, setStepSeriesId] = useState('')
    const [description, setDescription] = useState()

    // Access and Eligibility
    const [PSOptions, setPSOptions] = useState([])
    const [lockPSErr, setLockPSErr] = useState(false)
    const [lockBy, setLockBy] = useState(false)
    const [PS, setPS] = useState([])

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setIconUrl(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setIconUrl(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onChangeName = (e) => {
        if(e.target.value == ''){
            setName('')
        }else{
            errors.nameError = ''
            setName(e.target.value)
        }
    }

    const onChangeId = (e) => {
        if(e.target.value == ''){
            setStepSeriesId('')
        }else{
            errors.idError = ''
            setStepSeriesId(e.target.value)
        }
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    // Access and Eligibility

    useEffect(() => {
        getPS()
    }, [])

    // let progressionSystem = useSelector((state) => {
    //     return state.progressionSystem.progressionSystem
    // })

    // useEffect(() => {
    //     configPS()
    // }, [progressionSystem])

    // const configPS = () => {
    //     setPSOptions(progressionSystem)
    // }

    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }

    // Custom Data

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]); 
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
    
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }


    const [errors, setErrors] = useState({nameError : '', idError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
          error.nameError = 'Display Name is required';
          isValid = false;
          const element = document.getElementById('ssName');
          const offset = 140;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.taskGroupId == '') {
          error.idError = 'Step Series ID is required';
          isValid = false;
          if(data.name != ''){
            const element = document.getElementById('ssId');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }
    
    const [id, setId] = useState(null)
    const db = getFirestore(app);
    const submit = async (visibleModal, setVisibleModal, setId) => {

        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({archive: false, id: e.id, unlockItemId : null, levelSystemId : e.id, level : e.lockedLevel})
        })

        if (lockBy === false) {
            unlockPS = []
        }

        let unlockItemData = [...unlockPS]
        
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let eventTags = []
        tags && tags.forEach((e)=>{
            eventTags.push(e.text)
        })

        let taskDetails = JSON.parse(sessionStorage.getItem('step-series'))
        const stepSeriesData = taskDetails && taskDetails.map((e,i) => ({...e, sortingOrder : i+1, tags: e.tagsMapping.map(item => item.tag.name)}))
        const filteredData = stepSeriesData && stepSeriesData.map(({tagsMapping, ...rest}) => ({...rest}))

        let stepSeries = {
            typeId: 2,
            projectId : appid,
            name : name,
            taskGroupId : stepSeriesId,
            description : description,
            iconUrl : iconUrl,
            isLockedByLevel : lockBy,
            ...(lockBy == true ? {levelDetails : unlockPS} : {levelDetails : []}),
            tags: eventTags,
            meta : finalMetaData,
            taskDetails : filteredData,
            linkedRewardDetails: []
        }
        if(validation(stepSeries)){
            try {
                // Await the response from createGameAction
                if(visibleModal){
                    await dispatch(createStepSeriesAction(stepSeries, navigate, appid, 'step-series', setDisable, visibleModal, setVisibleModal, setId))
                    setIsChanged(false)
                }else{
                    await dispatch(createStepSeriesAction(stepSeries, navigate, appid, 'step-series', setDisable))
                    setIsChanged(false)
                }
                // Access the response data (e.g., game ID)
                    // const IdFromResponse = res?.data?.data?.id;

                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "STEP SERIES",
                        // resource_id: IdFromResponse, // Use the ID from response
                        description: `Step Series '${name}' created successfully.`,
                        quantity: 1,
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName,
                        request_json:JSON.stringify(stepSeries),
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
                    console.log("Activity logged successfully.");
                
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }

        }
    }

    const cancel = () => {
        navigate(`/step-series/${appid}`)
        setIsChanged(false)
    }

    // Rewards


        const [allCurrency, setAllCurrency] = useState([])
        const [currencyPage, setCurrencyPage] = useState(1)
        const [currencyLimit, setCurrencyLimit] = useState(8)

        const getAllCurrencyData = () => {
            let getAllCurrency = {
                projectId : appid,
                offset: ((currencyPage-1)*currencyLimit),
                limit: currencyLimit
            }
            dispatch(getAllCurrencyAction(getAllCurrency))
        }

        useEffect(() => {
            getAllCurrencyData()
        }, [currencyPage])
    
        const customizeCurrencyData = () => {
            if(currenciesData && currenciesData.length > 0){
                setAllCurrency(currenciesData && currenciesData.map(value => ({...value, status : false, quantity : null})))
            }
        }
    
        let currenciesData = useSelector((state) => {
            return state.currencies.currency
        })
    
        useEffect(()=>{
            customizeCurrencyData()
        }, [currenciesData])
    
        const [allItems, setAllItems] = useState([])
        const [itemPage, setItemPage] = useState(1)
        const [itemLimit, setItemLimit] = useState(8)
        
        const getAllItemsData = () => {
            let items = {
                projectId : appid,
                offset: ((itemPage-1)*itemLimit),
                limit: itemLimit
            }
            dispatch(getAllItemAction(items))
        }
    
        let itemsData = useSelector((state) => {
            return state.item.items
        })

        useEffect(() => {
            getAllItemsData()
        }, [itemPage])
    
    
        const customizeItemData = () => {
            if(itemsData && itemsData.length > 0){
                setAllItems(itemsData && itemsData.map(value => ({...value, status : false, quantity : null})))
            }
        }
    
        useEffect(() => {
            customizeItemData()
        }, [itemsData])
    
        const [allBundle, setAllBundle] = useState([])
        const [bundlePage, setBundlePage] = useState(1)
        const [bundleLimit, setBundleLimit] = useState(8)
    
        const getAllBundlesData = () => {
            let bundles = {
                projectId : appid,
                offset: ((bundlePage-1)*bundleLimit),
                limit: bundleLimit
            }
            dispatch(getAllBundlesAction(bundles))
        }
    
        let bundleData = useSelector((state) => {
            return state.bundles.bundles
        })

        useEffect(() => {
            getAllBundlesData()
        }, [bundlePage])
    
        const customizeBundleData = () => {
            if(bundleData && bundleData.length > 0){
                setAllBundle(bundleData && bundleData.map(value => ({...value, status : false, quantity : null})))
            }
        }
    
        useEffect(() => {
            customizeBundleData()
        }, [bundleData])
    
    
        const [allPM, setAllPM] = useState([])
        const [pmPage, setPMPage] = useState(1)
        const [pmLimit, setPMLimit] = useState(8)
    
        const getAllPMData = () => {
            let progressionMarkers = {
                projectId : appid,
                ids : [],
                offset: ((pmPage-1)*pmLimit),
                limit: pmLimit
            }
            dispatch(getAllProgressionMarkerAction(progressionMarkers))
        }
    
        let pmData = useSelector((state)=>{
            return state.progressionMarkers.progressionMarker
        })
    
        useEffect(() => {
            getAllPMData()
        }, [pmPage])

        const customizePMData = () => {
            if(pmData && pmData.length > 0){
                setAllPM(pmData && pmData.map(value => ({...value, status : false, quantity : null})))
            }
        }
    
        useEffect(() => {
            customizePMData()
        }, [pmData])
    
        const [content, setContent] = useState([])

        const [taskRewards, setTaskRewards] = useState([])

        const storeTaskRewards = () => {
            sessionStorage.setItem('task-rewards', JSON.stringify(taskRewards))
        }
    
        const storeContent = () => {
            sessionStorage.setItem('contents', JSON.stringify(content))
        }

        const [stepSeries, setStepSeries] = useState([])

        const storeStepSeries = () => {
            sessionStorage.setItem('step-series', JSON.stringify(stepSeries))
        }
    
        useEffect(()=>{
            
        }, [content, stepSeries, taskRewards])
    
        useEffect(()=> {
            getAllCurrencyData()
            getAllItemsData()
            getAllBundlesData()
            getAllPMData()
            storeContent()
            storeStepSeries()
            storeTaskRewards()
        }, [])

    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])


    const setchange = () => {
         
        if (
            (iconUrl==null||iconUrl==undefined)&&
            (name===''||name==="")&&
            (stepSeriesId===''||stepSeriesId==="")&&
            (description===undefined||description==''||description=="")&&
            (lockBy==false)&&
            (PS.length==0)&&
            (stepSeries.length==0)&&
            (tags.length==0)&&
            (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }
    // const [query, setQuery] = useState({combinator : 'and', rules : [{field : '', value : {value : null, type : false, allTime : true, noOfRecords : null}, operator : ''}]})
    useEffect(()=>{
        setchange()
    },[iconUrl,name,stepSeriesId,description,PS,lockBy,stepSeries,tags,metaData])


    return(
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>   
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {iconUrl != null && 
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        }
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={iconUrl} setIcon={setIconUrl} setValue={setLogo} onClose={onClose} />
                    <div className={styles.infoGroup}>
                        <div className={styles.group}>
                            <TextInput tooltip={TooltipTitle.SSName} id='ssName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required onChange={(e)=>{onChangeName(e)}} />
                            <TextInput isId={true} value={stepSeriesId} tooltip={TooltipTitle.SSID} id='ssId' className={styles.field} errorMessage={errors.idError} label="Step Series ID*" name="itemId" type="text" required onChange={(e)=>{onChangeId(e)}} />
                        </div>

                        <div className={styles.textArea}>
                            <TextArea value={description} tooltip={TooltipTitle.SSDescription} className={styles.field} rows={5} label="Step Series Description" onChange={(e)=>{onChangeDescription(e)}} />
                        </div>
                    </div>
                </div>
            </Card>
            <Card className={cn(styles.card, className)} title="Steps" classTitle="title-green"
                head={
                    <div className={styles.accessEligibility}>
                        <div className={styles.groupHeading}>
                            <Control pageItem={itemPage} pageCurrnecy={currencyPage} pageBundle={bundlePage} pagePM={pmPage} type='Task' width='medium' currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} itemPage={setItemPage} bundle={allBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} PM={allPM} setPM={setAllPM} PMCount={pmLimit} PMPage={setPMPage} setValue={setTaskRewards} setStepSeries={setStepSeries}/>
                        </div>
                    </div>
                }
            >
                {stepSeries && stepSeries.length > 0 &&
                    <div className={styles.cardDescription}>
                            <div>
                                <StepSeriesTableLayout usedFor='StepSeries' as='add' currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} itemPage={setItemPage} bundle={allBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} PM={allPM} setPM={setAllPM} PMCount={pmLimit} PMPage={setPMPage} setValue={setTaskRewards} items={stepSeries} setStepSeries={setStepSeries} type='list' />
                            </div>
                    </div>
                }
            </Card>

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by Level<span><Tooltip className={styles.tooltip} title={TooltipTitle.SSLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} value={lockBy} name="lockBy" onChange={() => setLockBy(true)} content="Yes" />
                                    <Radio className={styles.radio} value={!lockBy} name="lockBy" onChange={() => setLockBy(false)} content="No" />
                                </div>
                            </div>
                            {lockBy &&
                                <div className={styles.field}>
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.SSPS} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    
                    {lockBy &&
                        <div className={styles.accessEligibility}>
                            <div className={styles.group}>
                                {PS && PS.map((element, index) =>{
                                    return(
                                        <>
                                            <div style={{marginTop:"8px"}} className={styles.field}>
                                                <ProgressionLevelMultiSelect placeholder='Select Level' label={element.name} options={element.levelSystemLevelMapping} ind={index} value={PS} setValue={setPS}/>                                     
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </Card>


            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.customData}>  
                        <TagInputWithDropdown tooltip={TooltipTitle.SSTags} tags={tags} setTags={setTags}/>

                        <div className={styles.groupHeading}>
                            <span>Meta Data<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskMetaData} icon="info"/></span></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" required  value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e, i)}}/> */}
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={submit}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                      <Schedule id={id} setId={setId} as={`saveNschedule`} type='StepSeries' saveTaskGroup={submit} name={name}/>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )

}

export default StepSeriesInfo