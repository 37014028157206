import React, { useState, useRef, useEffect } from 'react'
import '../querybuilder.scss'
import cn from 'classnames'
import Toast from '../../../../../../../Toast'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {QueryBuilder, add, ActionWithRulesProps, Group, ActionElement, Rule} from 'react-querybuilder'
import Card from '../../../../../../../Card/ModalCards'
import Form from '../../../../../../../Form';
import TextInput from '../../../../../../../TextInput'
import TextArea from '../../../../../../../TextArea'
import Dropdown from '../../../../../../../Dropdown';
import Radio from '../../../../../../../Radio';
import Tooltip from "../../../../../../../Tooltip";
import Checkbox from "../../../../../../../Checkbox"
import CommonDropdown from '../../../../../../../MultiselectDropDown/CommonDropdown'
import ContentTable from '../../../../../../../BuildComponents/EconomyComponent/BundlesComponent/ContentTableLayout'
import Control from '../Control'
import styles from './taskInformation.module.sass'
import CustomCombinatorSelector from '../CustomQueryBuilder/CustomCombinatorSelector' 
import CustomFieldSelector from '../CustomQueryBuilder/CustomFieldSelector'
import CustomOperatorSelector from '../CustomQueryBuilder/CustomOperatorSelector'
import CustomValueEditor from '../CustomQueryBuilder/CustomValueEditor';
import {getCustomEventAction, getDefaultEventAction} from '../../../../../../../../redux/action/builds/events/eventActions'
import {createTaskAction} from '../../../../../../../../redux/action/engage/Achievement/Task/TaskAction'
import Task from '../../../../../../../../media/images/placeholders/TAsk.png'
import {ReactComponent as AddButtonIcon} from '../../../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as AddRuleIcon} from '../../../../../../../../media/images/icons/Rule_Add_SVG.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../../../media/images/icons/remove.svg'
import { BASE_URL, GET_PROGRESSION_SYSTEM } from '../../../../../../../../resources/APIEndpoints';
import ItemsTableLayout from '../../../../../../../BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalItemTable'
import CurrencyTableLayout from '../../../../../../../BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalCurrencyTable'
import BundleTableLayout from '../../../../../../../BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalBundleTable'
import PMTableLayout from '../../../../../../../BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalPMTable'
import {ReactComponent as Previous} from '../../../../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import JSONMetaDataValueInput from '../../../../../../../MetaInput'
import TagInputWithDropdown from '../../../../../../../TagInputWithDropdown'
import TooltipTitle from '../../../../../../../../Tooltip/TooltipTitle'
import Switch from '../../../../../../../Switch'
// Static Data
const typesOption = ['Types']
const subTypesOption = ['Sub Types']
const tagsOption = ['Tags']
const sorting = ["Items", "Currency", "Bundles", "Progression Marker"];

const StepInformation = ({taskType, modalFor, onClose, task, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, value, setValue, setStepSeries, className}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
    const iconRef = useRef()

    // Task Informations
    const placeholder = Task
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [disable, setDisable] =  useState(false)
    const [iconUrl, setIconUrl] = useState()
    const [name, setName] = useState('')
    const [taskId, setTaskId] = useState('')
    const [description, setDescription] = useState()

    const [tabIndex, setTabIndex] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["Items", "Currency", "Bundles", "Progression Marker"])
    const [eventType, setEventType] = useState()
    const [displayId, setDisplayId] = useState(null)
    const [searchPage, setSearchPage] = useState(1)
    const [itemsSearchCount,setItemsSearchCount]=useState(null)
    const [bundleSearchCount,setBundleSearchCount]=useState(null)
    const [currencySearchCount,setCurrencySearchCount]=useState(null)
    const [pmSearchCount,setPmSearchCount]=useState(null)
    const [searchValue,setSearchValue]=useState('')

    const uploadIcon = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setIconUrl(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setIconUrl(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        iconRef.current.value = ''
    }

    const onChangeName = (e) => {
        if(e.target.value == ''){
            setName('')
        }else{
            errors.nameError = ''
            setName(e.target.value)
        }     
    }

    const onChangeId = (e) => {
        if(e.target.value == ''){
            setTaskId('')
        }else{
            errors.idError = ''
            setTaskId(e.target.value)
        }
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    // Rewards
    const [content, setContent] = useState([])
    const [isLinkTask, setIsLinkTask] = useState(false)
    const [isLinkTaskRewards, setIsLinkTaskRewards] = useState(false)
    const [linkTaskRewards, setLinkTaskRewards] = useState([])

    // Configure (QueryBuilder)
    // const [type, setType] = useState(taskType)
    // const [typeId, setTypeId] = useState('')
    // const typesOption = [{id : 1, name : 'static'},{id : 2, name : 'daily'},{id : 3, name : 'weekly'}]
    const typesOption = [{id : 1, name : 'static'},{id : 2, name : 'daily'},{id : 3, name : 'weekly'}]
    const [type, setType] = useState(typesOption[0].name)
    const [typeId, setTypeId] = useState(typesOption[0].id)
    const [reward, setReward] = useState(true)
    const [event, setEvent] = useState([])
    const [eventOptions, setEventOption] = useState([])
    const [eventId, setEventId] = useState('')
    const [fields, setFields] = useState([])
    const combinators = [{name : 'and', label : 'and'}, {name : 'or', label : 'or'}]
    const [query, setQuery] = useState({combinator : 'and', rules : [{field : '', value : {value : null, type : false, allTime : true, noOfRecords : null}, operator : ''}]})
    const [rule, setRule] = useState([])
    const [config, setConfig] = useState()
    const [ruleData, setRuleData] = useState()
    const [configData, setConfigData] = useState()

    const checkBooleanValue = (value) => {
        if (value === true || value === 'true') {
          return true;
        }
        return false;
    }

    const convertRule = (rules) => {
        var result = JSON.parse(JSON.stringify(rules))
            var configure = []
            function recurssive(result){
                for(var i = 0; i < result.length ; i++){
                    var current = result[i]
                    if(current.field != undefined){
                        configure.push({...(current.field.id && current.field.id != undefined && {parameterId : current.field.id}), parameterName : current.field.name, operator : current.operator, value : (current.field.dataType == 'integer' && current.value.value != null && current.value.value != '' ? parseInt(current.value.value) : current.field.dataType == 'integer' && (current.value.value == null || current.value.value == '') ? null : current.field.dataType == 'boolean' ? checkBooleanValue(current.value.value) : current.field.dataType == 'float' ? parseFloat(current.value.value) : current.value.value), incrementalType : (current.value.type == true ? 'cumulative' : 'one-shot'), noOfRecords : ((current.value.type == true && current.value.allTime == false && current.value.noOfRecords != null) ? parseInt(current.value.noOfRecords) : (current.value.type == true && current.value.allTime == true) ? 'all' : null), type : current.field.type, dataType: current.field.dataType})
                        current.fact = current.field.name;
                        if(current.field.dataType == 'string'){
                            current.value = current.value.value
                        }else if(current.field.dataType == 'integer'){
                            current.value = ((current.value.value != null && current.value.value != '') ? parseInt(current.value.value) : null)
                        }else if(current.field.dataType == 'boolean'){
                            const booleanValue = checkBooleanValue(current.value.value)
                            current.value = booleanValue
                            // current.value = (current.value.value === 'true')
                        }else if(current.field.dataType == 'float'){
                            current.value = parseFloat(current.value.value)
                        }else{
                            current.value = current.value.value
                        }
                    }
                    delete current.valueSource
                    delete current.field
                    delete current.id
                    if((current.rules || current.all || current.any) && (current.rules.length || current.all.length || current.any.length) > 0){
                        if(current.combinator == 'and'){
                            current.all = current['rules']
                        }else{
                            current.any = current['rules']
                        }
                        delete current.fact
                        recurssive(current.rules)
                        delete current.rules
                        delete current.combinator
                        delete current.not
                    }
                }
            }
            recurssive(result.rules)
            setRule(result.rules)
            setConfig(configure)
            return { rulesData: result.rules,  configData : configure};
        // var result = JSON.parse(JSON.stringify(rules))
        // var configure = []
        // function recurssive(result){
        //     for(var i = 0; i < result.length ; i++){
        //         var current = result[i]
        //         if(current.field != undefined){
        //             console.log(current.field, "Field", current.value)
        //             configure.push({parameterId : current.field.id.toString(), parameterName : current.field.name, operator : current.operator, value : (current.field.dataType == 'integer' ? parseInt(current.value.value) : current.field.dataType == 'boolean' ? JSON.parse(current.value.value)  : current.value.value), incrementalType : (current.value.type == true ? 'cumulative' : 'one-shot'), noOfRecords : ((current.value.type == true && current.value.allTime == false) ? parseInt(current.value.noOfRecords) : null), type : current.field.type, dataType : current.field.dataType})
        //             current.fact = current.field.name;
        //             if(current.field.dataType == 'string'){
        //                 current.value = current.value.value
        //             }else if(current.field.dataType == 'integer'){
        //                 current.value = parseInt(current.value.value)
        //             }else if(current.field.dataType == 'boolean'){
        //                 current.value = current.value.value
        //             }else if(current.field.dataType == 'float'){
        //                 current.value = Math.round(current.value.value)
        //             }else{
        //                 current.value = current.value.value
        //             }
        //         }
        //         delete current.valueSource
        //         delete current.field
        //         delete current.id
        //         if((current.rules || current.all || current.any) && (current.rules.length || current.all.length || current.any.length) > 0){
        //             if(current.combinator == 'and'){
        //                 current.all = current['rules']
        //             }else{
        //                 current.any = current['rules']
        //             }
        //             delete current.fact
        //             recurssive(current.rules)
        //             delete current.rules
        //             delete current.combinator
        //             delete current.not
        //         }
        //     }
        // }
        // recurssive(result.rules)
        // setRule(result.rules)
        // setConfig(configure)
        // return { rulesData: result.rules,  configData : configure};
    }

    const onchangeQuery = (newQuery) => {
        setQuery(newQuery);
    };

    const buildClasses = {
        queryBuilder : "qb-container",
        ruleGroup : 'rule-group',
        rule : 'rule',
        removeRule : 'remove'
    }

    const [eventPage, setEventPage] = useState(1)
    const [eventLimit, setEventLimit] = useState(100)
    const [isCalled, setIsCalled] = useState(false)

    const getAllEvents = async() => {
        let getEvents = {
            projectId : appid,
            offset: ((eventPage-1)*eventLimit),
            limit: eventLimit
        }

        Promise.all([await dispatch(getCustomEventAction(getEvents)), await dispatch(getDefaultEventAction(getEvents))]).then(res =>{
            setIsCalled(true)
        })
        
    }

    let customeEvents = useSelector((state) => {
        return state.event.customEvents
    })
    
    let defaultEvents = useSelector((state) => {
        return state.event.defaultEvents
    })

    let totalCustomEvents = useSelector((state)=>{
        return state.event.totalCustomEventCount
    })
    
    let totalDefaultvents = useSelector((state)=>{
        return state.event.totalDefaultEventCount
    })

    const configureEvent = () => {
        if(isCalled){
            const customEventData = customeEvents.map(v => ({...v, type: 'custom'}))
            const defaultEventData = defaultEvents.map(v => ({...v, type: 'default'}))
            let events = [...customEventData, ...defaultEventData]
            let allEvents = []
            if(events.length > 0){
                events && events.forEach((e,i) => {
                    allEvents.push(e)
                })
                setEventOption(eventOption => [...eventOption, ...allEvents])
                setIsCalled(false)
            }
        }
    }

    useEffect(()=>{
        configureEvent()
    }, [defaultEvents, customeEvents])

    useEffect(() => {
        getAllEvents()
    }, [])

    const handleAddGroup = () => {
        const newGroup = {
            id: "new_group_" + Math.random(),
            combinator : 'and',
            not : false,
            rules: [],
        };
        const updatedQuery = {
            ...query,
            rules: [...(query.rules || []), newGroup],
        };
      
        setQuery(updatedQuery);
    }

    const AddRuleButton = (ActionWithRulesProps) => {
        const onClickFirst = () => setQuery(add(query,{ field: '', operator: '', value: {value : null, type : false, allTime : true, noOfRecords : null}}, ActionWithRulesProps.path))
        return (
        <>
            <button className={styles.ml} onClick={onClickFirst}><AddRuleIcon /></button>
        </>
        );
    };

    const AddGroupButton = () => {
        return(
            <></>
        )
    }

    const getRequiredField = () => {
        let selectedEvent = eventOptions && eventOptions.filter(event => {
            return event.id === eventId
        })
        if(selectedEvent && selectedEvent.length > 0){
            if(selectedEvent[0].type == 'custom'){
                let field = [...selectedEvent[0].customParameterDetails,...selectedEvent[0].defaultParameterDetails]
                let fieldOptions = []
                field && field.forEach((e,i) => {
                    fieldOptions.push({id : e.id, name : e.name, inputType : e.dataType.dataTypeName, type : e.type})
                })
                setFields(field)
            }else{
                let field = [...selectedEvent[0].customParameterDetails, ...selectedEvent[0].defaultParameterDetails, ...selectedEvent[0].specterParameterDetails, ...selectedEvent[0].optionalParameterDetails]
                let fieldOptions = []
                field && field.forEach((e,i) => {
                    fieldOptions.push({id : e.id, name : e.name, inputType : e.dataType.dataTypeName, type : e.type})
                })
                setFields(field)
            }
            // let field = [...selectedEvent[0].customParameterDetails, ...selectedEvent[0].defaultParameterDetails]
            // let fieldOptions = []
            // field && field.forEach((e,i) => {
            //     fieldOptions.push({id : e.id, name : e.name, inputType : e.dataType.dataTypeName, type : e.type})
            // })
            // setFields(field)
        }
    }

    useEffect(() => {
        getRequiredField()
    }, [eventOptions, eventId])

    

    useEffect(()=>{
        configureTask()
    }, [task])

    const [ruleDetails, setRuleDetails] = useState([])
    const [checkTaskRewards, setCheckTaskRewards] = useState([])

    const configureTask = () => {
        if(task && task != null && task != undefined){
            setName(task.name)
            setDescription(task.description)
            setLogo({src: (task.iconUrl != null ? task.iconUrl : Task), alt: 'Upload an Image'});
            setIconUrl(task.iconUrl)
            setEventId(task.defaultEventId != null ? task.defaultEventId : task.customEventId)
            setDisplayId(task.defaultEventId != null ? task.defaultEventId : task.customEvent.eventId)
            setEventType(task.defaultEventId != null ? 'default' : 'custom')
            setEvent(task.defaultEventId != null ? task.defaultEvent.name : task.customEvent.name)
            setType(task.type)
            setReward(task.rewardClaim == 'automatic' ? false : true)
            setConfig(task.config)
            if(task.businessLogic != undefined && task.businessLogic != null && task.businessLogic.all != undefined && task.businessLogic.any == undefined){
                setRule(task.businessLogic.all)
            }else if(task.businessLogic != undefined && task.businessLogic != null && task.businessLogic.all == undefined && task.businessLogic.any != undefined){
                setRule(task.businessLogic.any)
            }
            setRuleDetails(task.businessLogic)
            //Custom Data
            task.tagsMapping && task.tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });
            let meta = []
            if(task.meta == null || JSON.stringify(task.meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                task.meta && Object.keys(task.meta).forEach(e => {
                meta.push({key : e, value : task.meta[e]})
            });
                setMetaData(meta)
            }
            // Rewards
            let RewardsData = [...task.currencies,...task.items, ...task.bundles, ...task.progressionMarkers]
            let contentData = []
            RewardsData && RewardsData.forEach((e,i) =>{
                if((e.currencyId != undefined || e.currencyId != null)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, currencyId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.currencyId})
                    }
                }
                if((e.itemId != null || e.itemId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, itemId : e.id, quantity : e.quantity, type : (e.isConsumable == true ? 'Consumable' : 'Durable'), archive : false, displayId : e.itemId})
                    }
                }
                if((e.bundleId != null || e.bundleId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, bundleId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.bundleId})
                    }
                }
                if((e.progressionMarkerId != null || e.progressionMarkerId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, progressionMarkerId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.progressionMarkerId})
                    }
                }
            })
            setContent(contentData)
            sessionStorage.setItem('task-rewards', JSON.stringify(contentData))
            // Link Rewards
            let totalLinkRewards = []
            let linkRewardData = task.linkedRewardDetails          
            let updatedLinkReward = [...linkRewardData.items, ...linkRewardData.bundles, ...linkRewardData.currencies, ...linkRewardData.progressionMarkers]
            if(updatedLinkReward?.length > 0){
                setIsLinkTask(true)
            }
            setIsLinkTaskRewards(!task.isLinkedRewardSameAsGeneralRewards)
            updatedLinkReward && updatedLinkReward.forEach((e,i) =>{
                if((e.currencyId != undefined || e.currencyId != null)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, currencyId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.currencyId})
                    }
                }
                if((e.itemId != null || e.itemId != undefined)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, itemId : e.id, quantity : e.quantity, type : (e.isConsumable == true ? 'Consumable' : 'Durable'), archive : false, displayId : e.itemId})
                    }
                }
                if((e.bundleId != null || e.bundleId != undefined)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, bundleId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.bundleId})
                    }
                }
                if((e.progressionMarkerId != null || e.progressionMarkerId != undefined)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, progressionMarkerId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.progressionMarkerId})
                    }
                }
            })
            setLinkTaskRewards(totalLinkRewards)
            setCheckTaskRewards(totalLinkRewards)
            sessionStorage.setItem('link-task-rewards', JSON.stringify(totalLinkRewards))
        }
    }

    const [linkCurrency, setLinkCurrency] = useState([])
    const [linkItem, setLinkItem] = useState([])
    const [linkBundle, setLinkBundle] = useState([])
    const [linkPm, setLinkPm] = useState([])

    const customizeCurrencyData = () => {
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = content.find(element => element.currencyId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setCurrency(updatedArray)
        }
    }

    const customizeLinkCurrency = () => {
        if(currenciesData && currenciesData?.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = linkTaskRewards.find(element => element.currencyId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setLinkCurrency(updatedArray)
        }
    }

    const customizeItemData = () => {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map(ele => {
                const matchObj = content.find(element => element.itemId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setItem(updatedArray)
        }
    }

    const customizeLinkItemData = () => {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map(ele => {
                const matchObj = linkTaskRewards.find(element => element.itemId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setLinkItem(updatedArray)
        }
    }

    const customizeBundleData = () => {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map(ele => {
                const matchObj = content.find(element => element.bundleId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setBundle(updatedArray)
        }
    }

    const customizeLinkBundleData = () => {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map(ele => {
                const matchObj = linkTaskRewards.find(element => element.bundleId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setLinkBundle(updatedArray)
        }
    }

    const customizePMData = () => {
        if(pmData && pmData.length > 0){
            const updatedArray = pmData && pmData.map(ele => {
                const matchObj = content.find(element => element.progressionMarkerId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setPM(updatedArray)
        }
    }

    const customizeLinkPMData = () => {
        if(pmData && pmData.length > 0){
            const updatedArray = pmData && pmData.map(ele => {
                const matchObj = linkTaskRewards.find(element => element.progressionMarkerId == ele.id && (!element.archive || element.archive === false))
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setLinkPm(updatedArray)
        }
    }

    const saveDecision = () => {
        if(ruleDetails == null || ruleDetails == undefined){
                
        }else{
            let result
            if(ruleDetails.all != undefined && ruleDetails.any == undefined){
                result = JSON.parse(JSON.stringify(ruleDetails.all))
            }else if(ruleDetails.all == undefined && ruleDetails.any != undefined){
                result = JSON.parse(JSON.stringify(ruleDetails.any))
            }
            let x = 0;
            if(result != undefined){
                function recurssive(result){
                    for(var i=0 ; i< result.length ; i++){
                        var current = result[i]
                        if(current.fact != undefined){
                            current.field = {name : current['fact'], id : config[x].parameterId, type : config[x].type, dataType : config[x].dataType}
                            // current.value = {id: x, value : current.value, type : (config[x].incrementalType == 'one-shot' ? false : true), allTime : ((config[x].incrementalType == 'cumulative' && config[x].noOfRecords != null) ? false : true), noOfRecords : ((config[x].incrementalType == 'cumulative' && config[x].noOfRecords != null) ? config[x].noOfRecords : null)}
                            current.value = {id: x, value : current.value, type : (config[x].incrementalType == 'one-shot' ? false : true), allTime : ((config[x].incrementalType == 'cumulative' && config[x].noOfRecords != 'all') ? false : true), noOfRecords : ((config[x].incrementalType == 'cumulative' && config[x].noOfRecords != null && config[x].noOfRecords != 'all' ) ? config[x].noOfRecords : null)}
                            x++
                            delete current.fact
                        }
                        if((current.all || current.any || current.rules)){
                            if(current.all){
                                current.rules = current['all']
                                current.combinator = 'and'
                                recurssive(current.all)
                                delete current.all
                            }else{
                                current.rules = current['any']
                                current.combinator = 'or'
                                recurssive(current.any)
                                delete current.any
                            }
                        }
                    }
                }
                recurssive(result)
                let initialQuery
                if(ruleDetails.all != undefined && ruleDetails.any == undefined){
                    initialQuery = {
                        combinator : 'and',
                        rules : result
                    }
                }else if(ruleDetails.all == undefined && ruleDetails.any != undefined){
                    initialQuery = {
                        combinator : 'or',
                        rules : result
                    }
                }
                setQuery(initialQuery)
            }
        }
    }


    useEffect(()=>{
        saveDecision()
    }, [ruleDetails])

    // Save and Cancel Action

    const [errors, setErrors] = useState({nameError : '', idError : '', eventIdErr : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
          error.nameError = 'Display Name is required';
          isValid = false
          const element = document.getElementById('missionTaskName');
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      
        if (data.taskId == '') {
            error.idError = 'Task ID is required';
            isValid = false;
            if(data.name != ''){
                const element = document.getElementById('missionTaskId');
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }

        if ((data.customEventId == undefined && (data.defaultEventId == '' || data.defaultEventId == null)) || (data.defaultEventId == undefined && (data.customEventId == '' || data.customEventId == null))) {
            error.eventIdErr = 'Event is required';
            isValid = false;
            if(data.name != '' && data.taskId != ''){
                const element = document.getElementById('missionEvent');
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const validateQuery = (rules) => {
        var result = JSON.parse(JSON.stringify(rules))
        let isValid = true
        if(result.rules?.length > 0  && result.rules[0]?.field != ''){
            function recurssive(result){
                for(var i = 0; i < result.length ; i++){
                    var current = result[i]
                    if(current.rules === undefined || current.rules === null){
                        if(current.field === '' || current.field === undefined || current.field === null){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Parameter cannot be empty.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                        }
                        if(current.operator === '' || current.operator === undefined || current.operator === null || current.operator === '=' ){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Operator cannot be empty.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                        }
                        if(current.value?.value === '' || current.value?.value === undefined || current.value?.value === null){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Value cannot be empty.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                        }
                    }
                    if(current.rules && current.rules?.length > 0){
                        recurssive(current.rules)
                    }
                }
            }
            recurssive(result.rules)
        }
        if(!isValid){
            const element = document.getElementById('query-builder');
            element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        return isValid;
    }

    const submit = () => {
            let conevertRuleData;
            let conevertConfig
            if(query.rules && query.rules.length > 0 && query.rules[0].field != ''){
                const {rulesData, configData} = convertRule(query);
                conevertRuleData = rulesData
                conevertConfig = configData
            }
            
            const metaDataList = [...metaData];
            const finalMetaData = {};
            for(var i = 0; i < metaDataList.length ; i++){
                finalMetaData[metaDataList[i].key] = metaDataList[i].value;
            }
    
            let eventTags = []
            tags && tags.forEach((e)=>{
                eventTags.push({tagId: e.id, tag:{id: e.id, name: e.text}})
                // eventTags.push(e.text)
            })

            const contents = JSON.parse(sessionStorage.getItem('task-rewards'))
            const addedContent = contents.map(({displayId, ...content}) => content)
            const eventContent = addedContent.filter(item => !item.archive)

            const rewards = JSON.parse(sessionStorage.getItem('link-task-rewards'))
            const addedRewards = rewards && rewards.map(({displayId, ...content}) => content)
            const rewardsTask = addedRewards.filter(item => !item.archive)

            let createStepSeries = {
                active: true,
                archive : false,
                displayId : displayId,
                eventName : event,
                stepsDetail : (task == null ? 'New' : 'Existing'),
                projectId : appid,
                name : name,
                // type : type,
                ...(eventType == 'custom' ? {customEventId : eventId, defaultEventId : null} : {customEventId : null, defaultEventId : eventId}),
                taskId : taskId,
                description : description,
                iconUrl : iconUrl,
                rewardClaim : (reward == false ? 'automatic' : 'on-claim'),
                rewardDetails : eventContent,
                linkedRewardDetails: rewardsTask,
                isLinkedRewardSameAsGeneralRewards : !isLinkTaskRewards,
                ...(query.rules && query.rules.length > 0 && conevertRuleData != undefined && {config : conevertConfig}),
                ...((query.combinator == 'and' && (query.rules && query.rules.length > 0 && conevertRuleData != undefined)) ? {businessLogic : {all : conevertRuleData}} : (query.combinator == 'or' && (query.rules && query.rules.length > 0 && conevertRuleData != undefined)) ? {businessLogic : {any : conevertRuleData}} : {}),
                tagsMapping: eventTags,
                meta : finalMetaData,
            }

            if(validation(createStepSeries)){
                if(validateQuery(query)){
                    let stepSeries = JSON.parse(sessionStorage.getItem('step-series'))
                    stepSeries.push(createStepSeries)
                    sessionStorage.setItem('step-series', JSON.stringify(stepSeries))
                    setStepSeries(stepSeries)
    
                    setContent([])
                    refreshStorage()
                    onClose()
                }
            }

    }


    const refreshStorage = () => {
        sessionStorage.setItem('task-rewards', JSON.stringify([]))
        sessionStorage.setItem('link-task-rewards', JSON.stringify([]))
    }
    
    const cancel = () => {
        refreshStorage()
        onClose()
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    let itemsData = useSelector((state) => {
        return state.item.items
    })

    let bundleData = useSelector((state) => {
        return state.bundles.bundles
    })

    let pmData = useSelector((state)=>{
        return state.progressionMarkers.progressionMarker
    })

    useEffect(()=>{
        setTimeout(()=>{
            customizeCurrencyData()
        }, 500)
    }, [content, currencyPage, currenciesData])

    useEffect(()=>{
        setTimeout(()=>{
            customizeLinkCurrency()
        }, 500)
    }, [linkTaskRewards, currencyPage, currenciesData])

    useEffect(()=>{
        setTimeout(()=>{
            customizeLinkItemData()
        }, 500)
    }, [linkTaskRewards, itemPage, itemsData])

    useEffect(()=>{
        setTimeout(()=>{
            customizeItemData()
        }, 500)
    }, [content, itemPage, itemsData])

    useEffect(()=>{
        setTimeout(()=>{
            customizeLinkBundleData()
        }, 500)
    }, [linkTaskRewards, bundlePage, bundleData])

    useEffect(()=>{
        setTimeout(()=>{
            customizeBundleData()
        }, 500)
    }, [content, bundlePage, bundleData])

    useEffect(()=>{
        setTimeout(()=>{
            customizeLinkPMData()
        }, 500)
    }, [linkTaskRewards, PMPage, pmData])

    useEffect(()=>{
        setTimeout(()=>{
            customizePMData()
        }, 500)
    }, [content, PMPage, pmData])

    let totalCurrency = useSelector((state)=>{
        return state.currencies.totalCount
    })

    const handleCurrencyChange = (event, value)  => {
        if (searchValue=='') {
            currencyPage(value)
            setCurrencySearchCount(null)
        }
        else{
            setSearchPage(value)
        }
    }

    let totalItems = useSelector((state)=>{
        return state.item.totalCount
    })

    const handleItemChange = (event, value)  => {
        if (searchValue=='') {
            itemPage(value)
            setItemsSearchCount(null)
        }
        else{
            setSearchPage(value)
        }
    }

    let totalBundles = useSelector((state)=>{
        return state.bundles.totalCount
    })

    const handleBundleChange = (event, value)  => {
        if (searchValue=='') {
            bundlePage(value)
            setBundleSearchCount(null)
        }
        else{
            setSearchPage(value)
        }
    }

    let totalPM = useSelector((state)=>{
        return state.progressionMarkers.totalCount
    })

    const handlePMChange = (event, value)  => {
        if (searchValue=='') {
            PMPage(value)
            setPmSearchCount(null)
        }
        else{
            setSearchPage(value)
        }
    }

    const [checkId, setCheckId] = useState(null)

    const refreshQureyBuilder = () => {
        setQuery({combinator : 'and', rules : [{field : '', value : {value : '', type : false, allTime : true, noOfRecords : null}, operator : ''}]})
        setRule([])
        setRuleDetails([])
        setConfig()
    }

    useEffect(() => {
        if(checkId != null){
            refreshQureyBuilder()
            setCheckId(null)
        }
    }, [event])

    // Custom Data

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
          
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    
    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };
      
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]); 
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
        
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = [...tags].slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
    };
      
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
      
    const onClearAll = () => {
        setTags([]);
    };
      
    const onTagUpdate = (i, newTag) => {
        const updatedTags = tags.slice();
        updatedTags.splice(i, 1, newTag);
        setTags(updatedTags);
    };
    
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const searchReward = () => {
        if(searchValue == ''){
            customizeCurrencyData()
            customizeLinkCurrency()
            customizeItemData()
            customizeLinkItemData()
            customizeBundleData()
            customizeLinkBundleData()
            customizePMData()
            customizeLinkPMData()
        }else{
            let search = {
                projectId : appid,
                search : searchValue,
                offset: ((searchPage-1)*itemCount),
                limit: itemCount
            }
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/inventory/search`, search, headers)
            .then(res=>{
                if(res?.data?.data?.currencies && res?.data?.data?.currencies.length > 0){
                    const updatedArray = res?.data?.data?.currencies && res?.data?.data?.currencies.map((ele) => {
                        const matchObj = content.find(element => element.currencyId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setCurrency&&setCurrency(updatedArray)
                    setCurrencySearchCount(res?.data?.data?.currenciesSearchCount)

                    const updatedLinkCurrency = res?.data?.data?.currencies && res?.data?.data?.currencies.map((ele) => {
                        const matchObj = linkTaskRewards.find(element => element.currencyId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setLinkCurrency(updatedLinkCurrency)
                }
                else{
                    setCurrency&&setCurrency([])
                    setLinkCurrency([])
                }
                if(res?.data?.data?.inventoryItems && res?.data?.data?.inventoryItems.length > 0){
                    const updatedArray = res?.data?.data?.inventoryItems && res?.data?.data?.inventoryItems.map(ele => {
                        const matchObj = content.find(element => element.itemId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setItem&&setItem(updatedArray)
                    setItemsSearchCount(res?.data?.data?.itemsSearchCount)

                    const updatedLinkItem = res?.data?.data?.inventoryItems && res?.data?.data?.inventoryItems.map(ele => {
                        const matchObj = linkTaskRewards.find(element => element.itemId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setLinkItem(updatedLinkItem)
                }
                else{
                    setItem&&setItem([])
                    setLinkItem([])
                }
                if(res?.data?.data?.inventoryBundles && res?.data?.data?.inventoryBundles.length > 0){
                    const updatedArray = res?.data?.data?.inventoryBundles && res?.data?.data?.inventoryBundles.map(ele => {
                        const matchObj = content.find(element => element.bundleId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setBundle&&setBundle(updatedArray)
                    setBundleSearchCount(res?.data?.data?.bundlesSearchCount)

                    const updatedLinkBundle = res?.data?.data?.inventoryBundles && res?.data?.data?.inventoryBundles.map(ele => {
                        const matchObj = linkTaskRewards.find(element => element.bundleId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setLinkBundle(updatedLinkBundle)
                }
                else{
                    setBundle&&setBundle([])
                    setLinkBundle([])
                }
                if(res?.data?.data?.progressionMarkers && res?.data?.data?.progressionMarkers.length > 0){
                    const updatedArray = res?.data?.data?.progressionMarkers && res?.data?.data?.progressionMarkers.map(ele => {
                        const matchObj = content.find(element => element.progressionMarkerId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setPM&&setPM(updatedArray)
                    setPmSearchCount(res?.data?.data?.progressionMarkersSearchCount)

                    const updatedLinkPM =  res?.data?.data?.progressionMarkers && res?.data?.data?.progressionMarkers.map(ele => {
                        const matchObj = linkTaskRewards.find(element => element.progressionMarkerId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setLinkPm(updatedLinkPM)
                }
                else{
                    setPM&&setPM([])
                    setLinkPm([])
                }
            })
        }
    }

    useEffect(()=>{
        setSearchPage(1)
    },[activeIndex,searchValue])


    useEffect(()=>{
        searchReward()
    },[searchValue,searchPage])

    // Link Rewards

    const onChangeLink = (e) => {
        if(e.target.checked){
            setIsLinkTask(true)
            setLinkTaskRewards(content)
            sessionStorage.setItem('link-task-rewards',  JSON.stringify(content))
        }else{
            setIsLinkTask(false)
            setIsLinkTaskRewards(false)
            setLinkTaskRewards([])
            sessionStorage.setItem('link-task-rewards',  JSON.stringify([]))
        }
    }
    
    const onChangeLinkRewards = (e) => {
        if(isLinkTaskRewards){
            let rewards = JSON.parse(sessionStorage.getItem('link-task-rewards'))
            let allRewards = [...content, ...rewards.map(e => ({...e, archive: true}))]
            setIsLinkTaskRewards(false)
            setLinkTaskRewards(allRewards)
            sessionStorage.setItem('link-task-rewards',  JSON.stringify(allRewards))
        }else{
            setIsLinkTaskRewards(true)
            setLinkTaskRewards(checkTaskRewards)
            sessionStorage.setItem('link-task-rewards',  JSON.stringify(checkTaskRewards))
        }
    }

    const configureRewards = () => {
        if(isLinkTask){
            if(!isLinkTaskRewards){
                setLinkTaskRewards(content)
                sessionStorage.setItem('link-task-rewards',  JSON.stringify(content))  
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            configureRewards()
        }, 500)
    }, [content])

    return(
        <>
            <div id='formContainer'>
            <Card className={cn(styles.card, className)}>
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>   
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" id='step_series_icon' className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={iconRef} onChange={(e)=>{uploadIcon(e)}}/>
                            <label for='step_series_icon' className={styles.button}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {iconUrl != null && 
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        }
                    </div>

                    <div className={styles.infoGroup}>
                        <div className={styles.group}>
                            <TextInput id='missionTaskName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" value={name} required onChange={(e)=>{onChangeName(e)}} />
                            <TextInput id='missionTaskId' className={styles.field} errorMessage={errors.idError} label={modalFor == 'Mission' ? 'Task ID*' : 'Step ID*'} name="itemId" type="text" required value={taskId} onChange={(e)=>{onChangeId(e)}} />
                        </div>

                        <div className={styles.textArea}>
                            <TextArea className={styles.field} rows={5} label={modalFor == 'Mission' ? "Task Description" : "Step Description"} value={description} onChange={(e)=>{onChangeDescription(e)}} />
                        </div>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Configure" classTitle="title-green">
                <div className={styles.cardDescription}>
                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Grant Reward</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={true} className={styles.radioConfig} value={!reward} name="reward" onChange={() => setReward(false)} content="Server Side" />
                                    <Radio className={styles.radioConfig} value={reward} name="reward" onChange={() => setReward(true)} content="Client Side" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.field}>
                                <Dropdown setIsCalled={setIsCalled} eventPage={eventPage} setEventPage={setEventPage} eventLimit={eventLimit} limit={totalCustomEvents+totalDefaultvents} id='missionEvent' label='Event Name*' errorMessage={errors.eventIdErr} placeholder='Select Event' options={eventOptions} value={event} setValue={setEvent} setDisplayId={setDisplayId} setField={setFields} setId={setEventId} setType={setEventType}/>
                            </div>
                            <TextInput className={styles.field} disabled value={displayId} label="Event ID" name="itemId" type="text" />
                        </div>
                    </div>

                    <div className={styles.configure}>
                        <span className={styles.params}>Parameter Type <span className={styles.addGroup} onClick={handleAddGroup}><AddMetaIcon /></span></span>
                    </div>
                    <div className={styles.configure} id='query-builder'>
                        <QueryBuilder controlClassnames={buildClasses} combinators={combinators} query={query} fields={fields} onQueryChange={onchangeQuery} controlElements={{combinatorSelector : (props) => (<CustomCombinatorSelector {...props} />), fieldSelector : (props) => (<CustomFieldSelector {...props} />), operatorSelector : (props) => (<CustomOperatorSelector {...props} />)  ,valueEditor : CustomValueEditor, addRuleAction : AddRuleButton , addGroupAction : props => (props.level === 0 ? <AddGroupButton {...props} /> : null)}}/>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title={modalFor == 'Mission' ? 'Add Reward to Task' : 'Add Reward to Step'} classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.accessEligibility}>
                        <div className={styles.addContentModal}>
                            <div className={styles.addContentContainer}>
                                <div className={styles.addContent}>
                                    <div className={styles.modalBody}>
                                        <div className={styles.modalContent}>
                                            <div className={styles.modalSearch}>
                                                <Form className={styles.form} placeholder="Search" type="text" name="search" icon="search"  onChange={(e)=>{setSearchValue(e?.target?.value)}}/>
                                            </div>
                                            <div className={styles.contentBody}>
                                                <div className={styles.toggle}>
                                                    {sort && sort.map((x, index) => (
                                                        <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => setActiveIndex(index)} key={index}>
                                                            <p>{x}</p>
                                                        </button>
                                                    ))}
                                                </div>
                                                    <>
                                                        <div className={styles.wrap}>
                                                            {activeIndex === 0 && 
                                                                <>
                                                                    <ItemsTableLayout tableFor='configure' type='Item' items={item} setData={setItem} setValue={setContent} title="Last edited" />
                                                                    <div className={styles.pagination}>
                                                                        <Stack spacing={2}>
                                                                            <Pagination renderItem={(item) => (
                                                                                <PaginationItem
                                                                                    {...item}
                                                                                    sx={{
                                                                                        '&.MuiPaginationItem-root': {
                                                                                          minWidth: '28px',
                                                                                          height: '28px',
                                                                                          fontSize: '12px'
                                                                                        },
                                                                                        '&.Mui-selected': {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        },
                                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                )} siblingCount={1} count={Math.ceil((searchValue !== ''&&itemsSearchCount)?(itemsSearchCount/itemCount):(totalItems/itemCount))} color="primary" onChange={handleItemChange} />
                                                                        </Stack>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className={styles.wrap}>
                                                            {activeIndex === 1 &&
                                                                <>
                                                                    <CurrencyTableLayout tableFor='configure' type='Currency' items={currency} setData={setCurrency} setValue={setContent} title="Last edited" />
                                                                    <div className={styles.pagination}>
                                                                        <Stack spacing={2}>
                                                                            <Pagination renderItem={(item) => (
                                                                                <PaginationItem
                                                                                    {...item}
                                                                                    sx={{
                                                                                        '&.MuiPaginationItem-root': {
                                                                                          minWidth: '28px',
                                                                                          height: '28px',
                                                                                          fontSize: '12px'
                                                                                        },
                                                                                        '&.Mui-selected': {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        },
                                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                )} siblingCount={1} count={Math.ceil((searchValue !== ''&&currencySearchCount)?(currencySearchCount/currencyCount):(totalCurrency/currencyCount))} color="primary" onChange={handleCurrencyChange} />
                                                                        </Stack>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                    <>
                                                        <div className={styles.wrap}>
                                                            {activeIndex === 2 &&
                                                                <>
                                                                    <BundleTableLayout tableFor='configure' type='Bundles' items={bundle} setData={setBundle} setValue={setContent} title="Last edited" />
                                                                    <div className={styles.pagination}>
                                                                        <Stack spacing={2}>
                                                                            <Pagination renderItem={(item) => (
                                                                                <PaginationItem
                                                                                    {...item}
                                                                                    sx={{
                                                                                        '&.MuiPaginationItem-root': {
                                                                                          minWidth: '28px',
                                                                                          height: '28px',
                                                                                          fontSize: '12px'
                                                                                        },
                                                                                        '&.Mui-selected': {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        },
                                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                )} siblingCount={1} count={Math.ceil((searchValue !== ''&&bundleSearchCount)?(bundleSearchCount/bundleCount):(totalBundles/bundleCount))} color="primary" onChange={handleBundleChange} />
                                                                        </Stack>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className={styles.wrap}>
                                                            {activeIndex === 3 &&
                                                                <>
                                                                    <PMTableLayout tableFor='configure' type='Progression Marker' items={PM} setData={setPM} setValue={setContent} title="Last edited" />
                                                                    <div className={styles.pagination}>
                                                                        <Stack spacing={2}>
                                                                            <Pagination renderItem={(item) => (
                                                                                <PaginationItem
                                                                                    {...item}
                                                                                    sx={{
                                                                                        '&.MuiPaginationItem-root': {
                                                                                          minWidth: '28px',
                                                                                          height: '28px',
                                                                                          fontSize: '12px'
                                                                                        },
                                                                                        '&.Mui-selected': {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        },
                                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                          color: 'rgb(255, 255, 255)',
                                                                                          fontWeight : 'bold',
                                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                                          background : 'rgba(42, 133, 255)'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                )} siblingCount={1} count={Math.ceil((searchValue !== ''&&pmSearchCount)?(pmSearchCount/PMCount):(totalPM/PMCount))} color="primary" onChange={handlePMChange} />
                                                                        </Stack>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title={modalFor == 'Mission' ? 'Link reward to Task' : 'Link Reward to Step'} classTitle="title-red"
            head={
                <>
                    <div className={styles.selection}>
                        <Switch value={isLinkTask} onChange={(e) => {onChangeLink(e)}}/>
                    </div>
                </>
            }>
            {isLinkTask &&
                <div className={styles.cardDescription}>
                    <div className={styles.accessEligibility}>
                        {/* <div className={styles.content}>
                            <div className={styles.wrapper}>
                                <Checkbox size='small' content='Link Rewards' value={isLinkTask} onChange={(e) => {onChangeLink(e)}}/>
                            </div>                      
                        </div> */}
                            <div  className={styles.group}>
                                <TextInput className={styles.field} label="Link Reward Type" name="linkeward" type="text" required disabled value='User Referral' />
                                <div className={styles.fieldRadio}>
                                    <div className={styles.radioLabel}>
                                        <span>Is link rewards same as task rewards?</span>
                                    </div>
                                    <div className={styles.variants}>
                                        <Radio className={styles.radioConfig} value={!isLinkTaskRewards} name="isLinkTaskRewards" onChange={(e) => {onChangeLinkRewards(e)}} content="Yes" />
                                        <Radio className={styles.radioConfig} value={isLinkTaskRewards} name="isLinkTaskRewards" onChange={(e) => {onChangeLinkRewards(e)}} content="No" />
                                    </div>
                                </div>
                            </div>
                    </div>

                    {!isLinkTaskRewards &&
                        <div className={styles.accessEligibility}>
                            <div className={styles.content}>
                                <div className={styles.wrapper}>
                                    <ContentTable usedFor='Link Rewards' disable={!isLinkTaskRewards} items={linkTaskRewards} setValue={setLinkTaskRewards} />
                                </div>
                            </div>
                        </div> 
                    }

                    {isLinkTaskRewards &&
                        <div className={styles.accessEligibility}>
                            <div className={styles.addContentModal}>
                                <div className={styles.addContentContainer}>
                                    <div className={styles.addContent}>
                                        <div className={styles.modalBody}>
                                            <div className={styles.modalContent}>
                                                <div className={styles.modalSearch}>
                                                    <Form className={styles.form} placeholder="Search" type="text" name="search" icon="search"  onChange={(e)=>{setSearchValue(e?.target?.value)}}/>
                                                </div>
                                                <div className={styles.contentBody}>
                                                    <div className={styles.toggle}>
                                                        {sort && sort.map((x, index) => (
                                                            <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => setActiveIndex(index)} key={index}>
                                                                <p>{x}</p>
                                                            </button>
                                                        ))}
                                                    </div>
                                                        <>
                                                            <div className={styles.wrap}>
                                                                {activeIndex === 0 && 
                                                                    <>
                                                                        <ItemsTableLayout usedFor='Link Rewards' tableFor='configure' type='Item' items={linkItem} setData={setLinkItem} setValue={setLinkTaskRewards} title="Last edited" />
                                                                        <div className={styles.pagination}>
                                                                            <Stack spacing={2}>
                                                                                <Pagination renderItem={(item) => (
                                                                                    <PaginationItem
                                                                                        {...item}
                                                                                        sx={{
                                                                                            '&.MuiPaginationItem-root': {
                                                                                              minWidth: '28px',
                                                                                              height: '28px',
                                                                                              fontSize: '12px'
                                                                                            },
                                                                                            '&.Mui-selected': {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            },
                                                                                            '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    )} siblingCount={1} count={Math.ceil((searchValue !== ''&&itemsSearchCount)?(itemsSearchCount/itemCount):(totalItems/itemCount))} color="primary" onChange={handleItemChange} />
                                                                            </Stack>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={styles.wrap}>
                                                                {activeIndex === 1 &&
                                                                    <>
                                                                        <CurrencyTableLayout usedFor='Link Rewards' tableFor='configure' type='Currency' items={linkCurrency} setData={setLinkCurrency} setValue={setLinkTaskRewards} title="Last edited" />
                                                                        <div className={styles.pagination}>
                                                                            <Stack spacing={2}>
                                                                                <Pagination renderItem={(item) => (
                                                                                    <PaginationItem
                                                                                        {...item}
                                                                                        sx={{
                                                                                            '&.MuiPaginationItem-root': {
                                                                                              minWidth: '28px',
                                                                                              height: '28px',
                                                                                              fontSize: '12px'
                                                                                            },
                                                                                            '&.Mui-selected': {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            },
                                                                                            '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    )} siblingCount={1} count={Math.ceil((searchValue !== ''&&currencySearchCount)?(currencySearchCount/currencyCount):(totalCurrency/currencyCount))} color="primary" onChange={handleCurrencyChange} />
                                                                            </Stack>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className={styles.wrap}>
                                                                {activeIndex === 2 &&
                                                                    <>
                                                                        <BundleTableLayout usedFor='Link Rewards' tableFor='configure' type='Bundles' items={linkBundle} setData={setLinkBundle} setValue={setLinkTaskRewards} title="Last edited" />
                                                                        <div className={styles.pagination}>
                                                                            <Stack spacing={2}>
                                                                                <Pagination renderItem={(item) => (
                                                                                    <PaginationItem
                                                                                        {...item}
                                                                                        sx={{
                                                                                            '&.MuiPaginationItem-root': {
                                                                                              minWidth: '28px',
                                                                                              height: '28px',
                                                                                              fontSize: '12px'
                                                                                            },
                                                                                            '&.Mui-selected': {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            },
                                                                                            '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    )} siblingCount={1} count={Math.ceil((searchValue !== ''&&bundleSearchCount)?(bundleSearchCount/bundleCount):(totalBundles/bundleCount))} color="primary" onChange={handleBundleChange} />
                                                                            </Stack>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={styles.wrap}>
                                                                {activeIndex === 3 &&
                                                                    <>
                                                                        <PMTableLayout usedFor='Link Rewards' tableFor='configure' type='Progression Marker' items={linkPm} setData={setLinkPm} setValue={setLinkTaskRewards} title="Last edited" />
                                                                        <div className={styles.pagination}>
                                                                            <Stack spacing={2}>
                                                                                <Pagination renderItem={(item) => (
                                                                                    <PaginationItem
                                                                                        {...item}
                                                                                        sx={{
                                                                                            '&.MuiPaginationItem-root': {
                                                                                              minWidth: '28px',
                                                                                              height: '28px',
                                                                                              fontSize: '12px'
                                                                                            },
                                                                                            '&.Mui-selected': {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            },
                                                                                            '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                                              color: 'rgb(255, 255, 255)',
                                                                                              fontWeight : 'bold',
                                                                                              border : '1px solid rgb(42, 133, 255)',
                                                                                              background : 'rgba(42, 133, 255)'
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    )} siblingCount={1} count={Math.ceil((searchValue !== ''&&pmSearchCount)?(pmSearchCount/PMCount):(totalPM/PMCount))} color="primary" onChange={handlePMChange} />
                                                                            </Stack>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.customData}>  
                        <TagInputWithDropdown id={`step-tag`} tooltip={TooltipTitle.SSTags} tags={tags} setTags={setTags}/>

                        <div className={styles.groupHeading}>
                            <span>Meta Data<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskMetaData} icon="info"/></span></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={styles.Save} onClick={submit}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={styles.Cancel} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
            </div>
        </>
    )

}

export default StepInformation