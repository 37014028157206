import {GET_LEADERBOARD, GET_LEADERBOARD_SUCCESS, GET_LEADERBOARD_ERROR, CREATE_LEADERBOARD, CREATE_LEADERBOARD_SUCCESS, CREATE_LEADERBOARD_ERROR, GET_LEADERBOARD_DETAIL, GET_LEADERBOARD_DETAIL_SUCCESS, GET_LEADERBOARD_DETAIL_ERROR, GET_LEADERBOARD_RESULT, GET_LEADERBOARD_RESULT_SUCCESS, GET_LEADERBOARD_RESULT_ERROR, FILTER_LEADERBOARD_SUCCESS, FILTER_LEADERBOARD_ERROR} from '../../../actionTypes/actionTypes'

const initialState = {
    leaderBoard : [],
    leaderBoardDetail : [],
    leaderBoardResult : [],
    leaderboardResultDates : [],
    resultCount : null,
    isLoading : false,
    isFiltered : false,
    error : null,
    totalCount : null
};

export const leaderboardReducer =( state=initialState, action )=>{
    switch(action.type){

        case GET_LEADERBOARD : {
            return {
                ...state,
                isLoading: true
            }
        }

        case GET_LEADERBOARD_SUCCESS : {
            return {
                ...state,
                leaderBoard : action.payload,
                isLoading : false,
                isFiltered : false,
                error : null,
                totalCount : action.totalCount,
                leaderboardResultDates : []
            }
        }

        case GET_LEADERBOARD_ERROR : {
            return {
                ...state,
                isLoading : false,
                isFiltered : false,
               error : action.payload,
               leaderboardResultDates : []
            }
        }

        case FILTER_LEADERBOARD_SUCCESS : {
            return {
                ...state,
                leaderBoard : action.payload,
                isLoading: false,
                isFiltered: true,
                totalCount: action.totalCount,
            }
        }

        case FILTER_LEADERBOARD_ERROR : {
            return {
                ...state,
                isLoading: false,
                isFiltered: false,
                error: action.payload
            }
        }

        case GET_LEADERBOARD_DETAIL : {
            return {
                ...state,
                leaderboardResultDates : []
            }
        }

        case GET_LEADERBOARD_DETAIL_SUCCESS : {
            return {
                ...state,
                leaderBoardDetail : action.payload,
                leaderboardResultDates : []
            }
        }

        case GET_LEADERBOARD_DETAIL_ERROR : {
            return {
                ...state,
               error : action.payload,
               leaderboardResultDates : []
            }
        }

        case GET_LEADERBOARD_RESULT : {
            return {
                ...state,
                leaderboardResultDates : []
            }
        }

        case GET_LEADERBOARD_RESULT_SUCCESS : {
            return {
                ...state,
                leaderBoardResult : action.payload,
                resultCount : action.totalCount,
                leaderboardResultDates : action.dates,
            }
        }

        case GET_LEADERBOARD_RESULT_ERROR : {
            return {
                ...state,
                leaderboardResultDates : []
            }
        }

        case CREATE_LEADERBOARD_SUCCESS : {
            return {
                ...state,
                leaderBoard : [...state.leaderBoard, action.payload],
                leaderboardResultDates : []
            }
        }

        case CREATE_LEADERBOARD_ERROR : {
            return {
                ...state,
               error : action.payload,
               leaderboardResultDates : []
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   