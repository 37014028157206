import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../Checkbox";
import Row from "./Row";

const Table = ({ items, title, locationOption , access}) => {

  const desiredLength = 10;
  items = items.concat(Array(desiredLength - items.length).fill({})).slice(0, desiredLength)

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Icon</div>
          <div className={styles.col}>Display Name</div>
          <div className={styles.col}>Categories</div>
          <div className={styles.col}>Location</div>
          <div className={styles.col}>Platform</div>
          <div className={styles.col}></div>
        </div>
        {items && items.map((x, index) => (
          <Row locationOption={locationOption} access={access} item={x} key={index} index={index} isLast={index>=items.length-3&&index>=2} />))}
      </div>
    </div>
  );
};

export default Table;
