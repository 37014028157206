import React, {useState} from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../../../../../components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import cn from 'classnames';
import styles from './eventInformation.module.sass'
import Card from '../../../../../components/Card';
import TextInput from '../../../../../components/TextInput';
import Dropdown from '../../../../../components/Dropdown/EventDropdown/DataTypesDropdown';
import TextArea from '../../../../../components/TextArea';
import Icon from '../../../../../components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import { WithContext as ReactTags } from "react-tag-input";
import Tooltip from '../../../../../components/Tooltip';
import Event from '../../../../../media/images/placeholders/App.png'
import { useRef } from 'react';
import Radio from '../../../../../components/Radio';
import { useEffect } from 'react';
import { createCustomEventAction } from '../../../../../redux/action/builds/events/eventActions';
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import {ReactComponent as AddButtonIcon} from '../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import TooltipTitle from '../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../components/MetaInput';
import { BASE_URL } from '../../../../../resources/APIEndpoints';
import app from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const EventInfo = ({className,isChanged, setIsChanged}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()

    const placeholder = Event
    const[{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 

    const [displayName, setDisplayName] = useState('')
    const [eventIcon, setEventIcon] = useState(null)
    const [eventId, setEventId] = useState('')
    const [eventDescription, setEventDescription] = useState()
    const [dataTypes, setDataTypes] = useState([])

    useEffect(()=>{
        getMetaData()
    }, [])

    const getMetaData = () => {
        dispatch(getAllMetaDataAction())
    }

    let data = useSelector((state) => {
        return state.common.metaData.dataTypes
    })

    const setAllData = () => {
        const allDataType = data && data.map((e,i) => ({id : e.id, name : e.dataTypeName}))
        setDataTypes(allDataType)
    }

    useEffect(()=>{
        setAllData()
    }, [data])

    

    const onDisplayName= (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    }

    const onEventId = (e) => {
        if(e.target.value == ''){
            setEventId('')
        }else{
            errors.idError = ''
            setEventId(e.target.value)
        }
    }

    const onEventDescription = (e) => {
        setEventDescription(e.target.value)
    }

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setEventIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setEventIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            if (/^[a-zA-Z0-9_ ]+$/i.test(tag.text)) {
                setTags([...tags, tag]);
            } 
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('eventName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.eventId == '') {
            error.idError = 'Event ID is required';
            isValid = false;
            if(data.name != ''){
                const element = document.getElementById('eventId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const db = getFirestore(app);

    const save = async() => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let eventTags = []
        tags && tags.forEach((e)=>{
            eventTags.push(e.text)
        })
        let event = {
            projectId : appid,
            name : displayName,
            description : eventDescription,
            eventId : eventId,
            customParameterDetails : customParameter.map(({status, dataType, ...rest}) => {return rest;}).filter(obj => obj.name != ''),
            meta : finalMetaData,
            tags : eventTags,
            defaultParameterDetails : [{name : 'eventID', type : 'state', dataTypeId : 1}]
        }
        if(validation(event)){
            try {
                // Await the response from createGameAction
                const res = await dispatch(createCustomEventAction(event, navigate, appid, setDisable))
                // Access the response data (e.g., game ID)
                if (res) {
                    const IdFromResponse = res?.data?.data?.appEventDetails?.id;
                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "CUSTOM EVENT",
                        resource_id: IdFromResponse, // Use the ID from response
                        description: `Custom Event '${displayName}' created successfully.`,
                        quantity: 1,
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
                    console.log("Activity logged successfully.");
                }
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }
        }
        
    }

    const cancel = () => {
        navigate(`/custom-events/${appid}`)
        setIsChanged(false)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [customParameter, setCustomParameter] = useState([{name : '', status : true, type: 'state', dataType : 'integer', dataTypeId : 2}])

    const addParaMeters = () => {
        setCustomParameter(prevState => [...prevState, {name : '', status : true, type: 'state', dataType : 'integer', dataTypeId : 2}])
    }

    const onChangeParameterName = (value, i) => {
        let customParameters = [...customParameter]
        customParameters[i].name = value
        setCustomParameter(customParameters)
    }

    const changeCustomeParameter = (status, value, i) => {
        let customParameters = [...customParameter]
        customParameters[i].status = status
        customParameters[i].type = value
        if(value == 'statistic'){
            customParameter[i].dataType = 'integer'
            customParameter[i].dataTypeId = 2
        }
        setCustomParameter(customParameters)
    }

    useEffect(()=>{

    }, [customParameter])


    const removeParameter = (index) => {
        let removeField = customParameter
        let metaFields = customParameter.filter((e,i,c)=>{
            return i != index
        })
        setCustomParameter(metaFields)
    }

    const [disable, setDisable] =  useState(false)


    const setchange = () => {
        if (
            (eventIcon==null||eventIcon==undefined)&&
            (displayName===undefined||displayName==''||displayName=="")&&
            (eventId===undefined||eventId==''||eventId=="")&&
            // (gameGerne.length==0)&&
            (eventDescription==undefined||eventDescription==''||eventDescription=="")&&
            (customParameter.length==1 && customParameter[0].name == '' && customParameter[0].dataTypeId == 2 && customParameter[0].status == true && customParameter[0].type == 'state' &&customParameter[0].dataType == 'integer')&&
            (tags.length==0)&&
            (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
        
    },[displayName,eventIcon,eventId,eventDescription,customParameter,tags,metaData])


    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                    <div className={styles.description}>
                        <div className={styles.group}>
                            <TextInput id='eventName' className={styles.field} errorMessage={errors.nameError} tooltip={TooltipTitle.EventName} label="Display Name*" name="DisplayName" type="text" required onChange={(e)=>{onDisplayName(e)}}/>
                            <TextInput isId={true} value={eventId} id='eventId' className={styles.field} errorMessage={errors.idError} tooltip={TooltipTitle.EventId} label="Event ID*" name="eventId" type="text" required onChange={(e)=>{onEventId(e)}}/>
                        </div>
                        <TextArea className={styles.field} value={eventDescription} rows={5} tooltip={TooltipTitle.EventDescription} label="Event Description" onChange={(e)=>{onEventDescription(e)}}/>
                    </div>
                </Card>
                <Card className={cn(styles.card, className)} title="Parameters" classTitle="title-blue">
                    <div className={styles.description}>
                        <div className={styles.group}>
                            <span className={styles.default}>Parameters</span>
                        </div>

                        <div className={styles.group}>
                            <TextInput disabled value='EventId' className={styles.field} label="" name="Name" type="text" required onChange={(e)=>{onDisplayName(e)}}/>
                        </div>

                        <div className={styles.group}>
                            <span>Custom Parameters</span><span className={styles.addIcon} onClick={addParaMeters}><AddMetaIcon /></span>
                        </div>
                        {customParameter && customParameter.map((e,i) => {
                            return(
                                <>
                                    <div className={styles.groupbyThree}>
                                        <TextInput className={styles.field}  label="Name" name="Name" type="text" required value={e.name} onChange={(e) => {onChangeParameterName(e.target.value, i)}}/>
                                        <div className={styles.field}>
                                            <div className={styles.radioLabel}>
                                                <span>Is Parameters<span><Tooltip className={styles.tooltip} title={TooltipTitle.EventParameter} icon="info"/></span></span>
                                            </div>
                                            <div className={styles.variants}>
                                                <Radio className={styles.radio} key={i} name={`parameter${i}`} value={!e.status} onChange={() => {changeCustomeParameter(false, 'statistic', i)}} content="Statistic" />
                                                <Radio className={styles.radio} key={i} name={`parameter${i}`} value={e.status} onChange={() => {changeCustomeParameter(true, 'state', i)}} content="State" />
                                            </div>
                                        </div>
                                        <div className={styles.field}>
                                            <Dropdown label='Type' value={e.dataType} setValue={setCustomParameter} data={customParameter} index={i} setData={setCustomParameter} options={e.type == 'statistic' && dataTypes && dataTypes.length > 0 ? dataTypes && dataTypes.filter((e, i) => {return (e.name == 'integer')}) : dataTypes}/>
                                        </div>
                                        <span className={styles.remove} onClick={()=>{removeParameter(i)}}>
                                            <Remove className={styles.icon} />
                                        </span>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                    <div className={styles.cardDescription}>
                    <TagInputWithDropdown tooltip={TooltipTitle.EventTags} tags={tags} setTags={setTags}/>
                        <div className={styles.createCurrency}>
                            <div className={styles.groupHeading}>
                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.EventMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                            </div>

                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>

                            <div className={styles.groupMeta}>
                                {metaData && metaData.map((e,i)=>{
                                        return(
                                            <>
                                                <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e,i)}}/>
                                                <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                                {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                                <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon} /></span>
                                            </>
                                        )
                                })}
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
        </>
    )
}

export default EventInfo