import { takeEvery, call, put, delay } from 'redux-saga/effects';
import axios from 'axios';
import { START_BULK_UPLOAD, apiBulkUploadSuccess, apiBulkUploadFailure, endBulkUploadCall } from '../../actions/itemsNbundles/bulkFileUploadAction';
import customAxios from '../../../utils/interceptor';
import { ADD_ITEMS, ADD_BUNDLES, BASE_URL, EDIT_ITEMS, EDIT_BUNDLES } from '../../../resources/APIEndpoints';

// API function to call the placeholder endpoint
function bulkFileUpload(payload, economy, method) {
    // We're calling the posts API, and payload can be used for query params if needed
    if(method === 'POST'){
      console.log('POST', payload)
      const url = BASE_URL + (economy === 'Items' ? ADD_ITEMS : economy === 'Bundles' ? ADD_BUNDLES : '')
      return customAxios.post(url, payload);
    }else if(method === 'PUT'){
      console.log('PUT', payload)
      const url = BASE_URL + (economy === 'Items' ? EDIT_ITEMS : economy === 'Bundles' ? EDIT_BUNDLES : '')
      return customAxios.post(url, payload);
    }
}

// Worker saga: handles the side effects
function* handleApiCalls(action) {
    const {payloads, length, reset, edit, economy, method} = action;
    const totalEntries = (economy === 'Items' ? payloads.items.length : economy === 'Bundles' ? payloads.bundles.length : [])
    const noOfCalls = (economy === 'Items' ? Math.ceil(payloads.items.length/100) : economy === 'Bundles' ? Math.ceil(payloads.bundles.length/100) : [])
    console.log(payloads, 'Payload', totalEntries, noOfCalls)
    // Array of payloads to send with each request
    for (let i = 0; i < noOfCalls; i++) {
        let payload = {[economy === 'Items' ? 'items' : economy === 'Bundles' ? 'bundles' : '']: payloads[economy === 'Items' ? 'items' : economy === 'Bundles' ? 'bundles' : ''].slice((i*100), ((i+1)*100))}
        console.log(payload)
        try {
        // Try to make the API call for the current payload
        const response = yield call(bulkFileUpload, payload, economy, method);
        // Dispatch success action if the API call succeeds
        yield put(apiBulkUploadSuccess(response.data, totalEntries, payload[economy === 'Items' ? 'items' : economy === 'Bundles' ? 'bundles' : '']?.length));
      } catch (error) {
        // Dispatch failure action if the API call fails
        yield put(apiBulkUploadFailure(error.message, totalEntries,  payload[economy === 'Items' ? 'items' : economy === 'Bundles' ? 'bundles' : '']?.length));
      }       
      // Wait for 10 seconds between each API call
      yield delay(10000);
    }
    yield delay(2000)
    if(method === 'POST'){
      if(!length){
        sessionStorage.removeItem('lockBy')
        reset()
        yield put(endBulkUploadCall());
      }else{
        if(edit){
          edit()
        }
      }
    }
    if(method === 'PUT'){
      sessionStorage.removeItem('lockBy')
      reset()
      yield put(endBulkUploadCall());
    }
}

// Watcher saga: watches for START_API_CALLS action
export default function* watchApiCallsItemNBundle() {
  yield takeEvery(START_BULK_UPLOAD, handleApiCalls);
}

// export default function* rootSaga() {
//   yield watchApiCallsItemNBundle();
// }
