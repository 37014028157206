import axios from '../../../utils/interceptor'
import { BASE_URL, UPDATE_ORGANISATION, VERIFY_BILLING, GET_PLANS, GET_INVOICE, GET_ADD_ONS, BUY_ADD_ONS, BUY_NEW_PLAN, GET_MY_PLAN, GET_PAYMENT, GET_PRICE_BOOK, EDIT_PAYMENT_METHOD, DOWNLOAD_INVOICE } from '../../../resources/APIEndpoints'

function BillingServices(){
    this.getPlans = () => axios.post(BASE_URL + GET_PLANS)
    this.getPlanBook = (body) => axios.post(BASE_URL + GET_PRICE_BOOK, body)
    this.getInvoice = (body) => axios.post(BASE_URL + GET_INVOICE, body)
    this.verifyBilling = () => axios.post(BASE_URL + VERIFY_BILLING)
    this.getAddOns = () => axios.post(BASE_URL + GET_ADD_ONS)
    this.buyAddOns = (body) => axios.post(BASE_URL + BUY_ADD_ONS, body)
    this.buyNewPlan = (body) => axios.post(BASE_URL + BUY_NEW_PLAN, body)
    this.updateOrganisation = (body) => axios.post(BASE_URL + UPDATE_ORGANISATION, body)
    this.getMyPlan = (body) => axios.post(BASE_URL + GET_MY_PLAN, body)
    this.getPayment = (body) => axios.post(BASE_URL + GET_PAYMENT, body)
    this.updateCardDetails = (body) => axios.post(BASE_URL + EDIT_PAYMENT_METHOD, body)
    this.downloadInvoice = (body) => axios.get(BASE_URL + DOWNLOAD_INVOICE+`?invoiceId=${body}`, {responseType: 'blob'})
}

export default new BillingServices();