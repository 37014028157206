import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./RadioDropdown.module.sass"
import { Link} from "react-router-dom";
import Radio from '../../Radio';
import Tooltip from "../../Tooltip";
import AddAppsModal from "../../../components/EngageComponents/LiveOpsComponent/AddCategoryModal";
import Modal from "../../../components/Modals/ModalComponent";
import { ReactComponent as  Divider} from "../../../media/images/icons/Divider.svg";
import { ReactComponent as  AddIconSvg} from "../../../media/images/icons/radio button/element/checked.svg";
const RadioDropdown = ({className, groupBy , classLabel, classDropdownHead,bgColor, classDropdownLabel, addOptionLink, placeholder, value, setValue,setBgColor, setId, setType, setDisplayId, setField, options, label, small, upBody, tooltip}) => {

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    function titleCase(str) {
        if (str) {
        var splitStr = str.split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' ');
        }
    }
    const handleClick = (values) => {
        setBgColor(values.colorCode)
        setValue(values.name)
        setId(values.id)
        if(setDisplayId){
            if(values.type == 'custom'){
                setDisplayId(values.eventId)
            }else{
                setDisplayId(values.id)
            }
        }
        if(setField){
           if(values.type == 'custom'){
            let field = [...values.customParameterDetails,...values.defaultParameterDetails]
            let fieldOptions = []
            field && field.forEach((e,i) => {
                fieldOptions.push({id : e.id, name : e.name, inputType : e.dataType.dataTypeName, type : e.type})
            })
            setField(field)
           }else{
            let field = [...values.customParameterDetails,...values.defaultParameterDetails, ...values.specterParameterDetails]
            let fieldOptions = []
            field && field.forEach((e,i) => {
                fieldOptions.push({id : e.id, name : e.name, inputType : e.dataType.dataTypeName, type : e.type})
            })
            setField(field)
           }
        }
        if(setType){
            setType(values.type)
        }
        setVisible(false)
    };
    const handleRadioChange = (option) => {
        handleClick(option);
        
    };
      
    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                {label}{" "}
                {tooltip && (
                    <Tooltip
                    className={styles.tooltip}
                    title={tooltip}
                    icon="info"
                    place="right"
                    />
                )}
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                <div className={styles.placeholder} ><span className={styles.optionValues} style={{backgroundColor:bgColor}}>{(value == null || value == undefined || value == '') ? placeholder : value}</span></div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {options && options.length>0? options.map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                        <Radio className={styles.radioOptionavalues} name="Options" value={x.name==value}/>
                        <span className={styles.optionValues} style={{backgroundColor:x.colorCode}}>{x.name != null ? x.name : x.levelName}</span>
                    </div>
                ))
                :<div style={{textAlign:"center"}} className={styles.opt}><div>No <span style={{color:"#33383F"}}>'{titleCase(label)}'</span> available</div><div>Click on <span style={{color:"#33383F"}}>'+'</span> to create</div></div>
                }
                <div className={styles.divider}></div>
                <div className={styles.add}>
                <Link onClick={()=>{setVisibleModal(true)}} className={styles.button}>
                    <AddIconSvg />
                </Link>
                </div>
                </div>
            </div>
            <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <AddAppsModal onClose={() => setVisibleModal(false)}/>
            </Modal>
            </OutsideClickHandler>
        </div>
    )
}

export default RadioDropdown