import React, { useEffect, useState } from "react";
import styles from './billingDetail.module.sass'
import TextInput from "../../../../components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import SearchDropdown from '../../../../components/Dropdown/SearchDropdown'
import { getAllCountriesForSignUpAction, getAllMetaDataAction, getAllStatesForSignUpAction, getCitiesAction, getRegionsAction } from "../../../../redux/action/common/commonAction";
import cn from 'classnames'
import { useLocation } from "react-router-dom";
import { updateOrganisationAction } from "../../../../redux/action/organisation/Billing/billingAction";
import {ReactComponent as Circle} from '../../../../media/images/smaller_circle.svg'
import {ReactComponent as OutlineCircle} from '../../../../media/images/full_hollow_circle.svg'
import Cross from '../../../../media/images/fill_cross.png'
import {ReactComponent as OutlineCross} from '../../../../media/images/hollow_crossed.svg'
import {ReactComponent as HalfCircle} from '../../../../media/images/half_ellipse.svg'
import {ReactComponent as FullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../../media/images/filled_cross.svg'
import { ToastContainer } from "react-toastify";
import BillingHeader from "../BillingHeader";

const BillingDetails = ({className}) => {

    const dispatch = useDispatch()
    const Location = useLocation();
    const { plan } = Location.state || {}

    const [isGSTRequired, setISGSTRequired] = useState(false)
    const [billingDetails, setBillingDetails] = useState({streetLineOne: '', streetLineTwo: '', country: '', state: '', city: '', zip: '', gstNo: ''})

    const [location, setLocation] = useState(null)
    const [locationId, setLocationId] = useState(null)
    const [locationOptions, setLocationOptions] = useState([])

    const [region, setRegion] = useState(null)
    const [regionId, setRegionId] = useState(null)
    const [regionOptions, setRegionOptions] = useState([])

    const [city, setCity] = useState(null)

    const onChangeBillingDetails = (e) => {
        const {name, value} = e.target
        setBillingDetails(prevState => ({...prevState, [name] : value}))
    }

    const configureCountry = () => {
        if(locationOption && locationOption?.length > 0){
            setLocationOptions(locationOption)
        }
    }

    const configureStates = () => {
        if(regionOption && regionOption?.length > 0){
            setRegionOptions(regionOption)
        }
    }

    const getRegions = () => {
        let country = {
            country : location,
            limit : 50,
            offset : 0
        }
        dispatch(getAllStatesForSignUpAction(country))
    }

    const [errors, setErrors] = useState({streetErr : '', countryErr : '', stateErr : '', cityErr: '', zipErr: '', gstErr: ''})

    const validation = (data) => {
        console.log(data)
        let isValid = true
        const error = {}
        if((data.billingAddress.street === '' || data.billingAddress.street === null)){
            isValid = false
            error.streetErr = 'Address is required';
        }
        if((data.billingAddress.country === '' || data.billingAddress.country === null)){
            isValid = false
            error.countryErr = 'Country is required';
        }
        if((data.billingAddress.state === '' || data.billingAddress.state === null)){
            isValid = false
            error.stateErr = 'State is required';
        }
        if((data.billingAddress.city === '' || data.billingAddress.city === null)){
            isValid = false
            error.cityErr = 'City is required';
        }
        if((data.billingAddress.zip === '' || data.billingAddress.zip === null)){
            isValid = false
            error.zipErr = 'ZipCode is required';
        }
        if(isGSTRequired){
            if(!data.gstNo || data.gstNo === '' || data.gstNo === null){
                isValid = false
                error.gstErr = `${location === 'India' ? 'GST No' : location !== 'India' ? 'VAT No' : ''}  is required`
            }
        }
        setErrors(error)

        if(isValid){
            return true
        }
    }

    const save = () => {
        let gstTreatment = JSON.parse(sessionStorage.getItem('gstTreatment'))
        let details = {
            billingAddress: {
                ...billingDetails,
                country: location,
                state: region,
                street: billingDetails.streetLineOne.concat((billingDetails.streetLineTwo && billingDetails.streetLineTwo !== ''  ? " " : ''), (billingDetails.streetLineTwo && billingDetails.streetLineTwo !== '' ? billingDetails.streetLineTwo : '')),
            },
            ...(billingDetails.gstNo !== null && billingDetails.gstNo !== '' && {gstNo: billingDetails.gstNo}),
            gstTreatment: gstTreatment
        }
        if(validation(details)){
            delete details?.billingAddress?.streetLineOne
            delete details?.billingAddress?.streetLineTwo
            delete details?.billingAddress?.gstNo
            dispatch(updateOrganisationAction(details, plan))
        }
    }


    const getAllLocation = () => {
        dispatch(getAllCountriesForSignUpAction())
    }

    let locationOption = useSelector((state) => {
        return state.common.countries
    })

    let regionOption = useSelector((state) => {
        return state.common.states
    })

    useEffect(() => {
        getAllLocation()
        configueGST()
    }, [])

    useEffect(() => {
        if(location !== null && location !== ''){
            getRegions()
        }
    }, [location])


    useEffect(() => {
        configureCountry()
    }, [locationOption])

    useEffect(() => {
        configureStates()
    }, [regionOption])

    let billingAddress = useSelector((state) => {
        return state.billing.billingDetails
    })

    const configureAddress = () => {
        if(billingAddress){
            setBillingDetails(details => ({...details, streetLineOne: (billingAddress?.billingAddress?.street ? billingAddress?.billingAddress?.street : ''), streetLineTwo: (billingAddress?.billingAddress?.street ? billingAddress?.billingAddress?.street : ''), country: (billingAddress?.billingAddress?.country ? billingAddress?.billingAddress?.country : ''), state: (billingAddress?.billingAddress?.state ? billingAddress?.billingAddress?.state : ''), city: (billingAddress?.billingAddress?.city ? billingAddress?.billingAddress?.city : ''), zip: (billingAddress?.billingAddress?.zip ? billingAddress?.billingAddress?.zip : ''), gstNo: (billingAddress?.billingAddress?.gstNo ? billingAddress?.billingAddress?.gstNo : '')}))
            setLocation(billingAddress?.billingAddress?.country ? billingAddress?.billingAddress?.country : '')
            setRegion(billingAddress?.billingAddress?.state ? billingAddress?.billingAddress?.state : '')
            setCity(billingAddress?.billingAddress?.city ? billingAddress?.billingAddress?.city : '')
        }
    }

    useEffect(() => {
        configureAddress()
    }, [billingAddress])

    useEffect(() => {
        if(locationId !== null && locationId !== ''){
            reset('country')
        }
    }, [locationId])

    const reset = (change) => {
        if(change === 'country'){
            setRegion('')
            setRegionId(null)
            setCity(null)
        }
        setRegionOptions([])
    }

    const configueGST = () => {
        let gst = JSON.parse(sessionStorage.getItem('isGST'))
        setISGSTRequired(gst)
    }

    return(
        <>
            <ToastContainer />
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <HalfCircle className={styles.topLeft}/>
                    <HalfCircle className={styles.bottomLeft}/>
                    <HalfCircle className={styles.middleRight}/>
                    <OutlineCross className={styles.crossLeftMiddle}/>
                    <FullCircle className={styles.fullCircle}/>
                    <OutlineCross className={styles.crossRightTop}/>
                    <OutlineCross className={styles.crossRightBottom}/>
                    <FullCircle className={styles.circleBottom}/>
                    <OutlineCircle className={styles.bottomOutline} />
                    <div className={styles.intercept}>
                        <Circle className={styles.overlapCircle} />
                        <OutlineCircle className={styles.overlapOutlineCircle} />
                    </div>
                    <img src={Cross} width={22} height={22} className={styles.leftFilledCross}/>
                    <img src={Cross} width={22} height={22} className={styles.rightTopFilledCross}/>
                    <img src={Cross} width={22} height={22} className={styles.rightBottomFilledCross}/>
                </div>
            <div className={styles.container}>
                <div className={styles.billings}>
                    <div className={styles.title}>Billing Details</div>
                    <div className={styles.billingContainer}>
                        <div className={styles.billingDetails}>
                            <div className={styles.group}>
                                <TextInput placeHolder='Address 1' className={styles.block} name='streetLineOne' errorMessage={errors.streetErr} position={`bottom`} value={billingDetails.streetLineOne} onChange={(e) => {onChangeBillingDetails(e)}}/>
                            </div>
                            <div className={styles.group}>
                                <TextInput placeHolder='Address 2' className={styles.block} name='streetLineTwo' errorMessage={errors.streetErr} position={`bottom`} value={billingDetails.streetLineTwo} onChange={(e) => {onChangeBillingDetails(e)}}/>
                            </div>
                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <SearchDropdown placeholder='Country' errorMessage={errors.countryErr} options={locationOptions} value={location} setValue={setLocation} setId={setLocationId}/>
                                </div>
                                <div className={styles.field}>
                                    <SearchDropdown placeholder='State' errorMessage={errors.stateErr} options={regionOptions} value={region} setValue={setRegion} setId={setRegionId}/>
                                </div>  
                            </div>
                            <div className={styles.group}>
                                {/* <div className={styles.field}>
                                    <SearchDropdown placeholder='City' errorMessage={errors.cityErr} options={cityOptions} value={city} setValue={setCity} setId={setCityId}/>
                                </div>   */}
                                <TextInput errorMessage={errors.cityErr} className={styles.field} position={`bottom`} type='text' name='city' placeHolder='City' value={billingDetails.city} onChange={(e) => {onChangeBillingDetails(e)}}/>
                                <TextInput errorMessage={errors.zipErr} className={styles.field} position={`bottom`} type='text' name='zip' placeHolder='Zipcode' value={billingDetails.zip} onChange={(e) => {onChangeBillingDetails(e)}}/>
                            </div>
                            {location &&
                                <div className={styles.group}>
                                    <TextInput errorMessage={errors.gstErr} placeHolder={location === 'India' ? 'GST No' : 'VAT No'} position={`bottom`} className={styles.block} name='gstNo' value={billingDetails.gstNo} onChange={(e) => {onChangeBillingDetails(e)}}/>
                                </div>
                            }
                            <div className={styles.group}>
                                <button className={cn(styles.button, 'button-small')} onClick={save}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    )
}

export default BillingDetails