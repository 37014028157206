import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";
import cn from 'classnames';
import styles from "./dropdown.module.sass";
import Tooltip from "../../Tooltip";
import { ReactComponent as AddIconSvg } from "../../../media/images/icons/radio button/element/checked.svg";
import Modal from "../../../components/Modals/ModalComponent";

const Dropdown = ({ className, setTask, details, groupBy, type, placeholder, classLabel, classDropdownHead, classDropdownLabel, value, setValue, setDetail, options, label, small, upBody, tooltip }) => {

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [filterText, setFilterText] = useState(value?value:'');

    function titleCase(str) {
        if (str) {
            var splitStr = str.split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }

    const createTask = () => {
        setTask(true);
        setFilterText(value?value:'');
        setVisible(false);
    };

    const handleClick = (val) => {
        setTask(false);
        setValue(val.name);
        setDetail(val);
        setVisible(false);
        setFilterText(value?value:'');
    };

    const filteredOptions = options&&options.filter(option =>
        option.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "30px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };

    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(value?value:''); }}>
                {label && (
                    <div className={cn(classLabel, styles.label)}>
                        {label}{" "}
                        {tooltip && (
                            <Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />
                        )}
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible })}>
                    <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.selection}>
                            <input
                                type="text"
                                style={inputStyle}
                                placeholder={placeholder}
                                value={filterText?filterText:''}
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                            />
                        </div>
                    </div>
                    {visible && (
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {filteredOptions.length > 0 ? filteredOptions.map && filteredOptions.map((x, index) => (
                                <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.name === value })} key={index}>
                                    {x.name}
                                </div>
                            )) : (
                                <div style={{ textAlign: "center" }} className={styles.option}>
                                    <div>No <span style={{ color: "#33383F" }}>'{titleCase(type)}'</span> available</div>
                                    <div>Click on <span style={{ color: "#33383F" }}>'+'</span> to create</div>
                                </div>
                            )}
                            <div className={styles.fxd} onClick={() => { setVisibleModal(true); }}>
                            <div className={styles.divider}></div>
                            <div onClick={createTask} style={{ textAlign: "center" }} className={cn(styles.option)}>
                                <Link className={styles.button}>
                                    <AddIconSvg />
                                </Link>
                            </div>
                            </div>
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
}

export default Dropdown;
