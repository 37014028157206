import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './changePassword.module.sass'
import Icon from '../../../../../components/Icon'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../Radio'
import { useParams } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import Dropdown from '../../../../Dropdown'
import { BASE_URL, GET_USER_WALLET } from '../../../../../resources/APIEndpoints'
import axios from 'axios'
import { resetPlayerPasswordAction } from '../../../../../redux/action/engage/Players/PlayersAction'
import app from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
 
const ChangePasswordModal = ({className, onClose, authProvider}) => {

    const {appid} = useParams()
    const {id} = useParams()

    const dispatch = useDispatch()

    const [userEmail, setUserEmail] = useState('')
    const [password, setPassword] = useState('')

    const db = getFirestore(app);
    const playerProfile = useSelector((state) => {
        return state.players.profile
    })

    const resetPassword = async () => {
        try {
            let resetPassword = {
                password : password
            }
            const res = await dispatch(resetPlayerPasswordAction(resetPassword))
    
            // If dispatch is successful, continue to log the activity
            const user = JSON.parse(localStorage.getItem('user'));
            const memberID = user?.id;
            const memberName = user?.name;
            const orgId = localStorage.getItem('orgId');
    
            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                app_id: appid,
                resource: "PLAYER",
                resource_id: id, // Use the ID from response
                description: `Password for player '${playerProfile?.[0]?.username}' updated successfully.`,
                quantity: 1,
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by: memberName,
                request_json: JSON.stringify(resetPassword),
            };
    
            await addDoc(collection(db, "activityFeed"), activityLog);
            console.log("Activity logged successfully.");
    
            // Close the modal or perform any post-operation tasks
            onClose();
        } catch (error) {
            // Handle the error properly here (e.g., show a toast, log to console, etc.)
            console.error("Error creating payout or logging activity:", error);
            // toast.error("Failed to create payout. Please try again.", {
            //     position: "top-right",
            //     icon: false,
            //     hideProgressBar: true,
            //     autoClose: 2000,
            //     style: { background: "#FAE8E7" },
            // });
        }
    }

    return (
        <>
            <div className={styles.resetPasswordModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.resetPassword}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Reset Password</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.changePasswordBody}>
                                <div className={styles.changePassword}>
                                    <div className={styles.group}>
                                        <TextInput className={styles.field} label='Email' type='email' placeholder='Enter Email' onChange={(e) => setUserEmail(e.target.value)} />
                                        <TextInput className={styles.field} label='Password' type='password' placeholder='Enter Password' onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.btnGroup}>
                                <button className={styles.save} onClick={resetPassword}>Save</button>
                                <button className={styles.cancel} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePasswordModal