// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1YZpZ5YNyN2fl_Y7Kp_cO4E97f5vNXaA",
  authDomain: "specter-dashboard-activity.firebaseapp.com",
  projectId: "specter-dashboard-activity",
  storageBucket: "specter-dashboard-activity.appspot.com",
  messagingSenderId: "412897099545",
  appId: "1:412897099545:web:5d2724064768649193cf88"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;