import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "./competition.module.sass";
import Card from "../../../../../../components/Card";
import Icon from "../../../../../../components/Icon";
import TextInput from "../../../../../../components/TextInput";
import TextArea from "../../../../../../components/TextArea";
import Radio from "../../../../../../components/Radio";
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
import ItemDropdown from "../../../../../Builds/Economy/Store/EditStores/StoreInformation/ItemDropdown"
import CommonDropdown from '../../../../../Builds/Economy/Store/EditStores/StoreInformation/CommonDropdown'
import Rewards from './Rewards'
import {ReactComponent as AddIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as EditIcon} from '../../../../../../media/images/icons/editSvg.svg'
import MatchDropdown from '../../../../../../components/Dropdown/LeaderboardMatchesDropdown';
import CurrencyDropdown from "../../../../../../components/Dropdown/CurrencyDropdown";
import PlatformDropdown from "../../../../../../components/Dropdown/PlatformDropdown";
// import Dropdown from "../../../../../../components/BuildComponents/EconomyComponent/CurrencyComponent/Dropdown";
import {addCurrecyAction, getAllCurrencyAction} from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import addIcon from '../../../../../../media/images/icons/light.png'
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../media/images/icons/remove.svg'
import { WithContext as ReactTags } from "react-tag-input";
import removeIcon from '../../../../../../media/images/icons/remove.png'
import Tooltip from "../../../../../../components/Tooltip";
import Toast from '../../../../../../components/Toast'
import Currency from '../../../../../../media/images/placeholders/Currency.png'
import { getAllMatchesAction } from "../../../../../../redux/action/builds/matches/matchesAction";
import Checkbox from "../../../../../../components/Checkbox";
import { BASE_URL, GET_CURRENCY, GET_ITEMS, GET_PROGRESSION_SYSTEM } from "../../../../../../resources/APIEndpoints";
import Multiselect from "../../../../../../components/MultiselectDropDown/PricingDropdown";
import { editCompetitionTournamentAction, getCompetitionTournamentDetailAction } from "../../../../../../redux/action/engage/Competitions/competitionAction";
import currencyPlaceholder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceholder from '../../../../../../media/images/placeholders/Items.png'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from "../../../../../../components/FileUploadSidebar";
import { getAllMediaAction } from "../../../../../../redux/action/AppSettings/Media/mediaAction";
import customAxios from "../../../../../../utils/interceptor";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../components/MetaInput";
import Dropdown from '../../../../../../components/Dropdown';
import Schedule from './Schedule'
import XLSX from 'xlsx';
import Control from "./Control";
import ControlFileUpload from "./ControlFileUpload";
import AddContentModal from "../../../../../../components/Modals/AddRewardModal";
import Modal from "../../../../../../components/Modals/ModalComponent";
import _ from 'lodash'
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const currencyTypeOption = ["Select Currency Type"]
const pricingOption = [{id: 1, name : "Virtual"}, {id: 3, name: "RMG"}]

const CompetitionInformation = ({ className, onClose, isChanged, setIsChanged }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {appid} = useParams()
    const {id} = useParams()
    const ref = useRef()

    const placeholder = Currency
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [currencyIcon, setCurrencyIcon] = useState(null)
    const [displayName, setDisplayName] = useState();
    const [competitionId, setComeptitionId] = useState('')
    const [description, setDescriptrion] = useState('')
    const [minPlayers, setMinPlayers] = useState(null)
    const [maxPlayers, setMaxPlayers] = useState(null)
    const [entry, setEntry] = useState(null)
    const [attempt, setAttempt] = useState(null)
    const [lockItems, setLockItems] = useState(false)
    const [lockProgression, setLockProgression] = useState(false)

    const lockBy = [{id: 1, title : "Item/Bundle", active : lockItems }, {id : 2, title : "Progression System",  active : lockProgression}]

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setCurrencyIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setCurrencyIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onDisplayName = (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    }

    const onCompetitionID = (e) => {
        if(e.target.value == ''){
            setComeptitionId('')
        }else{
            errors.idError = ''
            setComeptitionId(e.target.value)
        }
        
    }

    const onCompetitionDescription = (e) => {
        setDescriptrion(e.target.value)
    }

    const onMinPlayers = (e) => {
        if(e == '' || e == null || e == NaN){
            setMinPlayers(null)
        }else{
            errors.minError = ''
            setMinPlayers(parseInt(e))
        }
    }

    const onMaxPlayers = (e) => {
        if(e == '' || e == null || e == NaN){
            setMaxPlayers(null)
        }else{
            errors.maxError = ''
            setMaxPlayers(parseInt(e))
        }
    }

    const onEntry = (e) => {
        if(e == '' || e == null || e == NaN){
            setEntry(null)
        }else{
            errors.entryErr = ''
            setEntry(parseInt(e))
        }
    }

    const onAttempts = (e) => {
        if(e == '' || e == null || e == NaN){
            setAttempt(null)
        }else{
            errors.attemptErr = ''
            setAttempt(parseInt(e))
        }
    }

    // Prize Configure

    const [podiumRanks, setPodiumRanks] = useState([])

    const addRank = () => {
        setPodiumRanks(prevState => [...prevState, {ranks : podiumRanks.length+1, score : null}])
    }

    const onRankChange = (e) => {
      let initialRanks = []
      for(var i = 0; i < parseInt(e) ; i++){
        initialRanks.push({ranks : i+1, score : null})
      }
      setPodiumRanks(initialRanks)
    }

    const onScoreChange = (e, index) => {
      let totalPodiumRanks = [...podiumRanks]
      totalPodiumRanks[index].score = e
      setPodiumRanks(totalPodiumRanks)
    }

    const removePodiumRanks = (index) => {
      let removeField = [...podiumRanks]
      let podiumFields = removeField.filter((e,i,c)=>{
          return i != index
      })
      setPodiumRanks(podiumFields)
    }


    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', sourceErr: '', matchErr : '', minError : '', maxError: '', entryErr : '', attemptErr : '', pdErrorStart : [], pdErrorEnd : []})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name && data.name == '') {
          error.nameError = 'Display Name is required';
          isValid = false;
          const element = document.getElementById('competitionName');
          const offset = 140;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.competitionId && data.competitionId == '') {
          error.idError = 'Tournament ID is required';
          isValid = false;
          if(data.name && data.name != ''){
            const element = document.getElementById('competitionId');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }
        
        if (data.minPlayers && data.minPlayers == null) {
            error.minError = 'Minimum Player is required';
            isValid = false;
            if((data.name && data.name != '') && (data.competitionId && data.competitionId != '')){
                const element = document.getElementById('minPlayers');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        // if (data.maxPlayers == null) {
        //     error.maxError = 'Maximum Player is required';
        //     isValid = false;
        //     if(data.name != '' && data.competitionId != '' && data.minPlayers != null){
        //         const element = document.getElementById('maxPlayers');
        //         const offset = 140;
        //         const bodyRect = document.body.getBoundingClientRect().top;
        //         const elementRect = element.getBoundingClientRect().top;
        //         const elementPosition = elementRect - bodyRect;
        //         const offsetPosition = elementPosition - offset;
        //         window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        //     }
        // }

        if (data.sourceTypeId && data.sourceTypeId == null) {
            error.matchErr = 'Source is required';
            isValid = false;
            if((data.name && data.name != '') && (data.competitionId && data.competitionId != '') && (data.minPlayers && data.minPlayers != null)){
                const element = document.getElementById('source');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if ((data.matchId && data.matchId == null) && (data.sourceTypeId && data.sourceTypeId == 1)) {
            error.matchErr = 'Match is required';
            isValid = false;
            if((data.name && data.name != '') && (data.competitionId && data.competitionId != '') && (data.minPlayers && data.minPlayers != null) && (data.sourceTypeId && data.sourceTypeId != null)){
                const element = document.getElementById('match');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }
        
        if (data.maxEntryAllowed && data.maxEntryAllowed == null) {
            error.entryErr = 'Number of Entries is required';
            isValid = false;
            if((data.name && data.name != '') && (data.competitionId && data.competitionId != '') && (data.minPlayers && data.minPlayers != null) && (data.matchId && data.matchId != null)){
                const element = document.getElementById('entries');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.maxAttemptAllowed && data.maxAttemptAllowed == null) {
            error.attemptErr = 'Number of Attempt is required';
            isValid = false;
            if((data.name && data.name != '') && (data.competitionId && data.competitionId != '') && (data.minPlayers && data.minPlayers != null) && (data.maxEntryAllowed && data.maxEntryAllowed != null) && (data.matchId && data.matchId != null)){
                const element = document.getElementById('attempts');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }
        if(PD && data.prizeDistributionRule){
            error.pdErrorStart = []
            error.pdErrorEnd = []
            for(var i = 0 ; i < data.prizeDistributionRule.length ; i ++){
                if(data.prizeDistributionRule[i].startRank == '' || data.prizeDistributionRule[i].startRank == null){
                    error.pdErrorStart[i] = 'Start Rank is required'
                    isValid = false
                    if((data.name && data.name != '') && (data.competitionId && data.competitionId != '') && (data.minPlayers && data.minPlayers != null) && (data.maxEntryAllowed && data.maxEntryAllowed != null) && (data.maxAttemptAllowed && data.maxAttemptAllowed != null)){
                            const element = document.getElementById(`PDStart${i}`);
                            const offset = 100;
                            const bodyRect = document.body.getBoundingClientRect().top;
                            const elementRect = element.getBoundingClientRect().top;
                            const elementPosition = elementRect - bodyRect;
                            const offsetPosition = elementPosition - offset;
                            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                    }
                }else if(i > 0 && (data.prizeDistributionRule[i].startRank <= data.prizeDistributionRule[i-1].endRank)){
                    error.pdErrorStart[i] = 'Start rank must be greater than previous end rank'
                    toast.error(<Toast type='Error' messages='Start rank must be greater than previous end rank.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                    isValid = false
                    if((data.name && data.name != '') && (data.competitionId && data.competitionId != '') && (data.minPlayers && data.minPlayers != null) && (data.maxEntryAllowed && data.maxEntryAllowed != null) && (data.maxAttemptAllowed && data.maxAttemptAllowed != null)){
                            const element = document.getElementById(`PDStart${i}`);
                            const offset = 100;
                            const bodyRect = document.body.getBoundingClientRect().top;
                            const elementRect = element.getBoundingClientRect().top;
                            const elementPosition = elementRect - bodyRect;
                            const offsetPosition = elementPosition - offset;
                            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                    }
                }else if(i > 0 && (data.prizeDistributionRule[i].startRank > data.prizeDistributionRule[i].endRank)){
                    error.pdErrorEnd[i] = 'End rank must be equal to or greater that start rank'
                    toast.error(<Toast type='Error' messages='End rank must be equal to or greater that start rank.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                    isValid = false
                    if((data.name && data.name != '') && (data.competitionId && data.competitionId != '') && (data.minPlayers && data.minPlayers != null) && (data.maxEntryAllowed && data.maxEntryAllowed != null) && (data.maxAttemptAllowed && data.maxAttemptAllowed != null)){
                            const element = document.getElementById(`PDEnd${i}`);
                            const offset = 100;
                            const bodyRect = document.body.getBoundingClientRect().top;
                            const elementRect = element.getBoundingClientRect().top;
                            const elementPosition = elementRect - bodyRect;
                            const offsetPosition = elementPosition - offset;
                            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                    }
                }
                else{
                    error.pdErrorStart[i] = ''
                    error.pdErrorEnd[i] = ''
                }
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const removeIconKey = (data) => {
        data.forEach(entry => {
          const { rewardDetails } = entry;
          if (rewardDetails) {
            ['items', 'currencies', 'bundles', 'progressionMarkers'].forEach(key => {
              if (Array.isArray(rewardDetails[key])) {
                rewardDetails[key].forEach(item => {
                    if(item.hasOwnProperty('quantity')){
                        item.amount = item.quantity
                        // delete item.quantity
                    }
                    if (item && item.hasOwnProperty('iconUrl')) {
                        if(item.iconUrl && item.iconUrl.includes('data:image/png;base64')){
                            item.iconUrl = null
                        }
                    }
                    if (item.status && item.hasOwnProperty('status')) {
                        delete item.status;
                    }
                });
              }
            });
          }
        });
        return data;
    }

    const checkPricing = (arr1, arr2) => {
        // Step 1: Filter for changed objects in both arrays
        const updatedObjects = arr2.filter((item2) => {
            const item1 = _.find(arr1, { id: item2.id });
        
            if (!item1) return false; // Skip if there's no corresponding object in the first array
        
            if (item1.priceType === 'virtual currency') {
              // Check only specified fields for virtual currency
              return (
                item1.currencyId !== item2.currencyId ||  // Check currencyId
                item1.archive !== item2.archive ||         // Check archive
                parseFloat(item1.price) !== parseFloat(item2.price) ||  // Check price (amount)
                parseFloat(item1.discount) !== parseFloat(item2.discount)  // Check discount
              );
            }

            if (item1.priceType === 'RMG') {
                return (
                  item1.currencyId !== item2.currencyId ||          // Check currencyId
                  item1.archive !== item2.archive ||                 // Check archive
                  parseFloat(item1.bonusCashAllowance) !== parseFloat(item2.bonusCashAllowance) || // Check bonusCashAllowance
                  item1.hosting !== item2.hosting ||                 // Check hosting
                  item1.hostingFee !== item2.hostingFee ||           // Check hostingFee
                  parseFloat(item1.price) !== parseFloat(item2.price) // Check price (amount)
                );
            }
        
            return false; // Skip other price types if needed
        })

        // Step 2: Find newly added objects in the second array (not in the first array)
        const newObjects = arr2.filter(item2 => !_.find(arr1, { id: item2.id }));

        // Step 3: Combine the changed and newly added objects
        return [...updatedObjects, ...newObjects]
    }

    const getUpdatedLeaderboardObject = (referenceObject, updatedObject) => {
        const hasTypeMasterIdChanged = referenceObject[0].leaderboardOutcomeTypeMasterId !== updatedObject.leaderboardOutcomeTypeMasterId;
        // Check for changes in leaderboardOutcomeDetails
        const hasDetailsChanged = !_.isEqualWith(referenceObject[0].leaderboardOutcomeDetails, updatedObject.leaderboardOutcomeDetails, (val1, val2) => {
            // Custom comparison for leaderboardOutcomeDetails
            return _.isEqual(val1, val2); // Return true if each item is equal, otherwise false
        })
        // If there are changes in either field, return the updated object
        if (hasTypeMasterIdChanged || hasDetailsChanged) {
            return updatedObject; // Return the updated object without any additional keys
        }

        // Return null if there are no changes
        return null;
    }

    const compareMeta = (meta1, meta2) => {
        const keys1 = Object.keys(meta1);
        const keys2 = Object.keys(meta2);
        // If the number of keys or their values differ, return the whole meta2
        if (keys1.length !== keys2.length || !_.isEqual(meta1, meta2)) {
            return meta2; // Return the whole meta2 object
        }
        // Return null if there are no changes
        return null;
    }

    const compareRewardUnlock = (referenceArray, updatedArray) => {
        const findById = (arr, id) => arr && arr.find(item => item.id === id);
        // Iterate through the updated array to check for changes
        const result = updatedArray && updatedArray.filter(updatedItem => {
            const referenceItem = findById(referenceArray, updatedItem.id);
            // If no matching referenceItem exists, it's a new object
            if (!referenceItem) {
                updatedItem.archive = false;
                delete updatedItem.id;
                return true;
            }
            // Check if the relevant fields have changed
            const hasChanges = (
                referenceItem.archive !== updatedItem.archive ||
                referenceItem.unlockItemId !== updatedItem.unlockItemId ||
                referenceItem.unlockBundleId !== updatedItem.unlockBundleId ||
                referenceItem.unlockLevelSystemId !== updatedItem.unlockLevelSystemId ||
                referenceItem.lockedLevel !== updatedItem.lockedLevel
            );
            return hasChanges; // Include the object if any relevant field has changed
        });

        return result;
    }
    
    const filterChanged = (obj1, obj2) => {
        return _.reduce(obj2, (result, value, key) => {
          // Always include 'id' and 'uuid' keys
          if (key === 'id' || key === 'projectId') {
            result[key] = value;
          }
          // Special case: compare 'tags' in obj2 with 'tagsMapping' in obj1
          else if (key === 'tags') {
            const tagsFromMapping = obj1['tagsMapping'].map(mapping => mapping.tag.name);
            if (!_.isEqual(tagsFromMapping, value)) {
              result[key] = value; // map tags changes to 'tagsMapping'
            }
          }
          // Compare nested objects (like 'meta')
          else if (key === 'meta' && _.isObject(value) && _.isObject(obj1[key])) {
            const metaChanges = compareMeta(obj1.meta, obj2.meta);
            if (metaChanges) {
              result.meta = metaChanges; // Include the whole new meta if there are changes
            }
          }
          else if(key === 'competitionEntryPrices'){
            const updatedPrices = checkPricing(obj1.competitionEntryPrices, obj2.competitionEntryPrices)
            if(updatedPrices?.length > 0){
                result[key] = updatedPrices
            }
          }
          else if(key === 'prizeDistributionRule'){
            const updatedData = removeIconKey(ranks)
            result[key] = updatedData
          }
          else if(key === 'leaderboard'){
            let updatedLeaderboard = getUpdatedLeaderboardObject(obj1.leaderboard, obj2.leaderboard)
            if(updatedLeaderboard){
                result[key] = updatedLeaderboard
            }
          }
          else if(key === 'rewardUnlockCondition'){
            let updatedRewardUnlock = compareRewardUnlock(obj1.rewardsUnlockCondition, obj2.rewardUnlockCondition)
            if(updatedRewardUnlock?.length > 0){
                result[key] = updatedRewardUnlock
            }
          }
          // Normal comparison: add the key if values differ
          else if (!_.isEqual(obj1[key], value)) {
            result[key] = value;
          }
          return result;
        }, {})
    }
    const db = getFirestore(app);

    const submit = async (visible, setVisible) => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let competitionTags = []
        tags && tags.forEach((e)=>{
            competitionTags.push(e.text)
        })

        let competitionPrices = [...virtualCurrency, ...realMoney, ...IAP]
        const filteredPrice = competitionPrices.filter(item => {
            const price = item.amount;
            return (price !== null && price !== undefined) ;
        });

        const podiumRanksDetail = []
        podiumRanks && podiumRanks.forEach((e, i) => {
          var key_one = e.ranks
          var key_two = e.score
          podiumRanksDetail.push({[key_one]:key_two})
        })

        let unlockItems = []
        selectedItemId && selectedItemId.map((e,i) =>{
            unlockItems.push({archive:e.archive, id: e.id, unlockItemId : (e.unlockItemId ? e.unlockItemId : null), unlockBundleId: null, name:e.name, unlockLevelSystemId : null, lockedLevel : null})
        })

        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({archive:e.archive, id: e.dbID, unlockItemId : null, unlockBundleId: null, name:e.name, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })

        if (lockItems === false) {
            unlockItems && unlockItems.map((e)=>(e.archive=true))
        }
        if (lockProgression === false) {
            unlockPS && unlockPS.map((e)=>(e.archive=true,e.lockedLevel=null))
        }

        let unlockItemData = [...unlockItems, ...unlockPS]

        let competitionData = {
            id : id,
            competitionId: competitionId,
            name: displayName,
            icon : currencyIcon, 
            description: description,
            minPlayers: minPlayers,
            maxPlayers: (!limit ? maxPlayers : null),
            maxEntryAllowed: entry,
            maxAttemptAllowed: attempt,
            gameId: gameId,
            matchId: (sourceId == 1 ? matchId : null),
            competitionFormatTypeMasterId: 2,
            projectId: appid,
            isSpecialEvent: true,
            competitionEntryPrices: filteredPrice && filteredPrice.map((e) => {const {amount, currencyName, code, symbol, priceTypes, ...rest} = e; return {price: amount, priceType: priceTypes, competitionId: id, ...rest}}),
            active: true,
            archive: false,
            meta: finalMetaData,
            tags: competitionTags,
            sourceTypeId : sourceId,
            leaderboard:{
                id: leaderboardId,
                leaderboardOutcomeTypeMasterId: leaderboardOutcomeId,
                leaderboardOutcomeDetails: (leaderboardOutcomeId == 4 ? podiumRanksDetail : [])
            },
            prizeDistributionRule : (PD && ranks && ranks.length > 0 ? ranks : []),
            isLocked : ((lockItems == false && lockProgression == false) ? false : true),
            rewardUnlockCondition : unlockItemData.length > 0 ? unlockItemData : null,
        }
        let tournament = filterChanged(checkObject, competitionData)
        if(validation(tournament)){
            try {
                if(visible){
                    await dispatch(editCompetitionTournamentAction(tournament, navigate, appid, setDisable, visible, setVisible, 'Tournament'))
                    setIsChanged(false)
                }else{
                    await dispatch(editCompetitionTournamentAction(tournament, navigate, appid, setDisable, null, null , 'Tournament'))
                    setIsChanged(false)
                }
                // Access the response data (e.g., game ID) 
                setIsChanged(false);
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "UPDATE",
                    app_id:appid,
                    resource: "TOURNAMENT",
                    resource_id: id, // Use the ID from response
                    description: `Tournament '${displayName}' updated successfully.`,
                    quantity: 1,
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(tournament),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
                console.log("Activity logged successfully.");
            
                setIsChanged(false)
            } catch (error) {
                
            }
        }
    }

    const [disable, setDisable] = useState(false)

    const cancel = () => {
        navigate(`/competition-tournament/${appid}`)
        setIsChanged(false)
    }

    const onTagInput = () => {
        if(document.getElementById('currencyTagInput')){
            document.getElementById('currencyTagInput').focus()
        }
    }

    const [matchOptions, setMatchOptions] = useState([])
    const [matchName, setMatchName] = useState()
    const [matchId, setMatchId] = useState()
    const [gameId, setGameId] = useState()
    const [matchPage, setMatchPage] = useState(1)
    const [matchLimit, setMatchLimit] = useState(10)
    const [isCalled, setIsCalled] = useState(false)

    const getAllMatch = async() => {
        let match = {
            projectId : appid,
            offset: ((matchPage-1)*matchLimit),
            limit: matchLimit
        }
        await dispatch(getAllMatchesAction(match, setIsCalled))
      }
  
      useEffect(()=>{
        getAllMatch()
      }, [matchPage])
  
    let matches = useSelector((state) =>{
        return state.matches.matches
    })

    const configureMatch = () => {
        if(isCalled){
            if(matches && matches.length > 0){
                let allMatches = []
                matches && matches.forEach((e,i) => {
                    allMatches.push(e)
                })
                setMatchOptions(matchOptions => [...matchOptions, ...allMatches])
            }
        }
      }
  
    useEffect(()=>{
        configureMatch()
    }, [matches])

    const [ranks, setRanks] = useState([{startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}}])
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [indexLevel, setIndexLevel] = useState()
    const [type, setType] = useState()
    const [PD, setPD] = useState(false)

    useEffect(()=>{
        setStorage();
    }, [])

    const setStorage = () => {
      sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
    }

    const addRanks = () => {
      const totalRanks = [...ranks]
      totalRanks.push({startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}})
      setRanks(totalRanks)
      sessionStorage.setItem('PDRanks', JSON.stringify(totalRanks))
    }

    const onChangeStartRank = (index, value) => {
      if (/^[a-zA-Z0-9- ]+$/i.test(value) || value == '') {
          const updatedInputData = [...ranks];
          updatedInputData[index].startRank = parseInt(value);
          setRanks(updatedInputData);
          sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
          if(value != null || value != NaN){
            let errorData = errors
            errorData.pdErrorStart[index] = ''
            setErrors(errorData)
          }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
      };
    
    const onChangeRankReward = (e) => {
        let initialRanks = []
        for(var i = 0; i < parseInt(e) ; i++){
          initialRanks.push({startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}})
        }
        setRanks(initialRanks)
        sessionStorage.setItem('PDRanks', JSON.stringify(initialRanks))
    }
    
    
    const removeRanks = (index) => {
        let removeField = [...ranks]
        let rankFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setRanks(rankFields)
        sessionStorage.setItem('PDRanks', JSON.stringify(rankFields))
    }
    
    const onChangeEndRank = (index, value) => {
        const updatedInputData = [...ranks];
        updatedInputData[index].endRank = parseInt(value);
        setRanks(updatedInputData);
        sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
        if(value != null || value != NaN){
            let errorData = errors
            errorData.pdErrorEnd[index] = ''
            setErrors(errorData)
          }
    }

    const onLockBy = (e, title) => {
        if(e.target.checked){
            if(title == 'Item/Bundle'){
                setLockItems(true)
            }else{
                setLockProgression(true)
            }
        }else{
            if(title == 'Item/Bundle'){
                setLockItems(false)
            }else{
                setLockProgression(false)
            }
        }
    }

    // Get Item API Call
    const [itemOptions, setItemOption] = useState()
    const [items, setItems] = useState()
    const getItem = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_ITEMS, items, headers)
        setItemOption(res.data.data.items)
    }

    // Get Progression System API Call
    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])

    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }

    const [unlockItemId, setUnlockItemId] = useState()
    const [unlockSystemId, setUnlockSystemId] = useState()

    const [lockItemErr, setLockItemErr] = useState(false)
    const [lockPSErr, setLockPSErr] = useState(false)

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }

    // Entry Type
    const [pricing, setPricing] = useState([])
    const [showVirtual, setShowVirtual] = useState(false)
    const [showInApp, setShowInApp] = useState(false)
    const [showRMG, setShowRMG] = useState(false)

    const[virtualCurrency, setVirtualCurrency] = useState([])

    const addVirtualCurrency = () => {
        const currencyFields = []
        currencyFields.push(...virtualCurrency, {priceTypes: '', currencyId : null, amount : null , discount : null, archive: false})
        setVirtualCurrency(currencyFields)
    }

    const removeVirtualCurrency = (index) => {
        let removeField = virtualCurrency
        let currentFields = removeField.filter((e,i,c)=>{
            return (i == index ? e.archive = true : e.archive == false)
        })
        setVirtualCurrency(currentFields)
        const activeVirtualCurrency = currentFields.filter(item => !item.archive)
        if(activeVirtualCurrency.length == 0){
            setPricing(pricing.filter((values) => values.name != 'Virtual'))
            setShowVirtual(false)
        }
    }

    const onVirtualCurrency = (e, index, type) => {
        let virtualCurrencyList = [...virtualCurrency]
        if(type == 'currency'){
            virtualCurrencyList[index].currencyId = e.value
            virtualCurrencyList[index].priceTypes = (e.currencyTypes == 'real' ? 'real money' : e.currencyTypes == 'virtual' ? 'virtual currency'  : '')
        }else{
            virtualCurrencyList[index][e.target.name] = (type == 'amount' ? parseFloat(e.target.value) : parseInt(e.target.value))
        }
        setVirtualCurrency(virtualCurrencyList)
    }

    const[IAP, setIAP] = useState([])

    const addIAP = () => {
        const IAPFields = []
        IAPFields.push(...IAP, {priceTypes: '', realWorldCurrencyId : null, gamePlatformMasterId: null, productId : '', gamePlatformName:'',  amount : null , discount : null})
        setIAP(IAPFields)
    }

    const removeIAP = (index) => {
        let removeField = IAP
        let currentFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setIAP(currentFields)
        if(currentFields.length == 0){
            setPricing(pricing.filter((values) => values.name != 'In-App Purchase'))
            setShowInApp(false)
        }
    }

    const onIAP = (e, index, type) => {
        let IAPList = [...IAP]
        IAPList[index][e.target.name] =  parseInt(e.target.value)
        setIAP(IAPList)
    }

    const[realMoney, setRealMoney] = useState([])

    const addRealMoney = () => {
        const realMoneyFields = []
        realMoneyFields.push(...realMoney, {priceTypes: '', currencyId : null, bonusCashAllowance : null,  amount : null , hosting: false, hostingFeeType: 'percentage', hostingFee : null, archive: false})
        setRealMoney(realMoneyFields)
    }

    const removeRealMoney = (index) => {
        let removeField = realMoney
        let currentFields = removeField.filter((e,i,c)=>{
            return (i == index ? e.archive = true : e.archive == false)
        })
        setRealMoney(currentFields)
        const activeVirtualCurrency = currentFields.filter(item => !item.archive)
        if(activeVirtualCurrency.length == 0){
            setPricing(pricing.filter((values) => values.name != 'RMG'))
            setShowRMG(false)
        }
    }

    const onRealMoney = (e, index, type) => {
        let realMoneyList = [...realMoney]
        if(type == 'currency'){
            realMoneyList[index].currencyId = e.value
            realMoneyList[index].priceTypes = (e.currencyTypes == 'real' ? 'real money' : e.currencyTypes == 'virtual' ? 'virtual currency'  : '')
        }else{
            realMoneyList[index][e.target.name] = (type == 'amount' ? parseFloat(e.target.value) : parseInt(e.target.value))
        }
        setRealMoney(realMoneyList)
    }

    const onChangeHostingType = (value, type, index) => {
        let realMoneyList = [...realMoney]
        realMoneyList[index].hosting = value
        realMoneyList[index].hostingFeeType = type
        setRealMoney(realMoneyList)
    }

    const [allCurrency, setAllCurrency] = useState([])
    const getAllCurrencyData = () => {
        let getAllCurrency = {
            projectId : appid,
        }
        dispatch(getAllCurrencyAction(getAllCurrency))
    }

    const [virtualCurrencyData, setVirtualCurrencyData] = useState([])
    const [realMoneyData, setRealMoneyData] = useState([])

    const customizeCurrencyData = () => {
        if(currenciesData){
            setAllCurrency(currenciesData && currenciesData.map(value => ({...value, status : false, quantity : null})))
            let vc = currenciesData && currenciesData.filter((e,i) => e.type == 'virtual')
            let vcData = []
            vc && vc.forEach((e,i) =>{
                vcData.push( {name : e.name, id : e.id, type : 'virtual currency'})
            })
            setVirtualCurrencyData(vcData)
            let rm = currenciesData && currenciesData.filter((e,i) => e.type == 'real')
            let rmData = []
            rm && rm.forEach((e,i) =>{
                rmData.push({name : e.name, id : e.id, type : 'RMG'})
            })
            setRealMoneyData(rmData)
        }
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    useEffect(()=>{
        customizeCurrencyData()
    }, [currenciesData])

    useEffect(() => {
        getAllCurrencyData()
        getMetaData()
        getRealWorldCurrency()
        getItem()
        getPS()
    }, [])

        // Get Meta Data API Call for Platform 
    const [platform, setPlatform] = useState([])
    const [platformOptions, setPlatformOptions] = useState([])

    const [leaderboardOutcomeOption, setLeaderboardOutcomeOption] = useState()
    const [leaderboardOutcome, setLeaderboardOutcome] = useState('')
    const [leaderboardOutcomeId, setLeaderboardOutcomeId] = useState(null)
    
    const getMetaData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.forEach((element) => {
            setPlatformOptions(prevState => [...prevState, {id : element.id, name : (element.platformName == 'android' ? 'Android' : 'iOS')}])
        })
        setSourceOption(res.data.data.leaderboardSourceTypeMasterIds && res.data.data.leaderboardSourceTypeMasterIds.map((obj) => {
            if(obj.id !== 2){
                return {
                    id: obj.id,
                    name: (obj.name == 'match' ? 'Match' : obj.name == 'custom' ? 'Custom': ''),
                };
            }else{
                return null
            }
        }).filter(Boolean))
        setLeaderboardOutcomeOption(res.data.data.leaderboardOutcomeIds && res.data.data.leaderboardOutcomeIds.map((obj) => {
            return {
              id: obj.id,
              name: obj.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
            };
        }))
    }

    const [realWorldCurrency, setRealWorldCurrency] = useState([])
    const getRealWorldCurrency = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
        res.data.data && res.data.data.forEach((element) => {
            setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"), type : 'IAP'}])
        })
    }

    const getCompetitionTournamentDetail = async() => {
        let competitionId = {
            ids : [id],
            projectId : appid,
            competitionFormatTypeMasterId : 2
        }
        await dispatch(getCompetitionTournamentDetailAction(competitionId))
    }

    let tournamentDetail = useSelector((state)=>{
        return state.competition.tournamentDetail
    })

    const [selectedItemId, setSelectedItemId] = useState([])

    const [checkObject, setCheckObject] = useState()
    const [leaderboardId, setLeaderboardId] = useState(null)
    const [disableSchedule, setDisableSchedule] = useState(false)

    const convertAmountToQuantity = (rewardsArray) => {
        rewardsArray.forEach(reward => {
            const { rewardDetails } = reward;
            // Arrays to modify: currencies, items, bundles, progressionMarkers
            const arraysToModify = ['currencies', 'items', 'bundles', 'progressionMarkers'];
    
            arraysToModify.forEach(key => {
                if (rewardDetails[key]?.length > 0) {
                    rewardDetails[key].forEach(item => {
                        if (item.hasOwnProperty('amount')) {
                            item.quantity = item.amount;
                            delete item.amount;
                        }
                    });
                }
            });
        });
        return rewardsArray;
    }

    const configureTournaments = () => {
        if(tournamentDetail && tournamentDetail.length > 0){
            let tournament = tournamentDetail[0]
            if(tournament.scheduleDetails && tournament.scheduleDetails?.length > 0 && tournament.scheduleDetails.scheduleType === 'normal'){
                if(tournament.scheduleDetails.scheduleInstances?.length > 0 && tournament.scheduleDetails[0].scheduleInstances && tournament.scheduleDetails[0].scheduleInstances?.length > 0 && tournament.scheduleDetails.scheduleInstances[0].status !== 'yet to start'){
                  setDisableSchedule(true)
                }else{
                  setDisableSchedule(false)
                }
              }else{
                if(tournament.scheduleDetails && tournament.scheduleDetails?.length > 0 && tournament.scheduleDetails[0].scheduleInstances && tournament.scheduleDetails[0].scheduleInstances?.length > 0 && tournament.scheduleDetails[0].scheduleInstances?.length > 1){
                  setDisableSchedule(true)
                }else{
                  if(tournament.scheduleDetails && tournament.scheduleDetails?.length > 0 && tournament.scheduleDetails[0].scheduleInstances && tournament.scheduleDetails[0].scheduleInstances?.length > 0 && tournament.scheduleDetails[0].scheduleInstances[0].status !== 'yet to start'){
                    setDisableSchedule(true)
                  }else{
                    setDisableSchedule(false)
                  }
                }
            }

            setDisplayName(tournament.name)
            setCurrencyIcon(tournament.icon)
            setLogo({src : (tournament.icon && tournament.icon != null ? tournament.icon : currencyPlaceholder) , alt: 'competition_logo'})
            setComeptitionId(tournament.competitionId)
            setDescriptrion(tournament.description)
            setMinPlayers(parseInt(tournament.minPlayers))
            setMaxPlayers(parseInt(tournament.maxPlayers))
            setLimit(tournament.maxPlayers === null ? true : false)
            setGameId(tournament.gameId != null ? tournament.gameId : null)
            setMatchId(tournament.matchId != null ? tournament.matchId : null)
            setMatchName(tournament.match != null ? tournament.match.name : null)
            //Config
            setCheckObject(tournament)
            setEntry(parseInt(tournament.maxEntryAllowed))
            setAttempt(parseInt(tournament.maxAttemptAllowed))
            if(tournament.leaderboard != null && tournament.leaderboard?.length > 0){
                setLeaderboardId(tournament.leaderboard[0].id)
                setLeaderboardOutcome(tournament.leaderboard[0]?.leaderboardOutcomeMaster?.id == 1 ? 'High Score' : tournament.leaderboard[0]?.leaderboardOutcomeMaster?.id == 2 ? 'Time Trail' : tournament.leaderboard[0]?.leaderboardOutcomeMaster?.id == 3 ? 'Win Loss Draw Points' : tournament.leaderboard[0]?.leaderboardOutcomeMaster?.id == 4 ? 'Position Weighting' : tournament.leaderboard[0]?.leaderboardOutcomeMaster?.id == 5 ? 'Cumulative Score' : '')
                setLeaderboardOutcomeId(tournament.leaderboard[0].leaderboardOutcomeMaster?.id)
                if(tournament.leaderboard[0].leaderboardOutcomeMaster?.id == 4){
                    const podiumRanksDetail = tournament.leaderboard[0]?.leaderboardOutcomeDetails && tournament.leaderboard[0]?.leaderboardOutcomeDetails?.map((e, i) => {
                        const [ranks, score] = Object.entries(e)[0];
                        return {ranks: parseInt(ranks), score: score}
                    })
                    setPodiumRanks(podiumRanksDetail)
                }
            }
            
            if(tournament.leaderboardSourceTypeMaster != null){
                setSource(tournament.leaderboardSourceTypeMaster.id == 1 ? 'Match' : tournament.leaderboardSourceTypeMaster.id == 3 ? 'Custom' : '')
                setSourceId(tournament.leaderboardSourceTypeMaster.id)
            }
            const pricing = []
            if(tournament.competitionEntryPrices != null && tournament.competitionEntryPrices.length > 0){
                for(var i = 0 ; i < tournament.competitionEntryPrices.length ; i++){
                    let currentPricing = tournament.competitionEntryPrices[i]
                    if(currentPricing.priceType == 'virtual currency' || currentPricing.priceType == 'virtual'){
                            const index = pricing.findIndex(object => object.name == 'Virtual')
                            if(index === -1){
                                pricing.push({id : 1, name : "Virtual"})
                            }
                            setVirtualCurrency(prevState => [...prevState, {id : currentPricing.id, currencyName : (currentPricing.currencies != null ? currentPricing.currencies.name : null), inventoryItemId : currentPricing.inventoryItemId, priceTypes: currentPricing.priceType, currencyId : currentPricing.currencyId, amount : parseFloat(currentPricing.price), discount : parseFloat(currentPricing.discount), archive : currentPricing.archive}])
                            setShowVirtual(true)
                    }else if(currentPricing.priceType == 'RMG' || currentPricing.priceType == 'real money'){
                            const index = pricing.findIndex(object => object.name == 'RMG')
                            if(index === -1){
                                pricing.push({id : 2, name : "RMG"})
                            }
                            setRealMoney(prevState => [...prevState, {id : currentPricing.id, currencyName : (currentPricing.currencies != null ? currentPricing.currencies.name : null), inventoryItemId : currentPricing.inventoryItemId, priceTypes: currentPricing.priceType, currencyId : currentPricing.currencyId, bonusCashAllowance : parseFloat(currentPricing.bonusCashAllowance),  amount : parseFloat(currentPricing.price) , hosting : currentPricing.hosting, hostingFee : currentPricing.hostingFee, discount : parseFloat(currentPricing.discount), archive : currentPricing.archive}])
                            setShowRMG(true)
                    }else if(currentPricing.priceType == 'IAP'){
                            const index = pricing.findIndex(object => object.name == 'In-App Purchase')
                            if(index === -1){
                                pricing.push({id : 3, name : "In-App Purchase"})
                            }
                            setIAP(prevState => [...prevState, {id : currentPricing.id, realWorldCurrencyId: (currentPricing.realWorldCurrency != null ? currentPricing.realWorldCurrency.id : null) , currencyName : (currentPricing.realWorldCurrency != null ? currentPricing.realWorldCurrency.code.concat(" (", currentPricing.realWorldCurrency.symbol, ")") : null), inventoryItemId : currentPricing.inventoryItemId, priceTypes : 'IAP', amount : parseFloat(currentPricing.price) , productId: currentPricing.productId, discount : parseFloat(currentPricing.discount), gamePlatformName : (currentPricing.gamePlatformMasterId == 1 ? 'Android' : 'iOS'), archive : currentPricing.archive}])
                            setShowInApp(true)
                    }
                }
            }
            setPricing(pricing)
            //PD
            let PDReward = []
            if(tournament.prizeDistributionRule == null || tournament.prizeDistributionRule?.length == 0){
                let initialLevels = [{startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}}]
                setPD(false)
                setRanks(initialLevels)
                sessionStorage.setItem('Ranks', JSON.stringify(initialLevels))
            }else{
                const updatedRewardsArray = convertAmountToQuantity(tournament.prizeDistributionRule)
                setPD(true)
                sessionStorage.setItem('Ranks', JSON.stringify(updatedRewardsArray))
                setRanks(updatedRewardsArray)
            }
            if(tournament.rewardsUnlockCondition){
                tournament.rewardsUnlockCondition && tournament.rewardsUnlockCondition.forEach((ele, i) => {
                    if(ele && ele.unlockItemId != null){
                        if(ele.id){
                            setSelectedItemId(prevState => [...prevState,{id: ele.id, unlockItemId: ele.inventoryUnlockItems.id, archive: ele.archive, name : ele.inventoryUnlockItems.name}])
                            setUnlockItemId(ele.inventoryUnlockItems.id)
                        }
                        if (!ele.archive) {
                            setLockItems(true)
                        }
                        
                    }else{
                        if (!ele.archive) {
                            setLockProgression(true)
                        }
                        if(ele.id){
                            if (!ele.archive) {
                                setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.levelSystem.id, name : ele.levelSystem.name, lockedLevel: ele.lockedLevel, archive:ele.archive}])
                            }
                            else{
                                setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.levelSystem.id, name : ele.levelSystem.name, lockedLevel: null, archive:ele.archive}])
                            }
                        }
                    }
                })
            }
            let tournamentsTags = []
            tournament.tagsMapping && tournament.tagsMapping.forEach(e => {
                e.tag && tournamentsTags.push({id : e.tag.id, text : e.tag.name})
            })
            setTags(tournamentsTags)
            let meta = []
            if(tournament.meta == null || JSON.stringify(tournament.meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                tournament.meta && Object.keys(tournament.meta).forEach(e => {
                meta.push({key : e, value : tournament.meta[e]})
              })
              setMetaData(meta)
            } 
        }
    }

    useEffect(()=>{
        configureTournaments()
    }, [tournamentDetail])

    useEffect(()=>{
        getCompetitionTournamentDetail()
        setVirtualCurrency([])
        setRealMoney([])
        setIAP([])
        setItems()
        setUnlockItemId()
        setSelectedItemId([])
        setLockItems()
        setLockProgression()
        setPS([])
    }, [])

    const [pdValidation, setPDValidation] = useState(false)
    const [visibleFile, setVisibleFile] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
        getAllRewards()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    function comparePricingArrays(arr1, arr2) {
         
        let array1=arr1.filter(item => !item.archive)
        let array2=arr2.filter(item => !item.archive)
        if (array1.length !== array2.length) {
            return false
        }
        for (let i = 0; i < array1?.length; i++) {
            if (
                (array1[i]?.realWorldCurrencyId===array2[i]?.realWorldCurrencyId|| array2[i]?.realWorldCurrencyId==undefined|| array2[i]?.realWorldCurrencyId==null)&&
                (array1[i]?.currencyId===array2[i]?.currencyId || array2[i]?.currencyId==undefined|| array2[i]?.currencyId==null)&&
                (parseInt(array1[i]?.amount)===array2[i]?.price)&&
                array1[i].priceTypes===array2[i].priceType
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }
    function compareRewardsArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false;
        }
      
        // Iterate over the arrays
        if(arr1.length>0){
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]?.id===array2[i]?.id )&&
                (array1[i]?.quantity===array2[i]?.quantity)
            ) {
            }
            else{
                return false;
            }
        }
        }
        return true;
      }
      
        function compareRankArrays(arr1, arr2) {
             
          let array1=arr1&& arr1.filter(item => !item?.archive)
          let array2=arr2&& arr2.filter(item => !item?.archive)
          if (array1?.length !== array2?.length) {
              return false;
          }
      
          // Iterate over the arrays
          for (let i = 0; i < array1.length; i++) {
            let rewardCheck=compareRewardsArrays(array1[i]?.rewardDetails,array2[i]?.rewardDetails)
              if (
                  (array1[i]?.endRank===array2[i]?.endRank || array2[i]?.endRank==undefined|| array2[i]?.endRank==null)&&
                  (array1[i]?.startRank===array2[i]?.startRank || array2[i]?.startRank==undefined|| array2[i]?.startRank==null)&&
                //   (array2[i]?.no==undefined|| array2[i]?.no==null)&&
                  (rewardCheck)
              ) {
              }
              else{
                  return false;
              }
          }
      
          return true;
        }
      

    const setchange = () => {
         
        let itemPrices = [...virtualCurrency, ...IAP, ...realMoney]
        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })
        // let PDReward = []
        // if(checkObject?.prizeDistributionRule == null || checkObject?.prizeDistributionRule?.length == 0){
            
        //     PDReward = [{no : 1, startRank : null, endRank : null, rewardDetails : []}]
        // }else{
        //     for(var i = 0 ; i < checkObject?.prizeDistributionRule.length ; i++){
        //         let currentReward = checkObject?.prizeDistributionRule[i].rewardDetails.map(i => ({id: (i.type == 'currency' ? i.currency.id : i.type == 'item' ?  i.item.id : ''), icon : (i.type == 'currency' && i.currency && i.currency.iconUrl != null ? i.currency.iconUrl : i.type == 'currency' && i.currency && i.currency.iconUrl == null ? currencyPlaceholder  : i.type == 'item' && i.item && i.item.iconUrl != null ? i.item.iconUrl : i.type == 'item' && i.item && i.item.iconUrl == null ? itemPlaceholder : '') , name : (i.type == 'currency' ? i.currency.name : i.type == 'item' ? i.item.name : ''), quantity : i.amount , status : true, type: i.type}))
        //         PDReward.push({no : i+1, startRank : checkObject.prizeDistributionRule[i].startRank, endRank : checkObject.prizeDistributionRule[i].endRank, rewardDetails : currentReward})
        //     }
        // }

        // let rankCheck =checkObject&& compareRankArrays(ranks,PDReward)
        // let pricecheck =checkObject && ((checkObject.competitionEntryPrices&& comparePricingArrays(itemPrices,checkObject.competitionEntryPrices))||!checkObject?.competitionEntryPrices)
        if (checkObject&&
            (currencyIcon==checkObject.icon)&&
            (displayName===checkObject.name)&&
            (competitionId===checkObject.competitionId)&&
            (description===checkObject.description)&&
            (minPlayers==checkObject.minPlayers)&&
            (maxPlayers==checkObject.maxPlayers)&&
            (entry==checkObject.maxEntryAllowed)&&
            (attempt==checkObject.maxAttemptAllowed)&&
            (matchId==checkObject.matchId)&&
            // (pricecheck)&& 
            // (rankCheck)&&
            // (PD==((checkObject.prizeDistributionRule == null || checkObject.prizeDistributionRule?.length == 0)?false:true))&&
            //((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject?.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }
    useEffect(()=>{
        setchange()
    },[currencyIcon,displayName,competitionId,description,minPlayers,maxPlayers,entry,attempt,matchId,pricing,PD,pdValidation,ranks,tags,metaData,lockProgression,lockItems,items,PS,virtualCurrency,realMoney,IAP])

    const [sourceOption, setSourceOption] = useState()
    const [source, setSource] = useState()
    const [sourceId, setSourceId] = useState(null)

    const [limit, setLimit] = useState(false)
    
    const onChangeLimit = (e) => {
        if(e.target.checked){
            setLimit(true)
        }else{
            setLimit(false)
            setMaxPlayers(null)
        }
    }

  // CSV Upload
  const [file, setFile] = useState(null)
  const [validate, setValidate] = useState(false)
  const [fileErrors, setFileErrors] = useState({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}});

  const parseRewards = (rewards, type) => {
    if (!rewards) return [];
    let rows = rewards.split(', ').map(reward => {
      const match = reward.match(/(.+)\((\d+)\)/);
      return match ? {name: match[1], id: match[1], quantity: parseInt(match[2]), rewardType: type } : null;
    })
    return rows
  };

  const validateRewards = async(data) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, data, headers)
    if(res.data.data){
      let errorRewards = [...res.data.data.bundlesDetails.nonExistingBundles, ...res.data.data.currenciesDetails.nonExistingCurrencies, ...res.data.data.itemDetails.nonExistingItems, ...res.data.data.progressionMarkersDetails.nonExistingProgressionMarkers]
      if(errorRewards?.length > 0){
        setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: res.data.data.bundlesDetails.nonExistingBundles.map(bundle => `Bundle with Id ${bundle} does not exist`), currencies: res.data.data.currenciesDetails.nonExistingCurrencies.map(currency => `Currency with Id ${currency} does not exist`), items: res.data.data.itemDetails.nonExistingItems.map(item => `Item with Id ${item} does not exist`), pm: res.data.data.progressionMarkersDetails.nonExistingProgressionMarkers.map(pm => `Progression Marker with Id ${pm} does not exist`)}})
        return false
      }else{
        setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
        return true
      }
    }
  }
      
  const transformData = (data) => {
    return data.map((entry, index) => ({
      startRank: entry["Start Rank"],
      endRank: entry["End Rank"],
      rewardDetails: {
        items: parseRewards(entry.Items, 'Items'),
        bundles: parseRewards(entry.Bundles, 'Bundle'),
        currencies: parseRewards(entry.Currencies, 'Currency'),
        progressionMarkers: parseRewards(entry["Progression Markers"], 'PM')
      }
    }));
  };

  const checkRewards = async(data) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }

    data && data.forEach((e, index) => {
      const extractIds = (x) => {
        if(x){
          const items = x.split(", ");
          return items.map(item => item.match(/^([^\(]+)/)[1]);
        }
        return [];
      }
      const addUniqueIds = (ids, key) => {
        ids.forEach(id => {
            if (!rewards.rewardDetails[key].ids.includes(id)) {
              rewards.rewardDetails[key].ids.push(id);
            }
        });
      };

      addUniqueIds(extractIds(e["Items"]), 'items');
      addUniqueIds(extractIds(e["Bundles"]), 'bundles');
      addUniqueIds(extractIds(e["Currencies"]), 'currencies');
      addUniqueIds(extractIds(e["Progression Marker"]), 'progressionMarkers');
    })

    let validateRewardData = await validateRewards(rewards)
    if(validateRewardData){
      return true
    }else{
      return false
    }
  }

  const checkDataType = (data) => {
    const errors = [];
    data.forEach((obj, index) => {
      // Validate Start Rank
      if (!Number.isInteger(obj["Start Rank"]) || obj["Start Rank"] <= 0) {
        errors.push(`Error at row number ${index+2} in Start Rank : Start Rank should be a positive integer.`);
      }
      // Validate End Rank
      if (!Number.isInteger(obj["End Rank"]) || obj["End Rank"] <= 0) {
        errors.push(`Error at row number ${index+2} in End Rank : End Rank should be a positive integer.`);
      }
      // Function to validate amounts in parentheses
      const validateAmounts = (key) => {
        if (obj[key]) {
          const items = obj[key].split(", ");
          for (const item of items) {
            const match = item.match(/\((\d+)\)$/);
            if (!match || parseInt(match[1]) <= 0) {
              errors.push(`Error at row number ${index+2} in ${key} : ${obj[key]} contains invalid amount.`);
            }
          }
        }
      };
      // Validate Items
      validateAmounts("Items");
      // Validate Bundles
      validateAmounts("Bundles");
      // Validate Currencies
      validateAmounts("Currencies");
      // Validate Progression Marker
      validateAmounts("Progression Marker");
    });
    return errors;
  }
  
  const convertToJson = async(data) => {
    if(data.length > 1000){
      let newErrors = { sizeError : '', rowsError : 'Number of rows should be less than 1000', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
      setFileErrors(newErrors)
    }else{
      let errors = checkDataType(data)
      setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError : [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
      if(errors.length === 0){
        let checkRewardData = await checkRewards(data)
        if(checkRewardData){
          setValidate(true)
          let rows = transformData(data)
          setRanks(rows)
          sessionStorage.setItem('PDRanks', JSON.stringify(rows))
        }else{
          setValidate(false)
        }
      }else{
        setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: errors, nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
      }
    }

  }

  const checkErrors = (header) => {
    let newErrors = { sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
    let isError = true;
    if (header[0] !== 'Start Rank') {
      newErrors.startRankErr = `Please change 1st Column name from ${header[0]} should be Start Rank`;
      isError = false
    }
    if (header[1] !== 'End Rank') {
      newErrors.EndRankErr = `Please change 2nd Column name from ${header[1]} should be End Rank`;
      isError = false
    }
    if (header[2] !== 'Items') {
      newErrors.itemErr = `Please change 3rd Column name from ${header[2]} should be Items`;
      isError = false
    }
    if (header[3] !== 'Bundles') {
      newErrors.bundleErr = `Please change 4th Column name from ${header[3]} should be Bundles`;
      isError = false
    }
    if (header[4] !== 'Currencies') {
      newErrors.currenciesErr =  `Please change 5th Column name from ${header[4]} should be Currencies`;
      isError = false
    }
    if (header[5] !== 'Progression Marker') {
      newErrors.pmErr = `Please change 6th Column name from ${header[0]} should be Progression Marker`;
      isError = false
    }

    setFileErrors(newErrors);
    if(isError){
      return true
    }

  }

  const onCSV = async(event) => {
    const file = event.target.files[0];
    setFile(file)
    setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
    setValidate(false)
  }

  const onValidation = () => {
    if(file && (file.size/1024).toFixed(2) < 10000){
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        const headers = columnsArray[0];
        if(checkErrors(headers)){
          setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
          const json = XLSX.utils.sheet_to_json(worksheet);
          let xyz = convertToJson(json)
        }
      };  
      reader.readAsArrayBuffer(file);
      setLastEndRank(false)
    }else if(file && (file.size/1024).toFixed(2) > 10000){
      let newErrors = { sizeError : 'File size should be less than 10Mb', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
      setFileErrors(newErrors)
    }else{
      toast.error(<Toast type='Error' messages='Please Upload File.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
    }
  }
  
  const [lastEndRank, setLastEndRank] = useState(false)
  
  const onLastEndRank = (e) => {
    if(e.target.checked){
      setLastEndRank(true)
      const updatedInputData = [...ranks];
      updatedInputData[updatedInputData.length-1].endRank = parseInt(100000);
      setRanks(updatedInputData);
      sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
    }else{
      setLastEndRank(false)
      const updatedInputData = [...ranks];
      updatedInputData[updatedInputData.length-1].endRank = null;
      setRanks(updatedInputData);
      sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
    }
  }

  const [item, setItem] = useState([])
  const [currency, setCurrency] = useState([])
  const [bundle, setBundle] = useState([])
  const [pm, setPM] = useState([])

  const getAllRewards = () => {
      let search = {
        projectId : appid,
        search : ''
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      axios.post(`${BASE_URL}/inventory/search`, search, headers)
      .then(res=>{
          let currencies = res.data.data.currencies.map(i => ({uuid:i.id, id : i.currencyId, description: i.description,  iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), name : i.name, status : false, rewardType: 'Currency', type:i.type}));
          let items = res.data.data.inventoryItems.map(i => ({uuid:i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.itemId, description: i.description,  name : i.name, status : false, rewardType: 'Items'}));
          let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
          let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
          setItem(items)
          setCurrency(currencies)
          setBundle(bundles)
          setPM(pm)
      })
    }

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-green">
                <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input disabled={disableSchedule} type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                            {!disableSchedule && <label className={styles.button} onClick={() => {setVisibleFile(true)}}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        <div className={styles.previewCancel}>
                            {currencyIcon != null && !disableSchedule &&
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            }
                        </div>
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleFile} setVisible={setVisibleFile} icon={currencyIcon} setIcon={setCurrencyIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput disabled={disableSchedule} tooltip={TooltipTitle.TournamentName} id='competitionName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required value={displayName} onChange={(e)=>{onDisplayName(e)}}/>
                        <TextInput disabled={disableSchedule} isId={true} tooltip={TooltipTitle.TournamentID} id='competitionId' className={styles.field} errorMessage={errors.idError} label="Tournament ID*" name="competitionId" type="text" required value={competitionId} onChange={(e)=>{onCompetitionID(e)}}/>
                    </div>
                    <TextArea disabled={disableSchedule} tooltip={TooltipTitle.TournamentDescription} className={styles.field} rows={5} label="Description" value={description} onChange={(e)=>{onCompetitionDescription(e)}}/>
                    <div className={styles.group}>
                        <TextInput disabled={disableSchedule} tooltip={TooltipTitle.TournamentMin} id='minPlayers' className={styles.field} errorMessage={errors.minError} label="Minimum Player*" name="minPlayers" type="number" min={1} required  onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key)&&e.preventDefault()} max={maxPlayers}  value={minPlayers} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onMinPlayers(e.target.value)}}/>
                        {!limit && <TextInput disabled={disableSchedule} tooltip={TooltipTitle.TournamentMax} id='maxPlayers' className={styles.field} errorMessage={errors.maxError} label="Maximum Player*" name="maxPlayers" type="number" min={1} required onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key)&&e.preventDefault()} value={maxPlayers} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onMaxPlayers(e.target.value)}}/>}
                        {limit && <><div id='maxPlayers' className={styles.fieldDummy}><div><div className={styles.label}>Maximum Player*</div><div className={styles.sign}><div className={styles.infinity}>&infin;</div></div></div></div></>}
                    </div>
                    <div className={styles.checkboxGroup}>
                        <div className={styles.field}></div>
                        <div className={styles.field}>
                            <Checkbox size='small' content='No Limit' onChange={(e) => {onChangeLimit(e)}} value={limit}/>
                        </div>
                    </div>
                    <div className={styles.group}>
                        <div className={styles.field}>
                            <Dropdown disabled={disableSchedule} tooltip={TooltipTitle.TournamentMatch} id='source' errorMessage={errors.matchErr} label='Select Source*' type="source" placeholder='Choose Source' value={source} setValue={setSource} setId={setSourceId} options={sourceOption} />
                        </div>
                        <div className={styles.field}>
                            <Dropdown disabled={disableSchedule} id='ranking' label='Leaderboard Ranking Method' placeholder='Choose Leaderboard Ranking Method' value={leaderboardOutcome} setValue={setLeaderboardOutcome} setId={setLeaderboardOutcomeId} options={leaderboardOutcomeOption}/>
                        </div> 
                    </div>
                    {source == 'Match' &&
                        <div className={styles.group}>
                            <div className={styles.field}>
                                <MatchDropdown disabled={disableSchedule} page={matchPage} setPage={setMatchPage} limit={matchLimit} tooltip={TooltipTitle.TournamentMatch} id='match' label='Select Match*' errorMessage={errors.matchErr} placeholder='Choose Match' type="match" value={matchName} setValue={setMatchName} setId={setMatchId} setOtherId={setGameId} options={matchOptions} />
                            </div> 
                        </div>
                    }
                    {leaderboardOutcomeId == 4 &&
                        <>
                        <div className={styles.group}>
                            <div className={styles.podiumSystem}>
                                <div className={styles.podiumGroup}>
                                    <div className={styles.rankLabel}>
                                        <span className={styles.podiumLabelTxt}>No of Podium Ranks</span><span onClick={addRank} className={styles.podiumLabelImg}><AddIcon /></span>
                                    </div>
                                    <div>
                                        <input disabled={disableSchedule} type='number' onWheelCapture={e => {e.currentTarget.blur()}}  className={styles.inputField} value={podiumRanks.length > 0 ? podiumRanks.length : ''} onChange={(e)=>{onRankChange(e.target.value)}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {podiumRanks && podiumRanks.length > 0 && (
                            <>
                            <div className={styles.groupRanking}>
                                <div className={styles.fieldBlank}></div>
                                <div className={styles.fieldRank}>Starting Rank</div>
                                <div className={styles.field}>Score</div>
                            </div>

                            {podiumRanks && podiumRanks.map((e,i) => (
                                <>
                                <div className={styles.groupRanking} key={i}>
                                    <div className={styles.fieldBlank}></div>
                                    <div className={styles.fieldRank}>{i+1}</div>
                                    <div className={styles.field}><TextInput disabled={disableSchedule} key={i} type='number' onWheelCapture={e => {e.currentTarget.blur()}}  value={e.score} onChange={(e)=>{onScoreChange(parseInt(e.target.value), i)}} /></div>  
                                    {!disableSchedule && <div className={styles.fieldRemove}><span onClick={()=>{removePodiumRanks(i)}}><Remove /></span></div>}
                                </div>
                                </>
                            ))}
                            </>
                        )}      
                    </>
                    }
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Entry Configuration" classTitle="title-green">
                <div className={styles.description}>
                    <div className={styles.group}>
                        <TextInput disabled={disableSchedule} tooltip={TooltipTitle.TournamentEntry} id='entries' className={styles.field} errorMessage={errors.entryErr} label="Number of Entries*" name="entries" type="number" min={1} required value={entry} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onEntry(e.target.value)}}/>
                        <TextInput disabled={disableSchedule} tooltip={TooltipTitle.TournamentAttempt} id='attempts' className={styles.field} label="Number of Attempt*" errorMessage={errors.attemptErr} name="attempt" type="number" min={1} required value={attempt} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onAttempts(e.target.value)}}/>
                    </div>

                    <div className={styles.pricing}>
                        <div className={styles.labelHeading}>
                            <span>Choose Pricing Options<span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentPricing} icon="info"/></span></span>
                        </div>
                        <div className={styles.selectInput}>
                            <Multiselect disabled={disableSchedule} as='competition' type='add' placeholder='Choose Pricing Options' options={pricingOption} value={pricing} setValue={setPricing} setShowVirtual={setShowVirtual} setShowRMG={setShowRMG} setShowInApp={setShowInApp} setVirtualCurrency={setVirtualCurrency} setIAP={setIAP} setRealMoney={setRealMoney}/>
                        </div>
                    </div>
                    
                    <div className={styles.competitionEntryRule}>
                        {showVirtual &&
                            <>
                                <div className={styles.groupHeading}>
                                    <span>Virtual</span>{!disableSchedule && <span className={styles.addIcon} onClick={addVirtualCurrency}><AddMetaIcon /></span>}
                                </div>

                                    <div className={styles.groupByVirtualCurrency}>
                                        {virtualCurrency && virtualCurrency.map((e,index)=>{
                                            if(e.archive == false){
                                                return(
                                                    <>
                                                        <div className={styles.field}>
                                                            <CurrencyDropdown disabled={disableSchedule} as='item' type="virtual currency" label="Select Currency" options={virtualCurrencyData} value={e.currencyName} data={virtualCurrency} setData={setVirtualCurrency} index={index}/>
                                                        </div> 
                                                        <TextInput disabled={disableSchedule} className={styles.field} label="Entry Amount" name="amount" type="text" required value={e.amount}  onChange={(e)=>{onVirtualCurrency(e, index, "amount")}}/>
                                                        <TextInput disabled={disableSchedule} className={styles.field} label="Discount" name="discount" type="number" value={e.discount} onWheelCapture={e => {e.currentTarget.blur()}}  required onChange={(e)=>{onVirtualCurrency(e, index)}}/>
                                                        {!disableSchedule && <span className={styles.remove} onClick={()=>{removeVirtualCurrency(index)}}><img className={styles.icon} src={removeIcon} alt="remove" /></span>}
                                                    </>
                                                )
                                            }
                                        })}
                                    </div>
                            </>
                        }
                    </div>

                    <div className={styles.competitionEntryRule}>
                    {showInApp &&
                        <>
                            <div className={styles.groupHeading}>
                                <span>In-App Purchase</span>{!disableSchedule && <span className={styles.addIcon} onClick={addIAP}><AddMetaIcon /></span>}
                            </div>
                            <div className={styles.groupByIAP}>
                                {IAP && IAP.map((e,index)=>{
                                    return(
                                        <>
                                            <div className={styles.field}>
                                                <CurrencyDropdown disabled={disableSchedule} as='item' type="IAP" label="Select Currency" options={realWorldCurrency} value={e.currencyName} data={IAP} setData={setIAP} index={index}/>
                                            </div>
                                            <div className={styles.field}>
                                                <PlatformDropdown disabled={disableSchedule} label="Select Platform" options={platformOptions} value={e.gamePlatformName} data={IAP} setData={setIAP} index={index}/>
                                            </div>
                                            <TextInput disabled={disableSchedule} className={styles.field} label="Entry Amount" name="amount" type="text" required value={e.amount} onChange={(e)=>{onIAP(e, index)}}/>
                                            <TextInput disabled={disableSchedule} className={styles.field} label="Discount" name="discount" type="number" value={e.discount} onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onIAP(e, index)}}/>
                                            {!disableSchedule && <span className={styles.remove} onClick={()=>{removeIAP(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>}
                                        </>
                                    )
                                })}
                            </div>
                        </>
                    }
                    </div>

                    <div className={styles.competitionEntryRule}>
                    {showRMG && 
                        <>
                            <div className={styles.groupHeading}>
                                <span>RMG</span>{!disableSchedule && <span className={styles.addIcon} onClick={addRealMoney}><AddMetaIcon /></span>}
                            </div>

                                {realMoney && realMoney.map((e, index) => {
                                    if(e.archive == false){
                                        return (
                                            <>
                                                <div>
                                                    <div className={styles.groupByVirtualCurrency}>
                                                        <div className={styles.field}>
                                                            <CurrencyDropdown disabled={disableSchedule} as='item' type="RMG" label="Select Currency" options={realMoneyData} value={e.currencyName} data={realMoney} setData={setRealMoney} index={index}/>
                                                        </div>
                                                        <TextInput disabled={disableSchedule} className={styles.field} label="Entry Amount" name="amount" type="text" required value={e.amount} onChange={(e)=>{onRealMoney(e, index, "amount")}} />
                                                        <TextInput disabled={disableSchedule} className={styles.field} label="Bonus Cash Allowance" name="bonusCashAllowance" type="number" value={e.bonusCashAllowance} onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onRealMoney(e, index)}} />
                                                        {!disableSchedule && <span className={styles.remove} onClick={()=>{removeRealMoney(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>}
                                                    </div>
                                                    <div className={styles.hosting}>
                                                        <div className={styles.groupByVirtualCurrency}>
                                                            <div className={styles.fieldRadio}>
                                                                <div className={styles.radioLabel}>
                                                                    <span>Hosting Fee Per User</span>
                                                                </div>
                                                                <div className={styles.variants}>
                                                                    <Radio disabled={disableSchedule} className={styles.radio} key={index} name={`hostingFeeType${index}`} value={!e.hosting} content="Percentage" onChange={() => {onChangeHostingType(false, 'percentag', index)}}/>
                                                                    <Radio disabled={disableSchedule} className={styles.radio} key={index} name={`hostingFeeType${index}`} value={e.hosting} content="Flat" onChange={() => {onChangeHostingType(true, 'flat', index)}}/>
                                                                </div>
                                                            </div>
                                                            <TextInput disabled={disableSchedule} tooltip={TooltipTitle.TournamentHosting} className={styles.field} label="Value for Hosting Fee Per User" name="hostingFee" type="number" value={e.hostingFee} onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onRealMoney(e, index, "amount")}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })}
                        </>
                    }
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Prize Configure" classTitle="title-green">
                <div className={styles.description}>
                    
                    <div className={styles.createPD}>
                            <div className={styles.group} style={{margin:"0 0 20px 0"}}>
                              <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Do you want to configure Prize Distribution for this Competition?</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={disableSchedule} className={styles.radio} name="PD" value={PD} onChange={() => setPD(true)} content="Yes" />
                                    <Radio disabled={disableSchedule} className={styles.radio} name="PD" value={!PD} onChange={() => setPD(false)} content="No" />
                                </div>
                              </div>
                            </div>
                            {PD&&<>
                                    <div className={styles.group}>
                                    <div className={styles.fieldRadio}>
                                        <div className={styles.radioLabel}>
                                            <span>Prize Distribution Validation<span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentPD} icon="info"/></span></span>
                                        </div>
                                        <div className={styles.variants}>
                                            <Radio disabled={disableSchedule} className={styles.radio} value={!pdValidation} onChange={()=>{setPDValidation(false)}} name="PDValidation" content="Non RMG" />
                                            <Radio disabled={disableSchedule} status={true} className={styles.radio} value={pdValidation} onChange={()=>{setPDValidation(true)}} name="PDValidation" content="RMG" />
                                        </div>
                                    </div>
                                    </div>
                                    {PD &&
                                    <>
                                        <div className={styles.group}>
                                            <div className={styles.pdSystem}>
                                                <div className={styles.pdSystemGroup}>
                                                    <div className={styles.pdLabel}>
                                                        <span className={styles.pdLabelTxt}>Ranks</span>{!disableSchedule && <span className={styles.pdLabelImg} onClick={addRanks}><AddIcon /></span>}
                                                    </div>
                                                    <div className={styles.pdLabel}>
                                                        <input disabled={disableSchedule} className={styles.inputField} type='number' onWheelCapture={e => {e.currentTarget.blur()}} value={ranks.length > 0 ? ranks.length : ''} onChange={(e)=>{onChangeRankReward(e.target.value)}}/>
                                                    </div>
                                                    {/* <label className={styles.pdUpload}>
                                                        <div className={styles.previewInput}> 
                                                            <input id='csv' type="file" className='file-input' accept=".xlsx, .xls, .csv" onChange={(e) => {onCSV(e)}}/>
                                                            <label for='csv' className={styles.button}><Icon name='upload' size='20' /> CSV</label>
                                                        </div>
                                                    </label> */}
                                                    <ControlFileUpload valueOne={file} setValueOne={setFile} error={fileErrors} validateOne={validate} onCSV={onCSV} onValidation={onValidation} count={1} placeHolderOne={`Click or drop PD CSV`} multiple={false} accept={`.xlsx, .xls, .csv`} labelOne='Prize Distribution' usedFor='Competition' />  
                                                    <Control data={ranks} value={ranks} setValue={setRanks}/>
                                                </div>
                                            </div>
                                        </div>
                                        {ranks && ranks.length > 0 ?
                                            <div className={styles.groupByThree}>
                                                <p></p>
                                                <p className={styles.fieldTitle}>Rank Start</p>
                                                <p className={styles.fieldTitle}>Rank End</p>
                                                <p className={styles.fieldTitle}>Rewards</p>
                                            </div>
                                        : ''} 

                                        {ranks && ranks.map((e,i)=>{
                                            return(
                                                <>
                                                    <div key={i} className={styles.groupByThree}>
                                                        <p className={styles.index}></p>
                                                        <TextInput disabled={disableSchedule} id={`PDStart${i}`} className={styles.field} errorMessage={errors && errors?.pdErrorStart ? errors.pdErrorStart[i] : ''} type="number" key={i} min={1} value={e.startRank} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=> onChangeStartRank(i, e.target.value) }/>
                                                        {!lastEndRank && <TextInput id={`PDEnd${i}`} disabled={disableSchedule} errorMessage={errors && errors?.pdErrorEnd ? errors.pdErrorEnd[i] : ''} className={styles.field} type="number" key={i} value={e.endRank} min={1} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e) => onChangeEndRank(i, e.target.value)} onKeyDown={handleKeyDown}/>}
                                                        {lastEndRank && i < ranks?.length - 1 && <TextInput id={`PDEnd${i}`} errorMessage={errors && errors?.pdErrorEnd ? errors.pdErrorEnd[i] : ''} disabled={disableSchedule} className={styles.field} type="number" key={i} value={e.endRank} min={1} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e) => onChangeEndRank(i, e.target.value)} onKeyDown={handleKeyDown}/>}
                                                        {lastEndRank && i + 1 === ranks?.length && <><div className={styles.fieldDummy}><div className={styles.sign}>&infin;</div></div></>}
                                                        <p className={styles.field}>
                                                            {ranks && ((ranks[i].rewardDetails?.currencies?.length + ranks[i].rewardDetails?.items?.length + ranks[i].rewardDetails?.bundles?.length + ranks[i].rewardDetails?.progressionMarkers?.length) === 0) ? 
                                                                <>
                                                                    {!disableSchedule && <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('add')}}><AddIcon className={styles.icon}/></span>}
                                                                </>
                                                            :
                                                                <div className={styles.levelRewards}>
                                                                    <div className={styles.reward}>
                                                                        {ranks && ((ranks[i].rewardDetails?.currencies?.length + ranks[i].rewardDetails?.items?.length + ranks[i].rewardDetails?.bundles?.length + ranks[i].rewardDetails?.progressionMarkers?.length) > 0) ? 
                                                                            (ranks[i].rewardDetails && [...ranks[i].rewardDetails?.currencies, ...ranks[i].rewardDetails?.items, ...ranks[i].rewardDetails?.bundles, ...ranks[i].rewardDetails?.progressionMarkers].filter(x => !x.archive).slice(0,2).map((x,index) => 
                                                                                <span><span>{index > 0 && ', '}</span><span>{x.name}</span><span>&nbsp;</span><span>({x.quantity})</span></span>
                                                                            )) 
                                                                        : ''}
                                                                    </div>
                                                                    {!disableSchedule && <div className={styles.editIcon} key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('edit')}}><EditIcon className={styles.icon}/></div>}
                                                                </div>
                                                            }
                                                        </p>  
                                                        {!disableSchedule && <p className={styles.remove}><span className={styles.removeIcon} onClick={()=>{removeRanks(i)}}><Remove /></span></p>}
                                                    </div>
                                                </>
                                            )
                                        })}

                                        {ranks && ranks.length > 0 ?
                                            <div className={styles.groupByThree}>
                                                <p></p>
                                                <p className={styles.fieldTitle}></p>
                                                <p className={styles.fieldTitle}><Checkbox size='small' content='To Last Participant' value={lastEndRank} onChange={(e) => {onLastEndRank(e)}}/></p>
                                                <p className={styles.fieldTitle}></p>
                                            </div>
                                        : ''} 
                                        
                                        <div>
                                            {/* <Rewards type={type} level={indexLevel} visible={visibleHelp} setVisible={setVisibleHelp} onClose={onClose} setPSLevels={setRanks}/> */}
                                            <Modal heading={true} width={'medium'} visible={visibleHelp} onClose={() => setVisibleHelp(false)}>
                                                <AddContentModal amountKey="quantity" categoryIndex={indexLevel} onClose={() => setVisibleHelp(false)} type={"Task"} currency={currency} setCurrency={setCurrency} item={item} setItem={setItem} bundles={bundle} setBundle={setBundle} PM={pm} setPM={setPM} value={ranks} setValue={setRanks} />
                                            </Modal>
                                        </div>
                                    </>
                                }
                            </>  
                            }
                        
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.description}>
                    <div className={styles.createPD}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by<span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    {lockBy.map((x, index) => (
                                        <Checkbox disable={disableSchedule} value={x.active} key={x.id} className={styles.checkbox} content={x.title} onChange={(e)=>{onLockBy(e, x.title)}}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.createPD}>
                        <div className={styles.group}>
                            {lockProgression ?
                                <>
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentPS} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown disabled={disableSchedule} placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                </>
                            : ''}
                            {lockItems ?
                                <div className={styles.lockGroup}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Item/Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentItemBundle} icon="info"/></span></span>
                                    </div>
                                    <ItemDropdown disabled={disableSchedule} className={styles.selectField} groupBy={1} options={itemOptions} value={selectedItemId} setValue={setSelectedItemId} setUnlockItemId={setUnlockItemId} error={setLockItemErr} />
                                    {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                </div>
                            : ''}
                        </div>
                    </div>
                    {lockProgression &&
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            {PS && PS.map((element, index) =>{
                                return(!element.archive &&
                                    <>
                                        <div style={{marginTop:"8px"}} className={styles.field}>
                                            <ProgressionLevelMultiSelect disabled={disableSchedule} placeholder='Select Level' label={element.name} options={(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)&&(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)} ind={index} value={PS} setValue={setPS}/>  
                                            {/* <ProgressionLevelMultiSelect disabled={disableSchedule} placeholder='Select Level' label={element.name}  options={(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)&&(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)} ind={index} value={PS} setValue={setPS}/>                                   */}
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    }
                </div>

            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                <TagInputWithDropdown disabled={disableSchedule} tooltip={TooltipTitle.TournamentTags} tags={tags} setTags={setTags}/>


                    <div className={styles.createCurrency}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentMetaData} icon="info"/></span>{!disableSchedule && <span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>}
                        </div>
         
                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                    return(
                                        <>
                                            <TextInput disabled={disableSchedule} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''}   onChange={(e)=>{onChangeKey(e,i)}}/>
                                            <JSONMetaDataValueInput disabled={disableSchedule} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                            {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                            {!disableSchedule && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon} /></span>}
                                        </>
                                    )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={() => {submit(false, null)}}>Save</button>
                    </div>
                    <div className={styles.cardSave}>
                        {tournamentDetail && tournamentDetail?.length > 0 && <Schedule scheduleId={tournamentDetail && tournamentDetail[0].scheduleDetails?.length > 0 ? tournamentDetail[0].scheduleDetails[0].id : null} liveOps={tournamentDetail && tournamentDetail[0].scheduleDetails?.length > 0 ? tournamentDetail[0].scheduleDetails : []} id={id} as={`saveNschedule`} type='Tournament' saveCompetition={submit} name={displayName}/>}
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default CompetitionInformation