import React, {useEffect, useRef, useState} from 'react'
import cn from "classnames";
import Card from "../../../../../components/Card";
import addIcon from '../../../../../media/images/icons/light.png'
import styles from './tournamentsResult.module.sass'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import EmptyStates from '../../../../../components/EmptyStates';
import moment from 'moment';
import { getCompetitionResultAction } from '../../../../../redux/action/engage/Competitions/competitionAction';
import Calendar from './Calendar';
import LiveOpsResultTableLayout from '../../../../../components/EngageComponents/LederboardComponent/LeaderboardResult';
import LiveOpsResultPDTableLayout from '../../../../../components/EngageComponents/LederboardComponent/LeaderboardResultPD';
import { getLeadboardResultPDAction } from '../../../../../redux/action/engage/LiveOps/LiveOpsAction';

const TournamentResult = ({props}) => {

    const {id} = useParams()
    const {appid} = useParams()
    const location = useLocation()

    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [selectionRange, setSelectionRange] = useState([{
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        key: 'selection',
    }])

    const [competitionInstanceId, setCompetitionInstanceId] = useState(location.state.instanceId)
    const [competitionStatus, setCompetitionStatus] = useState(location.state.status)
    const [pdStatus, setPDStatus] = useState(location.state.PD)
    const [placeHolderDate, setPlaceHolderDate] = useState(true)
    const [infinitySymbol, setInfinitySymbol] = useState('\u221E')
    //const [current, setCurrent] = useState(location.state.interval == 1 ? `${moment().format('MMMM DD, YYYY')}` : location.state.interval == 2 ? `${moment().startOf('week').format('MMMM DD, YYYY')} - ${moment().endOf('week').format('MMMM DD, YYYY')}` : location.state.interval == 3 ? `${moment().startOf('month').format('MMMM DD, YYYY')} - ${moment().endOf('month').format('MMMM DD, YYYY')}` : location.state.interval == 4 ? `${moment().startOf('year').format('MMMM DD, YYYY')} - ${moment().endOf('year').format('MMMM DD, YYYY')}` : '')
    const [current, setCurrent] = useState(location.state.interval === 6 ? `${moment(location.state.scheduleInstance[0].startDate).format('MMMM DD, YYYY')} - ${infinitySymbol}` : location.state.interval === 7 ? `${moment(location.state.scheduleInstance[0].startDate).format('MMMM DD, YYYY hh:mm A')} - ${moment(location.state.scheduleInstance[0].endDate).format('MMMM DD, YYYY hh:mm A')}` : '')
    const dispatch = useDispatch()

    const isInitialMount = useRef(true);

    useEffect(() => {
        if(competitionStatus === 'completed' && pdStatus){
            getLeadboardResultPD()
        }else{
            getCompetitionResult()
        }
    }, [competitionInstanceId, page]);

    const getCompetitionResult = () => {
        let competitionResult = {
            competitionId : id,
            instanceId : competitionInstanceId,
            pagination : {
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getCompetitionResultAction(competitionResult))
    }

    const getLeadboardResultPD = () => {
        let leaderboardResultPD = {
            instanceId : competitionInstanceId,
            pagination:{
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getLeadboardResultPDAction(leaderboardResultPD))
    }

    let competitionResults = useSelector((state) =>{
        return state.competition.competitionResults
    })

    let leaderboardPD = useSelector((state) =>{
        return state.liveOps.liveOpsPD
    })

    let totalResult = useSelector((state) =>{
        return state.competition.resultCount
    })

    let totalResultPD = useSelector((state) =>{
        return state.liveOps.resultCount
    })

    const handlePageChange = (event, value)  => {
        setPage(value)
    }

    return(
        <>
        <div className={styles.leaderboardHeading}>
            <div className={cn("h5", styles.title)}>{location.state.name}</div>
        </div>
        <Card className={styles.card} classCardHead={styles.head} title="Competition Result" classTitle={cn("title-purple", styles.title)}
            head={
            <>
                <div className={competitionStatus == 'yet to start' ? styles.purple : competitionStatus == 'in progress' ? styles.green : competitionStatus == 'completed' ? styles.grey : competitionStatus == 'stopped' ? styles.orange : competitionStatus == 'in review' ? styles.yellow : competitionStatus == 'failed' ? styles.red : ''}>{competitionStatus == 'yet to start' ? 'Yet-to-Start' : competitionStatus == 'in progress' ? 'Live' : competitionStatus == 'completed' ? 'Completed' : competitionStatus == 'in review' ? 'In-Review' : competitionStatus == 'stopped' ? 'Stopped' : competitionStatus == 'failed' ? 'Failed' : ''}</div>
                <Calendar setCompetitionStatus={setCompetitionStatus} setPlaceHolderDate={setPlaceHolderDate} current={current} setCurrent={setCurrent} 
                dates={location.state.scheduleInstance} setCompetitionInstanceId={setCompetitionInstanceId}
                selectionRange={selectionRange} setSelectionRange={setSelectionRange} interval={location.state.interval}/>
            </>
            }>
            <div>
                <div className={styles.wrapper}>
                    {competitionStatus !== 'completed' && competitionResults.length == 0 && 
                        <EmptyStates route={`/leaderboard/${appid}`} type="Leaderboard Result" />
                    }
                    {competitionStatus === 'completed' && leaderboardPD.length == 0 && 
                        <EmptyStates route={`/leaderboard/${appid}`} type="Leaderboard Result" />
                    }
                    {competitionResults.length > 0 &&
                        <>
                            {competitionStatus !== 'completed' && 
                                <>
                                    <LiveOpsResultTableLayout items={competitionResults} title="Last edited" usedFor={location.state.usedFor}/>
                                    <div className={styles.pagination}>
                                        <Stack>
                                            <Pagination renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        '&.MuiPaginationItem-root': {
                                                          minWidth: '28px',
                                                          height: '28px',
                                                          fontSize: '12px'
                                                        },
                                                        '&.Mui-selected': {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        },
                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        }
                                                    }}
                                                />
                                            )} siblingCount={1} count={Math.ceil(totalResult/limit)} color="primary" onChange={handlePageChange} />
                                        </Stack>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {leaderboardPD.length > 0 &&
                        <>
                            {competitionStatus === 'completed' && 
                                <>
                                    <LiveOpsResultPDTableLayout items={leaderboardPD} title="Last edited" usedFor={location.state.usedFor}/>
                                    <div className={styles.pagination}>
                                        <Stack>
                                            <Pagination renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        '&.MuiPaginationItem-root': {
                                                          minWidth: '28px',
                                                          height: '28px',
                                                          fontSize: '12px'
                                                        },
                                                        '&.Mui-selected': {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        },
                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        }
                                                    }}
                                                />
                                            )} siblingCount={1} count={Math.ceil(totalResultPD/limit)} color="primary" onChange={handlePageChange} />
                                        </Stack>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </Card>
    </>
  )
}

export default TournamentResult