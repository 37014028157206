import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getMembers.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import MembersTableLayout from '../../../../components/AppSettingComponent/MemberComponent/OrgMemberTableComponent'
import { acceptedUser } from '../../../AppSettings/Members/mockData/acceptedUser'
import { invitedUser } from '../../../AppSettings/Members/mockData/invitedUser'
import Control from './Control'
import { BASE_URL, GET_APPS } from '../../../../resources/APIEndpoints'
import axios from 'axios'
import { getallAppAction } from '../../../../redux/action/apps/appsAction'
import { getAcceptedMembersAction, getInvitedMembersAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import EmptyStates from '../../../../components/EmptyStates'
import Loader from '../../../../components/Loaders/LoaderProgress'
import { Pagination, PaginationItem, Stack } from '@mui/material'
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'

const Tabs = ["Accepted", "Invited"];

const GetMembers = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  const globalStates = useContext(GlobalStateContext)
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState()
  const [apps, setApps] = useState([])

  const getAllApps = () => {
    let apps = {

    }
    dispatch(getallAppAction(apps))
  }

  useEffect(()=>{
    getAllApps()
  }, [])

  let appList = useSelector((state) => {
    return state.apps.app
  })

  const configureApps = () => {
    if(appList && appList.length > 0){
      setApps(appList && appList.map((e) => ({...e, checked : true, permission : {id : 1, name : 'View Only'}})))
    }
  }

  useEffect(() => {
    configureApps()
  }, [appList])

  const getAllAppMembers = () => {
    let getMember = {
      organisationId : localStorage.getItem('orgId'),
      ...(activeIndex === 1 && {status : 'invited'})
      // status : (activeIndex == 0 ? 'accepted' : 'invited')
    }
    if(activeIndex == 0){
      dispatch(getAcceptedMembersAction(getMember))
    }else{
      dispatch(getInvitedMembersAction(getMember))
    }
  }

  useEffect(() =>{
    getAllAppMembers()
  }, [activeIndex])

  let acceptedMembers = useSelector((state) => {
    return state.members.acceptedMembers
  })

  let invitedMembers = useSelector((state) => {
    return state.members.invitedMembers
  })

  let isLoading = useSelector((state)=>{
    return state.members.isLoading
  })

  const onSearch = (search) => {
    if(search == ''){
      getAllApps()
    }else{
      let searchMembers = {search : search, projectId : appid}
    }
  }

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
        <ToastContainer />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title="Members" classTitle={cn("title-purple", styles.title)}
          head={
            <>
              {
              ((activeIndex === 0 && acceptedMembers.length > 0 && globalStates?.user?.type === 'admin')||(activeIndex === 1 && invitedMembers.length > 0 && globalStates?.user?.type === 'admin')) &&
                <div className={styles.add}>
                  <div className={styles.createButton}>
                    <Control memberAction='add' useFor='org' type='small' apps={apps} setApps={setApps} />
                  </div>
                </div>
              }
            </>
          }
        >
          
          <div className={styles.wrapper}>
            <div className={styles.toggle}>
              {Tabs && Tabs.map((x, index) => (
                <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => setActiveIndex(index)} key={index}>
                    <p>{x}</p>
                </button>
              ))}
            </div>
            {(activeIndex === 0 && acceptedMembers.length == 0)||(activeIndex === 1 && invitedMembers.length == 0) ?
            <>
              <div className={styles.wrapper}>
                <EmptyStates useFor='org' type="OrgMembers" memberAction='add' apps={apps} setApps={setApps} access={globalStates?.user?.type !== 'admin'}/>
              </div>
            </>
          : (activeIndex === 0 && acceptedMembers.length > 0)||(activeIndex === 1 && invitedMembers.length > 0) ?
          <>
          <div className={styles.wrapper}>
            <div className={styles.seprator}></div>
              <div className={styles.options}>
                <div className={styles.filters}>
                  <div className={styles.buttons}>
                    <Icon name='filter' size='20' className={styles.icon} />
                    <span className={styles.buttonText}>Filter</span>
                  </div>
                  <div className={styles.buttons}>
                    <ArchiveSvg className={styles.icon} />
                    <span className={styles.buttonText}>Show Archived</span>
                  </div>
                </div>
              <div className={styles.searchForm}>
                <Form className={styles.form} placeholder="Search Members" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
              </div>
            </div>
            {activeIndex === 0 &&
              <>
                <MembersTableLayout to='accepted' items={acceptedMembers} title="Last edited" apps={apps} setApps={setApps} userType={globalStates?.user?.type}/>
              </>  
            }
            {activeIndex === 1 && 
              <>
                <MembersTableLayout to='invited' items={invitedMembers} title="Last edited" apps={apps} setApps={setApps} userType={globalStates?.user?.type}/>
              </>
            }
          </div>
          <div className={styles.pagination}>
            <Stack>
              <Pagination renderItem={(item) => (
                    <PaginationItem
                        {...item}
                        sx={{
                          '&.MuiPaginationItem-root': {
                            minWidth: '28px',
                            height: '28px',
                            fontSize: '12px'
                          },
                          '&.Mui-selected': {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          },
                          '&.MuiPaginationItem-page.Mui-selected:hover' : {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          }
                      }}
                    />
                )} siblingCount={1} color="primary" />
              </Stack>
            </div>
          </>
          : ''}
          </div>
        </Card>        
    </>
  )

}

export default GetMembers