import React, {useEffect, useState, useRef} from 'react'
import cn from 'classnames'
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../../components/Card'
import Radio from '../../../../../components/Radio';
import TextInput from '../../../../../components/TextInput';
import TextArea from '../../../../../components/TextArea';
import Tooltip from '../../../../../components/Tooltip';
import Rewards from './Rewards'
import { BASE_URL, GET_APPS } from '../../../../../resources/APIEndpoints';
import RewardsTooltip from '../../../../../components/CustomTooltips/RewardsTooltip'
import Icon from '../../../../../components/Icon';
import styles from './leaderboard.module.sass'
import LeaderBoard from '../../../../../media/images/placeholders/LeaderBoard.png'
import FileImages from '../../../../../components/File/Images'
import FileVideos from '../../../../../components/File/Videos';
import { ReactComponent as AddButtonIcon } from '../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddIcon} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as EditIcon} from '../../../../../media/images/icons/editSvg.svg'
import Dropdown from '../../../../../components/Dropdown';
import MatchDropdown from '../../../../../components/Dropdown/LeaderboardMatchesDropdown';
import { getListOfAllGamesAction } from '../../../../../redux/action/builds/games/gamesAction';
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import { createMatchAction, getAllMatchesAction } from '../../../../../redux/action/builds/matches/matchesAction';
import moment from 'moment';
import { createLeaderboardAction } from '../../../../../redux/action/engage/Leaderboard/leaderboardAction';
import Toast from '../../../../../components/Toast';
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../redux/action/AppSettings/Media/mediaAction';
import customAxios from '../../../../../utils/interceptor';
import TooltipTitle from '../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../components/MetaInput';
import Checkbox from '../../../../../components/Checkbox';
import DatePicker from "react-datepicker";
import Item from '../../../../../components/Schedule/Item'
import { format } from "date-fns";
import Schedule from './Schedule'
import Control from "./Control";
import ControlFileUpload from "./ControlFileUpload";
import XLSX from 'xlsx';
import AddContentModal from "../../../../../components/Modals/AddRewardModal";
import Modal from "../../../../../components/Modals/ModalComponent";
import app from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const LeaderboardInformation = ({ className, onClose ,isChanged, setIsChanged}) => {

    const { appid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logoRef = useRef()
    const screenshotRef = useRef()
    const videoRef = useRef()
    const tagRef = useRef()

    const placeholder = LeaderBoard
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [leaderboardIcon, setLeaderboardIcon] = useState(null)
    const [tags, setTags] = useState([]);

    // Match Information
    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setLeaderboardIcon(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setLeaderboardIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    // Match and Leaderboard Detail
    const [minPlayer, setMinPlayer] = useState(null)
    const [maxPlayer, setMaxPlayer] = useState(null)
    const [leaderboardName, setLeaderboardName] = useState('')
    const [leaderboardId, setLeaderboardId] = useState('')
    const [leaderboardDescription, setLeaderbordDescription] = useState('')
    const [leaderboardMethod, setLeaderboardMethod] = useState('')
    const [leaderboardRequired, setLeaderboardRequired] = useState(true)
    const [frequency, setFrequency] = useState(true)

    const onMinPlayer = (e) => {
      setMinPlayer(parseInt(e.target.value))
    }

    const onMaxPlayer = (e) => {
      setMaxPlayer(parseInt(e.target.value))
    }

    const onLeaderboardName = (e) => {
      if(e.target.value == ''){
        setLeaderboardName('')
      }else{
        errors.nameError = ''
        setLeaderboardName(e.target.value)
      }
    }

    const onLeaderboardId = (e) => {
      if(e.target.value == ''){
        setLeaderboardId('')
      }else{
        errors.idError = ''
        setLeaderboardId(e.target.value)
      }
    }

    const onLeaderboardDescription = (e) => {
      setLeaderbordDescription(e.target.value)
    }

    const [leaderboardSourceOption, setLeaderboardSourceOption] = useState()
    const [leaderboardSource, setLeaderboardSource] = useState()
    const [leaderboardSourceId, setLeaderboardSourceId] = useState(null)
    const [leaderboardOutcomeOption, setLeaderboardOutcomeOption] = useState()
    const [leaderboardOutcome, setLeaderboardOutcome] = useState('')
    const [leaderboardOutcomeId, setLeaderboardOutcomeId] = useState(null)
    // const [recurringOptions, setRecurringOption] = useState()
    // const [recurring, setRecurring] = useState('')
    // const [recurringId, setRecurringId] = useState()

    // Custom Data
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleInputChange = (e) => {

    }

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', sourceError : '', outcomeError : '', frequencyError : '', pdErrorStart : [], pdErrorEnd : []})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
          error.nameError = 'Display Name is required';
          isValid = false;
          const element = document.getElementById('leaderboardName');
          const offset = 140;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.leaderboardId == '') {
          error.idError = 'Leaderboard ID is required';
          isValid = false;
          if(data.name != ''){
            const element = document.getElementById('leaderboardId');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }

        if (data.sourceTypeId == '' || data.sourceTypeId == null) {
          error.sourceError = 'Leaderboard Source is required';
          isValid = false;
          if(data.name != '' && data.leaderboardId != ''){
            const element = document.getElementById('source');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }
      
        if (data.leaderboardOutcomeTypeMasterId == '' || data.leaderboardOutcomeTypeMasterId == null) {
          error.outcomeError = 'Leaderboard Ranking Method is required';
          isValid = false;
          if(data.name != '' && data.leaderboardId != '' && (data.sourceTypeId != '' && data.sourceTypeId != null)){
            const element = document.getElementById('ranking');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }

        if(data.prizeDistributionRule){
          error.pdErrorStart = []
          error.pdErrorEnd = []
          for(var i = 0 ; i < data.prizeDistributionRule.length ; i ++){
              if(data.prizeDistributionRule[i].startRank == '' || data.prizeDistributionRule[i].startRank == null){
                  error.pdErrorStart[i] = 'Start Rank is required'
                  isValid = false
                  if(data.name != '' && data.leaderboardId != '' && (data.sourceTypeId != '' && data.sourceTypeId != null)){
                      if(data.name != '' && data.leaderboardId != '' && (data.sourceTypeId != '' && data.sourceTypeId != null)){
                          const element = document.getElementById(`PDStart${i}`);
                          const offset = 100;
                          const bodyRect = document.body.getBoundingClientRect().top;
                          const elementRect = element.getBoundingClientRect().top;
                          const elementPosition = elementRect - bodyRect;
                          const offsetPosition = elementPosition - offset;
                          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                      }
                  }
              }else if(i > 0 && (data.prizeDistributionRule[i].startRank <= data.prizeDistributionRule[i-1].endRank)){
                  error.pdErrorStart[i] = 'Start rank must be greater than previous end rank'
                  toast.error(<Toast type='Error' messages='Start rank must be greater than previous end rank.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                  isValid = false
                  if(data.name != '' && data.leaderboardId != '' && (data.sourceTypeId != '' && data.sourceTypeId != null)){
                      if(data.name != '' && data.leaderboardId != '' && (data.sourceTypeId != '' && data.sourceTypeId != null)){
                          const element = document.getElementById(`PDStart${i}`);
                          const offset = 100;
                          const bodyRect = document.body.getBoundingClientRect().top;
                          const elementRect = element.getBoundingClientRect().top;
                          const elementPosition = elementRect - bodyRect;
                          const offsetPosition = elementPosition - offset;
                          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                      }
                  }
              }else if(i > 0 && (data.prizeDistributionRule[i].startRank > data.prizeDistributionRule[i].endRank)){
                  error.pdErrorEnd[i] = 'End rank must be equal to or greater that start rank'
                  toast.error(<Toast type='Error' messages='End rank must be equal to or greater that start rank.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                  isValid = false
                  if(data.name != '' && data.leaderboardId != '' && (data.sourceTypeId != '' && data.sourceTypeId != null)){
                      if(data.name != '' && data.leaderboardId != '' && (data.sourceTypeId != '' && data.sourceTypeId != null)){
                          const element = document.getElementById(`PDEnd${i}`);
                          const offset = 100;
                          const bodyRect = document.body.getBoundingClientRect().top;
                          const elementRect = element.getBoundingClientRect().top;
                          const elementPosition = elementRect - bodyRect;
                          const offsetPosition = elementPosition - offset;
                          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                      }
                  }
              }
              else{
                  error.pdErrorStart[i] = ''
                  error.pdErrorEnd[i] = ''
              }
          }
        }

        setErrors(error);

        if(isValid){
          return true
        }
    }

    const cancel = () => {
      navigate(`/leaderboard/${appid}`)
      setIsChanged(false)
  }

    const [disable, setDisable] = useState(false)
    const [id, setId] = useState(null)

  function removeIconKey(data) {
      data && data.forEach(entry => {
        const { rewardDetails } = entry;
        if (rewardDetails) {
          ['items', 'currencies', 'bundles', 'progressionMarkers'].forEach(key => {
            if (Array.isArray(rewardDetails[key])) {
              rewardDetails[key].forEach(item => {
                if(item.hasOwnProperty('quantity')){
                  item.amount = item.quantity
                  // delete item.quantity
                }
                if (item.hasOwnProperty('iconUrl')) {
                  if(item.iconUrl&&item.iconUrl.includes('data:image/png;base64')){
                    item.iconUrl = null
                  }
                }
                if (item.hasOwnProperty('status')) {
                  delete item.status;
                }
              });
            }
          });
        }
      });
    return data;
  }

  const db = getFirestore(app);

  const saveLeaderboard = async (visibleModal, setVisibleModal, setId) => {

    const metaDataList = [...metaData];
    const finalMetaData = {};
    for(var i = 0; i < metaDataList.length ; i++){
      finalMetaData[metaDataList[i].key] = metaDataList[i].value;
    }

    let leaderboardTags = []
    tags && tags.forEach((e)=>{
      leaderboardTags.push(e.text)
    })

    const podiumRanksDetail = []
    podiumRanks && podiumRanks.forEach((e, i) => {
      var key_one = e.ranks
      var key_two = e.score
      podiumRanksDetail.push({[key_one]:key_two})
    })

    // let rankDetails = JSON.parse(localStorage.getItem('Ranks'))
    const updatedData = removeIconKey(ranks)

    let leaderboard = {
      name : leaderboardName,
      iconUrl : leaderboardIcon,
      leaderboardId : leaderboardId,
      description : leaderboardDescription,
      leaderboardOutcomeDetails : (leaderboardOutcomeId == 4 ? podiumRanksDetail : []),
      active : true,
      archive : false,
      projectId : appid,
      ...(leaderboardSourceId == 1 && {matchId : matchId}),
      ...(leaderboardSourceId == 1 && {gameId : gameId}),
      ...(leaderboardSourceId == 2 && paramType == 'default' && {defaultStatisticId : parameterId}),
      ...leaderboardSourceId == 2 && (paramType == 'custom' && {customStatisticId : parameterId}),
      leaderboardOutcomeTypeMasterId : leaderboardOutcomeId,
      sourceTypeId : leaderboardSourceId,
      tags : leaderboardTags,
      meta : finalMetaData,
      prizeDistributionRule : (PD && updatedData && updatedData.length > 0 ? updatedData : []),
      prizeDistributionOffset : (PD == false ? 0 : PD && offset ? 0 : parseInt(((hours * 60 * 60) + (minutes * 60))))
    }
    if(validation(leaderboard)){
      try {
        if(visibleModal){
          await dispatch(createLeaderboardAction(leaderboard, navigate, appid, setDisable, visibleModal, setVisibleModal, setId))
        }else{
          await dispatch(createLeaderboardAction(leaderboard, navigate, appid, setDisable))
        }
        // Access the response data (e.g., game ID)
            // const IdFromResponse = res?.data?.data?.id;

            setIsChanged(false);
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "CREATE",
                app_id:appid,
                resource: "LEADERBOARD",
                // resource_id: IdFromResponse, // Use the ID from response
                description: `Leaderboard '${leaderboardName}' created successfully.`,
                quantity: 1,
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(leaderboard),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
            console.log("Activity logged successfully.");
        
    } catch (error) {
        console.error("Error creating game or logging activity:", error);
    }
    }
  }

    const [podiumRanks, setPodiumRanks] = useState([])

    const addRank = () => {
      setPodiumRanks(prevState => [...prevState, {ranks : podiumRanks.length+1, score : null}])
    }

    const onRankChange = (e) => {
      let initialRanks = []
      for(var i = 0; i < parseInt(e) ; i++){
        initialRanks.push({ranks : i+1, score : null})
      }
      setPodiumRanks(initialRanks)
    }

    const onScoreChange = (e, index) => {
      let totalPodiumRanks = [...podiumRanks]
      totalPodiumRanks[index].score = e
      setPodiumRanks(totalPodiumRanks)
    }

    const removePodiumRanks = (index) => {
      let removeField = [...podiumRanks]
      let podiumFields = removeField.filter((e,i,c)=>{
          return i != index
      })
      setPodiumRanks(podiumFields)
    }

    const [startDateTime, setStartDateTime] = useState('')
    const [endDateTime, setEndDateTime] = useState('')

    const onChangeStartDateTime = (e) => {
      setStartDateTime(e.target.value)
    }

    const onChangeEndDateTime = (e) => {
      setEndDateTime(e.target.value)
    }

    const [startDate, setStartDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endDate, setEndDate] = useState('')
    const [endTime, setEndTime] = useState('')

    const onChangeStartDate = (e) => {
      setStartDate(e)
      setEndDate('')
    }

    const onChangeStartTime = (e) => {
      setStartTime(e.target.value)
    }

    const onChangeEndDate = (e) => {
      setEndDate(e)
    }

    const onChangeEndTime = (e) => {
      setEndTime(e.target.value)
    }

    useEffect(()=>{
      getMetaData()
    },[])
    
    const getMetaData = () => {
      dispatch(getAllMetaDataAction())
    }

    let isMetaDataLoading = useSelector((state) => {
      return state.common.isLoading
    })

    let allMetaData = useSelector((state) => {
      return state.common.metaData
    })

    useEffect(()=>{ 
      configureMetaData()
    }, [allMetaData])

    const [leaderboardDefaultParams, setLeaderboardDefaultParams] = useState([])
    const [leaderboardCustomParams, setLeaderboardCustomParams] = useState([])

    const [leaderBoardParamsOptions, setLeaderboardParamsOptions] = useState([])
    const [parameter, setParameter] = useState([])
    const [parameterId, setParameterId] = useState([])

    const getCustomParameter = async() => {
      let customParams = {
        projectId : appid
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await customAxios.post(`${BASE_URL}/app/get-custom-params`, customParams, headers)
      setLeaderboardCustomParams(res.data.data && res.data.data.map((obj) =>{
        return {
          id: obj.id,
          name: obj.name,
          type : 'custom'
        };
      }))
    }

    useEffect(()=>{
      getCustomParameter()
    }, [])

    useEffect(() => {
      configParams()
    }, [leaderboardDefaultParams, leaderboardCustomParams])

    const configParams = () => {
      if(leaderboardDefaultParams != null && leaderboardDefaultParams != undefined && leaderboardCustomParams != null && leaderboardCustomParams != undefined){
        let allParams = [...leaderboardDefaultParams, ...leaderboardCustomParams]
        setLeaderboardParamsOptions(allParams)
      }
    }

    const configureMetaData = () => {
      setLeaderboardSourceOption(allMetaData.leaderboardSourceTypeMasterIds && allMetaData.leaderboardSourceTypeMasterIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.name == 'match' ? 'Match' : obj.name == 'statistics' ? 'Statistics' :obj.name == 'custom' ? 'Custom': ''),
        };
      }))
      setLeaderboardOutcomeOption(allMetaData.leaderboardOutcomeIds && allMetaData.leaderboardOutcomeIds.map((obj) => {
        return {
          id: obj.id,
          name: obj.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        };
      }))
      // setRecurringOption(allMetaData.leaderboardInterval && allMetaData.leaderboardInterval.map((obj) => {
      //   return {
      //     id: obj.id,
      //     name: (obj.intervalName == 'daily' ? 'Daily' : obj.intervalName == 'weekly' ? 'Weekly' : obj.intervalName == 'monthly' ? 'Monthly' : obj.intervalName == 'yearly' ? 'Yearly' : obj.intervalName == 'all_time' ? 'All Time' : obj.intervalName == 'custom' ? 'Custom' : ''),
      //   };
      // }))
      setLeaderboardDefaultParams(allMetaData.defaultParameter && allMetaData.defaultParameter.map((obj) => {
        return {
          id: obj.id,
          name: obj.name,
          type : 'default'
        }
      }))
    }

    const[paramType, setParamType] = useState()
    const [matchOptions, setMatchOptions] = useState()
    const [matchName, setMatchName] = useState()
    const [matchId, setMatchId] = useState()
    const [gameId, setGameId] = useState()

    const getAllMatch = async() => {
      let match = {
        projectId : appid,
        offset: 0,
        limit: 100
      }
      await dispatch(getAllMatchesAction(match))
    }

    useEffect(()=>{
      getAllMatch()
    }, [])

    let matches = useSelector((state) =>{
      return state.matches.matches
    })

    const configureMatch = () => {
      if(matches && matches.length > 0){
        setMatchOptions(matches)
      }
    }

    useEffect(()=>{
      configureMatch()
    }, [matches])

    const [ranks, setRanks] = useState([{no: 1, startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}}])
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [indexLevel, setIndexLevel] = useState()
    const [type, setType] = useState()
    const [PD, setPD] = useState(false)

    useEffect(()=>{
        setStorage();
    }, [])

    const setStorage = () => {
      sessionStorage.setItem('Ranks', JSON.stringify(ranks))
    }

    const addRanks = () => {
      const totalRanks = [...ranks]
      totalRanks.push({no: ranks.length + 1, startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}})
      setRanks(totalRanks)
      sessionStorage.setItem('Ranks', JSON.stringify(totalRanks))
    }

    const onChangeStartRank = (index, value) => {
      if (/^[a-zA-Z0-9- ]+$/i.test(value) || value == '') {
          const updatedInputData = [...ranks];
          updatedInputData[index].startRank = parseInt(value);
          setRanks(updatedInputData);
          sessionStorage.setItem('Ranks', JSON.stringify(ranks))
          if(value != null || value != NaN){
            let errorData = errors
            errorData.pdErrorStart[index] = ''
            setErrors(errorData)
          }
        }
    }

    const onChangeEndRank = (index, value) => {
        const updatedInputData = [...ranks];
        updatedInputData[index].endRank = parseInt(value);
        setRanks(updatedInputData);
        sessionStorage.setItem('Ranks', JSON.stringify(ranks))
        if(value != null || value != NaN){
            let errorData = errors
            errorData.pdErrorEnd[index] = ''
            setErrors(errorData)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
      };
    
    const onChangeRankReward = (e) => {
        let initialRanks = []
        for(var i = 0; i < parseInt(e) ; i++){
          initialRanks.push({startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}})
        }
        setRanks(initialRanks)
        sessionStorage.setItem('Ranks', JSON.stringify(initialRanks))
    }
    
    
    const removeRanks = (index) => {
        let removeField = [...ranks]
        let rankFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setRanks(rankFields)
        sessionStorage.setItem('Ranks', JSON.stringify(rankFields))
    }

  const [offset, setOffset] = useState(true)
  const [hours, setHours] = useState(null)
  const [minutes, setMinutes] = useState(null)

  const onChangeHours = (e) => {
    setHours(parseInt(e.target.value))
  }

  const onChangeMinutes = (e) => {
    setMinutes(parseInt(e.target.value))
  }

  const [visibleFile, setVisibleFile] = useState(false)

  const setchange = () => {
    if (
        (leaderboardName===undefined||leaderboardName==''||leaderboardName==""||leaderboardName===null)&&
        (leaderboardId===undefined||leaderboardId==''||leaderboardId==""||leaderboardId===null)&&
        (leaderboardDescription===undefined||leaderboardDescription==''||leaderboardDescription==""||leaderboardDescription===null)&&
        (leaderboardSourceId===undefined||leaderboardSourceId==''||leaderboardSourceId==""||leaderboardSourceId===null)&&
        (leaderboardOutcomeId===undefined||leaderboardOutcomeId==''||leaderboardOutcomeId==""||leaderboardOutcomeId===null)&&
        (PD===false)&&
        (offset===true)&&
        (offset||(hours===undefined||hours==''||hours==""||hours===null))&&
        (offset||(minutes===undefined||minutes==''||minutes==""||minutes===null))&&
        // (ranks.length==1&& ranks[0].startRank ==null &&ranks[0].endRank ==null &&ranks[0].rewardDetails.length ==0)&&
        (tags.length==0)&&
        (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
    )
    {
        setIsChanged(false)
    }
    else
    {
        setIsChanged(true)
    }
}


useEffect(()=>{
    setchange()
},[leaderboardName,leaderboardId,leaderboardDescription,leaderboardSourceId,leaderboardOutcomeId,PD,offset,ranks,tags,metaData])

  const [media, setMedia] = useState([])

  const getMedia = async() => {
      let getMediaData = {
          projectId : appid,
          typeId : 0
      }
      await dispatch(getAllMediaAction(getMediaData))
  }

  let files = useSelector((state) => {
      return state.media.media.mediaDetails
  })

  const configureMedia = () => {
      if(files && files.length > 0){
          setMedia(files && files.map((e, i) => ({...e, status : false})))
      }else{
          setMedia([])
      }
  }

  useEffect(() => {
      getMedia()
      getAllRewards()
  }, [])

  useEffect(() => {
      configureMedia()
  }, [files])

  // CSV Upload
  const [file, setFile] = useState(null)
  const [validate, setValidate] = useState(false)
  const [fileErrors, setFileErrors] = useState({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}});

  const parseRewards = (rewards, type) => {
    if (!rewards) return [];
    let rows = rewards.split(', ').map(reward => {
      const match = reward.match(/(.+)\((\d+)\)/);
      return match ? {name: match[1], id: match[1], quantity: parseInt(match[2]), rewardType: type } : null;
    })
    return rows
  };

  const validateRewards = async(data) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, data, headers)
    if(res.data.data){
      let errorRewards = [...res.data.data.bundlesDetails.nonExistingBundles, ...res.data.data.currenciesDetails.nonExistingCurrencies, ...res.data.data.itemDetails.nonExistingItems, ...res.data.data.progressionMarkersDetails.nonExistingProgressionMarkers]
      if(errorRewards?.length > 0){
        setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: res.data.data.bundlesDetails.nonExistingBundles.map(bundle => `Bundle with Id ${bundle} does not exist`), currencies: res.data.data.currenciesDetails.nonExistingCurrencies.map(currency => `Currency with Id ${currency} does not exist`), items: res.data.data.itemDetails.nonExistingItems.map(item => `Item with Id ${item} does not exist`), pm: res.data.data.progressionMarkersDetails.nonExistingProgressionMarkers.map(pm => `Progression Marker with Id ${pm} does not exist`)}})
        return false
      }else{
        setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
        return true
      }
    }
  }
      
  const transformData = (data) => {
    return data.map((entry, index) => ({
      startRank: entry["Start Rank"],
      endRank: entry["End Rank"],
      rewardDetails: {
        items: parseRewards(entry.Items, 'Items'),
        bundles: parseRewards(entry.Bundles, 'Bundle'),
        currencies: parseRewards(entry.Currencies, 'Currency'),
        progressionMarkers: parseRewards(entry["Progression Markers"], 'PM')
      }
    }));
  };

  const checkRewards = async(data) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids: []}
      }
    }

    data && data.forEach((e, index) => {
      const extractIds = (x) => {
        if(x){
          const items = x.split(", ");
          return items.map(item => item.match(/^([^\(]+)/)[1]);
        }
        return [];
      }
      const addUniqueIds = (ids, key) => {
        ids.forEach(id => {
            if (!rewards.rewardDetails[key].ids.includes(id)) {
              rewards.rewardDetails[key].ids.push(id);
            }
        });
      };

      addUniqueIds(extractIds(e["Items"]), 'items');
      addUniqueIds(extractIds(e["Bundles"]), 'bundles');
      addUniqueIds(extractIds(e["Currencies"]), 'currencies');
      addUniqueIds(extractIds(e["Progression Marker"]), 'progressionMarkers');
    })

    let validateRewardData = await validateRewards(rewards)
    if(validateRewardData){
      return true
    }else{
      return false
    }
  }

  const checkDataType = (data) => {
    const errors = [];
    data.forEach((obj, index) => {
      // Validate Start Rank
      if (!Number.isInteger(obj["Start Rank"]) || obj["Start Rank"] <= 0) {
        errors.push(`Error at row number ${index+2} in Start Rank : Start Rank should be a positive integer.`);
      }
      // Validate End Rank
      if (!Number.isInteger(obj["End Rank"]) || obj["End Rank"] <= 0) {
        errors.push(`Error at row number ${index+2} in End Rank : End Rank should be a positive integer.`);
      }
      // Function to validate amounts in parentheses
      const validateAmounts = (key) => {
        if (obj[key]) {
          const items = obj[key].split(", ");
          for (const item of items) {
            const match = item.match(/\((\d+)\)$/);
            if (!match || parseInt(match[1]) <= 0) {
              errors.push(`Error at row number ${index+2} in ${key} : ${obj[key]} contains invalid amount.`);
            }
          }
        }
      };
      // Validate Items
      validateAmounts("Items");
      // Validate Bundles
      validateAmounts("Bundles");
      // Validate Currencies
      validateAmounts("Currencies");
      // Validate Progression Marker
      validateAmounts("Progression Marker");
    });
    return errors;
  }
  
  const convertToJson = async(data) => {
    if(data.length > 1000){
      let newErrors = { sizeError : '', rowsError : 'Number of rows should be less than 1000', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
      setFileErrors(newErrors)
    }else{
      let errors = checkDataType(data)
      setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError : [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
      if(errors.length === 0){
        let checkRewardData = await checkRewards(data)
        if(checkRewardData){
          setValidate(true)
          let rows = transformData(data)
          setRanks(rows)
          sessionStorage.setItem('Ranks', JSON.stringify(rows))
        }else{
          setValidate(false)
        }
      }else{
        setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: errors, nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
      }
    }

  }

  const checkErrors = (header) => {
    let newErrors = { sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
    let isError = true;
    if (header[0] !== 'Start Rank') {
      newErrors.startRankErr = `Please change 1st Column name from ${header[0]} should be Start Rank`;
      isError = false
    }
    if (header[1] !== 'End Rank') {
      newErrors.EndRankErr = `Please change 2nd Column name from ${header[1]} should be End Rank`;
      isError = false
    }
    if (header[2] !== 'Items') {
      newErrors.itemErr = `Please change 3rd Column name from ${header[2]} should be Items`;
      isError = false
    }
    if (header[3] !== 'Bundles') {
      newErrors.bundleErr = `Please change 4th Column name from ${header[3]} should be Bundles`;
      isError = false
    }
    if (header[4] !== 'Currencies') {
      newErrors.currenciesErr =  `Please change 5th Column name from ${header[4]} should be Currencies`;
      isError = false
    }
    if (header[5] !== 'Progression Marker') {
      newErrors.pmErr = `Please change 6th Column name from ${header[0]} should be Progression Marker`;
      isError = false
    }

    setFileErrors(newErrors);
    if(isError){
      return true
    }

  }

  const onCSV = async(event) => {
    const file = event.target.files[0];
    setFile(file)
    setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
    setValidate(false)
  }

  const onValidation = () => {
    if(file && (file.size/1024).toFixed(2) < 10000){
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        const headers = columnsArray[0];
        if(checkErrors(headers)){
          setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
          const json = XLSX.utils.sheet_to_json(worksheet);
          let xyz = convertToJson(json)
        }
      };  
      reader.readAsArrayBuffer(file);
      setLastEndRank(false)
    }else if(file && (file.size/1024).toFixed(2) > 10000){
      let newErrors = { sizeError : 'File size should be less than 10Mb', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
      setFileErrors(newErrors)
    }else{
      toast.error(<Toast type='Error' messages='Please Upload File.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
    }
  }
  
  const [lastEndRank, setLastEndRank] = useState(false)
  
  const onLastEndRank = (e) => {
    if(e.target.checked){
      setLastEndRank(true)
      const updatedInputData = [...ranks];
      updatedInputData[updatedInputData.length-1].endRank = parseInt(100000);
      setRanks(updatedInputData);
      sessionStorage.setItem('Ranks', JSON.stringify(ranks))
    }else{
      setLastEndRank(false)
      const updatedInputData = [...ranks];
      updatedInputData[updatedInputData.length-1].endRank = null;
      setRanks(updatedInputData);
      sessionStorage.setItem('Ranks', JSON.stringify(ranks))
    }
  }
  
  const [item, setItem] = useState([])
  const [currency, setCurrency] = useState([])
  const [bundle, setBundle] = useState([])
  const [pm, setPM] = useState([])

  const getAllRewards = () => {
      let search = {
          projectId : appid,
          search : ''
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      axios.post(`${BASE_URL}/inventory/search`, search, headers)
      .then(res=>{
          let currencies = res.data.data.currencies.map(i => ({uuid:i.id, id : i.currencyId, description: i.description,  iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), name : i.name, status : false, rewardType: 'Currency', type:i.type}));
          let items = res.data.data.inventoryItems.map(i => ({uuid:i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.itemId, description: i.description,  name : i.name, status : false, rewardType: 'Items'}));
          let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
          let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
          setItem(items)
          setCurrency(currencies)
          setBundle(bundles)
          setPM(pm)
      })
  }
  
  return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
              <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => {setVisibleFile(true)}}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {leaderboardIcon != null ?
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleFile} setVisible={setVisibleFile} icon={leaderboardIcon} setIcon={setLeaderboardIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                      <TextInput tooltip={TooltipTitle.LeaderboardName} id='leaderboardName' className={styles.field} errorMessage={errors.nameError}  label="Leaderboard Name*" name="leaderboardName" type="text" required onChange={(e)=>{onLeaderboardName(e)}}/>
                      <TextInput tooltip={TooltipTitle.LeaderboardId} isId={true} value={leaderboardId} id='leaderboardId' className={styles.field} errorMessage={errors.idError}  label="Leaderboard ID*" name="leaderboardId" type="text" required onChange={(e)=>{onLeaderboardId(e)}}/>
                    </div>

                    <div className={styles.textGroup}>
                      <TextArea value={leaderboardDescription} tooltip={TooltipTitle.LeaderboardDescription} rows={5} label="Leaderboard Description" onChange={(e)=>{onLeaderboardDescription(e)}}/>
                    </div>
                </div>
            </Card>
            
            <Card className={cn(styles.card, className)} title="Leaderboard Creation" classTitle="title-purple">
              <div className={styles.description}>
                <>
                  <div className={styles.group}>
                    <div className={styles.field}>
                      <Dropdown tooltip={TooltipTitle.LeaderboardSource} id='source' label='Leaderboard Source*' errorMessage={errors.sourceError} placeholder='Choose Leaderboard Source' value={leaderboardSource} setValue={setLeaderboardSource} setId={setLeaderboardSourceId} options={leaderboardSourceOption}/>
                    </div>

                    {leaderboardSourceId == 1 &&
                      <div className={styles.field}>
                        <MatchDropdown label='Select Match' placeholder='Choose Match' type="match" value={matchName} setValue={setMatchName} setId={setMatchId} setOtherId={setGameId} options={matchOptions} />
                      </div>
                    }
                    {leaderboardSourceId == 2 &&
                      <div className={styles.field}>
                        <Dropdown label='Select Parameter' placeholder='Choose Parameter' options={leaderBoardParamsOptions} value={parameter} setValue={setParameter} setId={setParameterId} setType={setParamType}/>
                      </div>
                    }
                  </div>
                  <div className={styles.group}>
                    <div className={styles.field}>
                      <Dropdown tooltip={TooltipTitle.LeaderboardRanking} id='ranking' label='Leaderboard Ranking Method*' errorMessage={errors.outcomeError} placeholder='Choose Leaderboard Ranking Method' value={leaderboardOutcome} setValue={setLeaderboardOutcome} setId={setLeaderboardOutcomeId} options={leaderboardOutcomeOption}/>
                    </div>
                  </div>
                  
                  {leaderboardOutcomeId == 4 &&
                    <>
                      <div className={styles.group}>
                        <div className={styles.podiumSystem}>
                              <div className={styles.podiumGroup}>
                                  <div className={styles.rankLabel}>
                                      <span className={styles.podiumLabelTxt}>No of Podium Ranks</span><span onClick={addRank} className={styles.podiumLabelImg}><AddIcon /></span>
                                  </div>
                                  <div>
                                      <input type='number' onWheelCapture={e => {e.currentTarget.blur()}}  className={styles.inputField} value={podiumRanks.length > 0 ? podiumRanks.length : ''} onChange={(e)=>{onRankChange(e.target.value)}}/>
                                  </div>
                              </div>
                        </div>
                      </div>
                      {podiumRanks && podiumRanks.length > 0 && (
                        <>
                          <div className={styles.groupRanking}>
                            <div className={styles.fieldBlank}></div>
                            <div className={styles.fieldRank}>Starting Rank</div>
                            <div className={styles.field}>Score</div>
                          </div>

                          {podiumRanks && podiumRanks.map((e,i) => (
                            <>
                              <div className={styles.groupRanking} key={i}>
                                <div className={styles.fieldBlank}></div>
                                <div className={styles.fieldRank}>{i+1}</div>
                                <div className={styles.field}><TextInput key={i} type='number' onWheelCapture={e => {e.currentTarget.blur()}}  value={e.score} onChange={(e)=>{onScoreChange(parseInt(e.target.value), i)}} /></div>  
                                <div className={styles.fieldRemove}><span onClick={()=>{removePodiumRanks(i)}}><Remove /></span></div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                        
                    </>
                  }
                </>
              </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Prize Configuration" classTitle="title-blue">
                    <div className={styles.cardDescription}>
                        <div className={styles.createPD}>
                            <div className={styles.groups}>
                              <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Do you want to configure Prize Distribution for this leaderboard?<span><Tooltip className={styles.tooltip} title={TooltipTitle.LeaderboardPD} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="PD" value={PD} onChange={() => setPD(true)} content="Yes" />
                                    <Radio className={styles.radio} name="PD" value={!PD} onChange={() => setPD(false)} content="No" />
                                </div>
                              </div>
                            </div>
                              {PD &&
                              <>
                                <div className={styles.group}>
                                    <div className={styles.pdSystem}>
                                        <div className={styles.pdSystemGroup}>
                                          <div className={styles.pdLabel}>
                                            <span className={styles.pdLabelTxt}>Ranks</span><span className={styles.pdLabelImg} onClick={addRanks}><AddIcon /></span>
                                          </div>
                                          <div className={styles.pdLabel}>
                                            <input className={styles.inputField} type='number' onWheelCapture={e => {e.currentTarget.blur()}} value={ranks.length > 0 ? ranks.length : ''} onChange={(e)=>{onChangeRankReward(e.target.value)}}/>
                                          </div>
                                          <ControlFileUpload valueOne={file} setValueOne={setFile}  error={fileErrors} validateOne={validate} onCSV={onCSV} onValidation={onValidation} count={1} placeHolderOne={`Click or drop PD CSV`} multiple={false} accept={`.xlsx, .xls, .csv`} labelOne='Prize Distribution' usedFor='Leaderboard' />  
                                          <Control data={ranks} value={ranks} setValue={setRanks}/>
                                        </div>
                                    </div>
                                </div>
                                
                                {ranks && ranks.length > 0 ?
                                  <div className={styles.groupByThree}>
                                    <p></p>
                                    <p className={styles.fieldTitle}>Rank Start</p>
                                    <p className={styles.fieldTitle}>Rank End</p>
                                    <p className={styles.fieldTitle}>Rewards</p>
                                  </div>
                                : ''} 

                                {ranks && ranks.map((e,i)=>{
                                  return(
                                    <>
                                      <div key={i} className={styles.groupByThree}>
                                        <p className={styles.index}></p>
                                        <TextInput id={`PDStart${i}`} className={styles.field} errorMessage={errors.pdErrorStart[i]} type="number" key={i} min={1} value={e.startRank} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=> onChangeStartRank(i, e.target.value) }/>
                                        {!lastEndRank && <TextInput id={`PDEnd${i}`} className={styles.field} errorMessage={errors.pdErrorEnd[i]} type="number" key={i} value={e.endRank} min={1} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e) => onChangeEndRank(i, e.target.value)} onKeyDown={handleKeyDown}/>}
                                        {lastEndRank && i < ranks?.length - 1 && <TextInput id={`PDEnd${i}`} className={styles.field} errorMessage={errors.pdErrorEnd[i]} type="number" key={i} value={e.endRank} min={1} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e) => onChangeEndRank(i, e.target.value)} onKeyDown={handleKeyDown}/>}
                                        {lastEndRank && i + 1 === ranks?.length && <><div className={styles.fieldDummy}><div className={styles.sign}>&infin;</div></div></>}
                                          <p className={styles.field}>
                                            {ranks && ((ranks[i].rewardDetails?.currencies?.length + ranks[i].rewardDetails?.items?.length + ranks[i].rewardDetails?.bundles?.length + ranks[i].rewardDetails?.progressionMarkers?.length) === 0) ? 
                                              <>
                                                <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('add')}}><AddIcon className={styles.icon}/></span> 
                                              </>
                                            :
                                              <div className={styles.levelRewards}>
                                                <div className={styles.reward}>
                                                  {ranks && ((ranks[i].rewardDetails?.currencies?.length + ranks[i].rewardDetails?.items?.length + ranks[i].rewardDetails?.bundles?.length + ranks[i].rewardDetails?.progressionMarkers?.length) > 0) ? 
                                                    (ranks[i].rewardDetails && [...ranks[i].rewardDetails?.currencies, ...ranks[i].rewardDetails?.items, ...ranks[i].rewardDetails?.bundles, ...ranks[i].rewardDetails?.progressionMarkers].slice(0,2).map((x,index) => 
                                                      <span><span>{index > 0 && ', '}</span><span>{x.name}</span><span>&nbsp;</span><span>({x.quantity})</span></span>
                                                    )) 
                                                  : ''}
                                                </div>
                                                <div className={styles.editIcon} key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('edit')}}><EditIcon className={styles.icon}/></div>
                                              </div>
                                            }
                                          </p>  
                                          <p className={styles.remove}><span className={styles.removeIcon} onClick={()=>{removeRanks(i)}}><Remove /></span></p>
                                      </div>
                                    </>
                                  )
                                })}

                                {ranks && ranks.length > 0 ?
                                  <div className={styles.groupByThree}>
                                    <p></p>
                                    <p className={styles.fieldTitle}></p>
                                    <p className={styles.fieldTitle}><Checkbox size='small' content='To Last Participant' value={lastEndRank} onChange={(e) => {onLastEndRank(e)}}/></p>
                                    <p className={styles.fieldTitle}></p>
                                  </div>
                                : ''} 
                                
                                <div>
                                  {/* <Rewards type={type} level={indexLevel} visible={visibleHelp} setVisible={setVisibleHelp} onClose={onClose} setPSLevels={setRanks}/> */}
                                  <Modal heading={true} width={'medium'} visible={visibleHelp} onClose={() => setVisibleHelp(false)}>
                                    <AddContentModal amountKey="quantity" categoryIndex={indexLevel} onClose={() => setVisibleHelp(false)} type={"Task"} currency={currency} setCurrency={setCurrency} item={item} setItem={setItem} bundles={bundle} setBundle={setBundle} PM={pm} setPM={setPM} value={ranks} setValue={setRanks} />
                                  </Modal>
                                </div>

                            <div className={styles.pdGroup}>
                              <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Prize Distribution</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="PDOffset" value={offset} onChange={() => setOffset(true)} content="On End" />
                                    <Radio className={styles.radio} name="PDOffset" value={!offset} onChange={() => setOffset(false)} content="Offset" />
                                </div>
                              </div>
                              {!offset && 
                                <>
                                  <TextInput type='number' className={styles.fields} label='Hours' onChange={(e) => {onChangeHours(e)}}/>
                                  <TextInput type='number' className={styles.fields} label='Minutes' onChange={(e) => {onChangeMinutes(e)}}/>
                                </>
                              }
                            </div> 
                                  </>
                              }  
                        </div>
                    </div>
                </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}> 
                    <TagInputWithDropdown tooltip={TooltipTitle.LeaderboardTags} tags={tags} setTags={setTags}/>

                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.LeaderboardMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required  onChange={(e)=>{onChangeKey(e,i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon}/></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                      <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={()=>{saveLeaderboard(false, null , null)}}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                      <Schedule id={id} setId={setId} as={`saveNschedule`} type='Leaderboard' saveLeaderboard={saveLeaderboard} offset={(PD == false ? 0 : PD && offset ? 0 : parseInt(((hours * 60 * 60) + (minutes * 60))))} name={leaderboardName}/>
                    </div>
                    <div className={styles.cardCancel}>
                      <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default LeaderboardInformation