import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom'
import styles from './addMembers.module.sass'
import Icon from '../../../Icon'
import SendInviteInput from '../SendInviteInput'
import MemberTableLayout from '../MemberTableComponent'
import {acceptedUser} from '../../../../screens/AppSettings/Members/mockData/acceptedUser'
import MemberPermissionDropdown from '../MemberPermissionDropdown'
import MemberPermissionDropdowns from './MemberPermissionDropdown'
import axios from 'axios'
import { BASE_URL } from '../../../../resources/APIEndpoints'
import { toast } from 'react-toastify'
import Toast from '../../../Toast'
import Checkbox from '../../../Checkbox'
import AppPlaceholder from '../../../../media/images/placeholders/App.png'
import { editInvitedMemberPermissionAction, editMemberPermissionAction, sendMemberInviteAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import app from "../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const AddMemberModal = ({className, to, id, memberAction, usedFor, onClose, apps, setApps, permissions}) => {

  const {appid} = useParams()
  const dispatch = useDispatch()

  const [appsList, setAppsList] = useState()
  const [permissionOption, setPermissionOption] = useState([{id : 1, name : 'View Only'},{id : 2, name : 'Can Edit'}])
  const [permission, setPermission] = useState(permissionOption[0].name)
  const [userPermission, setUserPermission] = useState(1)

  const [email, setEmail] = useState()
  const [organisationId, setOrganisationId] = useState()

  useEffect(()=>{
    if(memberAction === 'Add'){
      setAppsList(apps)
    }else{
      configureAppAccess()
    }
  }, [])

  const configureAppAccess = () => {
    if(permissions && permissions?.length > 0){
      let appAccess = apps && apps.map((e, i) => {
        let matchObj = permissions.find(ele => e.id === ele.projectId)
        if(matchObj){
          return{
            ...e,
            permission : {name : (matchObj.permissionId === 1 ? 'View Only' : matchObj.permissionId === 2 ? 'Can Edit' : ''), id: matchObj.permissionId},
            checked : true
          }
        }else{
          return{
            ...e,
            permission : {name : 'View-Only', id: 1},
            checked : false
          }
        }
      })
      let check = appAccess && appAccess.filter(app => app.checked == false)
      if(check.length == 0){
        setCheckAllCheckBox(true)
      }else{
        setCheckAllCheckBox(false)
      }
      setAppsList(appAccess)
    }else{
      let appAccess = apps && apps.map((e,i) => ({...e, checked : false}))
      let check = appAccess && appAccess.filter(app => app.checked == false)
      if(check.length == 0){
        setCheckAllCheckBox(true)
      }else{
        setCheckAllCheckBox(false)
      }
      setAppsList(appAccess)
    }
  }

  const onChangeEmail = (e) =>{
    setEmail(e)
  }

  const toggleApp = (e, index, id) => {
    let apps = [...appsList]
    apps[index].checked = e.target.checked
    setAppsList(apps)
    let check = apps && apps.filter(app => app.checked == false)
    if(check.length == 0){
      setCheckAllCheckBox(true)
    }else{
      setCheckAllCheckBox(false)
    }
  }

  const [checkAllCheckBox, setCheckAllCheckBox] = useState(true)

  const checkAll = (check) => {
      setCheckAllCheckBox(!checkAllCheckBox)
      const appData = appsList && appsList.map((e) => ({
        ...e,
        checked: check.target.checked,
      }));
      setAppsList(appData);
  }

  const db = getFirestore(app);

  const sendInvite = async() => {
    const filteredObjects = appsList.filter(obj => obj.checked === true).map(({ id, permission }) => ({ id, permission }));
    let inviteData = {
      email : email,
      organisationId : localStorage.getItem('orgId'),
      permissions : filteredObjects && filteredObjects.map((e, i) => ({projectId : e.id, permissionId : e.permission.id}))
    }
    if(inviteData && inviteData.permissions && inviteData.permissions.length > 0){
      try {
        debugger
        await dispatch(sendMemberInviteAction(inviteData, null))
        const user = JSON.parse(localStorage.getItem('user'))
        const memberID = user?.id
        const memberName = user?.name
        const orgId = (localStorage.getItem('orgId'))

        // Log activity in Firestore
        const activityLog = {
            timestamp: new Date().toISOString(),
            action_type: "CREATE",
            // app_id:appid,
            resource: "MEMBER",
            // resource_id: IdFromResponse, // Use the ID from response
            description: `Member invited successfully.`,
            quantity: 1,
operation_status: "SUCCESS",
            activity_feed: false,
            priority: "LOW",
            performed_by_id: memberID,
            organization_id: orgId,
            performed_by:memberName,
            request_json:JSON.stringify(inviteData),
        };
        await addDoc(collection(db, "activityFeed"), activityLog);
        console.log("Activity logged successfully.");
    } catch (error) {
        console.error("Error creating game or logging activity:", error);
    }
      setTimeout(()=>{
        onClose()
        setApps(apps && apps.map((e) => ({...e, checked : true, permission : {id : 1, name : 'View Only'}})))
      }, 1000)
    }else{
      toast.error(<Toast type='Error' messages='Atleast One App Should Be Selected'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
    }
  }

  const onEditAccess = async() => {
    try {
      let memberPermission
      debugger
      if(to === 'invited'){
        const filteredObjects = appsList.filter(obj => obj.checked === true).map(({ id, permission }) => ({ id, permission }));
        memberPermission = {
          id : id,
          permissions : filteredObjects && filteredObjects.map((e, i) => ({projectId : e.id, permissionId : e.permission.id}))
        }
        await dispatch(editInvitedMemberPermissionAction(memberPermission, null))
        onClose()
      }else{
        const filteredObjects = appsList.filter(obj => obj.checked === true).map(({ id, permission }) => ({ id, permission }));
        memberPermission = {
          updateMemberAccess : filteredObjects && filteredObjects.map((e, i) => ({memberId: id, projectId : e.id, permissionId : e.permission.id}))
        }
        await dispatch(editMemberPermissionAction(memberPermission, null))
        onClose()
      }
        const user = JSON.parse(localStorage.getItem('user'))
        const memberID = user?.id
        const memberName = user?.name
        const orgId = (localStorage.getItem('orgId'))

        // Log activity in Firestore
        const activityLog = {
            timestamp: new Date().toISOString(),
            action_type: "UPDATE",
            // app_id:appid,
            resource: "MEMBER",
            // resource_id: IdFromResponse, // Use the ID from response
            description: `Member permissions updated successfully.`,
            quantity: 1,
operation_status: "SUCCESS",
            activity_feed: false,
            priority: "LOW",
            performed_by_id: memberID,
            organization_id: orgId,
            performed_by:memberName,
            request_json:JSON.stringify(memberPermission),
        };
        await addDoc(collection(db, "activityFeed"), activityLog);
        console.log("Activity logged successfully.");
    } catch (error) {
        console.error("Error logging activity:", error);
    }
  }

  return(
    <>
      <div className={styles.addMembersModal}>
        <div className={styles.addMemberContainer}>
          <div className={styles.memberHeader}>

            <div className={styles.headingContainer}>
              <div className={styles.headingBlock}></div>
              <div className={styles.headingContent}>{memberAction === 'add' ? 'Add Members' : 'Edit Member Access'}</div>
            </div>

          </div>

          <div className={cn(styles.memberBody, {[styles.active] : memberAction === 'edit'})}>
            <div className={styles.inviteBody}>
              {memberAction === 'add' &&
              <div className={styles.inviteForm}>
                <SendInviteInput usedFor={usedFor} onChange={(e)=>{onChangeEmail(e.target.value)}} options={permissionOption} value={permission} setValue={setPermission} permission={userPermission} setPermission={setUserPermission} className={styles.field} placeholder='Type Email' currency={<Icon name="profile-circle" size="20" />} />
                <Link className={styles.invitedUser} onClick={sendInvite}><span>Send Invite</span></Link>
              </div>
              }
              
              <div className={styles.memberLists}>
                <div className={styles.checkAll}>
                  <div>All Apps</div>
                  <div><Checkbox value={checkAllCheckBox} onChange={(e)=>{checkAll(e)}}/></div>
                </div>
                <div className={styles.seprator}></div>
                <div className={styles.appList}>
                {appsList && appsList.map((item, index) => {
                  return(
                    <>
                      <div className={styles.membersList}>
                        <div className={styles.player}>
                          <div className={styles.playerInsigne}>
                              <img src={item.iconUrl != null ? item.iconUrl : AppPlaceholder} className={styles.appIcon} alt={item.name}/>
                          </div>
                          <div className={styles.playerDetails}>
                              <div className={styles.userName}>{item.name}</div>
                              <div className={styles.playerId}>{item.id}</div>
                          </div>
                        </div>
                        <div className={styles.memberPermission}>
                          <MemberPermissionDropdowns key={item.index} index={index} type='modal' options={permissionOption} value={item.permission.name} setValue={setAppsList} list={appsList}/>
                        </div>
                        <div className={styles.check}>
                          <Checkbox key={item.id} className={styles.checkbox} value={item.checked} onChange={(e)=>{toggleApp(e, index, item.id)}}/>
                        </div>
                      </div>
                      <div className={styles.seprator}></div>
                    </>
                  )
                })}
                </div>
              </div>
            </div>
          </div>
          {memberAction === 'edit' && 
            <div className={styles.memberFooter}>
              <button className={cn(styles.button, 'button-small')} onClick={onEditAccess}>Edit</button>
              <button className={cn(styles.button, 'button-white-small')} onClick={onClose}>Cancel</button>
            </div>
          }

        </div>
      </div>
    </>
  )

}

export default AddMemberModal