export const LOGIN = "LOGIN"
export const SIGNUP = "SIGNUP"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"

export const GET_META_DATA = 'GET_META_DATA'
export const GET_STATES = 'GET_STATES'
export const GET_CITY = 'GET_CITY'
export const GET_PLATFORMS = 'GET_PLATFORMS'
export const GET_GOALS = 'GET_GOALS'
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY'
export const GET_ALL_COUNTRY_SUCCESS = 'GET_ALL_COUNTRY_SUCCESS'
export const GET_ALL_COUNTRY_ERROR = 'GET_ALL_COUNTRY_ERROR'

export const GET_ALL_STATES = 'GET_ALL_STATES'
export const GET_ALL_STATES_SUCCESS = 'GET_ALL_STATES_SUCCESS'
export const GET_ALL_STATES_ERROR = 'GET_ALL_STATES_ERROR'

export const GET_CURRENCY = "GET_CURRENCY"
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS"
export const GET_CURRENCY_ERROR = "GET_CURRENCY_ERROR"
export const FILTER_CURRENCY_SUCCESS = 'FILTER_CURRENCY_SUCCESS'
export const FILTER_CURRENCY_ERROR = 'FILTER_CURRENCY_ERROR'
export const GET_CURRENCY_BY_ID = "GET_CURRENCY_BY_ID"
export const ADD_CURRENCY = "ADD_CURRENCY"
export const EDIT_CURRENCY = "EDIT_CURRENCY"

export const GET_ALL_GAMES = 'GET_ALL_GAMES'
export const GET_ALL_GAMES_SUCCESS = 'GET_GAMES_SUCCESS'
export const GET_ALL_GAMES_ERROR = 'GET_GAMES_ERROR'
export const GET_GAMES = 'GET_GAMES'
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS'
export const GET_GAMES_ERROR = 'GET_GAMES_ERROR'
export const FILTER_GAMES_SUCCESS = 'FILTER_GAMES_SUCCESS'
export const FILTER_GAMES_ERROR = 'FILTER_GAMES_ERROR'
export const ADD_GAMES =  'ADD_GAMES'
export const ADD_GAMES_SUCCESS = 'ADD_GAMES_SUCCESS'
export const ADD_GAMES_ERROR = 'ADD_GAMES_ERROR'
export const EDIT_GAMES = 'EDIT_GAMES'
export const EDIT_GAMES_ERROR = 'EDIT_GAMES_ERROR'
export const EDIT_GAMES_SUCCESS = 'EDIT_GAMES_SUCCESS'

export const GET_MATCHES = 'GET_MATCHES'
export const GET_LEADERBOARD_MATCHES_SUCCESS = 'GET_LEADERBOARD_MATCHES_SUCCESS'
export const GET_LEADERBOARD_MATCHES_ERROR = 'GET_LEADERBOARD_MATCHES_ERROR'
export const GET_MATCHES_SUCCESS = 'GET_MATCHES_SUCCESS'
export const GET_MATCHES_ERROR = 'GET_MATCHES_ERROR'
export const FILTER_MATCHES_SUCCESS = 'FILTER_MATCHES_SUCCESS'
export const FILTER_MATCHES_ERROR = 'FILTER_MATCHES_ERROR'
export const GET_MATCH_DETAIL = 'GET_MATCH_DETAIL'
export const GET_MATCH_DETAIL_SUCCESS = 'GET_MATCH_DETAIL_SUCCESS'
export const GET_MATCH_DETAIL_ERROR = 'GET_MATCH_DETAIL_ERROR'
export const ADD_MATCHES =  'ADD_MATCHES'
export const ADD_MATCHES_SUCCESS = 'ADD_MATCHES_SUCCESS'
export const ADD_MATCHES_ERROR = 'ADD_MATCHES_ERROR'
export const EDIT_MATCHES = 'EDIT_MATCHES'
export const EDIT_MATCHES_SUCCESS = 'EDIT_MATCHES_SUCCESS'
export const EDIT_MATCHES_ERROR = 'EDIT_MATCHES_ERROR'

export const GET_ITEM = "GET_ITEMS"
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS"
export const GET_ITEM_ERROR = "GET_ITEM_ERROR"
export const FILTER_ITEMS_SUCCESS = 'FILTER_ITEMS_SUCCESS'
export const FILTER_ITEMS_ERROR = 'FILTER_ITEMS_ERROR'
export const ADD_ITEM = "ADD_ITEMS"
export const EDIT_ITEM = "EDIT_ITEMS"

export const GET_BUNDLES = "GET_BUNDLES"
export const GET_BUNDLES_SUCCESS = "GET_BUNDLES_SUCCESS"
export const GET_BUNDLES_ERROR = "GET_BUNDLES_ERROR"
export const GET_BUNDLES_BY_ID = "GET_BUNDLES_BY_ID"
export const FILTER_BUNDELS_SUCCESS = 'FILTER_BUNDELS_SUCCESS'
export const FILTER_BUNDELS_ERROR = 'FILTER_BUNDELS_ERROR'
export const ADD_BUNDLES = "ADD_BUNDLES"
export const EDIT_BUNDLES = "EDIT_BUNDLES"

export const GET_STORES = "GET_STORES"
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS"
export const GET_STORES_ERROR = "GET_STORES_ERROR"
export const FILTER_STORES_SUCCESS = 'FILTER_STORES_SUCCESS'
export const FILTER_STORES_ERROR = 'FILTER_STORES_ERROR'
export const ADD_STORES = "ADD_STORES"
export const GET_STORES_BY_ID = "GET_STORES_BY_ID"
export const EDIT_STORES = "EDIT_STORES"

export const GET_PROGRESSION_MARKER = "GET_PROGRESSION_MARKER"
export const GET_PROGRESSION_MARKER_SUCCESS = "GET_PROGRESSION_MARKER_SUCCESS"
export const GET_PROGRESSION_MARKER_ERROR = "GET_PROGRESSION_MARKER_ERROR"
export const ADD_PROGRESSION_MARKER = "ADD_PROGRESSION_MARKER"
export const EDIT_PROGRESSION_MARKER = "EDIT_PROGRESSION_MARKER"
export const FILTER_PROGRESSION_MARKER_SUCCESS = 'FILTER_PROGRESSION_MARKER_SUCCESS'
export const FILTER_PROGRESSION_MARKER_ERROR = 'FILTER_PROGRESSION_MARKER_ERROR'

export const GET_PROGRESSION_SYSTEM = "GET_PROGRESSION_SYSTEM"
export const GET_PROGRESSION_SYSTEM_SUCCESS = "GET_PROGRESSION_SYSTEM_SUCCESS"
export const GET_PROGRESSION_SYSTEM_ERROR = "GET_PROGRESSION_SYSTEM_ERROR"
export const ADD_PROGRESSION_SYSTEM = "ADD_PROGRESSION_SYSTEM"
export const EDIT_PROGRESSION_SYSTEM = "EDIT_PROGRESSION_SYSTEM"
export const FILTER_PROGRESSION_SYSTEM_SUCCESS = 'FILTER_PROGRESSION_SYSTEM_SUCCESS'
export const FILTER_PROGRESSION_SYSTEM_ERROR = 'FILTER_PROGRESSION_SYSTEM_ERROR'

export const GET_DEFAULT_EVENTS = 'GET_DEFAULT_EVENTS'
export const GET_DEFAULT_EVENTS_SUCCESS = 'GET_DEFAULT_EVENTS_SUCCESS'
export const GET_DEFAULT_EVENTS_ERROR = 'GET_DEFAULT_EVENTS_ERROR'
export const GET_CUSTOM_EVENTS = 'GET_CUSTOM_EVENTS'
export const GET_CUSTOM_EVENTS_SUCCESS = 'GET_CUSTOM_EVENTS_SUCCESS'
export const GET_CUSTOM_EVENTS_ERROR = 'GET_CUSTOM_EVENTS_ERROR'
export const ADD_CUSTOM_EVENTS = 'ADD_CUSTOM_EVENTS'
export const EDIT_CUSTOM_EVENTS = 'EDIT_CUSTOM_EVENTS'
export const EDIT_DEFAULT_EVENTS = 'EDIT_DEFAULT_EVENTS'
export const FILTER_CUSTOM_EVENT_SUCCESS = 'FILTER_CUSTOM_EVENT_SUCCESS'
export const FILTER_CUSTOM_EVENT_ERROR = 'FILTER_CUSTOM_EVENT_ERROR'
export const FILTER_DEFAULT_EVENT_SUCCESS = 'FILTER_DEFAULT_EVENT_SUCCESS'
export const FILTER_DEFAULT_EVENT_ERROR = 'FILTER_DEFAULT_EVENT_ERROR'
export const GET_PARAM_VALUES = 'GET_PARAM_VALUES'


export const GET_TASK = "GET_TASK"
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS"
export const GET_TASK_ERROR = "GET_TASK_ERROR"
export const CREATE_TASK = "CREATE_TASK"
export const EDIT_TASK = "EDIT_TASK"
export const FILTER_TASK_SUCCESS = 'FILTER_TASK_SUCCESS'
export const FILTER_TASK_ERROR = 'FILTER_TASK_ERROR'

export const GET_STEP_SERIES = "GET_STEP_SERIES"
export const GET_STEP_SERIES_SUCCESS = "GET_STEP_SERIES_SUCCESS"
export const GET_STEP_SERIES_ERROR = "GET_STEP_SERIES_ERROR"
export const GET_STEP_SERIES_BY_ID = "GET_STEP_SERIES_BY_ID"
export const CREATE_STEP_SERIES = "CREATE_STEP_SERIES"
export const EDIT_STEP_SERIES = "EDIT_STEP_SERIES"
export const FILTER_STEP_SERIES_SUCCESS = 'FILTER_STEP_SERIES_SUCCESS'
export const FILTER_STEP_SERIES_ERROR = 'FILTER_STEP_SERIES_ERROR'

export const GET_PLAYERS = 'GET_PLAYERS'
export const GET_PLAYERS_SUCCESS = 'GET_PLAYERS_SUCCESS'
export const CREATE_CUSTOM_PLAYER = 'CREATE_CUSTOM_PLAYER'
export const GET_PLAYER_PROFILE = 'GET_PLAYER_PROFILE'
export const GET_PLAYER_DATA = 'GET_PLAYER_DATA'
export const GET_PLAYER_INVENTORY = 'GET_PLAYER_INVENTORY'
export const GET_PLAYERS_WALLET = 'GET_PLAYERS_WALLET'
export const GET_PLAYER_PROGRESSION = 'GET_PLAYER_PROGRESSION'
export const GET_PLAYERS_REWARDS = 'GET_PLAYERS_REWARDS'
export const GET_PLAYER_MATCHES = 'GET_PLAYER_MATCHES'
export const GET_PLAYER_TASK = 'GET_PLAYER_TASK'
export const CREATE_PLAYER_PAYOUT = 'CREATE_PLAYER_PLAYOUT'
export const GRANT_PM = 'GRANT_PM'
export const EDIT_PLAYERS_PROFILE = 'EDIT_PLAYERS_PROFILE'
export const FILTER_PLAYERS_SUCCESS = 'FILTER_PLAYERS_SUCCESS'
export const FILTER_PLAYERS_ERROR = 'FILTER_PLAYERS_ERROR'

export const GET_LEADERBOARD = 'GET_LEADERBOARD'
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS'
export const GET_LEADERBOARD_ERROR = 'GET_LEADERBOARD_ERROR'
export const GET_LEADERBOARD_DETAIL = 'GET_LEADERBOARD_DETAIL '
export const GET_LEADERBOARD_DETAIL_SUCCESS = 'GET_LEADERBOARD_DETAIL_SUCCESS'
export const GET_LEADERBOARD_DETAIL_ERROR = 'GET_LEADERBOARD_DETAIL_ERROR'
export const GET_LEADERBOARD_RESULT = 'GET_LEADERBOARD_RESULT '
export const GET_LEADERBOARD_RESULT_SUCCESS = 'GET_LEADERBOARD_RESULT_SUCCESS'
export const GET_LEADERBOARD_RESULT_ERROR = 'GET_LEADERBOARD_RESULT_ERROR'
export const CREATE_LEADERBOARD = 'CREATE_LEADERBOARD'
export const CREATE_LEADERBOARD_SUCCESS = 'CREATE_LEADERBOARD_SUCCESS'
export const CREATE_LEADERBOARD_ERROR = 'CREATE_LEADERBOARD_ERROR'
export const EDIT_LEADERBOARD = 'EDIT_LEADERBOARD'
export const EDIT_LEADERBOARD_SUCCESS = 'EDIT_LEADERBOARD_SUCCESS'
export const EDIT_LEADERBOARD_ERROR = 'EDIT_LEADERBOARD_ERROR'
export const FILTER_LEADERBOARD_SUCCESS = 'FILTER_LEADERBOARD_SUCCESS'
export const FILTER_LEADERBOARD_ERROR = 'FILTER_LEADERBOARD_ERROR'

export const GET_COMPETITION_TOURNAMENTS = 'GET_COMPETITION_TOURNAMENTS'
export const GET_COMPETITION_TOURNAMENTS_SUCCESS = 'GET_COMPETITION_TOURNAMENTS_SUCCESS'
export const GET_COMPETITION_TOURNAMENTS_FAILURE = 'GET_COMPETITION_TOURNAMENTS_FAILURE'
export const GET_COMPETITION_TOURNAMENTS_DETAILS = 'GET_COMPETITION_TOURNAMENTS_DETAILS'
export const GET_COMPETITION_TOURNAMENTS_DETAILS_SUCCESS = 'GET_COMPETITION_TOURNAMENTS_DETAILS_SUCCESS'
export const GET_COMPETITION_TOURNAMENTS_DETAILS_FAILURE = 'GET_COMPETITION_TOURNAMENTS_DETAILS_FAILURE'
export const GET_COMPETITION_INSTANT_BATTLE = 'GET_COMPETITION_INSTANT_BATTLE'
export const GET_COMPETITION_INSTANT_BATTLE_SUCCESS = 'GET_COMPETITION_INSTANT_BATTLE_SUCCESS'
export const GET_COMPETITION_INSTANT_BATTLE_FAILURE = 'GET_COMPETITION_INSTANT_BATTLE_FAILURE'
export const GET_COMPETITION_INSTANT_BATTLE_DETAILS = 'GET_COMPETITION_INSTANT_BATTLE_DETAILS'
export const GET_COMPETITION_INSTANT_BATTLE_DETAILS_SUCCESS = 'GET_COMPETITION_INSTANT_BATTLE_DETAILS_SUCCESS'
export const GET_COMPETITION_INSTANT_BATTLE_DETAILS_FAILURE = 'GET_COMPETITION_INSTANT_BATTLE_DETAILS_FAILURE'
export const CREATE_COMPETITION_TOURNAMENT = 'CREATE_COMPETITION_TOURNAMENT'
export const CREATE_COMPETITION_TOURNAMENT_SUCCESS = 'CREATE_COMPETITION_TOURNAMENT_SUCCESS'
export const CREATE_COMPETITION_TOURNAMENT_ERROR = 'CREATE_COMPETITION_TOURNAMENT_ERROR'
export const CREATE_COMPETITION_INSTANT_BATTLE = 'CREATE_COMPETITION_INSTANT_BATTLE'
export const CREATE_COMPETITION_INSTANT_BATTLE_SUCCESS = 'CREATE_COMPETITION_INSTANT_BATTLE_SUCCESS'
export const CREATE_COMPETITION_INSTANT_BATTLE_ERROR = 'CREATE_COMPETITION_INSTANT_BATTLE_ERROR'
export const EDIT_COMPETITION_TOURNAMENT = 'EDIT_COMPETITION_TOURNAMENT'
export const EDIT_COMPETITION_TOURNAMENT_SUCCESS = 'EDIT_COMPETITION_TOURNAMENT_SUCCESS'
export const EDIT_COMPETITION_TOURNAMENT_ERROR = 'EDIT_COMPETITION_TOURNAMENT_ERROR'
export const EDIT_COMPETITION_INSTANT_BATTLE = 'EDIT_COMPETITION_INSTANT_BATTLE'
export const EDIT_COMPETITION_INSTANT_BATTLE_SUCCESS = 'EDIT_COMPETITION_INSTANT_BATTLE_SUCCESS'
export const EDIT_COMPETITION_INSTANT_BATTLE_ERROR = 'EDIT_COMPETITION_INSTANT_BATTLE_ERROR'
export const FILTER_COMPETITION_SUCCESS = 'FILTER_COMPETITION_SUCCESS'
export const FILTER_COMPETITION_ERROR = 'FILTER_COMPETITION_ERROR'
export const GET_COMPETITION_RESULT_SUCCESS = 'GET_COMPETITION_RESULT_SUCCESS'
export const GET_COMPETITION_RESULT_ERROR = 'GET_COMPETITION_RESULT_ERROR'

export const GET_LIVE_OPS_CATEGORY = 'GET_LIVE_OPS_CATEGORY'
export const CREATE_LIVE_OPS_CATEGORY = 'CREATE_LIVE_OPS_CATEGORY'
export const LIVE_OPS_RESULT = 'LIVE_OPS_RESULT'
export const LIVE_OPS_RESULT_SUCCESS = 'LIVE_OPS_RESULT_SUCCESS'
export const LIVE_OPS_RESULT_ERROR = 'LIVE_OPS_RESULT_ERROR'

export const SCHEDULE_COMPETITION = 'SCHEDULE_COMPETITION'
export const GET_SCHEDULE_COMPETITION = 'GET_SCHEDULE_COMPETITION'
export const EDIT_SCHEDULE_COMPETITION = 'EDIT_SCHEDULE_COMPETITION'
export const FILTER_SCHEDULE_COMPETITION_SUCCESS = 'FILTER_SCHEDULE_COMPETITION_SUCCESS'
export const FILTER_SCHEDULE_COMPETITION_ERROR = 'FILTER_SCHEDULE_COMPETITION_ERROR'

export const GET_ALL_MEMBERS = 'GET_ALL_MEMBERS'
export const GET_ALL_MEMBERS_SUCCESS = 'GET_ALL_MEMBERS_SUCCESS'
export const GET_ALL_MEMBERS_ERROR = 'GET_ALL_MEMBERS_ERROR'
export const GET_APP_ACCEPTED_MEMBERS = 'GET_APP_ACCEPTED_MEMBERS'
export const GET_APP_ACCEPTED_MEMBERS_SUCCESS = 'GET_APP_ACCEPTED_MEMBERS_SUCCESS'
export const GET_APP_ACCEPTED_MEMBERS_ERROR = 'GET_APP_ACCEPTED_MEMBERS_ERROR'
export const GET_APP_INVITED_MEMBERS = 'GET_APP_INVITED_MEMBERS'
export const GET_APP_INVITED_MEMBERS_SUCCESS = 'GET_APP_INVITED_MEMBERS_SUCCESS'
export const GET_APP_INVITED_MEMBERS_ERROR = 'GET_APP_INVITED_MEMBERS_ERROR'

export const GET_APPS = 'GET_APPS'
export const GET_APP_DETAILS = 'GET_APP_DETAILS'
export const GET_APP_DETAILS_SUCCESS = 'GET_APP_DETAILS_SUCCESS'
export const GET_APP_DETAILS_ERROR = 'GET_APP_DETAILS_ERROR'
export const GET_APP_LIST = 'GET_APP_LIST'
export const GET_APP_LIST_SUCCESS = 'GET_APP_LIST_SUCCESS'
export const GET_APP_LIST_ERROR = 'GET_APP_LIST_ERROR'
export const FILTER_APPS_SUCCESS = 'FILTER_APPS_SUCCESS'
export const FILTER_APPS_ERROR = 'FILTER_APPS_ERROR'
export const GET_APP_OVERVIEW = 'GET_APP_OVERVIEW'
export const GET_APP_OVERVIEW_SUCCESS = 'GET_APP_OVERVIEW_SUCCESS'
export const GET_APP_OVERVIEW_ERROR = 'GET_APP_OVERVIEW_ERROR'
export const ADD_APPS = 'ADD_APPS'
export const EDIT_APPS = 'EDIT_APPS'

export const GET_MEDIA = 'GET_MEDIA'
export const GET_MEDIA_SUCESS = 'GET_MEDIA_SUCCESS'
export const GET_MEDIA_ERROR = 'GET_MEDIA_ERROR'
export const ADD_MEDIA = 'ADD_MEDIA'
export const ADD_MEDIA_SUCESS = 'ADD_MEDIA_SUCCESS'
export const ADD_MEDIA_ERROR = 'ADD_MEDIA_ERROR'
export const DELETE_MEDIA = 'DELETE_MEDIA'
export const DELETE_MEDIA_SUCESS = 'DELETE_MEDIA_SUCCESS'
export const DELETE_MEDIA_ERROR = 'DELETE_MEDIA_ERROR'

export const GET_TAGS = 'GET_TAGS'
export const UPDATE_TAGS = 'UPDATE_TAGS'

export const GET_ORG_PROFILE = 'GET_ORG_PROFILE'
export const EDIT_ORG_PROFILE = 'EDIT_ORG_PROFILE'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE'

export const GET_CALENDAR = 'GET_CALENDAR'

export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS'
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR'
export const FILTER_DOCUMENT_SUCCESS = 'FILTER_DOCUMENT_SUCCESS'
export const FILTER_DOCUMENT_ERROR = 'FILTER_DOCUMENT_ERROR'
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT'
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'

export const GET_PLANS = 'GET_PLANS'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'
export const GET_PLANS_ERROR = 'GET_PLANS_ERROR'
export const GET_INVOICE = 'GET_INVOICE'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'
export const GET_INVOICE_ERROR = 'GET_INVOICE_ERROR'
export const GET_ADD_ONS = 'GET_ADD_ONS'
export const GET_ADD_ONS_SUCCESS = 'GET_ADD_ONS_SUCCESS'
export const GET_ADD_ONS_ERROR = 'GET_ADD_ONS_ERROR'
export const BUY_ADD_ONS = 'BUY_ADD_ONS'
export const BUY_ADD_ONS_SUCCESS = 'BUY_ADD_ONS_SUCCESS'
export const BUY_ADD_ONS_ERROR = 'BUY_ADD_ONS_ERROR'
export const BUY_NEW_PLAN = 'BUY_NEW_PLAN'
export const BUY_NEW_PLAN_SUCCESS = 'BUY_NEW_PLAN_SUCCESS'
export const BUY_NEW_PLAN_ERROR = 'BUY_NEW_PLAN_ERROR'
export const VERIFY_BILLING = 'VERIFY_BILLING'
export const VERIFY_BILLING_SUCCESS = 'VERIFY_BILLING_SUCCESS'
export const VERIFY_BILLING_ERROR = 'VERIFY_BILLING_ERROR'
export const GET_MY_PLAN = 'GET_MY_PLAN'
export const GET_MY_PLAN_SUCCESS = 'GET_MY_PLAN_SUCCESS'
export const GET_MY_PLAN_ERROR = 'GET_MY_PLAN_ERROR'
export const GET_MY_PAYMENT = 'GET_MY_PAYMENT'
export const GET_MY_PAYMENT_SUCCESS = 'GET_MY_PAYMENT_SUCCESS'
export const GET_MY_PAYMENT_ERROR = 'GET_MY_PAYMENT_ERROR'
export const GET_PRICE_BOOK_SUCCESS = 'GET_PRICE_BOOK_SUCCESS'
export const GET_PRICE_BOOK_ERROR = 'GET_PRICE_BOOK_ERROR'