import {GET_DEFAULT_EVENTS, GET_CUSTOM_EVENTS, ADD_CUSTOM_EVENTS, EDIT_DEFAULT_EVENTS, EDIT_CUSTOM_EVENTS, FILTER_EVENT, GET_DEFAULT_EVENTS_SUCCESS, GET_CUSTOM_EVENTS_SUCCESS, GET_CUSTOM_EVENTS_ERROR, GET_DEFAULT_EVENTS_ERROR, FILTER_CUSTOM_EVENT_SUCCESS, FILTER_CUSTOM_EVENT_ERROR, FILTER_DEFAULT_EVENT_SUCCESS, FILTER_DEFAULT_EVENT_ERROR} from '../../../actionTypes/actionTypes'

const initialState = {
    defaultEvents : [],
    customEvents : [],
    filteredEvent : [],
    isLoading : false,
    isFiltered: false,
    totalCustomEventCount : null,
    totalDefaultEventCount : null
};

export const eventReducer =( state=initialState, action )=>{
    switch(action.type){

        case GET_DEFAULT_EVENTS : {
            return {
                ...state,
                isLoading: true
            }
        }

        case GET_DEFAULT_EVENTS_SUCCESS : {
            return {
                ...state,
                defaultEvents: action.payload,
                totalDefaultEventCount : action.totalCount,
                filteredEvent : [],
                isLoading: false,
                isFiltered: false
            }
        }

        case GET_DEFAULT_EVENTS_ERROR : {
            return {
                ...state,
                isLoading: false
            }
        }

        case FILTER_CUSTOM_EVENT_SUCCESS : {
            return {
                ...state,
                customEvents : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_CUSTOM_EVENT_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case FILTER_DEFAULT_EVENT_SUCCESS : {
            return {
                ...state,
                defaultEvents : action.payload,
                totalDefaultEventCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_DEFAULT_EVENT_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case GET_CUSTOM_EVENTS : {
            return {
                ...state,
                isLoading: true
            }
        }

        case GET_CUSTOM_EVENTS_SUCCESS : {
            return {
                ...state,
                customEvents: action.payload,
                totalCustomEventCount : action.totalCount,
                filteredEvent : [],
                isLoading: false
            }
        }

        case GET_CUSTOM_EVENTS_ERROR : {
            return {
                ...state,
                isLoading: false
            }
        }

        case ADD_CUSTOM_EVENTS : {
            return {
                ...state,
                customEvents : action.payload,
                filteredEvent : [],
                isSearched : true
            }
        }

        case EDIT_DEFAULT_EVENTS : {
            return {
                ...state,
                defaultEvents: action.payload,
                filteredEvent : [],
                isSearched : true
            }
        }

        case EDIT_CUSTOM_EVENTS : {
            return {
                ...state,
                customEvents: action.payload,
                filteredEvent : [],
                isSearched : true
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   