import {GET_APP_LIST, GET_APPS, GET_APP_OVERVIEW, ADD_APPS, EDIT_APPS, FILTER_APPS, GET_APP_DETAILS, GET_APP_LIST_SUCCESS, GET_APP_LIST_ERROR, FILTER_APPS_SUCCESS, FILTER_APPS_ERROR, GET_APP_OVERVIEW_SUCCESS, GET_APP_OVERVIEW_ERROR, GET_APP_DETAILS_SUCCESS, GET_APP_DETAILS_ERROR } from "../../actionTypes/actionTypes";

const initialState = {
    app : [],
    appDetails : [],
    appList : [],
    appOverview : [],
    isFetched : true,
    isLoading : false,
    isFiltered: false,
    totalCount : null,
    error: []
};

export const appReducer =( state=initialState, action )=>{
    switch(action.type){
        case GET_APPS : {
            return {
                ...state,
                app: action.payload,
                isFetched : false,
                totalCount : action.count
            }
        }

        case GET_APP_DETAILS : {
            return{
                ...state,
                isLoading: true
            }
        }

        case GET_APP_DETAILS_SUCCESS : {
            return{
                ...state,
                appDetails : action.payload,
                isLoading: false,
                error: null
            }
        }

        case GET_APP_DETAILS_ERROR : {
            return{
                ...state,
                appDetails: [],
                isLoading: false,
                error : action.payload
            }
        }

        case GET_APP_LIST : {
            return {
                ...state,
                isLoading: true
            }
        }

        case GET_APP_LIST_SUCCESS : {
            return {
                ...state,
                appList : action.payload,
                totalAppCount : action.count,
                isLoading: false,
                isFiltered: false
            }
        }

        case GET_APP_LIST_ERROR : {
            return {
                ...state,
                isLoading: false,
                isFiltered: false,
                error: action.payload
            }
        }

        case FILTER_APPS_SUCCESS : {
            return {
                ...state,
                appList: action.payload,
                totalAppCount : action.count,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_APPS_ERROR : {
            return {
                ...state,
                isLoading: false,
                isFiltered: false,
                error: action.payload
            }
        }

        case GET_APP_OVERVIEW : {
            return {
                ...state,
                isLoading : true
            }
        }

        case GET_APP_OVERVIEW_SUCCESS : {
            return {
                ...state,
                isLoading : false,
                appOverview : action.payload
            }
        }

        case GET_APP_OVERVIEW_ERROR : {
            return {
                ...state,
                isLoading : false,
                error: action.payload
            }
        }

        case ADD_APPS : {
            return {
                ...state,
                app: action.payload
            }
        }

        case EDIT_APPS : {
            return {
                ...state,
                app: action.payload
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}    