import { START_STORE_API_CALLS, STORE_API_CALL_SUCCESS, STORE_API_CALL_FAILURE, STORE_RESET_SAGA_STATE } from '../../actions/stores/storeUploadAction';

const initialState = {
  loading: false,
  completedCalls: 0,
  failedCalls: 0,
  totalCalls: 0,
  error: null,
};

export const storeUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_STORE_API_CALLS:
      return {...state, completedCalls: 0, loading: true, error: null};
    case STORE_API_CALL_SUCCESS:
      return {...state, totalCalls: action.totalLength, completedCalls: state.completedCalls + 1
      };
    case STORE_API_CALL_FAILURE:
      return {...state, totalCalls: action.totalLength, failedCalls: state.failedCalls + 1, error: action.error};
    case STORE_RESET_SAGA_STATE: {
      return {...initialState}
    }
    default:
      return state;
  }
};
