import { GET_STEP_SERIES, GET_STEP_SERIES_BY_ID, CREATE_STEP_SERIES, EDIT_STEP_SERIES, FILTER_STEP_SERIES_SUCCESS, GET_STEP_SERIES_SUCCESS, GET_STEP_SERIES_ERROR, FILTER_STEP_SERIES_ERROR } from "../../../../actionTypes/actionTypes";

const initialState = {
    stepSeries: [],
    stepSeriesDetails: [],
    isLoading: false,
    isFiltered: false,
    totalCount : null
};

export const stepSeriesReducer =(state=initialState, action)=>{
    switch(action.type){

        case GET_STEP_SERIES : {
            return {
                ...state,
                isLoading: true
            }
        }
        
        case GET_STEP_SERIES_SUCCESS : {
            return {
                ...state,
                stepSeries: action.payload,
                isLoading: false,
                isFiltered: false,
                totalCount: action.totalCount,
                error: null
            }
        }

        case GET_STEP_SERIES_ERROR : {
            return {
                ...state,
                error: action.payload
            }
        }

        case FILTER_STEP_SERIES_SUCCESS : {
            return {
                ...state,
                stepSeries : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_STEP_SERIES_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case GET_STEP_SERIES_BY_ID : {
            return {
                ...state,
                stepSeriesDetails: action.payload,
                isLoading : false
            }
        }

        case CREATE_STEP_SERIES : {
            return {
                ...state,
                stepSeries: action.payload,
            }
        }

        case EDIT_STEP_SERIES : {
            return {
                ...state,
                stepSeries: action.payload,
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}    