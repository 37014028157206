// Action types
export const START_API_CALLS = 'START_API_CALLS';
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';
export const API_CALL_FAILURE = 'API_CALL_FAILURE';
export const RESET_SAGA_STATE = 'RESET_SAGA_STATE'

// Action creators
export const startApiCalls = (payloads, getCurrecy) => ({
  type: START_API_CALLS,
  payloads,
  getCurrecy
});

export const apiCallSuccess = (payloads, totalLength) => ({
  type: API_CALL_SUCCESS,
  payloads,
  totalLength
});

export const apiCallFailure = (error, totalLength) => ({
  type: API_CALL_FAILURE,
  error,
  totalLength
});

export const endApiCall = () => ({
  type: RESET_SAGA_STATE
});
