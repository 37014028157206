import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames'
import './SessionLogViewer.css';
import styles from '../terminalInfo.module.sass'
import Card from '../../../../components/Card'
import Switch from '../../../../components/Switch';
import {ReactComponent as GlobalIcon} from '../../../../media/images/icons/Group 146931.svg' 
import Icon from "../../../../components/Icon"
import {ReactComponent as Reload} from '../../../../media/images/icons/reload.svg'
import {ReactComponent as Live} from '../../../../media/images/icons/live.svg'
import Dropdown from '../../../../components/Dropdown/minSizeableDropdown';
import TransparentDropdown from '../../../../components/Dropdown/TransparentDropdown';
import {getCustomEventAction, getDefaultEventAction} from '../../../../redux/action/builds/events/eventActions'
import { filterPlayerAction, getAllPlayersAction } from '../../../../redux/action/engage/Players/PlayersAction';
import { useDispatch , useSelector } from 'react-redux';
import TooltipTitle from '../../../../Tooltip/TooltipTitle'
import axios from "axios";
import Modal from "../../../../components/Modals/ModalComponent";
import SelectDateRange from "../../../../components/SelectDateRange"
import moment from 'moment';
import {ReactComponent as ZoomIn} from "../../../../media/images/icons/copyZoomIn.svg"
import {ReactComponent as ZoomOut} from "../../../../media/images/icons/copyZoomOut.svg"
import {ReactComponent as Sync} from "../../../../media/images/icons/sync.svg"
import {ReactComponent as Clear} from "../../../../media/images/icons/clear.svg"
import {ReactComponent as Play} from "../../../../media/images/icons/play_new.svg"
import {ReactComponent as Pause} from "../../../../media/images/icons/pause_new.svg"
import { io } from 'socket.io-client';
import { BASE_URL } from '../../../../resources/APIEndpoints';

const SessionLogViewer = (className) => {
    const [isGLiveStream, setIsGLiveStream] = useState(false)
    const [copySuccess, setCopySuccess] = useState({});
    const dispatch = useDispatch()

  const [endDate,setEndDate]=useState(null)
  const [startDate,setStartDate]=useState(null)
  const [logs, setLogs] = useState([]);
  const [newLogIds, setNewLogIds] = useState([]);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [expandedDetails, setExpandedDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const logEntriesRef = useRef(null);
  const [page, setPage] = useState(1);
  const [eventScrollPage, setEventScrollPage] = useState(1);
  const [playerScrollPage, setPlayerScrollPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const {appid} = useParams()
  const [isFullscreen, setFullscreen] = useState(false);
  const [isCalled, setIsCalled] = useState(false)
  const [eventOptions, setEventOption] = useState([])
  const [players, setPlayers] = useState([])
  const [event, setEvent] = useState(null)
  const [eventType, setEventType] = useState(null)
  const [clientEventId, setClientEventId] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [visibleModal, setVisibleModal] = useState(false);
  const [socket, setSocket] = useState(null); 
  const [isSocketPaused, setIsSocketPaused] = useState(1);
  useEffect(() => {
    if (isGLiveStream) {
      isSocketPaused===1&&setLogs([])
    } else {
      (event&&event!=="")&&fetchLogs(1, true);
    }
    
    if (!isSocketPaused) {
      const socketInstance = io('https://socket-event-dev.specterapp.xyz/');
      setSocket(socketInstance);

      socketInstance.on('connect', () => {
        console.log('Socket.IO connected');
        socketInstance.emit("logs",{
          ...(isGLiveStream&&{apiKey:(localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}),
            projectId: appid,
            eventId: eventId?eventId:null,
            eventName:event?event:null,
            eventType: eventType?eventType:"default",
            clientEventId:clientEventId,
            filters: {
              userId: filterPlayerID&&filterPlayerID,
              // fromDate: startDate&&formatDate(new Date(startDate)),
              // toDate: formatDate(new Date(endDate))
            }
        })

        socketInstance.on('logs', (newLog) => {
          setLogs((prevLogs) => {
            const updatedLogs = [...prevLogs, newLog];
            
            return updatedLogs
              .filter((log, index, self) => index === self.findIndex((l) => l._id === log._id))
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          });
          setNewLogIds((prevNewLogIds) => [...prevNewLogIds, newLog._id]);
          setTimeout(() => {
            setNewLogIds((prevNewLogIds) => prevNewLogIds.filter((id) => id !== newLog._id));
          }, 1000);
          console.log("updatedLogs",newLog)
          if (logEntriesRef.current) {
            setTimeout(() => {
              logEntriesRef.current.scrollTop = logEntriesRef.current.scrollHeight;
            }, 0);
          }
        });
      });

      socketInstance.on('disconnect', () => {
        setIsSocketPaused(true);
        console.log('Socket.IO disconnected');
      });

      return () => {
        if (socketInstance) {
          socketInstance.disconnect();
        }
      };
    } else if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [isGLiveStream,isSocketPaused]);

function formatDate(date) {
  // Format the date into "YYYY-MM-DD HH:MM:SS+00:00"
  let isoString = date.toISOString();
  let formattedDate = isoString.slice(0, 19).replace('T', ' ') + '+00:00';
  return formattedDate;
}

  useEffect(() => {
    // setEndDate(calculateStartDate(endDate,timeRange))
    (event&&event!=="")&&fetchLogs(1, true);
  }, []);

  useEffect(() => {
  if (!isGLiveStream)
    {
      const handleScroll = () => {
        if (logEntriesRef.current.scrollTop === 0 && page < totalPages) {
          (event&&event!=="")&&fetchLogs(page + 1);
        }
      };

      const logEntriesElement = logEntriesRef.current;
      logEntriesElement&&logEntriesElement.addEventListener('scroll', handleScroll);
      return () => logEntriesElement&& logEntriesElement.removeEventListener('scroll', handleScroll);
    }
  }, [page, totalPages]);

  const fetchLogs = async (pageNumber = 1, initial = false) => {
      initial&&setLoading(true);
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let getEventLogs = {
          projectId: appid,
          eventId: eventId?eventId:null,
          eventName:event?event:null,
          eventType: eventType?eventType:"default",
          ...(isGLiveStream&&{apiKey:(localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}),
          filters: {
            userId: filterPlayerID&&filterPlayerID,
            fromDate: startDate&&formatDate(new Date(startDate)),
            ...(endDate&&{toDate: formatDate(new Date(endDate))})
          },
          limit: 50,
          offset: (pageNumber - 1) * 50
      }
      let currentScrollTop = logEntriesRef.current.scrollTop;
      let currentScrollHeight = logEntriesRef.current.scrollHeight;
      let data = await axios.post(`${BASE_URL}/terminal/logs/events`, getEventLogs, headers)

      if (initial) {
        
        setLogs(data.data.data.logs.reverse());
        setTotalPages(Math.ceil(data.data.data.totalCount  / 50));
        setPage(pageNumber);
        if (logEntriesRef.current) {
          setTimeout(() => {
            if (logEntriesRef.current) {
              logEntriesRef.current.scrollTop = logEntriesRef.current.scrollHeight;
            }
          }, 0);
        }
      } else {
        if (logEntriesRef.current) {
          const newLogs = data.data.data.logs.reverse();
          setLogs((prevLogs) => [...newLogs, ...prevLogs]);
          setTimeout(() => {
            if (logEntriesRef.current) {
              logEntriesRef.current.scrollTop = logEntriesRef.current.scrollHeight - currentScrollHeight + currentScrollTop;
            }
          }, 0);
          setPage(pageNumber);
        }
      }
        setLoading(false);
      
  };

  const toggleExpand = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
      setExpandedDetails((prevState) => {
        const newState = { ...prevState };
        delete newState[index];
        return newState;
      });
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  const toggleDetailExpand = (index, detail) => {
    setExpandedDetails((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [detail]: !prevState[index]?.[detail],
      },
    }));
  };

  const toggleExpandAll = () => {
    if (expandedIndexes.length === logs.length) {
      setExpandedIndexes([]);
      setExpandedDetails({});
    } else {
      setExpandedIndexes(logs.map((_, index) => index));
      const allDetails = {};
      logs.forEach((_, index) => {
        allDetails[index] = {
          defaultParams: true,
          customParams: true,
        };
      });
      setExpandedDetails(allDetails);
    }
  };

  const handleCopy = (text, section, index, event) => {
    event.stopPropagation(); // Stop the event from bubbling up
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess((prevState) => ({
        ...prevState,
        [index]: {
          ...prevState[index],
          [section]: 'Copied!',
        },
      }));
      setTimeout(() => {
        setCopySuccess((prevState) => ({
          ...prevState,
          [index]: {
            ...prevState[index],
            [section]: '',
          },
        }));
      }, 2000);
    });
  };

  const categorizeValue = (value) => {
    if (typeof value === 'string') {
      return 'string-value';
    } else if (typeof value === 'boolean') {
      return 'boolean-value';
    } else if (typeof value === 'number') {
      return 'number-value';
    }
    return '';
  };

  const formatParams = (params) => {
    return (
      <span>
        {params&&Object.entries(params).map(([key, value], index) => (
          <div style={{marginBottom:"4px",marginLeft:"24px"}} key={index}>
            {key}: <span className={categorizeValue(value)}>{JSON.stringify(value)}</span>
          </div>
        ))}
      </span>
    );
  };

  const formatSocketParams = (params) => {
    return (
      <span>
        {params&&Object.entries(params).map(([key, value], index) => (
          <>
            {key}: <span className={categorizeValue(value)}>{JSON.stringify(value)}</span>
            {index < Object.entries(params).length - 1 && ', '}
          </>
        ))}
      </span>
    );
  };

  const formatParamsInline = (params) => {
    return (
      <span>
        {params&&Object.entries(params).map(([key, value], index) => (
          <span key={index}>
            {key}: <span className={categorizeValue(value)}>{JSON.stringify(value)}</span>
            {index < Object.entries(params).length - 1 && ', '}
          </span>
        ))}
      </span>
    );
  };

  const hasParams = (params) => params && Object.keys(params).length > 0;



  function calculateStartDate(endDate, selectedOption) {
    let end = new Date(endDate);
    let startDate = new Date(end);
    switch (selectedOption) {
        case 'Last 10 minutes':
            startDate.setMinutes(end.getMinutes() - 10);
            break;
        case 'Last 30 minutes':
            startDate.setMinutes(end.getMinutes() - 30);
            break;
        case 'Last 1 hour':
            startDate.setHours(end.getHours() - 1);
            break;
        case 'Last 24 hours':
            startDate.setDate(end.getDate() - 1);
            break;
        case 'Last 7 days':
            startDate.setDate(end.getDate() - 7);
            break;
        default:
            console.log('Invalid option');
            return null;
    }
    return startDate;
}



  const timeRangeListOptions = [
    "Last 10 minutes",
    "Last 30 minutes",
    "Last 1 hour",
    "Last 24 hours",
    "Last 7 days",
    "Date Range"
  ]
  const [timeRange, setTimeRange] = useState(timeRangeListOptions[0])


const[searchEvent,setSearchEvent]=useState("")

  const getAllEvents = async() => {
    let getEvents = {
        projectId : appid,
        limit: 50,
        search:searchEvent,
        offset: (eventScrollPage - 1) * 50
    }

    Promise.all([await dispatch(getCustomEventAction(getEvents)), await dispatch(getDefaultEventAction(getEvents))]).then(res =>{
        setIsCalled(true)
    })
    
}
  let customeEvents = useSelector((state) => {
    return state.event.customEvents
  })

  let defaultEvents = useSelector((state) => {
    return state.event.defaultEvents
  })
  let totalCustomEvents = useSelector((state)=>{
      return state.event.totalCustomEventCount
  })
  let totalDefaultvents = useSelector((state)=>{
    return state.event.totalDefaultEventCount
  })
  
  

const configureEvent = () => {
    if(isCalled){
        const customEventData = customeEvents.map(v => ({...v, type: 'custom'}))
        const defaultEventData = defaultEvents.map(v => ({...v, type: 'default'}))
        let events = [...customEventData, ...defaultEventData]
        let allEvents = []
        if(events.length > 0){
            events && events.forEach((e,i) => {
                allEvents.push(e)
            })
            // setEventOption(eventOption => [...eventOption, ...allEvents])
            setEventOption(prev => {
              const combinedEvents = [...prev, ...allEvents];
              const uniqueEvents = Array.from(new Set(combinedEvents.map(player => JSON.stringify(player)))).map(player => JSON.parse(player));
              return uniqueEvents;
            });
            console.log("allEventsa",allEvents)
            setIsCalled(false)
        }
    }
}




const[searchPlayer,setSearchPlayer]=useState("")
const[filterPlayer,SetFilterPlayer]=useState("")
const[filterPlayerID,SetFilterPlayerID]=useState(null)

const getAllPlayers = async() => {

  // if(filterPlayer == ''){

    let playersData = {
      projectId : appid,
      limit: 50,
      search:searchPlayer,
      offset: (playerScrollPage - 1) * 50
    }
    await dispatch(getAllPlayersAction(playersData))

  // }else{
  //   let searchPlayer = 
  //   {
  //     search : filterPlayer,
  //     projectId : appid,
  //     limit: 50,
  //     offset: (playerScrollPage - 1) * 50
  //   }
  //   dispatch(filterPlayerAction(searchPlayer))
  // }

  }

  let allPlayers = useSelector((state) => {
    return state.players.players
  })

  let totalPlayers = useSelector((state)=>{
    return state.players.totalCount
  })

  useEffect(()=>{
      configureEvent()
  }, [defaultEvents, customeEvents])

  useEffect(() => {
      getAllEvents()
      getAllPlayers()
  }, [playerScrollPage,eventScrollPage,searchPlayer,searchEvent])

  useEffect(()=>{
    if (allPlayers&&allPlayers.length>0) {
      setPlayers(prev => {
        const combinedPlayers = [...prev, ...allPlayers];
        const uniquePlayers = Array.from(new Set(combinedPlayers.map(player => JSON.stringify(player)))).map(player => JSON.parse(player));
        return uniquePlayers;
      });
    }
  },[allPlayers])

  useEffect(() => {
    // if (endDate==null) {
    //   setEndDate(new Date())
    // }
    (event&&event!==""&&!isGLiveStream)&&fetchLogs(1, true);
    if(event&&event!==""&&isGLiveStream&&!isSocketPaused)
    {
      console.log("emmiting")
      try {
        socket.emit("logs",{
          ...(isGLiveStream&&{apiKey:(localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}),
            projectId: appid,
            eventId: eventId?eventId:null,
            eventName:event?event:null,
            eventType: eventType?eventType:"default",
            clientEventId:clientEventId,
            filters: {
              userId: filterPlayerID&&filterPlayerID,
              // fromDate: startDate&&formatDate(new Date(startDate)),
              // toDate: formatDate(new Date(endDate))
            }
        })
        console.log("emit done")
      } catch (error) {
        console.log("emit error",error)
      }
    }
  }, [startDate,endDate,eventId,filterPlayerID])

  // console.log("event",eventOptions)
  // console.log("players",players)
  console.log("socket",socket)

  return (

    <div style={isFullscreen?{ position: "fixed",top: "0",left: "0",width: "100vw",height: "100vh",zIndex: "9999",background: "white"}:{}}>
    <Card className={cn(styles.card, className)} classTitle="title-purple" head={<><div style={{marginLeft:"auto",display:"flex"}}><div style={{margin:"2px",display:"flex",height:"24px",width:"24px"}}><Live height="24px" width="24px"/></div><span style={{fontSize:"13px",lineHeight:"24px",fontWeight:"700",verticalAlign:"top"}}>Live Event Stream</span><Switch className={styles.switch} value={isGLiveStream} onChange={() => {setIsGLiveStream(!isGLiveStream);setIsSocketPaused(1);setStartDate(null);setEndDate(null);SetFilterPlayer("");SetFilterPlayerID(null);setEvent(null);setEventId(null);setClientEventId(null)}} /></div></>}>
  <div style={{height:"1px",background:"#EFEFEF",margin:"12px"}}></div>

  <div>
    {isGLiveStream&&
      <>
        <div className={styles.filters} style={{marginTop:"12px"}}>
          <div style={{display:"flex"}}>
            <div className={styles.buttons} style={{marginRight:"18px"}} onClick={() => setFullscreen(!isFullscreen)}>
              {isFullscreen ? 
                <ZoomOut style={{width:"16px",height:"16px",margin:"2px 8px 2px 0px"}}/>
                :
                <ZoomIn style={{width:"16px",height:"16px",margin:"2px 8px 2px 0px"}}/>
              }
              <span className={styles.buttonText}>Full Screen</span>
            </div>
            <div style={{marginRight:"18px",width:"240px"}}>
              <Dropdown search={searchPlayer} setSearch={setSearchPlayer} limit={totalPlayers} eventPage={playerScrollPage} setEventPage={setPlayerScrollPage} small={true} placeholder={"Select Player"} options={players&&players.map((e,i)=>{return {name:e?.username||"",id:e.id}})} value={filterPlayer} setValue={SetFilterPlayer} setId={SetFilterPlayerID}/>
            </div>
            <div style={{width:"240px"}}>
              <Dropdown search={searchEvent} setSearch={setSearchEvent} limit={totalCustomEvents+totalDefaultvents} eventPage={eventScrollPage} setEventPage={setEventScrollPage} small={true} tooltip={TooltipTitle.TaskEvent} placeholder='Select Event' options={eventOptions} value={event} setValue={setEvent} setType={setEventType} setId={setEventId} setDisplayId={setClientEventId}/>
            </div>
          </div>
          <div style={{marginLeft:"auto",display:"flex"}}>
          <div className={styles.buttons} style={{marginRight:"18px"}} onClick={()=>{setLogs([])}}>
              <Clear style={{width:"16px",height:"16px",margin:"2px 8px 2px 0px"}}/>
              <span className={styles.buttonText}>Clear</span>
            </div>
            <div className={styles.buttons}>
              {isSocketPaused?
              <div onClick={()=>setIsSocketPaused(false)}>
              <Play style={{width:"16px",height:"16px",margin:"2px 8px 2px 0px"}}/>
              <span className={styles.buttonText}>Play</span>
              </div>
              :
              <div onClick={()=>setIsSocketPaused(true)}>
              <Pause style={{width:"16px",height:"16px",margin:"2px 8px 2px 0px"}}/>
              <span className={styles.buttonText}>Pause</span>
              </div>}
            </div>
          </div>
        </div>
      </>}
    {!isGLiveStream&&
    <>
      <div className={styles.filters} style={{marginTop:"12px"}}>
        <div style={{display:"flex"}}>
          <div style={{width:"344px",display:"flex"}} className={styles.buttons} onClick={(()=>{setVisibleModal(true)})}>
            <div style={startDate?{marginRight:"8px"}:{fill:"#6F767E",marginRight:"8px"}}><Icon size={16} name={"clock"}/></div>
            <div style={startDate?{}:{color:"#6F767E"}} className={styles.buttonText}>{startDate?`${moment(startDate).format('MMM DD, YYYY hh:mm A')} - ${endDate?moment(endDate).format('MMM DD, YYYY hh:mm A'):"Now"}`:"Select Date Range"}</div>
          </div>
        </div>
        <div style={{marginLeft:"auto",display:"flex"}}>
          <div style={{marginRight:"18px",width:"280px"}}>
            <Dropdown search={searchPlayer} setSearch={setSearchPlayer} limit={totalPlayers} eventPage={playerScrollPage} setEventPage={setPlayerScrollPage} small={true} placeholder={"Select Player"} options={players&&players.map((e,i)=>{return {name:e?.username||"",id:e.id}})} value={filterPlayer} setValue={SetFilterPlayer} setId={SetFilterPlayerID}/>
          </div>
          <div style={{width:"280px"}}>
            <Dropdown search={searchEvent} setSearch={setSearchEvent} limit={totalCustomEvents+totalDefaultvents} eventPage={eventScrollPage} setEventPage={setEventScrollPage} small={true} tooltip={TooltipTitle.TaskEvent} placeholder='Select Event' options={eventOptions} value={event} setValue={setEvent} setType={setEventType} setId={setEventId}/>
          </div>
        </div>
      </div>

      <div className={styles.filters} style={{marginTop:"12px"}}>
        <div style={{display:"flex"}}>
          <div className={styles.buttons} style={{marginRight:"18px"}} onClick={() => setFullscreen(!isFullscreen)}>
            {isFullscreen ? 
              <ZoomOut style={{width:"16px",height:"16px",margin:"2px 8px 2px 0px"}}/>
              :
              <ZoomIn style={{width:"16px",height:"16px",margin:"2px 8px 2px 0px"}}/>
            }
            <span className={styles.buttonText}>Full Screen</span>
          </div>
          <div className={styles.buttons} style={{marginRight:"18px"}} onClick={() => (event&&event!=="")&&fetchLogs(1, true)}>
            <Sync className={styles.icon} height={16} width={16} style={{margin:"2px 8px 2px 0px"}}/>
            <span className={styles.buttonText}>Refresh</span>
          </div>
        </div>
        {/* <div style={{marginLeft:"auto",display:"flex"}}>
          <div className={styles.buttons}>
            <span className={styles.buttonText}>Live</span>
            <Live className={styles.icon} height={16} width={16} style={{margin:"2px 0px 2px 10px"}}/>
          </div>
        </div> */}
      </div>
    </>}

    <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
      <SelectDateRange newStartDate={startDate} setNewStartDate={setStartDate} newEndDate={endDate} setNewEndDate={setEndDate} onClose={() => setVisibleModal(false)}  />
    </Modal>

    <div className="session-log-viewer" style={isFullscreen?{height:"calc(100vh - 200px)"}:{}}>
      {!isGLiveStream&&<div className="log-header">
        <div>Timestamp</div>
        <div>User ID</div>
        <div>Parameters</div>
        <div style={{marginLeft:"auto",cursor:"pointer"}} onClick={toggleExpandAll}>{expandedIndexes.length === logs.length ? 'Collapse All -' : 'Expand All +'}</div>
      </div>}
      {!isGLiveStream&&<div className="log-entries" ref={logEntriesRef}>
        {loading ? (
          <p>Fetching latest logs...</p>
        ) : !logs||logs?.length === 0 ? (
          <p>No logs available.</p>
        ) : (
          logs.map((log, index) => (
            <div className={`log-entry`} key={index}>
              <div className={`log-entry-header`} onClick={() => toggleExpand(index)}>
                <div>{new Date(log.createdAt).toLocaleString()}</div>
                <div>{log.userId}</div>
                <div className="params-inline">
                  {log.data.defaultParams && formatParamsInline(log.data.defaultParams) }
                  {hasParams(log?.data?.customParams) && hasParams(log?.data?.customParams) && ', '}
                  {log.data.customParams && formatParamsInline(log.data.customParams) }
                  {(!hasParams(log.data.defaultParams) && !hasParams(log?.data?.customParams))&&'No parameters available'}
                </div>
                <div style={{marginLeft:"auto"}} className="expand-icon">{expandedIndexes.includes(index) ? '-' : '+'}</div>
              </div>
              {expandedIndexes.includes(index) && (
                <div className="log-entry-details" style={{ display: 'block' }}>
                  <div>
                    <div className="log-entry-section-header" onClick={() => toggleDetailExpand(index, 'defaultParams')}>
                      <div className="expand-icon">
                        {expandedDetails[index]?.defaultParams ? '-' : '+'}
                      </div>
                      <span>Default Parameters</span>
                      {copySuccess[index]?.defaultParams ? (
                        <span className="log-copy-success">Copied!</span>
                      ) : (
                      <span style={{marginLeft:"4px"}} onClick={(event) => handleCopy(JSON.stringify(log.data.defaultParams, null, 2), 'defaultParams', index, event)}>
                        <Icon size={10} fill={"#BAC2DE"} name={"copy"}/>
                      </span>
                      )}
                    </div>
                    {expandedDetails[index]?.defaultParams && (
                      <pre>{formatParams(log.data.defaultParams)}</pre>
                    )}
                    <div style={{marginBottom:"4px",marginLeft:"24px"}}>{(!log.data.defaultParams)&&"None"}</div>
                  </div>
                  <div>
                    <div className="log-entry-section-header" onClick={() => toggleDetailExpand(index, 'customParams')}>
                      <div className="expand-icon">
                        {expandedDetails[index]?.customParams ? '-' : '+'}
                      </div>
                      <span>Custom Parameters</span>
                      {copySuccess[index]?.customParams ? (
                        <span className="log-copy-success">Copied!</span>
                      ) : (
                        <span style={{marginLeft:"4px"}} onClick={(event) => handleCopy(JSON.stringify(log.data.customParams, null, 2), 'customParams', index, event)}>
                        <Icon size={10} fill={"#BAC2DE"} name={"copy"}/>
                      </span>
                      )}
                    </div>
                    {expandedDetails[index]?.customParams && (
                        <pre>{formatParams(log.data.customParams)}</pre>
                    )}
                  </div>
                  <div style={{marginBottom:"4px",marginLeft:"24px"}}>{(!log.data.customParams)&&"None"}</div>
                </div>
              )}
            </div>
          ))
        )}
      </div>}
      {isGLiveStream&&<div className="log-entries" ref={logEntriesRef}>
        {!loading && (
          logs.map((log, index) => (
            <div className={`log-entry ${newLogIds.includes(log._id) ? 'new' : ''}`} key={index}>
              <div className={`socket-log-entry-header`} onClick={() => toggleExpand(index)}>
                <div>{new Date(log.createdAt).toLocaleString()}</div>
                <div>
                    <div><span style={{color:"#fff"}}>User ID: </span>{log.userId}</div>
                    <div><span style={{color:"#fff"}}>Event: </span>{log?.eventName}</div>
                    <div style={{color:"#fff"}}>Default Parameters</div>
                    <div>{(log.data.defaultParams)?formatSocketParams(log.data.defaultParams):"None"}</div>
                    <div style={{color:"#fff"}}>Custom Parameters</div>
                    <div>{(log.data.customParams)?formatSocketParams(log.data.customParams):"None"}</div>
                </div>
              </div>
            </div>
          ))
        )}
        {isGLiveStream&&
        <div class="terminal_promt">
          <span class="terminal_user">{isSocketPaused?"Press Play to Start":"Session in Progress..."}</span>
          {isSocketPaused&&<>
            <span class="terminal_location">~</span>
            <span class="terminal_bling">$</span>
            <span class="terminal_cursor"></span>
          </>}
        </div>}
      </div>}
    </div>
    <div className="session-log-viewer-overlay" 
        style={{
          ...(isFullscreen ? { height: "calc(100vh - 200px)" } : {}),
          ...((!event || event === '')&&!isGLiveStream ? { display: "block" } : {})
        }}
    >
            <div>
              <div style={{fontSize:"20px",fontWeight:"600",textAlign:"center",marginTop:"80px"}}>Welcome to your App Terminal</div>
              <div style={{color:"#6F767E",fontSize:"15px",fontWeight:"500",marginTop:"8px",textAlign:"center"}}>Track Your Event Activities</div>
            </div>
            <div style={{color:"#6F767E",fontSize:"15px",fontWeight:"500",width:"660px",margin:"52px auto 0"}}>
              Easily monitor, filter, and analyze your application's events. View logs in real-time or explore historical data to gain insights and maintain control over your application's activities. 
            </div>
            <div style={{marginTop:"52px",fontSize:"14px",fontWeight:"600"}}>
              <div style={{textAlign:"center"}}>Select an Event </div>
              <div style={{width:"300px", margin:"12px auto 0"}}>
                <TransparentDropdown search={searchEvent} setSearch={setSearchEvent} limit={totalCustomEvents+totalDefaultvents} eventPage={eventScrollPage} setEventPage={setEventScrollPage} tooltip={TooltipTitle.TaskEvent} placeholder='Select Event' options={eventOptions} value={event} setValue={setEvent} setType={setEventType} setId={setEventId}/>
              </div>
            </div>
            
    </div>
  </div>
</Card>
</div>

  );
};

export default SessionLogViewer;
