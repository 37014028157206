import React, { useState } from "react";
import cn from "classnames";
import styles from "./feature.module.sass";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import BlogOne from '../../../../media/images/Blog_One.webp'
import BlogTwo from '../../../../media/images/Blog_Two.webp'
import BlogThree from '../../../../media/images/Blog_Three.webp'
import BlogFour from '../../../../media/images/Blog_Four.webp'
import BlogFive from '../../../../media/images/Blog_Five.webp'
import BlogSix from '../../../../media/images/Blog_Six.webp'
import { Link } from "react-router-dom";
import userOne from '../../../../media/images/blog_user_one.jpg'
import userTwo from '../../../../media/images/blog_user_two.jpg'

const tips = [
  {
    title: "What If…'Among Us' Used Specter? — Dynamic Achievements & Rewards",
    image: BlogOne,
    url : 'https://medium.com/specter-chronicles/what-if-among-us-used-specter-80a7436b838c',
    statusColor: "purple",
    statusText: "New",
    action: "6 min read",
  },
  {
    title: "What If…Fortnite Used Specter? — Designing The Battle Pass System",
    url : 'https://medium.com/specter-chronicles/what-if-fortnite-used-specter-enhancing-battle-pass-system-3a237c9653cd',
    image: BlogTwo,
    statusColor: "purple",
    statusText: "New",
    action: "4 min read",
  },
  {
    title: "Navigating the Future of Gaming: How Specter Aligns with Industry Trends",
    image: BlogThree,
    url : 'https://medium.com/specter-chronicles/navigating-the-future-of-gaming-how-specter-aligns-with-industry-trends-74dabfc6ac9e',
    statusColor: "red",
    statusText: "Hot",
    action: "5 mins read",
  },
  {
    title: "Specter's Role in Player Engagement: The Power of a Comprehensive Backend",
    image: BlogFour,
    url : 'https://medium.com/specter-chronicles/specters-role-in-player-engagement-the-power-of-a-comprehensive-backend-2996f003952a',
    statusColor: "red",
    statusText: "Hot",
    action: "3 min read",
  },
  {
    title: "Gamification: Not Just Play, But a Strategy for Success",
    image: BlogFive,
    url : 'https://medium.com/specter-chronicles/gamification-not-just-play-but-a-strategy-for-success-dab9ab206e5b',
    action: "3 min read",
  },
  {
    title: "The Indie Developer's Toolkit: Powering Up with Essential Backend Features",
    image: BlogSix,
    url : 'https://medium.com/specter-chronicles/the-indie-developers-toolkit-powering-up-with-essential-backend-features-cc0719ef2999',
    action: "3 mins read"
  },
];

const Features = ({ className }) => {


  return (
    <>
      <div className={styles.wrapper}>
      <Card
        className={cn(styles.card, className)}
        title="Explore Insights & Inspiration"
        classTitle="title-green"
      >
        <div className={styles.tips}>
          <div className={styles.info}>
            Dive Into Our Latest Blogs
          </div>
          <div className={styles.list}>
            {tips && tips.map((x, index) => (
                  <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <img src={x.image} width={20} height={20}/>
                  </div>
                  <Link to={x.url} target='_blank'>
                  <div className={styles.details}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.line}>
                      {x.statusText && (
                        <div
                          className={cn(
                            { "status-purple": x.statusColor === "purple" },
                            { "status-green-dark": x.statusColor === "green" },
                            { "status-red-dark": x.statusColor === "red" },
                            styles.status
                          )}
                        >
                          {x.statusText}
                        </div>
                      )}
                      <div className={styles.user}>
                        <div className={styles.action}>{x.action}</div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
            ))}
          </div>
        </div>
      </Card>
      </div>
    </>
  );
};

export default Features;
