import React, {useState} from 'react'
import cn from 'classnames'
import styles from './deleteModal.module.sass'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { deleteTaskAction } from '../../../../redux/action/engage/Achievement/Task/TaskAction'
import { deleteTaskGroupAction } from '../../../../redux/action/engage/Achievement/StepSeries/StepSeriesAction'
import { deleteCompetitionAction } from '../../../../redux/action/engage/Competitions/competitionAction'
import { deleteLeaderboardAction } from '../../../../redux/action/engage/Leaderboard/leaderboardAction'
import app from "../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const DeleteModal = ({className, id, name, type, onClose}) => {
  
  const dispatch = useDispatch()
  const {appid} = useParams()

  const remove = {
    task : deleteTaskAction,
    mission: deleteTaskGroupAction,
    'step-series' : deleteTaskGroupAction,
    tournament : deleteCompetitionAction,
    ib: deleteCompetitionAction,
    leaderboard: deleteLeaderboardAction,
  }

  const onArchiveActivity = async(type,req)=>{
    const user = JSON.parse(localStorage.getItem('user'))
    const memberID = user?.id
    const memberName = user?.name
    const orgId = (localStorage.getItem('orgId'))

    // Log activity in Firestore
    const activityLog = {
        timestamp: new Date().toISOString(),
        action_type: "ARCHIVE",
        app_id:appid,
        resource: type?.toUpperCase(),
        resource_id: id, // Use the ID from response
        description: `${type} '${name}' archived successfully.`,
        quantity: 1,
operation_status: "SUCCESS",
        activity_feed: true,
        priority: "LOW",
        performed_by_id: memberID,
        organization_id: orgId,
        performed_by:memberName,
        request_json:JSON.stringify(req),
    };
    await addDoc(collection(db, "activityFeed"), activityLog);
    console.log("Activity logged successfully.");
  }
  const db = getFirestore(app);

  const onDelete = () => {
    const deleteAction = remove[type]
    if(deleteAction){
        let data = {
            projectId: appid,
            ids: [id],
            ...((type === 'mission' || type === 'step-series') && {typeId: (type === 'mission' ? 1 : 2)}),
            ...((type === 'tournament' || type === 'ib') && {competitionFormatTypeMasterId : (type === 'tournament' ? 2 : 3)})
        }
        try {
          dispatch(deleteAction(data, appid, onClose))
          switch (type) {
            case "leaderboard":
              onArchiveActivity("Leaderboard",data)
              break;
            case "task":
              onArchiveActivity("Task",data)
              break;
            case "mission":
              onArchiveActivity("Mission",data)
              break;
            case "step-series":
              onArchiveActivity("Step Series",data)
              break;
            case "tournament":
              onArchiveActivity("Tournament",data)
              break;
            case "ib":
              onArchiveActivity("Instant Battle",data)
              break;
            default:
              break;
          }
          // type=="leaderboard"&& onArchiveActivity("Leaderboard",data)
        } catch (error) {
          console.log("error",error)
        }

    }else{
        console.warn('Unknown type')
    }
  }

  return (
    <div className={styles.deleteModal}>
        <div className={styles.deleteBody}>
            <div className={styles.warning}>
                <h5>Warning!</h5>
            </div>
            <div className={styles.warningContent}>
                <p>Are you sure you want to delete <span className={styles.title}>{name}</span>?</p>
            </div>
        </div>
        <div className={styles.btns}>
            <button className={styles.delete} onClick={onDelete}>Yes</button>
            <button className={styles.cancel} onClick={onClose}>Cancel</button>
        </div>
    </div>
  )
}

export default DeleteModal