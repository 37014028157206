import { takeEvery, call, put, delay } from 'redux-saga/effects';
import axios from 'axios';
import { START_API_CALLS, apiCallSuccess, apiCallFailure, endApiCall } from '../../actions/currency/bulkFileUploadAction';
import customAxios from '../../../utils/interceptor';
import { ADD_CURRENCY, BASE_URL } from '../../../resources/APIEndpoints';

// API function to call the placeholder endpoint
function fetchApi(payload) {
    // We're calling the posts API, and payload can be used for query params if needed
    const url = BASE_URL + ADD_CURRENCY
    return customAxios.post(url, payload);
}

// Worker saga: handles the side effects
function* handleApiCalls(action) {
    const {payloads, getCurrecy} = action;  
    // Array of payloads to send with each request
    for (let i = 0; i < payloads.length; i++) {
        try {
        // Try to make the API call for the current payload
        const response = yield call(fetchApi, payloads[i]);
        // Dispatch success action if the API call succeeds
        yield put(apiCallSuccess(response.data, payloads.length));
      } catch (error) {
        // Dispatch failure action if the API call fails
        yield put(apiCallFailure(error.message, payloads.length));
      }       
      // Wait for 5 seconds between each API call
      yield delay(10000);
    }
    yield delay(2000)
    getCurrecy()
    yield put(endApiCall());
}

// Watcher saga: watches for START_API_CALLS action
export default function* watchApiCallsCurrency() {
  yield takeEvery(START_API_CALLS, handleApiCalls);
}

// export default function* rootSaga() {
//   yield watchApiCalls();
// }
