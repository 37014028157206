import React, { useEffect, useState, useRef} from "react";
import cn from "classnames";
import axios from "axios";
import customAxios from "../../../../../../utils/interceptor";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import Toast from "../../../../../../components/Toast";
import 'react-toastify/dist/ReactToastify.css';
import CurrencyDropdown from "../../../../../../components/Dropdown/CurrencyDropdown";
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
import PlatformDropdown from "../../../../../../components/Dropdown/PlatformDropdown"
import Multiselect from "../../../../../../components/MultiselectDropDown/PricingDropdown";
import CommonDropdown from '../../../../../../components/MultiselectDropDown/CommonDropdown'
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./bundleInformation.module.sass";
import Card from "../../../../../../components/Card";
import Icon from "../../../../../../components/Icon";
import TextInput from "../../../../../../components/TextInput";
import TextArea from "../../../../../../components/TextArea";
import DaysDropdown from "../../../../../../components/Dropdown/DaysDropdown"
import ItemDropdown from "../../../../../../components/Dropdown/ItemDropdown"
import Radio from "../../../../../../components/Radio";
import Checkbox from "../../../../../../components/Checkbox"
import addIcon from "../../../../../../media/images/icons/add.png"
import removeIcon from "../../../../../../media/images/icons/remove.png"
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import Tooltip from "../../../../../../components/Tooltip";
import Control from "../Control";
import ContentTable from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/ContentTableLayout'
import './style.css'
import {createBundleAction, getAllBundlesAction} from '../../../../../../redux/action/builds/economy/bundles/bundleAction'
import {getAllCurrencyAction} from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import { BASE_URL, GET_CURRENCY, GET_ITEMS, GET_PROGRESSION_SYSTEM } from "../../../../../../resources/APIEndpoints";
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import Bundle from '../../../../../../media/images/placeholders/Bundles.png'
import { getAllItemAction } from "../../../../../../redux/action/builds/economy/items/itemAction";
import FileUploadSidebar from "../../../../../../components/FileUploadSidebar";
import { getAllMediaAction } from "../../../../../../redux/action/AppSettings/Media/mediaAction";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../components/MetaInput";
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
// Static Data And Placeholder
const pricingOption = [{id: 1, name : "Virtual"}, {id: 2, name :"In-App Purchase"}, {id: 3, name: "RMG"}]
const daysOptions = ["Seconds", "Minutes", "Hours"]
const itemSubType = [{id: 1, title: "Stackable"}, {id: 2,title: "Equippable"}, {id: 3,title: "Tradeable"}, {id: 4,title: "Rentable"}, {id: 5,title: "Time Stackable"}];
const lockBy = [{id: 1, title : "Item/Bundle"}, {id : 2, title : "Progression System"}]
const itemPlaceholder = ["Select Item"]
const progressionPlaceholder = ["Select Progression System"]


const BundleInformation = ({className,isChanged, setIsChanged, onClose}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
    const ref = useRef()
    const [checkObject, setCheckObject] = useState()
    const placeholder = Bundle
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [itemIcon, setItemIcon] = useState(null)
    const [itemName, setItemName] = useState('')
    const [itemId, setItemId] = useState('')
    const [itemDescription, setItemDescription] = useState()
    const [isStackable, setIsStackable] = useState(false)
    const [isEquippable, setIsEquippable] = useState(false)
    const [isTradeable, setIsTradeable] = useState(false)
    const [isRentable, setIsRentable] = useState(false)
    const [isTimeStackable, setIsTimeStackable] = useState(false)
    const [itemCount, setItemCount] = useState()
    const [itemTime, setItemTime] = useState()
    const [quantity, setQuantity] = useState(null)
    const [levelSystem, setLevelSystem] = useState()
    const [battleLevel, setBattleLevel] = useState()
    const [currency, setCurrency] = useState();
    const [days, setDays] = useState(daysOptions[0])
    const [items, setItems] = useState()
    const [progressionSystem, setProgressionSystem] = useState(progressionPlaceholder[0])
    const [itemType, setItemType] = useState(false);
    const [lockLevel, setLockLevel] = useState(false)
    const [value, setvalue] = useState('')
    const [currencyId, setCurrencyId] = useState()
    const [isLimited, setIsLimited] = useState(false)
    const [pricing, setPricing] = useState([])
    const [called, setCalled] = useState(false)
    // Get Progression System API Call
    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])

    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }

    useEffect(()=>{
        getPS()
        getRealWorldCurrency()
        getMetaData()
        getAllItemsData()
        getAllCurrencyData()
        storeContent()
    }, [])

    const [allCurrency, setAllCurrency] = useState([])
    const [currencyPage, setCurrencyPage] = useState(1)
    const [currencyLimit, setCurrencyLimit] = useState(8)

    const getAllCurrencyData = () => {
        let getAllCurrency = {
            projectId : appid,
            offset: ((currencyPage-1)*currencyLimit),
            limit: currencyLimit
        }
        dispatch(getAllCurrencyAction(getAllCurrency))
    }

    useEffect(() => {
        getAllCurrencyData()
    }, [currencyPage])

    const [virtualCurrencyData, setVirtualCurrencyData] = useState([])
    const [realMoneyData, setRealMoneyData] = useState([])

    const customizeCurrencyData = () => {
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = content.find(element => element.currencyId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllCurrency(updatedArray)
            let vc = currenciesData && currenciesData.filter((e,i) => e.type == 'virtual')
            let vcData = []
            vc && vc.forEach((e,i) =>{
                vcData.push( {name : e.name, id : e.id, type : 'virtual currency'})
            })
            setVirtualCurrencyData(vcData)
            let rm = currenciesData && currenciesData.filter((e,i) => e.type == 'real')
            let rmData = []
            rm && rm.forEach((e,i) =>{
                rmData.push({name : e.name, id : e.id, type : 'RMG'})
            })
            setRealMoneyData(rmData)
        }
    }

    const [allItems, setAllItems] = useState([])
    const [itemOptions, setItemOption] = useState()
    const [itemPage, setItemPage] = useState(1)
    const [itemLimit, setItemLimit] = useState(8)
    
    const getAllItemsData = () => {
        let items = {
            projectId : appid,
            offset: ((itemPage-1)*itemLimit),
            limit: itemLimit
        }
        dispatch(getAllItemAction(items))
    }

    useEffect(() => {
        getAllItemsData()
    }, [itemPage])

    const customizeItemData = () => {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map(ele => {
                const matchObj = content.find(element => element.itemId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllItems(updatedArray)
            setItemOption(itemsData)
        }
    }

    const [getAllBundle, setAllBundle] = useState([])
    const [bundlePage, setBundlePage] = useState(1)
    const [bundleLimit, setBundleLimit] = useState(8)

    const getAllBundlesData = () => {
        let bundles = {
            projectId : appid,
            offset: ((bundlePage-1)*bundleLimit),
            limit: bundleLimit
        }
        dispatch(getAllBundlesAction(bundles))
    }

    useEffect(() => {
        getAllBundlesData()
    }, [bundlePage])

    const customizeBundleData = () => {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map(ele => {
                const matchObj = content.find(element => element.bundleId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllBundle(updatedArray)
        }
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    let itemsData = useSelector((state) => {
        return state.item.items
    })

    let bundleData = useSelector((state) => {
        return state.bundles.bundles
    })

    useEffect(()=>{
        customizeCurrencyData()
    }, [currenciesData,called])

    useEffect(() => {
        customizeItemData()
    }, [itemsData,called])

    useEffect(() => {
        customizeBundleData()
    }, [bundleData,called])

    // Get Meta Data API Call for Platform 
    const [platform, setPlatform] = useState([])
    const [platformOptions, setPlatformOptions] = useState([])

    const getMetaData = async() => {
        
        let res = await axios.get(`${BASE_URL}/master-data`)
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.forEach((element) => {
            setPlatformOptions(prevState => [...prevState, {id : element.id, name : (element.platformName == 'android' ? 'Android' : 'iOS')}])
        })
    }

    // Get Real World Currency API Call
    const [realWorldCurrency, setRealWorldCurrency] = useState([])
    const getRealWorldCurrency = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
        res.data.data && res.data.data.forEach((element) => {
            setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"), type : 'IAP'}])
        })
    }


    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setItemIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setItemIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onChangeItemName = (e) => {
        if(e.target.value == ''){
            setItemName('')
        }else{
            errors.nameError = ''
            setItemName(e.target.value)
        }
    }

    const onChangeItemId = (e) => {
        if(e.target.value == ''){
            setItemId('')
        }else{
            errors.idError = ''
            setItemId(e.target.value)
        }
    }

    const onChangeItemDescription = (e) => {
        setItemDescription(e.target.value)
    }

    const onSubType = (e, title) => {
        if(e.target.checked == true){
            if(title == 'Stackable'){
                setIsStackable(true)
                setStacksCount(null)
            }else if(title == 'Equippable'){
                setIsEquippable(true)
            }else if(title == 'Tradeable'){
                setIsTradeable(true)
            }else if(title == 'Rentable'){
                setIsRentable(true)
            }else if(title == 'Time Stackable'){
                setIsTimeStackable(true)
                setBy(true)
            }
        }else{
            if(title == 'Stackable'){
                setIsStackable(false)
                setStacksCount(null)
            }else if(title == 'Equippable'){
                setIsEquippable(false)
            }else if(title == 'Tradeable'){
                setIsTradeable(false)
            }else if(title == 'Rentable'){
                setIsRentable(false)
            }else if(title == 'Time Stackable'){
                setIsTimeStackable(false)
                setBy(false)
            }
        }
    }

    const [lockItems, setLockItems] = useState(false)
    const [lockProgression, setLockProgression] = useState(false)

    const onLockBy = (e, title) => {
        if(e.target.checked){
            if(title == 'Item/Bundle'){
                setLockItems(true)
            }else{
                setLockProgression(true)
            }
        }else{
            if(title == 'Item/Bundle'){
                setLockItems(false)
                setItems()
                setUnlockItemId()
            }else{
                setLockProgression(false)
                setPS([])
            }
        }
    }

    const onChangeByCount = (e) => {
        setItemCount(parseInt(e.target.value))
    }

    const onChangeTime = (e) => {
        setItemTime(e.target.value)
    }

    const onChangeQuantity = (e) => {
        setQuantity(parseInt(e.target.value))
    }

    const onChangeLevelSystem = (e) => {
        setLevelSystem(e.target.value)
    }

    const onChangeBattleLevel = (e) => {
        setBattleLevel(e.target.value)
    }

    const [stacksCount, setStacksCount] = useState(null)

    const onChangeStacks = (e) => {
        setStacksCount(parseInt(e.target.value))
    }

    const [maxCollectionInstances, setMaxCollectionInstances] = useState(null)

    const onChangemaxCollectionInstances = (e) => {
        setMaxCollectionInstances(parseInt(e.target.value))
    }

    const[virtualCurrency, setVirtualCurrency] = useState([])

    const addVirtualCurrency = () => {
        const currencyFields = []
        currencyFields.push(...virtualCurrency, {priceTypes: '', currencyId : null, price : null , discount : null})
        setVirtualCurrency(currencyFields)
    }

    const removeVirtualCurrency = (index) => {
        let removeField = virtualCurrency
        let currentFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setVirtualCurrency(currentFields)
        if(currentFields.length == 0){
            setPricing(pricing.filter((values) => values.name != 'Virtual'))
            setShowVirtual(false)
            // setPricing(pricing.filter((values) => console.log(values)))
            // setShowVirtual(false)
            //setPricing(oldValues => {return oldValues.filter(pricing => console.log(pricing))})
        }
    }

    const onVirtualCurrency = (e, index, type) => {
        let virtualCurrencyList = [...virtualCurrency]
        virtualCurrencyList[index][e.target.name] = (type == 'price' ? parseInt(e.target.value) : parseInt(e.target.value))
        setVirtualCurrency(virtualCurrencyList)
    }

    const[IAP, setIAP] = useState([])

    const addIAP = () => {
        const IAPFields = []
        IAPFields.push(...IAP, {priceTypes: '', realWorldCurrencyId : null, gamePlatformMasterId: null, gamePlatformName:'',  price : null , discount : null})
        setIAP(IAPFields)
    }

    const removeIAP = (index) => {
        let removeField = IAP
        let currentFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setIAP(currentFields)
        if(currentFields.length == 0){
            setPricing(pricing.filter((values) => values.name != 'In-App Purchase'))
            setShowInApp(false)
            // setPricing(pricing.filter((values) => console.log(values)))
            // setShowVirtual(false)
            //setPricing(oldValues => {return oldValues.filter(pricing => console.log(pricing))})
        }
    }

    const onIAP = (e, index, type) => {
        let IAPList = [...IAP]
        IAPList[index][e.target.name] = (type == "product" ? e.target.value : parseInt(e.target.value))
        setIAP(IAPList)
    }

    const[realMoney, setRealMoney] = useState([])

    const addRealMoney = () => {
        const realMoneyFields = []
        realMoneyFields.push(...realMoney, {priceTypes: '', currencyId : null, bonusCashAllowance : null,  price : null , discount : null})
        setRealMoney(realMoneyFields)
    }

    const removeRealMoney = (index) => {
        let removeField = realMoney
        let currentFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setRealMoney(currentFields)
        if(currentFields.length == 0){
            setPricing(pricing.filter((values) => values.name != 'RMG'))
            setShowRMG(false)
            // setPricing(pricing.filter((values) => console.log(values)))
            // setShowVirtual(false)
            //setPricing(oldValues => {return oldValues.filter(pricing => console.log(pricing))})
        }
    }

    const onRealMoney = (e, index, type) => {
        let realMoneyList = [...realMoney]
        realMoneyList[index][e.target.name] = (type == 'price' ? parseInt(e.target.value) : parseInt(e.target.value))
        setRealMoney(realMoneyList)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]); 
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
    
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [segments, setSegments] = useState([]);

    const handleDeleteSegments = (i) => {
        setSegments(segments.filter((segments, index) => index !== i));
    };
  
    const handleAdditionSegments = (segment) => {
        setSegments([...segments, segment]);
    };
  
    const handleDragSegments = (segment, currPos, newPos) => {
      const newSegments = [...segments].slice();
  
      newSegments.splice(currPos, 1);
      newSegments.splice(newPos, 0, segment);
  
      setSegments(newSegments);
    };
  
    const handleTagClickSegments = (index) => {

    };
  
    const onClearAllSegments = () => {
      setSegments([]);
    };
  
    const onSegmentUpdate = (i, newsegments) => {
      const updatedSegments = segments.slice();
      updatedSegments.splice(i, 1, newsegments);
      segments(updatedSegments);
    };


    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [unlockItemId, setUnlockItemId] = useState()
    const [unlockSystemId, setUnlockSystemId] = useState()

    const [errors, setErrors] = useState({nameError : '', idError : '', typeError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.bundles[0].name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('bundleName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.bundles[0].bundleId == '') {
          error.idError = 'Bundle ID is required';
          isValid = false;
          if(data.bundles[0].name != ''){
                const element = document.getElementById('bundleId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }

        // if (data.bundles[0].isConsumable == '') {
        //     error.typeError = 'Bundle Type is required';
        //     isValid = false;
        // }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const db = getFirestore(app);

    const submit = async () => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let bundlePrices = [...virtualCurrency, ...realMoney, ...IAP]
        
        const filteredPrice = bundlePrices.filter(item => {
            const price = item.price;
            return price !== null && price !== undefined ;
        });
        
        // let unlockItems = []
        // unlockItems.push({unlockItemId : unlockItemId, unlockLevelSystemId: null})

        // let unlockPS = []
        // PS && PS.map((e,i) =>{
        //     unlockPS.push({unlockItemId : null, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        // })

        // let unlockItemData = [...unlockItems, ...unlockPS]

        let unlockItems = []
        if(unlockItemId){
            unlockItems.push({ unlockItemId : unlockItemId, unlockLevelSystemId: null})
        }
        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({id: e.dbID, unlockItemId : null, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })

        if (lockItems === false) {
            unlockItems = []
        }

        if (lockProgression === false) {
            unlockPS = []
        }

        let unlockItemData = [...unlockItems, ...unlockPS]

        const contents = JSON.parse(sessionStorage.getItem('contents'))
        const addedContent = contents.map(({iconUrl, name, type, displayId, ...content}) => content)
        const bundleContent = addedContent.filter(item => !item.archive).map(obj => {const { bundleId, ...rest } = obj; return { contentBundleId: bundleId, ...rest };})

        const createBundle = {
            bundles : [
                {
                    projectId : appid,
                    iconUrl : itemIcon,
                    name : itemName,
                    bundleId : itemId,
                    isManual : bundle,
                    description : itemDescription,
                    isEquippable : isEquippable,
                    isTradable : isTradeable,
                    isStackable : isStackable,
                    isRentable: isRentable,
                    isTimeStackable : isTimeStackable,
                    itemType : itemType,
                    isConsumable : itemType,
                    ...(itemType && by && {consumeByTimeFormat : days, consumeByTime : (days == 'Seconds' ? parseInt(itemTime) : days == 'Minutes' ? (parseInt(itemTime)*60) : days == 'Hours' ? (parseInt(itemTime)*60*60) : days == 'Days'? (parseInt(itemTime)*24*60*60) : days == 'Months' ? (itemTime*30.42*24*60*60) : days == 'Years' ? (itemTime*365*24*60*60) : null)}),
                    ...(itemType && !by && {consumeByUses : itemCount}),
                    quantity : (isLimited ? quantity : null),
                    stackCapacity : (isStackable ? stacksCount : null),
                    bundlePrices: filteredPrice.map(({currencyName,gamePlatformName, ...rest}) => {return rest;}),
                    typeId : (itemType == false ? "1" : "2"),
                    isLock : ((lockItems == false && lockProgression == false) ? false : true),
                    rewardUnlockCondition : unlockItemData.length > 0 ? unlockItemData : null,
                    meta : finalMetaData,
                    tags : itemTags,
                    active : true,
                    archive : false,
                    bundleContent : bundleContent,
                    maxCollectionInstance: maxCollectionInstances,
                }
            ]
        }
        if((lockItems && unlockItemId == undefined || (lockProgression && PS.length == 0))){
            if(lockItems && unlockItemId == undefined){
                setLockItemErr(true)
            }
            if(lockProgression && PS.length == 0){
                setLockPSErr(true)
            }
        }else{
            if(validation(createBundle)){
                try {
                    // Await the response from createGameAction
                    await dispatch(createBundleAction(createBundle, navigate, appid, setDisable))
                    // Access the response data (e.g., game ID)
                        // const IdFromResponse = res?.data?.data?.id;

                        setIsChanged(false);
                        const user = JSON.parse(localStorage.getItem('user'))
                        const memberID = user?.id
                        const memberName = user?.name
                        const orgId = (localStorage.getItem('orgId'))
        
                        // Log activity in Firestore
                        const activityLog = {
                            timestamp: new Date().toISOString(),
                            action_type: "CREATE",
                            app_id:appid,
                            resource: "BUNDLE",
                            // resource_id: IdFromResponse, // Use the ID from response
                            description: `Bundle '${itemName}' created successfully.`,
                            quantity: 1,
operation_status: "SUCCESS",
                            activity_feed: true,
                            priority: "LOW",
                            performed_by_id: memberID,
                            organization_id: orgId,
                            performed_by:memberName,
                            request_json:JSON.stringify(createBundle),
                        };
                        await addDoc(collection(db, "activityFeed"), activityLog);
                        console.log("Activity logged successfully.");
                    
                } catch (error) {
                    console.error("Error creating game or logging activity:", error);
                }
            }
        }
        
    }

    const [lockItemErr, setLockItemErr] = useState(false)
    const [lockPSErr, setLockPSErr] = useState(false)

    const cancel = () => {
        setIsChanged(false)
        navigate(`/bundle/${appid}`)
    }

    // Select Pricing States
    const [showVirtual, setShowVirtual] = useState(false)
    const [showInApp, setShowInApp] = useState(false)
    const [showRMG, setShowRMG] = useState(false)

    const [by, setBy] = useState(false)

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }

    const [content, setContent] = useState([])

    const storeContent = () => {
        sessionStorage.setItem('contents', JSON.stringify(content))
    }

    useEffect(()=>{

    }, [content])

    const [disable, setDisable] =  useState(false)
    const [visibleHelp, setVisibleHelp] = useState(false)

    const setchange = () => {
        if (
            itemIcon===null&&
            (itemName===''||itemName==="")&&
            (itemId===''||itemId==="")&&
            (itemDescription===undefined||itemDescription==''||itemDescription=="")&&
            isStackable===false&&
            isEquippable===false&&
            isTradeable===false&&
            isRentable===false&&
            isTimeStackable===false&&
            isLimited===false&&
            itemType===false&&
            pricing?.length==0&&
            days===daysOptions[0]&&
            lockItems===false&&
            lockProgression===false&&
            stacksCount===null&&
            maxCollectionInstances===null&&
            tags.length==0&&
            (metaData.length==1&&metaData[0].key == '' && (metaData[0].value == '' || metaData[0].value == null))
            ) {
                setIsChanged(false)
            }
            else {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
    }, [itemIcon,itemName,itemId,itemDescription,isStackable,isEquippable,isTradeable,isRentable,isTimeStackable,itemCount,itemTime,quantity,levelSystem,battleLevel,currency,days,items,progressionSystem,itemType,lockLevel,value,currencyId,isLimited,pricing,lockItems, lockProgression, stacksCount, maxCollectionInstances, tags, metaData])
    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    const [bundle, setBundle] = useState(false)

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Bundle Information" classTitle="title-purple">
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>   
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {itemIcon != null && 
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        }
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={itemIcon} setIcon={setItemIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <TextInput tooltip={TooltipTitle.BundleName} id='bundleName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required onChange={(e)=>{onChangeItemName(e)}} />
                            <TextInput tooltip={TooltipTitle.BundleId} value={itemId} isId={true} id='bundleId' className={styles.field} errorMessage={errors.idError} label="Bundle ID*" name="itemId" type="text" required onChange={(e)=>{onChangeItemId(e)}} />
                        </div>

                        <div className={styles.textArea}>
                            <TextArea value={itemDescription} tooltip={TooltipTitle.BundleDescription} className={styles.field} rows={5} label="Bundle Description" onChange={(e)=>{onChangeItemDescription(e)}} />
                        </div>
                    </div>

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Add Content to Bundle" classTitle="title-red" 
                head={
                    <div className={styles.createBundle}>
                        <div className={styles.groupHeading}>
                            <Control type='Bundle' currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} pageItem={itemPage} pageCurrnecy={currencyPage} pageBundle={bundlePage} itemPage={setItemPage} bundles={getAllBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} setValue={setContent} setCalled={setCalled} value={content}  width='medium'/>
                        </div>
                    </div>
                }
            >
                {content && content.length > 0 &&
                    <div className={styles.cardDescription}>
                        <div className={styles.createBundle}>
                            <div className={styles.content}>
                                <div className={styles.wrapper}>
                                    <ContentTable items={content} setValue={setContent} allCurrency={allCurrency} setAllCurrency={setAllCurrency} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Card>
            <Card className={cn(styles.card, className)} title="Pricing" classTitle="title-green">
                <div className={styles.cardDescription}>
                    <div className={styles.createItem}>
                        <div className={styles.labelHeading}>
                            <span>Choose Pricing Options<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundlePricing} icon="info"/></span></span>
                        </div>
                        <div className={styles.selectInput}>
                            <Multiselect as='bundle' type='add' placeholder='Choose Pricing Options' options={pricingOption} value={pricing} setValue={setPricing} setShowVirtual={setShowVirtual} setShowRMG={setShowRMG} setShowInApp={setShowInApp} setVirtualCurrency={setVirtualCurrency} setIAP={setIAP} setRealMoney={setRealMoney}/>
                        </div>
                        {showVirtual &&
                            <>
                                <div className={styles.groupHeading}>
                                    <span>Virtual</span><span className={styles.addIcon} onClick={addVirtualCurrency}><AddMetaIcon /></span>
                                </div>

                                <div className={styles.groupByThree}>
                                    {virtualCurrency && virtualCurrency.map((e,index)=>{
                                        return(
                                            <>
                                                <div className={styles.field}>
                                                    <CurrencyDropdown as='bundle' type="virtual currency" label="Select Currency" options={virtualCurrencyData} value={e.currencyName} data={virtualCurrency} setData={setVirtualCurrency} index={index}/>
                                                </div> 
                                                <TextInput className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required  onChange={(e)=>{onVirtualCurrency(e, index, "price")}}/>
                                                <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onVirtualCurrency(e, index)}}/>
                                                <span className={styles.remove} onClick={()=>{removeVirtualCurrency(index)}}><img className={styles.icon} src={removeIcon} alt="remove" /></span>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>

                    <div className={styles.createItem}>
                        {showInApp &&
                            <>
                                <div className={styles.groupHeading}>
                                    <span>In-App Purchase</span><span className={styles.addIcon} onClick={addIAP}><img src={addIcon} alt="add" /></span>
                                </div>
                                <div className={styles.groupByFive}>
                                    {IAP && IAP.map((e,index)=>{
                                        return(
                                            <>
                                                <div className={styles.field}>
                                                    <CurrencyDropdown as='bundle' type="IAP" label="Select Currency" options={realWorldCurrency} value={e.currencyName} data={IAP} setData={setIAP} index={index}/>
                                                </div>
                                                <div className={styles.field}>
                                                    <PlatformDropdown label="Select Platform" options={platformOptions} value={e.gamePlatformName} data={IAP} setData={setIAP} index={index}/>
                                                </div>
                                                <TextInput className={styles.field} label="Product ID" name="productId" type="text" required onChange={(e)=>{onIAP(e, index, "product")}}/>
                                                <TextInput className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onIAP(e, index, "price")}}/>
                                                <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onIAP(e, index)}}/>
                                                <span className={styles.remove} onClick={()=>{removeIAP(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>

                    <div className={styles.createItem}>
                        {showRMG && 
                            <>
                                <div className={styles.groupHeading}>
                                    <span>RMG</span><span className={styles.addIcon} onClick={addRealMoney}><AddMetaIcon /></span>
                                </div>
                                <div className={styles.groupByFour}>
                                    {realMoney && realMoney.map((e, index) => {
                                        return (
                                            <>
                                                <div className={styles.field}>
                                                    <CurrencyDropdown as='bundle' type="RMG" label="Select Currency" options={realMoneyData} value={e.currencyName} data={realMoney} setData={setRealMoney} index={index}/>
                                                </div>
                                                <TextInput className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onRealMoney(e, index, "price")}} />
                                                <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onRealMoney(e, index)}} />
                                                <TextInput className={styles.field} label="Bonus Cash Allowance" name="bonusCashAllowance" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onRealMoney(e, index)}} />
                                                <span className={styles.remove} onClick={()=>{removeRealMoney(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    {lockBy.map((x, index) => (
                                        <Checkbox key={x.id} className={styles.checkbox} content={x.title} onChange={(e)=>{onLockBy(e, x.title)}} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.createItem}>
                            <div className={styles.group}>
                                {lockItems &&
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Item/Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreItemBundle} icon="info"/></span></span>
                                        </div>
                                        <ItemDropdown className={styles.selectField} groupBy={1} options={itemOptions} value={items} setValue={setItems} setUnlockItemId={setUnlockItemId} error={setLockItemErr}/>
                                        {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                    </div>
                                }
                                {lockProgression ?
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.StorePS} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                : ''}
                            </div>
                        <div className={styles.group}>
                            {lockProgression &&
                                <>
                                    {PS && PS.map((element, index) =>{
                                        return(
                                            <>  
                                                <div style={{marginTop:"8px"}} className={styles.field}>
                                                    <ProgressionLevelMultiSelect placeholder='Select Level' label={element.name} options={element.levelSystemLevelMapping} ind={index} value={PS} setValue={setPS}/>                                     
                                                </div>
                                            </>
                                        )
                                    })}
                                </>
                            }
                        </div>     
                    </div>

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Bundle Type" classTitle="title-yellow">
                <div className={styles.cardDescription}>

                    <div className={styles.createItem}>
                        <div className={styles.groupByThrees}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Bundle Type*<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleType} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="itemType" value={!itemType} onChange={() => setItemType(false)} content="Durable" />
                                    <Radio className={styles.radio} name="itemType" value={itemType} onChange={() => setItemType(true)} content="Consumable" />
                                </div>
                            </div>
                            {itemType && 
                                <div className={styles.fieldRadio}>
                                    <div className={styles.radioLabel}>
                                        <span>Consumable By<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleBy} icon="info"/></span></span>
                                    </div>
                                    <div className={styles.variants}>
                                        <Radio className={styles.radio} value={!by} name="by" onChange={() => setBy(false)} content="By Uses" />
                                        <Radio className={styles.radio} value={by} name="by" onChange={() => setBy(true)} content="By Time" />
                                    </div>
                                </div>
                            }
                            {itemType ?
                                <>
                                    <div className={styles.field}>
                                        {!by && <TextInput label="By Uses" name="count" type="number" onWheelCapture={e => {e.currentTarget.blur()}} min="0" required  onChange={(e)=>{onChangeByCount(e)}} />}
                                        {by && <TextInput label="By Time" name="time" type="text" required onChange={(e)=>{onChangeTime(e)}} />}
                                    </div>
                                </>
                            : ''}
                        </div>
                    </div>

                    {itemType && by ?
                        <div className={styles.createItem}>
                            <div className={styles.itemDropdown}>
                                <DaysDropdown options={daysOptions} value={days} groupBy={3} setValue={setDays}/>
                            </div>
                        </div>
                    : ''}

                    <div className={styles.createItem}>
                        <div className={styles.label}>Bundle Sub Type<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleSubType} icon="info"/></span></div>
                        <div className={styles.list}>
                            {itemSubType.map((x, index) => (
                                <Checkbox disable={(x.title == 'Time Stackable' && by == false) ? true : false} key={x.id} className={styles.checkbox} content={x.title} onChange={(e)=>{onSubType(e, x.title)}} />
                            ))}
                        </div>
                    </div>

                    <div className={styles.createItem}>
                            <div className={styles.group}>
                                <TextInput className={styles.field} label="Max Collection Instances" name="maxCollectionInstances" type="number" required onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onChangemaxCollectionInstances(e)}} />
                            {isStackable ?
                                <TextInput tooltip={TooltipTitle.BundleMaxStack} className={styles.field} label="Stack Capacity" name="NumberOfStacks" type="number" required onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onChangeStacks(e)}} />
                            : ''}
                            </div>
                        </div>

                    <div className={styles.createItem}>
                        <div className={styles.label}>Quantity</div>
                        <div className={styles.list}>
                            <Checkbox className={styles.checkbox} content="Is Limited" onChange={()=>{setIsLimited(!isLimited); setQuantity(null)}}/>
                        </div>
                    </div>
                    {isLimited &&
                        <div className={styles.createItem}>
                            <div className={styles.group}>
                                <TextInput tooltip={TooltipTitle.BundleQuantity} className={styles.field} label="Set Quantity" name="setQuantity" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onChangeQuantity(e)}} />
                            </div>
                        </div>
                    }

                    <div className={styles.radioInput}>
                        <div className={styles.radioButton}>
                            <div className={styles.radioLabel}>
                                <span>Open Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleBy} icon="info"/></span></span>
                            </div>
                            <div className={styles.variants}>
                                <Radio className={styles.radio} value={!bundle} name="open" onChange={() => setBundle(false)} content="Automatic" />
                                <Radio className={styles.radio} value={bundle} name="open" onChange={() => setBundle(true)} content="Manual" />
                            </div>
                        </div>
                    </div>          

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.createItem}>
                        
                    <TagInputWithDropdown tooltip={TooltipTitle.BundleTags} tags={tags} setTags={setTags}/>

                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" required  value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e, i)}}/> */}
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={submit}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default BundleInformation