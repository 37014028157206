import React, { useEffect, useState } from "react";
import Card from "../../../../components/Card";
import styles from './plans.module.sass'
import Icon from "../../../../components/Icon";
import { ReactComponent as  SpecterSvg} from "../../../../media/images/Spectre.svg";
import { ReactComponent as  Cube} from "../../../../media/images/icons/cube.svg";
import cn from 'classnames'
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { getMyPlanAction } from "../../../../redux/action/organisation/Billing/billingAction";
import Loader from "../../../../components/Loaders/LoaderProgress";

const Plans = ({className, myPlan}) => {

    const Navigate = useNavigate()

    const upgradePlan = () => {
        sessionStorage.setItem('plan_code', JSON.stringify(myPlan?.plan?.planCode || null))
        sessionStorage.setItem('active_plan_code', JSON.stringify(myPlan?.plan?.planCode || null))
        Navigate(`${routes.plans.path}`)
    }

    const onAddOns = () => {
        Navigate(`/add-on/${myPlan.subscriptionId}`)
    }

    console.log(myPlan)

    return(
        <>
            <Card className={styles.card} classCardHead={styles.head} title={'Plan'} classTitle={cn("title-purple", styles.title)}>
                <div className={styles.container}>
                    <div className={styles.planDetails}>
                        <div className={styles.details}>
                            <div className={styles.planAddOns}>
                                <div className={styles.planName}>
                                    {myPlan?.plan?.name}
                                </div>
                                <div className={styles.planDescription}>
                                    {myPlan?.plan?.description}
                                </div>
                            </div>
                        </div>
                        <div className={styles.planFeatures}>
                            <div className={styles.specter}>
                                <SpecterSvg />
                            </div>
                            <div className={styles.seperator}></div>
                            <div className={styles.features}>
                                {myPlan && myPlan?.plan && myPlan?.plan?.featureDetails && myPlan?.plan?.featureDetails?.features && myPlan?.plan?.featureDetails?.features.map(x => {
                                    return(
                                        <div className={styles.feature}>
                                            <div className={styles.cube}>
                                                <Cube />
                                            </div>
                                            <div className={styles.title}>
                                                {x.name}
                                            </div>
                                        </div>
                                    )
                                })}
                                {myPlan?.plan?.planCode === 'Plan_04' &&
                                    <div className={styles.addOns}>
                                        <div className={styles.buyAddOns}>
                                            <button className={styles.addOnsBtn} onClick={onAddOns}>Buy Add-on <Icon name='arrow-next' size='16'/></button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                        
                    {/* <div className={styles.specialOffer}>
                        <div className={styles.offer}>
                            <div className={styles.content}>
                                <div className={styles.title}>TaskFlow Engine Automation</div>
                                <div className={styles.description}>1 million TaskFlow Engine Automation</div>
                            </div>
                            <div className={styles.action}>
                                <button className={cn(styles.button, 'button-medium')}>Activate</button>
                            </div>
                        </div>
                    </div> */}
    
                    <div className={styles.actions}>
                        <button className={cn(styles.button, 'button-medium')} onClick={upgradePlan}>Upgrade Plan</button>
                        <button className={cn(styles.button, 'button-white')}>Cancel Plan</button>
                    </div>
                </div>
            </Card>
        </>
    )

}

export default Plans