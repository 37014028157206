import React, { useState, useEffect } from 'react'
import styles from './tooltip.module.sass'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import Radio from '../Radio';
import {ReactComponent as Ascending} from '../../media/images/icons/sort_ascending.svg'
import {ReactComponent as Descending} from '../../media/images/icons/sort_descending.svg'

const Tooltip = ({className, setPage, value, setValue, data, children, sortIndex, setSortIndex, sort, setSort, selected, setSelected }) => {

    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(sortIndex)

    return (
       <>
            <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible})}>
                    <div className={styles.children} onClick={() => setVisible(!visible)}>{children}</div>
                        <div className={styles.tooltipContainer}>
                            <div className={styles.sortingOrder}>
                                {sort && sort.map((e, index) => {
                                    return(
                                        <div className={cn(styles.sortBy, className,{ [styles.active]: index === sortIndex})} onClick={() => {setSortIndex(index);setIndex(index);setSelected(null)}}>
                                            {e.id === 1 && <div className={styles.icon}><Ascending /></div>}
                                            {e.id === 2 && <div className={styles.icon}><Descending /></div>}
                                            <div className={styles.Order}>{e.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={styles.seperator}></div>
                            {data && data.map((e, i) => {
                                return(
                                    <>
                                        <div className={styles.radioText}>
                                            <Radio size='small' reverse={true} name='sort' id={e.value} content={e.name} value={(value?.name === e.value && value?.value === sortIndex) ? true : false} onChange={() => {setPage(1);setSelected(e.value);setValue({name: e.value, value: index});setVisible(false)}}/>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
            </OutsideClickHandler>
       </>
    )
}

export default Tooltip