import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import Control from "./Control";
import { useParams } from "react-router-dom";

const Row = ({ item, key, index }) => {

    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>{item.name}</div>
                <div className={styles.col}>{item.documentId}</div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control className={styles.control} appid={appid} id={item.id} status={item.archive} name={item.name} />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
