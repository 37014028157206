// Action types
export const START_STORE_API_CALLS = 'START_STORE_API_CALLS';
export const STORE_API_CALL_SUCCESS = 'STORE_API_CALL_SUCCESS';
export const STORE_API_CALL_FAILURE = 'STORE_API_CALL_FAILURE';
export const STORE_RESET_SAGA_STATE = 'STORE_RESET_SAGA_STATE';

// Action creators
export const startStoreApiCalls = (payloads, getAction) => ({
    type: START_STORE_API_CALLS,
    payloads,
    getAction
});
  
export const storeApiCallSuccess = (payloads, totalLength) => ({
    type: STORE_API_CALL_SUCCESS,
    payloads,
    totalLength
});
  
export const storeApiCallFailure = (error, totalLength) => ({
    type: STORE_API_CALL_FAILURE,
    error,
    totalLength
});
  
export const endStoreApiCall = () => ({
    type: STORE_RESET_SAGA_STATE
});