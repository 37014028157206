import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "./competition.module.sass";
import Card from "../../../../../../components/Card";
import Icon from "../../../../../../components/Icon";
import TextInput from "../../../../../../components/TextInput";
import TextArea from "../../../../../../components/TextArea";
import Radio from "../../../../../../components/Radio";
import ItemDropdown from "../../../../../../components/Dropdown/ItemDropdown"
import CommonDropdown from '../../../../../../components/MultiselectDropDown/CommonDropdown'
import Rewards from './Rewards'
import {ReactComponent as AddIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as EditIcon} from '../../../../../../media/images/icons/editSvg.svg'
import MatchDropdown from '../../../../../../components/Dropdown/LeaderboardMatchesDropdown';
import CurrencyDropdown from "../../../../../../components/Dropdown/CurrencyDropdown";
import PlatformDropdown from "../../../../../../components/Dropdown/PlatformDropdown";
// import Dropdown from "../../../../../../components/BuildComponents/EconomyComponent/CurrencyComponent/Dropdown";
import {addCurrecyAction, getAllCurrencyAction} from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import addIcon from '../../../../../../media/images/icons/light.png'
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../media/images/icons/remove.svg'
import { WithContext as ReactTags } from "react-tag-input";
import removeIcon from '../../../../../../media/images/icons/remove.png'
import Tooltip from "../../../../../../components/Tooltip";
import Toast from '../../../../../../components/Toast'
import Currency from '../../../../../../media/images/placeholders/Currency.png'
import { getAllMatchesAction } from "../../../../../../redux/action/builds/matches/matchesAction";
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
import Checkbox from "../../../../../../components/Checkbox";
import { BASE_URL, GET_CURRENCY, GET_ITEMS, GET_PROGRESSION_SYSTEM } from "../../../../../../resources/APIEndpoints";
import Multiselect from "../../../../../../components/MultiselectDropDown/PricingDropdown";
import { createCompetitionInstantBattleAction, createCompetitionTournamentAction } from "../../../../../../redux/action/engage/Competitions/competitionAction";
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from "../../../../../../components/FileUploadSidebar";
import { getAllMediaAction } from "../../../../../../redux/action/AppSettings/Media/mediaAction";
import customAxios from "../../../../../../utils/interceptor";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../components/MetaInput";
import Dropdown from '../../../../../../components/Dropdown';
import Schedule from './Schedule'
import XLSX from 'xlsx';
import Control from "./Control";
import ControlFileUpload from "./ControlFileUpload";
import AddContentModal from "../../../../../../components/Modals/AddRewardModal";
import Modal from "../../../../../../components/Modals/ModalComponent";
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const currencyTypeOption = ["Select Currency Type"]
const pricingOption = [{id: 1, name : "Virtual"}, {id: 3, name: "RMG"}]
const lockBy = [{id: 1, title : "Item/Bundle"}, {id : 2, title : "Progression System"}]

const CompetitionInformation = ({ className, onClose, isChanged, setIsChanged}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {appid} = useParams()
    const ref = useRef()

    const placeholder = Currency
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [currencyIcon, setCurrencyIcon] = useState(null)
    const [displayName, setDisplayName] = useState('');
    const [competitionId, setComeptitionId] = useState('')
    const [description, setDescriptrion] = useState('')
    const [minPlayers, setMinPlayers] = useState(null)
    const [maxPlayers, setMaxPlayers] = useState(null)
    const [entry, setEntry] = useState(null)
    const [attempt, setAttempt] = useState(null)

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setCurrencyIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setCurrencyIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onDisplayName = (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    }

    const onCompetitionID = (e) => {
        if(e.target.value == ''){
            setComeptitionId('')
        }else{
            errors.idError = ''
            setComeptitionId(e.target.value)
        }
        
    }

    const onCompetitionDescription = (e) => {
        setDescriptrion(e.target.value)
    }

    const onMinPlayers = (e) => {
        if(e == '' || e == null || e == NaN){
            setMinPlayers(null)
        }else{
            errors.minError = ''
            setMinPlayers(parseInt(e))
        }
    }

    const onMaxPlayers = (e) => {
        if(e == '' || e == null || e == NaN){
            setMaxPlayers(null)
        }else{
            errors.maxError = ''
            setMaxPlayers(parseInt(e))
        }
    }

    const onEntry = (e) => {
        if(e == '' || e == null || e == NaN){
            setEntry(null)
        }else{
            errors.entryErr = ''
            setEntry(parseInt(e))
        }
    }

    const onAttempts = (e) => {
        if(e == '' || e == null || e == NaN){
            setAttempt(null)
        }else{
            errors.attemptErr = ''
            setAttempt(parseInt(e))
        }
    }

    // Prize Configure

    const [podiumRanks, setPodiumRanks] = useState([])

    const addRank = () => {
        setPodiumRanks(prevState => [...prevState, {ranks : podiumRanks.length+1, score : null}])
    }

    const onRankChange = (e) => {
        let initialRanks = []
        for(var i = 0; i < parseInt(e) ; i++){
          initialRanks.push({ranks : i+1, score : null})
        }
        setPodiumRanks(initialRanks)
    }

    const onScoreChange = (e, index) => {
      let totalPodiumRanks = [...podiumRanks]
      totalPodiumRanks[index].score = e
      setPodiumRanks(totalPodiumRanks)
    }

    const removePodiumRanks = (index) => {
      let removeField = [...podiumRanks]
      let podiumFields = removeField.filter((e,i,c)=>{
          return i != index
      })
      setPodiumRanks(podiumFields)
    }

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', matchErr : '', minError : '', maxError: '', entryErr : '', attemptErr : '', pdErrorStart : [], pdErrorEnd : []})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
          error.nameError = 'Display Name is required';
          isValid = false;
          const element = document.getElementById('competitionName');
          const offset = 140;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.competitionId == '') {
          error.idError = 'Tournament ID is required';
          isValid = false;
          if(data.name != ''){
            const element = document.getElementById('competitionId');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }
        
        // if (data.minPlayers == null) {
        //     error.minError = 'Minimum Player is required';
        //     isValid = false;
        //     if(data.name != '' && data.competitionId != ''){
        //         const element = document.getElementById('minPlayers');
        //         const offset = 140;
        //         const bodyRect = document.body.getBoundingClientRect().top;
        //         const elementRect = element.getBoundingClientRect().top;
        //         const elementPosition = elementRect - bodyRect;
        //         const offsetPosition = elementPosition - offset;
        //         window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        //     }
        // }

        // if(data.maxPlayers == null) {
        //     error.maxError = 'Maximum Player is required';
        //     isValid = false;
        //     if(data.name != '' && data.competitionId != '' && data.minPlayers != null){
        //         const element = document.getElementById('maxPlayers');
        //         const offset = 140;
        //         const bodyRect = document.body.getBoundingClientRect().top;
        //         const elementRect = element.getBoundingClientRect().top;
        //         const elementPosition = elementRect - bodyRect;
        //         const offsetPosition = elementPosition - offset;
        //         window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        //     }
        // }
        if (data.sourceTypeId == 1 && data.matchId == null) {
            error.matchErr = 'Match is required';
            isValid = false;
            if(data.name != '' && data.competitionId != ''){
                const element = document.getElementById('match');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }


        if(data.prizeDistributionRule){
            error.pdErrorStart = []
            error.pdErrorEnd = []
            for(var i = 0 ; i < data.prizeDistributionRule.length ; i ++){
                if(data.prizeDistributionRule[i].startRank == '' || data.prizeDistributionRule[i].startRank == null){
                    error.pdErrorStart[i] = 'Start Rank is required'
                    isValid = false
                    if(data.name != '' && data.competitionId != '' && data.maxAttemptAllowed != null){
                        if(data.name != '' && data.competitionId != '' && data.maxAttemptAllowed != null){
                            const element = document.getElementById(`PDStart${i}`);
                            const offset = 100;
                            const bodyRect = document.body.getBoundingClientRect().top;
                            const elementRect = element.getBoundingClientRect().top;
                            const elementPosition = elementRect - bodyRect;
                            const offsetPosition = elementPosition - offset;
                            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                        }
                    }
                }else if(i > 0 && (data.prizeDistributionRule[i].startRank <= data.prizeDistributionRule[i-1].endRank)){
                    error.pdErrorStart[i] = 'Start rank must be greater than previous end rank'
                    toast.error(<Toast type='Error' messages='Start rank must be greater than previous end rank.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                    isValid = false
                    if(data.name != '' && data.competitionId != '' && data.maxAttemptAllowed != null){
                        if(data.name != '' && data.competitionId != '' && data.maxAttemptAllowed != null){
                            const element = document.getElementById(`PDStart${i}`);
                            const offset = 100;
                            const bodyRect = document.body.getBoundingClientRect().top;
                            const elementRect = element.getBoundingClientRect().top;
                            const elementPosition = elementRect - bodyRect;
                            const offsetPosition = elementPosition - offset;
                            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                        }
                    }
                }else if(i > 0 && (data.prizeDistributionRule[i].startRank > data.prizeDistributionRule[i].endRank)){
                    error.pdErrorEnd[i] = 'End rank must be equal to or greater that start rank'
                    toast.error(<Toast type='Error' messages='End rank must be equal to or greater that start rank.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                    isValid = false
                    if(data.name != '' && data.competitionId != '' && data.maxAttemptAllowed != null){
                        if(data.name != '' && data.competitionId != '' && data.maxAttemptAllowed != null){
                            const element = document.getElementById(`PDEnd${i}`);
                            const offset = 100;
                            const bodyRect = document.body.getBoundingClientRect().top;
                            const elementRect = element.getBoundingClientRect().top;
                            const elementPosition = elementRect - bodyRect;
                            const offsetPosition = elementPosition - offset;
                            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
                        }
                    }
                }
                else{
                    error.pdErrorStart[i] = ''
                    error.pdErrorEnd[i] = ''
                }
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const [id, setId] = useState(null)

    function removeIconKey(data) {
        data.forEach(entry => {
          const { rewardDetails } = entry;
          if (rewardDetails) {
            ['items', 'currencies', 'bundles', 'progressionMarkers'].forEach(key => {
              if (Array.isArray(rewardDetails[key])) {
                rewardDetails[key].forEach(item => {
                    if(item.hasOwnProperty('quantity')){
                        item.amount = item.quantity
                        // delete item.quantity
                    }
                    if (item.hasOwnProperty('iconUrl')) {
                        if(item.iconUrl&&item.iconUrl.includes('data:image/png;base64')){
                            item.iconUrl = null
                        }
                    }
                    if (item.hasOwnProperty('status')) {
                        delete item.status;
                    }
                });
              }
            });
          }
        });
        return data;
    }

    const db = getFirestore(app);
    const submit = async (visibleModal, setVisibleModal, setId) => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let competitionTags = []
        tags && tags.forEach((e)=>{
            competitionTags.push(e.text)
        })

        let competitionPrices = [...virtualCurrency, ...realMoney, ...IAP]
        const filteredPrice = competitionPrices.filter(item => {
            const price = item.amount;
            return (price !== null && price !== undefined) ;
        });

        const podiumRanksDetail = []
        podiumRanks && podiumRanks.forEach((e, i) => {
          var key_one = e.ranks
          var key_two = e.score
          podiumRanksDetail.push({[key_one]:key_two})
        })

        const updatedData = removeIconKey(ranks)

        let unlockItems = []
        if(unlockItemId){
            unlockItems.push({ unlockItemId : unlockItemId, unlockBundleId: null, unlockLevelSystemId: null})
        }
        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({id: e.dbID, unlockItemId : null, unlockBundleId: null, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })
        let unlockItemData = [...unlockItems, ...unlockPS]

        let competitionData = {
            competitionId: competitionId,
            name: displayName,
            icon : currencyIcon,
            description: description,
            minPlayers: (matchDetail !== null ? matchDetail.minPlayers : null),
            maxPlayers: (matchDetail !== null ? matchDetail.maxPlayers : null),
            maxEntryAllowed: null,
            maxAttemptAllowed: 1,
            gameId: gameId,
            matchId: matchId,
            competitionFormatTypeMasterId: 3,
            projectId: appid,
            isSpecialEvent: true,
            competitionEntryPrices: filteredPrice && filteredPrice.map((e) => {const {amount, currencyName, code, symbol, priceTypes, ...rest} = e; return {price: amount, priceType: priceTypes, ...rest}}),
            active: true,
            archive: false,
            meta: finalMetaData,
            tags: competitionTags,
            sourceTypeId : 1,
            leaderboardOutcomeTypeMasterId : null,
            leaderboardOutcomeDetails : [],
            prizeDistributionRule : (PD && updatedData && updatedData.length > 0 ? updatedData : []),
            isLocked : ((lockItems == false && lockProgression == false) ? false : true),
            rewardUnlockCondition : unlockItemData.length > 0 ? unlockItemData : null,
        }

        if(validation(competitionData)){
            try {
                // Access the response data (e.g., game ID)
                    // const IdFromResponse = res?.data?.data?.id;
                    if(visibleModal){
                        await dispatch(createCompetitionInstantBattleAction(competitionData, navigate, appid, setDisable, visibleModal, setVisibleModal, setId, 'IB'))
                        setIsChanged(false)
                    }else{
                        await dispatch(createCompetitionInstantBattleAction(competitionData, navigate, appid, setDisable))
                        setIsChanged(false)
                    }
                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "INSTANT BATTLE",
                        // resource_id: IdFromResponse, // Use the ID from response
                        description: `Instant Battle '${displayName}' created successfully.`,
                        quantity: 1,
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName,
                        request_json:JSON.stringify(competitionData),
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
                    console.log("Activity logged successfully.");
                
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }
        }
        
    }

    const [disable, setDisable] = useState(false)

    const cancel = () => {
        navigate(`/competition-instant-battle/${appid}`)
        setIsChanged(false)
    }

    const onTagInput = () => {
        if(document.getElementById('currencyTagInput')){
            document.getElementById('currencyTagInput').focus()
        }
    }

    const [matchOptions, setMatchOptions] = useState([])
    const [matchDetail, setMatchDetail] = useState(null)
    const [matchName, setMatchName] = useState()
    const [matchId, setMatchId] = useState(null)
    const [gameId, setGameId] = useState()
    const [matchPage, setMatchPage] = useState(1)
    const [matchLimit, setMatchLimit] = useState(10)

    const [isCalled, setIsCalled] = useState(false)

    const getAllMatch = async() => {
        let match = {
            projectId : appid,
            offset: ((matchPage-1)*matchLimit),
            limit: matchLimit,
            matchFormatTypeMasterIds: [2]
        }
        await dispatch(getAllMatchesAction(match, setIsCalled))
      }
  
      useEffect(()=>{
        getAllMatch()
      }, [matchPage])
  
    let matches = useSelector((state) =>{
        return state.matches.matches
    })

    const configureMatch = () => {
        if(isCalled){
            if(matches && matches.length > 0){
                let allMatches = []
                matches && matches.forEach((e,i) => {
                    allMatches.push(e)
                })
                setMatchOptions(matchOptions => [...matchOptions, ...allMatches])
            }
        }
      }
  
    useEffect(()=>{
        configureMatch()
    }, [matches])

    const [ranks, setRanks] = useState([{startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}}])
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [indexLevel, setIndexLevel] = useState()
    const [type, setType] = useState()
    const [PD, setPD] = useState(false)

    useEffect(()=>{
        setStorage();
    }, [])

    const setStorage = () => {
      sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
    }

    const addRanks = () => {
      const totalRanks = [...ranks]
      totalRanks.push({startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}})
      setRanks(totalRanks)
      sessionStorage.setItem('PDRanks', JSON.stringify(totalRanks))
    }

    const onChangeStartRank = (index, value) => {
      if (/^[a-zA-Z0-9- ]+$/i.test(value) || value == '') {
          const updatedInputData = [...ranks];
          updatedInputData[index].startRank = parseInt(value);
          setRanks(updatedInputData);
          sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
          if(value != null || value != NaN){
            let errorData = errors
            errorData.pdErrorStart[index] = ''
            setErrors(errorData)
          }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
      };
    
    const onChangeRankReward = (e) => {
        let initialRanks = []
        for(var i = 0; i < parseInt(e) ; i++){
          initialRanks.push({startRank : null, endRank : null, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[]}})
        }
        setRanks(initialRanks)
        sessionStorage.setItem('PDRanks', JSON.stringify(initialRanks))
    }
    
    
    const removeRanks = (index) => {
        let removeField = [...ranks]
        let rankFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setRanks(rankFields)
        sessionStorage.setItem('PDRanks', JSON.stringify(rankFields))
    }
    
    const onChangeEndRank = (index, value) => {
        const updatedInputData = [...ranks];
        updatedInputData[index].endRank = parseInt(value);
        setRanks(updatedInputData);
        sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
        if(value != null || value != NaN){
            let errorData = errors
            errorData.pdErrorEnd[index] = ''
            setErrors(errorData)
        }
    }

    const [lockItems, setLockItems] = useState(false)
    const [lockProgression, setLockProgression] = useState(false)

    const onLockBy = (e, title) => {
        if(e.target.checked){
            if(title == 'Item/Bundle'){
                setLockItems(true)
            }else{
                setLockProgression(true)
            }
        }else{
            if(title == 'Item/Bundle'){
                setLockItems(false)
            }else{
                setLockProgression(false)
            }
        }
    }

    // Get Item API Call
    const [itemOptions, setItemOption] = useState()
    const [items, setItems] = useState()
    const getItem = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_ITEMS, items, headers)
        setItemOption(res.data.data.items)
    }

    // Get Progression System API Call
    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])

    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }

    const [unlockItemId, setUnlockItemId] = useState()
    const [unlockSystemId, setUnlockSystemId] = useState()

    const [lockItemErr, setLockItemErr] = useState(false)
    const [lockPSErr, setLockPSErr] = useState(false)

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }

    // Entry Type
    const [pricing, setPricing] = useState([])
    const [showVirtual, setShowVirtual] = useState(false)
    const [showInApp, setShowInApp] = useState(false)
    const [showRMG, setShowRMG] = useState(false)

    const[virtualCurrency, setVirtualCurrency] = useState([])

    const addVirtualCurrency = () => {
        const currencyFields = []
        currencyFields.push(...virtualCurrency, {priceTypes: '', currencyId : null, amount : null , discount : null})
        setVirtualCurrency(currencyFields)
    }

    const removeVirtualCurrency = (index) => {
        let removeField = virtualCurrency
        let currentFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setVirtualCurrency(currentFields)
        if(currentFields.length == 0){
            setPricing(pricing.filter((values) => values.name != 'Virtual'))
            setShowVirtual(false)
        }
    }

    const onVirtualCurrency = (e, index) => {
        let virtualCurrencyList = [...virtualCurrency]
        virtualCurrencyList[index][e.target.name] = parseInt(e.target.value)
        setVirtualCurrency(virtualCurrencyList)
    }

    const[IAP, setIAP] = useState([])

    const addIAP = () => {
        const IAPFields = []
        IAPFields.push(...IAP, {priceTypes: '', realWorldCurrencyId : null, gamePlatformMasterId: null, productId : '', gamePlatformName:'',  amount : null , discount : null})
        setIAP(IAPFields)
    }

    const removeIAP = (index) => {
        let removeField = IAP
        let currentFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setIAP(currentFields)
        if(currentFields.length == 0){
            setPricing(pricing.filter((values) => values.name != 'In-App Purchase'))
            setShowInApp(false)
        }
    }

    const onIAP = (e, index, type) => {
        let IAPList = [...IAP]
        IAPList[index][e.target.name] =  parseInt(e.target.value)
        setIAP(IAPList)
    }

    const[realMoney, setRealMoney] = useState([])

    const addRealMoney = () => {
        const realMoneyFields = []
        realMoneyFields.push(...realMoney, {priceTypes: '', currencyId : null, bonusCashAllowance : null,  amount : null , hosting: false, hostingFeeType: 'percentage', hostingFee : null})
        setRealMoney(realMoneyFields)
    }

    const removeRealMoney = (index) => {
        let removeField = realMoney
        let currentFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setRealMoney(currentFields)
        if(currentFields.length == 0){
            setPricing(pricing.filter((values) => values.name != 'RMG'))
            setShowRMG(false)
        }
    }

    const onRealMoney = (e, index) => {
        let realMoneyList = [...realMoney]
        realMoneyList[index][e.target.name] = parseInt(e.target.value)
        setRealMoney(realMoneyList)
    }

    const onChangeHostingType = (value, type, index) => {
        let realMoneyList = [...realMoney]
        realMoneyList[index].hosting = value
        realMoneyList[index].hostingFeeType = type
        setRealMoney(realMoneyList)
    }

    const [allCurrency, setAllCurrency] = useState([])
    const getAllCurrencyData = () => {
        let getAllCurrency = {
            projectId : appid,
        }
        dispatch(getAllCurrencyAction(getAllCurrency))
    }

    const [virtualCurrencyData, setVirtualCurrencyData] = useState([])
    const [realMoneyData, setRealMoneyData] = useState([])

    const customizeCurrencyData = () => {
        if(currenciesData){
            setAllCurrency(currenciesData && currenciesData.map(value => ({...value, status : false, quantity : null})))
            let vc = currenciesData && currenciesData.filter((e,i) => e.type == 'virtual')
            let vcData = []
            vc && vc.forEach((e,i) =>{
                vcData.push( {name : e.name, id : e.id, type : 'virtual currency'})
            })
            setVirtualCurrencyData(vcData)
            let rm = currenciesData && currenciesData.filter((e,i) => e.type == 'real')
            let rmData = []
            rm && rm.forEach((e,i) =>{
                rmData.push({name : e.name, id : e.id, type : 'RMG'})
            })
            setRealMoneyData(rmData)
        }
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    useEffect(()=>{
        customizeCurrencyData()
    }, [currenciesData])

    useEffect(() => {
        getAllCurrencyData()
        getMetaData()
        getRealWorldCurrency()
        getItem()
        getPS()
    }, [])

    // Get Meta Data API Call for Platform 
    const [platform, setPlatform] = useState([])
    const [platformOptions, setPlatformOptions] = useState([])
    const [leaderboardOutcomeOption, setLeaderboardOutcomeOption] = useState()
    const [leaderboardOutcome, setLeaderboardOutcome] = useState('')
    const [leaderboardOutcomeId, setLeaderboardOutcomeId] = useState(null)
    
    const getMetaData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.forEach((element) => {
            setPlatformOptions(prevState => [...prevState, {id : element.id, name : (element.platformName == 'android' ? 'Android' : 'iOS')}])
        })
        setSourceOption(res.data.data.leaderboardSourceTypeMasterIds && res.data.data.leaderboardSourceTypeMasterIds.map((obj) => {
            if(obj.id !== 2){
                return {
                    id: obj.id,
                    name: (obj.name == 'match' ? 'Match' : obj.name == 'custom' ? 'Custom': ''),
                };
            }else{
                return null
            }
        }).filter(Boolean))
        setLeaderboardOutcomeOption(res.data.data.leaderboardOutcomeIds && res.data.data.leaderboardOutcomeIds.map((obj) => {
            return {
              id: obj.id,
              name: obj.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
            };
        }))
    }

    const [realWorldCurrency, setRealWorldCurrency] = useState([])
    const getRealWorldCurrency = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
        res.data.data && res.data.data.forEach((element) => {
            setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"), type : 'IAP'}])
        })
    }

    const [showSlot, setShowSlot] = useState(false)
    const [slots, setSlots] = useState(null)

    const onChangeSlot = (e) => {
        setSlots(e)
    }

    const [pdValidation, setPDValidation] = useState(false)
    const [visibleFile, setVisibleFile] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
        getAllRewards()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    const setchange = () => {
         
        if (
            (currencyIcon==null||currencyIcon==undefined)&&
            (displayName===''||displayName==="")&&
            (competitionId===''||competitionId==="")&&
            (description===undefined||description==''||description=="")&&
            // (minPlayers==null||minPlayers==undefined||minPlayers=='')&&
            // (maxPlayers==null||maxPlayers==undefined||maxPlayers=='')&&
            (entry==null||entry==undefined||entry=='')&&
            (attempt==null||attempt==undefined||attempt=='')&&
            (matchId==null||matchId==undefined||matchId=='')&&
            (pricing.length==0)&&
            (PD==false)&&
            (pdValidation==false)&&
            // (showSlot==false)&&
            // (slots==null||slots==undefined||slots=='')&&
            //(ranks.length==1&&ranks[0].no==1&&ranks[0].startRank== null&& ranks[0].endRank == null&& ranks[0].rewardDetails.length==0)&&
            // (Category.length==1 && Category[0].catName == "Default Category" && Category[0].catID == "" && Category[0].ID=="" && (Category[0].categoryIcon==""||Category[0].categoryIcon==null) && Category[0].storeContent.filter(item => !item?.archive).length==0)&&
            (lockItems===false||lockItems==undefined)&&
            (lockProgression===false||lockProgression==undefined)&&
            (tags.length==0)&&
            (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }
    useEffect(()=>{
        setchange()
    },[currencyIcon,displayName,competitionId,description,minPlayers,maxPlayers,entry,attempt,matchId,pricing,PD,pdValidation,ranks,tags,metaData,lockProgression,lockItems,items,PS])


    const [sourceOption, setSourceOption] = useState()
    const [source, setSource] = useState()
    const [sourceId, setSourceId] = useState(null)

    const [limit, setLimit] = useState(false)
    
    const onChangeLimit = (e) => {
        if(e.target.checked){
            setLimit(true)
        }else{
            setLimit(false)
            setMaxPlayers(null)
        }
    }

  // CSV Upload
  const [file, setFile] = useState(null)
  const [validate, setValidate] = useState(false)
  const [fileErrors, setFileErrors] = useState({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}});

  const parseRewards = (rewards, type) => {
    if (!rewards) return [];
    let rows = rewards.split(', ').map(reward => {
      const match = reward.match(/(.+)\((\d+)\)/);
      return match ? {name: match[1], id: match[1], quantity: parseInt(match[2]), rewardType: type } : null;
    })
    return rows
  };

  const validateRewards = async(data) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, data, headers)
    if(res.data.data){
      let errorRewards = [...res.data.data.nonExistingBundles, ...res.data.data.nonExistingCurrencies, ...res.data.data.nonExistingItems, ...res.data.data.nonExistingProgressionMarkers]
      if(errorRewards?.length > 0){
        setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: res.data.data.nonExistingBundles.map(bundle => `Bundle with Id ${bundle} does not exist`), currencies: res.data.data.nonExistingCurrencies.map(currency => `Currency with Id ${currency} does not exist`), items: res.data.data.nonExistingItems.map(item => `Item with Id ${item} does not exist`), pm: res.data.data.nonExistingProgressionMarkers.map(pm => `Progression Marker with Id ${pm} does not exist`)}})
        return false
      }else{
        setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
        return true
      }
    }
  }
      
  const transformData = (data) => {
    return data.map((entry, index) => ({
      startRank: entry["Start Rank"],
      endRank: entry["End Rank"],
      rewardDetails: {
        items: parseRewards(entry.Items, 'Items'),
        bundles: parseRewards(entry.Bundles, 'Bundle'),
        currencies: parseRewards(entry.Currencies, 'Currency'),
        progressionMarkers: parseRewards(entry["Progression Markers"], 'PM')
      }
    }));
  };

  const checkRewards = async(data) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }

    data && data.forEach((e, index) => {
      const extractIds = (x) => {
        if(x){
          const items = x.split(", ");
          return items.map(item => item.match(/^([^\(]+)/)[1]);
        }
        return [];
      }
      const addUniqueIds = (ids, key) => {
        ids.forEach(id => {
            if (!rewards.rewardDetails[key].ids.includes(id)) {
              rewards.rewardDetails[key].ids.push(id);
            }
        });
      };

      addUniqueIds(extractIds(e["Items"]), 'items');
      addUniqueIds(extractIds(e["Bundles"]), 'bundles');
      addUniqueIds(extractIds(e["Currencies"]), 'currencies');
      addUniqueIds(extractIds(e["Progression Marker"]), 'progressionMarkers');
    })

    let validateRewardData = await validateRewards(rewards)
    if(validateRewardData){
      return true
    }else{
      return false
    }
  }

  const checkDataType = (data) => {
    const errors = [];
    data.forEach((obj, index) => {
      // Validate Start Rank
      if (!Number.isInteger(obj["Start Rank"]) || obj["Start Rank"] <= 0) {
        errors.push(`Error at row number ${index+2} in Start Rank : Start Rank should be a positive integer.`);
      }
      // Validate End Rank
      if (!Number.isInteger(obj["End Rank"]) || obj["End Rank"] <= 0) {
        errors.push(`Error at row number ${index+2} in End Rank : End Rank should be a positive integer.`);
      }
      // Function to validate amounts in parentheses
      const validateAmounts = (key) => {
        if (obj[key]) {
          const items = obj[key].split(", ");
          for (const item of items) {
            const match = item.match(/\((\d+)\)$/);
            if (!match || parseInt(match[1]) <= 0) {
              errors.push(`Error at row number ${index+2} in ${key} : ${obj[key]} contains invalid amount.`);
            }
          }
        }
      };
      // Validate Items
      validateAmounts("Items");
      // Validate Bundles
      validateAmounts("Bundles");
      // Validate Currencies
      validateAmounts("Currencies");
      // Validate Progression Marker
      validateAmounts("Progression Marker");
    });
    return errors;
  }
  
  const convertToJson = async(data) => {
    if(data.length > 1000){
      let newErrors = { sizeError : '', rowsError : 'Number of rows should be less than 1000', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
      setFileErrors(newErrors)
    }else{
      let errors = checkDataType(data)
      setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError : [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
      if(errors.length === 0){
        let checkRewardData = await checkRewards(data)
        if(checkRewardData){
          setValidate(true)
          let rows = transformData(data)
          setRanks(rows)
          sessionStorage.setItem('PDRanks', JSON.stringify(rows))
        }else{
          setValidate(false)
        }
      }else{
        setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: errors, nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
      }
    }

  }

  const checkErrors = (header) => {
    let newErrors = { sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
    let isError = true;
    if (header[0] !== 'Start Rank') {
      newErrors.startRankErr = `Please change 1st Column name from ${header[0]} should be Start Rank`;
      isError = false
    }
    if (header[1] !== 'End Rank') {
      newErrors.EndRankErr = `Please change 2nd Column name from ${header[1]} should be End Rank`;
      isError = false
    }
    if (header[2] !== 'Items') {
      newErrors.itemErr = `Please change 3rd Column name from ${header[2]} should be Items`;
      isError = false
    }
    if (header[3] !== 'Bundles') {
      newErrors.bundleErr = `Please change 4th Column name from ${header[3]} should be Bundles`;
      isError = false
    }
    if (header[4] !== 'Currencies') {
      newErrors.currenciesErr =  `Please change 5th Column name from ${header[4]} should be Currencies`;
      isError = false
    }
    if (header[5] !== 'Progression Marker') {
      newErrors.pmErr = `Please change 6th Column name from ${header[0]} should be Progression Marker`;
      isError = false
    }

    setFileErrors(newErrors);
    if(isError){
      return true
    }

  }

  const onCSV = async(event) => {
    const file = event.target.files[0];
    setFile(file)
    setFileErrors({ sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
    setValidate(false)
  }

  const onValidation = () => {
    if(file && (file.size/1024).toFixed(2) < 10000){
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        const headers = columnsArray[0];
        if(checkErrors(headers)){
          setFileErrors({sizeError : '', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}})
          const json = XLSX.utils.sheet_to_json(worksheet);
          let xyz = convertToJson(json)
        }
      };  
      reader.readAsArrayBuffer(file);
      setLastEndRank(false)
    }else if(file && (file.size/1024).toFixed(2) > 10000){
      let newErrors = { sizeError : 'File size should be less than 10Mb', rowsError : '', startRankErr: '', EndRankErr: '', itemErr: '', currenciesErr: '', bundleErr: '', pmErr: '', dataTypeError: [], nonExistingRewards: {bundles: [], currencies: [], items: [], pm: []}}
      setFileErrors(newErrors)
    }else{
      toast.error(<Toast type='Error' messages='Please Upload File.'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
    }
  }
  
  const [lastEndRank, setLastEndRank] = useState(false)
  
  const onLastEndRank = (e) => {
    if(e.target.checked){
      setLastEndRank(true)
      const updatedInputData = [...ranks];
      updatedInputData[updatedInputData.length-1].endRank = parseInt(100000);
      setRanks(updatedInputData);
      sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
    }else{
      setLastEndRank(false)
      const updatedInputData = [...ranks];
      updatedInputData[updatedInputData.length-1].endRank = null;
      setRanks(updatedInputData);
      sessionStorage.setItem('PDRanks', JSON.stringify(ranks))
    }
  }


  const [item, setItem] = useState([])
  const [currency, setCurrency] = useState([])
  const [bundle, setBundle] = useState([])
  const [pm, setPM] = useState([])

  const getAllRewards = () => {
      let search = {
          projectId : appid,
          search : ''
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      axios.post(`${BASE_URL}/inventory/search`, search, headers)
      .then(res=>{
          let currencies = res.data.data.currencies.map(i => ({uuid:i.id, id : i.currencyId, description: i.description,  iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), name : i.name, status : false, rewardType: 'Currency', type:i.type}));
          let items = res.data.data.inventoryItems.map(i => ({uuid:i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.itemId, description: i.description,  name : i.name, status : false, rewardType: 'Items'}));
          let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
          let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
          setItem(items)
          setCurrency(currencies)
          setBundle(bundles)
          setPM(pm)
      })
  }

  

    return (
        <>
            {/* <ToastContainer /> */}
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-green">
                <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => {setVisibleFile(true)}}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        <div className={styles.previewCancel}>
                            {currencyIcon != null &&
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            }
                        </div>
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleFile} setVisible={setVisibleFile} icon={currencyIcon} setIcon={setCurrencyIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput tooltip={TooltipTitle.IBName} id='competitionName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required onChange={(e)=>{onDisplayName(e)}}/>
                        <TextInput isId={true} value={competitionId} tooltip={TooltipTitle.IBID} id='competitionId' className={styles.field} errorMessage={errors.idError} label="Instant Battle ID*" name="competitionId" type="text" required onChange={(e)=>{onCompetitionID(e)}}/>
                    </div>
                    <TextArea tooltip={TooltipTitle.IBDescription} value={description} className={styles.field} rows={5} label="Description" onChange={(e)=>{onCompetitionDescription(e)}}/>
                    {/* <div className={styles.group}>
                        <TextInput tooltip={TooltipTitle.IBMin} id='minPlayers' className={styles.field} errorMessage={errors.minError} label="Minimum Player*" name="minPlayers" type="number" min={1} required onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key)&&e.preventDefault()} max={maxPlayers}  onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onMinPlayers(e.target.value)}}/>
                        {!limit &&<TextInput tooltip={TooltipTitle.IBMax} id='maxPlayers' className={styles.field} errorMessage={errors.maxError} label="Maximum Player*" name="maxPlayers" type="number" min={1} required onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key)&&e.preventDefault()} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onMaxPlayers(e.target.value)}}/>}
                        {limit && <><div id='maxPlayers' className={styles.fieldDummy}><div><div className={styles.label}>Maximum Player*</div><div className={styles.sign}><div className={styles.infinity}>&infin;</div></div></div></div></>}
                    </div> */}
                    {/* <div className={styles.checkboxGroup}>
                        <div className={styles.field}></div>
                        <div className={styles.field}>
                            <Checkbox size='small' content='No Limit' onChange={(e) => {onChangeLimit(e)}} value={limit}/>
                        </div>
                    </div> */}
                    {/* <div className={styles.group}>
                        <div className={styles.field}>
                            <Dropdown tooltip={TooltipTitle.TournamentMatch} id='source' label='Select Source*' errorMessage={errors.matchErr} placeholder='Choose Source' type="source" value={source} setValue={setSource} setId={setSourceId} options={sourceOption} />
                        </div>
                        <div className={styles.field}>
                            <Dropdown id='ranking' label='Leaderboard Ranking Method' placeholder='Choose Leaderboard Ranking Method' value={leaderboardOutcome} setValue={setLeaderboardOutcome} setId={setLeaderboardOutcomeId} options={leaderboardOutcomeOption}/>
                        </div> 
                    </div> */}
                    <div className={styles.group}>
                        <div className={styles.field}>
                            <MatchDropdown page={matchPage} setPage={setMatchPage} limit={matchLimit} tooltip={TooltipTitle.IBMatch} id='match' label='Select Match*' errorMessage={errors.matchErr} placeholder='Choose Match' type="match" value={matchName} setValue={setMatchName} setId={setMatchId} setOtherId={setGameId} setObj={setMatchDetail} options={matchOptions} />
                        </div> 
                    </div>
                    {matchDetail !== null &&
                        <>
                            <div className={styles.group}>
                                <TextInput tooltip={TooltipTitle.IBMin} value={matchDetail.minPlayers} className={styles.field} errorMessage={errors.minError} label="Minimum Player*" name="minPlayers" type="number" min={1} disabled/>
                                <TextInput tooltip={TooltipTitle.IBMax} value={matchDetail.maxPlayers} className={styles.field} errorMessage={errors.maxError} label="Maximum Player*" name="maxPlayers" type="number" min={1} disabled/>
                            </div>
                            <div className={styles.group}>
                                <TextInput tooltip={TooltipTitle.IBMin} id='winningCondition' value={matchDetail.matchWinningConditionTypeMasterId === 1 ? "Higher Wins" : matchDetail.matchWinningConditionTypeMasterId === 2 ? "Lower Wins" : ''} className={styles.field} errorMessage={errors.winningError} label="Match Winning Condition" name="winningCondition" type="text" disabled/>
                            </div>
                        </>
                    }
                    
                    {/* {leaderboardOutcomeId == 4 &&
                        <>
                        <div className={styles.group}>
                            <div className={styles.podiumSystem}>
                                <div className={styles.podiumGroup}>
                                    <div className={styles.rankLabel}>
                                        <span className={styles.podiumLabelTxt}>No of Podium Ranks</span><span onClick={addRank} className={styles.podiumLabelImg}><AddIcon /></span>
                                    </div>
                                    <div>
                                        <input type='number' onWheelCapture={e => {e.currentTarget.blur()}}  className={styles.inputField} value={podiumRanks.length > 0 ? podiumRanks.length : ''} onChange={(e)=>{onRankChange(e.target.value)}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {podiumRanks && podiumRanks.length > 0 && (
                            <>
                            <div className={styles.groupRanking}>
                                <div className={styles.fieldBlank}></div>
                                <div className={styles.fieldRank}>Starting Rank</div>
                                <div className={styles.field}>Score</div>
                            </div>

                            {podiumRanks && podiumRanks.map((e,i) => (
                                <>
                                <div className={styles.groupRanking} key={i}>
                                    <div className={styles.fieldBlank}></div>
                                    <div className={styles.fieldRank}>{i+1}</div>
                                    <div className={styles.field}><TextInput key={i} type='number' onWheelCapture={e => {e.currentTarget.blur()}}  value={e.score} onChange={(e)=>{onScoreChange(parseInt(e.target.value), i)}} /></div>  
                                    <div className={styles.fieldRemove}><span onClick={()=>{removePodiumRanks(i)}}><Remove /></span></div>
                                </div>
                                </>
                            ))}
                            </>
                        )}      
                    </>
                    } */}
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Entry Configuration" classTitle="title-green">
                <div className={styles.description}>
                    {/* <div className={styles.group}>
                        <TextInput tooltip={TooltipTitle.IBEntry} id='entries' className={styles.field} errorMessage={errors.entryErr} label="Number of Entries*" name="entries" type="number" min={1} required onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onEntry(e.target.value)}}/>
                        <TextInput tooltip={TooltipTitle.IBAttempt} id='attempts' className={styles.field} errorMessage={errors.attemptErr} label="Number of Attempt*" name="attempt" type="number" min={1} required onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onAttempts(e.target.value)}}/>
                    </div> */}

                    {/* <div className={styles.group}>
                        <div className={styles.fieldRadio}>
                            <div className={styles.radioLabel}>
                                <span>Number of slots<span><Tooltip className={styles.tooltip} title={TooltipTitle.IBSlots} icon="info"/></span></span>
                            </div>
                            <div className={styles.variants}>
                                <Radio className={styles.radio} name="slots" content="Unlimited" value={!showSlot} onChange= {() => {setShowSlot(false)}}/>
                                <Radio className={styles.radio} name="slots" content="Custom" value={showSlot} onChange= {() => {setShowSlot(true)}}/>
                            </div>
                        </div>
                        {showSlot == true &&
                            <TextInput tooltip={TooltipTitle.IBSlots} className={styles.field} label="Specify Number of slots" name="slots" type="text" required onChange={(e)=>{onChangeSlot(parseInt(e.target.value))}}/>
                        }
                    </div> */}

                    <div className={styles.pricing}>
                        <div className={styles.labelHeading}>
                            <span>Choose Pricing Options<span><Tooltip className={styles.tooltip} title={TooltipTitle.IBPricing} icon="info"/></span></span>
                        </div>
                        <div className={styles.selectInput}>
                            <Multiselect as='competition' type='add' placeholder='Choose Pricing Options' options={pricingOption} value={pricing} setValue={setPricing} setShowVirtual={setShowVirtual} setShowRMG={setShowRMG} setShowInApp={setShowInApp} setVirtualCurrency={setVirtualCurrency} setIAP={setIAP} setRealMoney={setRealMoney}/>
                        </div>
                    </div>
                    
                    <div className={styles.competitionEntryRule}>
                        {showVirtual &&
                            <>
                                <div className={styles.groupHeading}>
                                    <span>Virtual</span><span className={styles.addIcon} onClick={addVirtualCurrency}><AddMetaIcon /></span>
                                </div>

                                    <div className={styles.groupByVirtualCurrency}>
                                        {virtualCurrency && virtualCurrency.map((e,index)=>{
                                            return(
                                                <>
                                                    <div className={styles.field}>
                                                        <CurrencyDropdown as='item' type="virtual currency" label="Select Currency" options={virtualCurrencyData} value={e.currencyName} data={virtualCurrency} setData={setVirtualCurrency} index={index}/>
                                                    </div> 
                                                    <TextInput className={styles.field} label="Entry Amount" name="amount" type="text" required  onChange={(e)=>{onVirtualCurrency(e, index)}}/>
                                                    <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}}  required onChange={(e)=>{onVirtualCurrency(e, index)}}/>
                                                    <span className={styles.remove} onClick={()=>{removeVirtualCurrency(index)}}><img className={styles.icon} src={removeIcon} alt="remove" /></span>
                                                </>
                                            )
                                        })}
                                    </div>
                            </>
                        }
                    </div>

                    <div className={styles.competitionEntryRule}>
                    {showInApp &&
                        <>
                            <div className={styles.groupHeading}>
                                <span>In-App Purchase</span><span className={styles.addIcon} onClick={addIAP}><AddMetaIcon /></span>
                            </div>
                            <div className={styles.groupByIAP}>
                                {IAP && IAP.map((e,index)=>{
                                    return(
                                        <>
                                            <div className={styles.field}>
                                                <CurrencyDropdown as='item' type="IAP" label="Select Currency" options={realWorldCurrency} value={e.currencyName} data={IAP} setData={setIAP} index={index}/>
                                            </div>
                                            <div className={styles.field}>
                                                <PlatformDropdown label="Select Platform" options={platformOptions} value={e.gamePlatformName} data={IAP} setData={setIAP} index={index}/>
                                            </div>
                                            <TextInput className={styles.field} label="Entry Amount" name="amount" type="text" required onChange={(e)=>{onIAP(e, index)}}/>
                                            <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onIAP(e, index)}}/>
                                            <span className={styles.remove} onClick={()=>{removeIAP(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>
                                        </>
                                    )
                                })}
                            </div>
                        </>
                    }
                    </div>

                    <div className={styles.competitionEntryRule}>
                    {showRMG && 
                        <>
                            <div className={styles.groupHeading}>
                                <span>RMG</span><span className={styles.addIcon} onClick={addRealMoney}><AddMetaIcon /></span>
                            </div>

                                {realMoney && realMoney.map((e, index) => {
                                    return (
                                        <>
                                            <div>
                                                <div className={styles.groupByVirtualCurrency}>
                                                    <div className={styles.field}>
                                                        <CurrencyDropdown as='item' type="RMG" label="Select Currency" options={realMoneyData} value={e.currencyName} data={realMoney} setData={setRealMoney} index={index}/>
                                                    </div>
                                                    <TextInput className={styles.field} label="Entry Amount" name="amount" type="text" required onChange={(e)=>{onRealMoney(e, index, "amount")}} />
                                                    <TextInput className={styles.field} label="Bonus Cash Allowance" name="bonusCashAllowance" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onRealMoney(e, index)}} />
                                                    <span className={styles.remove} onClick={()=>{removeRealMoney(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>
                                                </div>
                                                <div className={styles.hosting}>
                                                    <div className={styles.groupByVirtualCurrency}>
                                                        <div className={styles.fieldRadio}>
                                                            <div className={styles.radioLabel}>
                                                                <span>Hosting Fee Per User<span><Tooltip className={styles.tooltip} title={TooltipTitle.IBHosting} icon="info"/></span></span>
                                                            </div>
                                                            <div className={styles.variants}>
                                                                <Radio className={styles.radio} key={index} name={`hostingFeeType${index}`} value={!e.hosting} content="Percentage" onChange={() => {onChangeHostingType(false, 'percentag', index)}}/>
                                                                <Radio className={styles.radio} key={index} name={`hostingFeeType${index}`} value={e.hosting} content="Flat" onChange={() => {onChangeHostingType(true, 'flat', index)}}/>
                                                            </div>
                                                        </div>
                                                        <TextInput className={styles.field} label="Value for Hosting Fee Per User" name="hostingFee" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e)=>{onRealMoney(e, index)}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                        </>
                    }
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Prize Configure" classTitle="title-green">
                <div className={styles.description}>
                    
                    <div className={styles.createPD}>
                            <div className={styles.group} style={{margin:"0 0 20px 0"}}>
                              <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Do you want to configure Prize Distribution for this Instant-Battle?</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="PD" value={PD} onChange={() => setPD(true)} content="Yes" />
                                    <Radio className={styles.radio} name="PD" value={!PD} onChange={() => setPD(false)} content="No" />
                                </div>
                              </div>
                            </div>
                            {PD&&<>
                                    <div className={styles.group}>
                                    <div className={styles.fieldRadio}>
                                        <div className={styles.radioLabel}>
                                            <span>Prize Distribution Validation<span><Tooltip className={styles.tooltip} title={TooltipTitle.IBPD} icon="info"/></span></span>
                                        </div>
                                        <div className={styles.variants}>
                                            <Radio className={styles.radio} value={!pdValidation} onChange={()=>{setPDValidation(false)}} name="PDValidation" content="Non RMG" />
                                            <Radio status={true} className={styles.radio} value={pdValidation} onChange={()=>{setPDValidation(true)}} name="PDValidation" content="RMG" />
                                        </div>
                                    </div>
                                    </div>
                                    {PD &&
                                    <>
                                        <div className={styles.group}>
                                            <div className={styles.pdSystem}>
                                                <div className={styles.pdSystemGroup}>
                                                    <div className={styles.pdLabel}>
                                                        <span className={styles.pdLabelTxt}>Ranks</span><span className={styles.pdLabelImg} onClick={addRanks}><AddIcon /></span>
                                                    </div>
                                                    <div className={styles.pdLabel}>
                                                        <input className={styles.inputField} type='number' onWheelCapture={e => {e.currentTarget.blur()}} value={ranks.length > 0 ? ranks.length : ''} onChange={(e)=>{onChangeRankReward(e.target.value)}}/>
                                                    </div>
                                                    <ControlFileUpload valueOne={file} setValueOne={setFile} error={fileErrors} validateOne={validate} onCSV={onCSV} onValidation={onValidation} count={1} placeHolderOne={`Click or drop PD CSV`} multiple={false} accept={`.xlsx, .xls, .csv`} labelOne='Prize Distribution' usedFor='Competition' />  
                                                    <Control data={ranks} value={ranks} setValue={setRanks}/>
                                                </div>
                                            </div>
                                        </div>
                                        {ranks && ranks.length > 0 ?
                                        <div className={styles.groupByThree}>
                                            <p></p>
                                            <p className={styles.fieldTitle}>Rank Start</p>
                                            <p className={styles.fieldTitle}>Rank End</p>
                                            <p className={styles.fieldTitle}>Rewards</p>
                                        </div>
                                        : ''} 
                                    
                                        {ranks && ranks.map((e,i)=>{
                                            return(
                                                <>
                                                    <div key={i} className={styles.groupByThree}>
                                                        <p className={styles.index}></p>
                                                        <TextInput id={`PDStart${i}`} className={styles.field} errorMessage={errors.pdErrorStart[i]} type="number" key={i} min={1} value={e.startRank} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=> onChangeStartRank(i, e.target.value) }/>
                                                        {!lastEndRank && <TextInput id={`PDEnd${i}`} className={styles.field} errorMessage={errors.pdErrorEnd[i]} type="number" key={i} value={e.endRank} min={1} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e) => onChangeEndRank(i, e.target.value)} onKeyDown={handleKeyDown}/>}
                                                        {lastEndRank && i < ranks?.length - 1 && <TextInput id={`PDEnd${i}`} className={styles.field} errorMessage={errors.pdErrorEnd[i]} type="number" key={i} value={e.endRank} min={1} onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e) => onChangeEndRank(i, e.target.value)} onKeyDown={handleKeyDown}/>}
                                                        {lastEndRank && i + 1 === ranks?.length && <><div className={styles.fieldDummy}><div className={styles.sign}>&infin;</div></div></>}
                                                        <p className={styles.field}>
                                                            {ranks && ((ranks[i].rewardDetails?.currencies?.length + ranks[i].rewardDetails?.items?.length + ranks[i].rewardDetails?.bundles?.length + ranks[i].rewardDetails?.progressionMarkers?.length) === 0) ? 
                                                                <>
                                                                <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('add')}}><AddIcon className={styles.icon}/></span> 
                                                                </>
                                                            :
                                                                <div className={styles.levelRewards}>
                                                                    <div className={styles.reward}>
                                                                    {ranks && ((ranks[i].rewardDetails?.currencies?.length + ranks[i].rewardDetails?.items?.length + ranks[i].rewardDetails?.bundles?.length + ranks[i].rewardDetails?.progressionMarkers?.length) > 0) ? 
                                                                            (ranks[i].rewardDetails && [...ranks[i].rewardDetails?.currencies, ...ranks[i].rewardDetails?.items, ...ranks[i].rewardDetails?.bundles, ...ranks[i].rewardDetails?.progressionMarkers].filter(x => !x.archive).slice(0,2).map((x,index) => 
                                                                                <span><span>{index > 0 && ', '}</span><span>{x.name}</span><span>&nbsp;</span><span>({x.quantity})</span></span>
                                                                            )) 
                                                                        : ''}
                                                                    </div>
                                                                    <div className={styles.editIcon} key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('edit')}}><EditIcon className={styles.icon}/></div>
                                                                </div>
                                                            }
                                                        </p>  
                                                        <p className={styles.remove}><span className={styles.removeIcon} onClick={()=>{removeRanks(i)}}><Remove /></span></p>
                                                    </div>
                                                </>
                                            )
                                        })}

                                        {ranks && ranks.length > 0 ?
                                            <div className={styles.groupByThree}>
                                                <p></p>
                                                <p className={styles.fieldTitle}></p>
                                                <p className={styles.fieldTitle}><Checkbox size='small' content='To Last Participant' value={lastEndRank} onChange={(e) => {onLastEndRank(e)}}/></p>
                                                <p className={styles.fieldTitle}></p>
                                            </div>
                                        : ''} 
                                        
                                        <div>
                                            {/* <Rewards type={type} level={indexLevel} visible={visibleHelp} setVisible={setVisibleHelp} onClose={onClose} setPSLevels={setRanks}/> */}
                                            <Modal heading={true} width={'medium'} visible={visibleHelp} onClose={() => setVisibleHelp(false)}>
                                                <AddContentModal amountKey="quantity" categoryIndex={indexLevel} onClose={() => setVisibleHelp(false)} type={"Task"} currency={currency} setCurrency={setCurrency} item={item} setItem={setItem} bundles={bundle} setBundle={setBundle} PM={pm} setPM={setPM} value={ranks} setValue={setRanks} />
                                            </Modal>
                                        </div>
                                    </>
                                }
                            </>  
                            }
                        
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.description}>
                    <div className={styles.createPD}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by<span><Tooltip className={styles.tooltip} title={TooltipTitle.IBLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    {lockBy.map((x, index) => (
                                        <Checkbox key={x.id} className={styles.checkbox} content={x.title} onChange={(e)=>{onLockBy(e, x.title)}} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.createPD}>
                        <div className={styles.group}>
                            {lockProgression ?
                                <>
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentPS} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                </>
                            : ''}
                            {lockItems ?
                                <div className={styles.lockGroup}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Item/Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.TournamentItemBundle} icon="info"/></span></span>
                                    </div>
                                    <ItemDropdown className={styles.selectField} groupBy={1} options={itemOptions} value={items} setValue={setItems} setUnlockItemId={setUnlockItemId} error={setLockItemErr}/>
                                    {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                </div>
                            : ''}
                        </div>
                    </div>
                    {lockProgression &&
                        <div className={styles.createItem} >
                            <div className={styles.group}>
                                {PS && PS.map((element, index) =>{
                                    return(
                                        <>  
                                            <div style={{marginTop:"8px"}} className={styles.field}>
                                                <ProgressionLevelMultiSelect placeholder='Select Level' label={element.name}  options={element.levelSystemLevelMapping} ind={index} value={PS} setValue={setPS}/>                                     
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    }

                    {/* <div className={styles.createPD}>
                        <div className={styles.group}>
                            {lockProgression ?
                                <>
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.IBPS} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                </>
                            : ''}
                            {lockItems ?
                                <div className={styles.lockGroup}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Item/Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.IBItemBundle} icon="info"/></span></span>
                                    </div>
                                    <ItemDropdown className={styles.selectField} groupBy={1} options={itemOptions} value={items} setValue={setItems} setUnlockItemId={setUnlockItemId} error={setLockItemErr}/>
                                    {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                </div>
                            : ''}
                        </div>
                    </div> */}
                    {/* {lockProgression &&
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            {PS && PS.map((element, index) =>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label={`${element.name}`} currency='Level' style='grey' type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e) => {onChangeLevel(e.target.value, index)}}/>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    } */}
                </div>

            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.IBTags} tags={tags} setTags={setTags}/>

                    <div className={styles.createCurrency}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.IBMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>
         
                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                    return(
                                        <>
                                            <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''}   onChange={(e)=>{onChangeKey(e,i)}}/>
                                            <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                            {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                            <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon} /></span>
                                        </>
                                    )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={() => {submit(false, null , null)}}>Save</button>
                    </div>
                    <div className={styles.cardSave}>
                        <Schedule id={id} setId={setId} as={`saveNschedule`} type='Instant Battle' saveCompetition={submit} name={displayName}/>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default CompetitionInformation