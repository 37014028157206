import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import Modal from "../../../../../Modals/ModalComponent";
import DeleteMarkerModal from "../../../../../Modals/Build/Progression/DeleteModal";
import ArchiveMarkerModal from "../../../../../Modals/Build/Progression/ArchiveModal";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import EyeIcon from '../../../../../../media/images/icons/eye.png'
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const Control = ({className, access, id, appid, archiveStatus, deleteStatus, markerName}) => {

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)

  const actions = [
    {
      pathName : `/view-custom-events/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    {
      icon: "edit",
      pathName : `/edit-custom-events/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    
    {
      icon: "download",
      action: () => setVisibleArchiveModal(true),
    },
    {
      icon: "trash",
      action:  () => setVisibleModal(true),
    }
  ];
  const db = getFirestore(app);
  const onArchiveActivity = async(type)=>{
    const user = JSON.parse(localStorage.getItem('user'))
    const memberID = user?.id
    const memberName = user?.name
    const orgId = (localStorage.getItem('orgId'))

    // Log activity in Firestore
    const activityLog = {
        timestamp: new Date().toISOString(),
        action_type: "ARCHIVE",
        app_id:appid,
        resource: type?.toUpperCase(),
        resource_id: id, // Use the ID from response
        description: `${type} '${markerName}' archived successfully.`,
        quantity: 1,
operation_status: "SUCCESS",
        activity_feed: true,
        priority: "LOW",
        performed_by_id: memberID,
        organization_id: orgId,
        performed_by:memberName
    };
    await addDoc(collection(db, "activityFeed"), activityLog);
    console.log("Activity logged successfully.");
  }

  return (
    <>
      <div className={cn(styles.control, className)}>
      {access ? 
          (actions && actions.slice(0, 1).map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, markerName : markerName, archiveStatus : archiveStatus}} className={styles.button} key={index} onClick={x.action}>
                <img src={EyeIcon} width={20} height={20} className={styles.icon} />
              </Link>
            )
          }))
        : 
          (actions && actions.slice(1).map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, markerName : markerName, archiveStatus : archiveStatus}} className={styles.button} key={index} onClick={x.action}>
                {x.icon == 'download' ?  <ArchiveSvg /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
              </Link>
            )
          }))
        }  
        {/* {actions.map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, markerName : markerName, archiveStatus : archiveStatus}} className={styles.button} key={index} onClick={x.action}>
                {x.icon == 'download' ?  <ArchiveSvg /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
              </Link>
            )
        })} */}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <DeleteMarkerModal afterArchive={()=>onArchiveActivity("Custom Event")} onClose={() => setVisibleModal(false)}/>
      </Modal>
      <Modal visible={visibleArchiveModal} onClose={() => setVisibleArchiveModal(false)}>
        <ArchiveMarkerModal onClose={() => setVisibleArchiveModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
