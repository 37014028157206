import React from "react";
import cn from "classnames";
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import styles from './archive.module.sass'

const ToggleArchive = ({className, archive, setArchive}) => {
    return(
        <>
            <div className={cn(styles.buttons, {[styles.active] : archive})} onClick={() => setArchive(!archive)}>
                <ArchiveSvg className={styles.icon} />
                <span className={styles.buttonText}>Show Archived</span>
            </div>
        </>
    )
}

export default ToggleArchive