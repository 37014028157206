import React from "react";
import styles from './loader.module.sass'

const Loader = () => {
    return(
        <>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.loader}>
                        <div className={styles.balls}></div>
                        <div className={styles.balls}></div>
                        <div className={styles.balls}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader