import { GET_DOCUMENTS_SUCCESS, GET_DOCUMENTS_ERROR, CREATE_DOCUMENT, EDIT_DOCUMENT, FILTER_CURRENCY_SUCCESS, FILTER_CURRENCY_ERROR, GET_DOCUMENTS } from "../../../actionTypes/actionTypes";

const initialState = {
    documents: [],
    isLoading: false,
    isFiltered: false,
    error : null,
    totalCount : null
};

export const documentReducer = (state=initialState, action) => {
    switch(action.type){

        case GET_DOCUMENTS : {
            return{
                ...state,
                isLoading: true
            }
        }

        case GET_DOCUMENTS_SUCCESS : {
            return {
                ...state,
                isLoading: false,
                isFiltered: false,
                documents: action.payload,
                totalCount: action.totalCount,
                error: null
            }
        }

        case GET_DOCUMENTS_ERROR : {
            return {
                ...state,
                isLoading: false,
                error : action.payload
            }
        }

        case FILTER_CURRENCY_SUCCESS : {
            return {
                ...state,
                documents : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_CURRENCY_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case CREATE_DOCUMENT : {
            return {
                ...state,
                documents: [...state.documents, action.payload],
                error: null
            }
        }

        case EDIT_DOCUMENT : {
            return{
                ...state,
                documents: [...state.documents, action.payload],
                error: null
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}    