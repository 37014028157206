import React, { useState, useEffect } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Skeleton from '@mui/material/Skeleton';

const SkeletonTable = ({usedFor, rows, column, controls, type, colWidth, colHeight}) => {

  const arrayToMap = Array.from({ length: 10 }, (_, index) => index)

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          {Array.from({length : column}, (_, x) => {
            if(x < (column-1)){
              return(
                <div className={styles.col}><Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={60} height={20}/></div>
              )
            }
          })}
        </div>
        
        {arrayToMap && arrayToMap.map((e, index) => {
          return(
            <>
              <div className={styles.rowContent}>
                {Array.from({length : column}, (_, i) => {
                  if(type[i] == 'circular'){
                    return(
                      <>
                        <div className={styles.colContent}>
                          <div className={styles.control}>
                            {Array.from({length : controls}, (_, x) => {
                              return(
                                  <>
                                    <Skeleton sx={{ bgcolor: 'grey.200' }} animation="wave" variant='circular' width={colWidth[i]} height={colHeight[i]}/>
                                  </>
                              )
                            })}
                          </div>
                        </div>
                      </>
                    )
                  }else{
                    return(
                      <>
                        <div className={styles.col}>
                          <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px' }} animation="wave" variant='rectangular' width={colWidth[i]} height={colHeight[i]}/>
                          {i == 1 && <div className={styles.id}><Skeleton sx={{ bgcolor: 'grey.200' }} animation="wave" variant='reactangular' width={30} height={10}/></div>}
                        </div>
                      </>
                    )
                  }
                })}
              </div>
            </>
          )
        })}

      </div>
    </div>
  );
};

export default SkeletonTable;