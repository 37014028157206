import React from "react";
import styles from './billingCycle.module.sass'
import Card from "../../../../components/Card";
import cn from 'classnames'
import Icon from "../../../../components/Icon";

const BillingCycle = ({items, className}) => {


    return(
        <>
            <Card className={styles.card} classCardHead={styles.head} title={'Billing Cycle'} classTitle={cn("title-red", styles.title)}>
                <div className={styles.container}>
                    <div className={styles.billingContainer}>
                        <div className={styles.payment}>
                            <div className={styles.paymentDetail}>
                                <div className={styles.detail}>
                                    <div className={styles.title}>Next Payment</div>
                                    <div className={styles.amountDetail}>
                                        {items.amount && items.amount !== null && items.amount !== '' &&
                                            <>
                                                <span className={styles.symbol}>{items.currencyCode === 'INR' ? <>&#8377;</> : items.currencyCode === 'USD' ? <>&#36;</> : null}</span><span className={styles.amount}>{Math.floor(items.amount)}</span><span className={styles.price}>{(items.amount - Math.floor(items.amount)) > 0 ? '.' + ((items.amount - Math.floor(items.amount)) * 100).toFixed(0) : ''}</span>
                                                <div className={styles.secondary}>*Excluding Taxes</div>
                                            </>
                                        }
                                    </div>
                                    <div className={styles.date}>{items.nextBillingAt}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default BillingCycle