import { GET_PLANS, GET_PLANS_SUCCESS, GET_PLANS_ERROR, GET_INVOICE, GET_INVOICE_SUCCESS, GET_INVOICE_ERROR, GET_ADD_ONS, GET_ADD_ONS_SUCCESS, GET_ADD_ONS_ERROR, BUY_ADD_ONS, BUY_ADD_ONS_SUCCESS, BUY_ADD_ONS_ERROR, BUY_NEW_PLAN, BUY_NEW_PLAN_SUCCESS, BUY_NEW_PLAN_ERROR, VERIFY_BILLING_SUCCESS, VERIFY_BILLING_ERROR, GET_MY_PLAN, GET_MY_PLAN_SUCCESS, GET_MY_PLAN_ERROR, GET_MY_PAYMENT_SUCCESS, GET_MY_PAYMENT_ERROR, GET_MY_PAYMENT, GET_PRICE_BOOK_SUCCESS, GET_PRICE_BOOK_ERROR } from "../../../actionTypes/actionTypes";

const initialState = {
    plans: [],
    invoices: [],
    addOns: [],
    billingDetails: [],
    myPlan: [],
    payment: [],
    price: [],
    isLoadingPlans : false,
    isLoadingAddOns : false,
    isLoadingInvocies : false,
    isLoadingPayments : false,
    error: []
};

export const billingReducer = (state = initialState, action )=>{
    switch(action.type){
        
        case GET_PLANS : {
            return {
                ...state,
                isLoadingPlans: true
            }
        }

        case GET_PLANS_SUCCESS : {
            return {
                ...state,
                plans: action.payload,
                isLoadingPlans: false
            }
        }

        case GET_PLANS_ERROR : {
            return {
                ...state,
                isLoadingPlans: false,
                error: action.payload
            }
        }

        case GET_ADD_ONS : {
            return {
                ...state,
                isLoadingAddOns: true
            }
        }

        case GET_ADD_ONS_SUCCESS : {
            return {
                ...state,
                addOns: action.payload,
                isLoadingAddOns: false
            }
        }

        case GET_ADD_ONS_ERROR : {
            return {
                ...state,
                isLoadingAddOns: false,
                error: action.payload
            }
        }

        case VERIFY_BILLING_SUCCESS : {
            return {
                ...state,
                billingDetails: action.payload
            }
        }

        case VERIFY_BILLING_ERROR : {
            return {
                ...state,
                error: action.payload,
                billingDetails: []
            }
        }

        case GET_MY_PLAN : {
            return {
                ...state,
                isLoadingPlans: true
            }
        }

        case GET_MY_PLAN_SUCCESS : {
            return {
                ...state,
                myPlan: action.payload,
                isLoadingPlans: false
            }
        }

        case GET_MY_PLAN_ERROR : {
            return {
                ...state,
                myPlan: [],
                error: action.payload,
                isLoadingPlans: false
            }
        }

        case GET_INVOICE : {
            return {
                ...state,
                isLoadingInvocies: true
            }
        }

        case GET_INVOICE_SUCCESS : {
            return {
                ...state,
                invoices: action.payload,
                isLoadingInvocies: false
            }
        }

        case GET_INVOICE_ERROR : {
            return {
                ...state,
                isLoadingInvocies: false,
                error: action.payload,
                invoices: []
            }
        }

        case GET_MY_PAYMENT  : {
            return {
                ...state,
                isLoadingPayments: true
            }
        }

        case GET_MY_PAYMENT_SUCCESS  : {
            return {
                ...state,
                isLoadingPayments: false,
                payment: action.payload
            }
        }

        case GET_MY_PAYMENT_ERROR  : {
            return {
                ...state,
                isLoadingPayments: false,
                error: action.payload,
                payment: []
            }
        }

        case GET_PRICE_BOOK_SUCCESS : {
            return {
                ...state,
                price: action.payload
            }
        }

        case GET_PRICE_BOOK_ERROR : {
            return {
                ...state,
                error: action.payload,
                price: []
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   