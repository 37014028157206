import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../../../Icon";
import Icon from "../../../../../../Icon";
import Control from "./Control";
import { useParams } from "react-router-dom";
import {ReactComponent as Add} from '../../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../../media/images/icons/remove.svg'
import Currency from '../../../../../../../media/images/placeholders/Currency.png'

/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({usedFor, tableFor, item, items, index, setValue, setData }) => {

    const {appid} = useParams()

    const addContent = (item, index) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, currencyId : item.id, quantity : null, displayId : item.currencyId}
                content.push(newContent)
                setValue(content)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(content))
            }else{
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('task-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, currencyId : item.id, quantity : null, displayId : item.currencyId}
                content.push(newContent)
                setValue(content)
                sessionStorage.setItem('task-rewards', JSON.stringify(content))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('link-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, currencyId : item.id, quantity : null, displayId : item.currencyId,priceList: item.inventoryBundlesPrices}
                content.push(newContent)
                // setValue(content)
                sessionStorage.setItem('link-rewards', JSON.stringify(content))
            }else{
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('contents'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, currencyId : item.id, quantity : null, displayId : item.currencyId,priceList: item.inventoryBundlesPrices}
                content.push(newContent)
                // setValue(content)
                sessionStorage.setItem('contents', JSON.stringify(content))
            }
        }
    }

    const RemoveContent = (item, id) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                let removedReward = [...content]
                let remainingRewards = removedReward && removedReward.map(e => e.currencyId === id ? {...e, archive: true} : e)
                setValue(remainingRewards)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(remainingRewards))
            }else{
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('task-rewards'))
                let removedReward = [...content]
                let remainingRewards = removedReward && removedReward.map(e => e.currencyId === id ? {...e, archive: true} : e)
                setValue(remainingRewards)
                sessionStorage.setItem('task-rewards', JSON.stringify(remainingRewards))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('link-rewards'))
                let removedReward = [...content]
                // setValue(remainingRewards)
                let remainingRewards = removedReward && removedReward.map(e => e.currencyId === id ? {...e, archive: true} : e)
                sessionStorage.setItem('link-rewards', JSON.stringify(remainingRewards))
            }else{
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('contents'))
                let removedReward = [...content]
                // setValue(remainingRewards)
                let remainingRewards = removedReward && removedReward.map(e => e.currencyId === id ? {...e, archive: true} : e)
                sessionStorage.setItem('contents', JSON.stringify(remainingRewards))
            }
        }
    }

    const onInputChange = (e, id) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                const updatedArray = content.map(item => {
                    if (item.currencyId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                setValue(updatedArray)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(updatedArray))
            }else{
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('task-rewards'))
                const updatedArray = content.map(item => {
                    if (item.currencyId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                setValue(updatedArray)
                sessionStorage.setItem('task-rewards', JSON.stringify(updatedArray))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('link-rewards'))
                const updatedArray = content.map(item => {
                    if (item.currencyId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                // setValue(updatedArray)
                sessionStorage.setItem('link-rewards', JSON.stringify(updatedArray))
            }else{
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('contents'))
                const updatedArray = content.map(item => {
                    if (item.currencyId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                // setValue(updatedArray)
                sessionStorage.setItem('contents', JSON.stringify(updatedArray))
            }
        }
    }

    useEffect(()=>{

    }, [item])

    return (
        <>
            <div className={styles.row}>
                {item.status == false ?
                    <div className={styles.col}><Add className={styles.addIcon} onClick={()=>{addContent(item, index)}}/></div>
                :item.status == true ?
                    <div className={styles.col}><Remove className={styles.addIcon} onClick={()=>{RemoveContent(item, item.id)}}/></div>
                : ''}
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.modalPreview}>
                            <img src={item.iconUrl != null ? item.iconUrl : Currency} alt="Currency" />
                        </div>
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.price}>{item.type == 'real' ? "RMG" : item.type == 'virtual' ? "Virtual" : ""}</div>
                            <div className={item.type == 'real' ? styles.real : styles.virtual }>{item.type}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}><div className={styles.itemName}>{item.name}</div></div>
                <div className={styles.col}><div className={item.type == 'real' ? styles.real : styles.virtual }>{item.type == 'real' ? "RMG" : item.type == 'virtual' ? "Virtual" : ""}</div></div>
                <div className={styles.col}><div className={styles.rewardsInput}><input disabled={items[index].status == false ? true : false} key={item.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={items[index].status == false ? '' : items[index].quantity} onChange={(e)=>{onInputChange(e, item.id)}}/></div></div>
            </div>
        </>
    );
};

export default Row;
