import React, {useEffect, useState} from 'react'
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import GamesTableLayout from '../../../../components/BuildComponents/GamesComponent/GamesTableComponent'
import { useDispatch, useSelector } from 'react-redux';
import {getAllGamesAction, filterGamesAction} from '../../../../redux/action/builds/games/gamesAction'
import Form from '../../../../components/Form';
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import addIcon from '../../../../media/images/icons/light.png'
import styles from './Games.module.sass'
import EmptyStates from '../../../../components/EmptyStates';
import { ReactComponent as EmptyCurrencyState } from "../../../../media/images/icons/Currency_Empty_State.svg";
import Loader from '../../../../components/Loaders/LoaderProgress';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton';
import EmptyStateSkeleton from '../../../../components/Skeleton/EmptyStateSkeleton'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext';
import { useContext } from 'react';
import Control from './Filters/Control';
import { ToastContainer } from 'react-toastify';
import Navigation from '../../../../components/Header/SecondaryNavigation';

const sorting = ["list", "grid"];

const Games = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  
  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  // Filters and Search
  const [genre, setGenre] = useState([])
  const [location, setLocation] = useState([])
  const [platform, setPlatform] = useState([])
  const filters = {genre, setGenre, location, setLocation, platform, setPlatform}
  // Search
  const [search, setSearch] = useState(null)
  // Archive
  const [showArchive, setShowArchive] = useState(false)
  // Sort
  const sortList = [{id: 1, name: 'Game Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  const getAllGames = async(search) => {
    let getGames = {
      projectId : appid,
      isDefault:false,
      // Archive
      showArchived: showArchive,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      //Filters
      genres : genre,
      platforms : platform,
      locations : location,
      //Sort
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort,
      // Pagination
      offset: ((page-1)*limit),
      limit: limit
    }
    let keysToCheck = ['genre', 'location', 'platform']
    let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
    if(!search && isFiltered){
      await dispatch(getAllGamesAction(getGames, configureSkeleton))
    }else{
      await dispatch(filterGamesAction(getGames, configureSkeleton, !isFiltered))
    }
  }

  let games = useSelector((state) => {
    return state.games.games
  })

  let isLoading = useSelector((state) => {
    return state.games.isLoading
  })

  let isFiltered = useSelector((state) => {
    return state.games.isFiltered
  })

  let totalGames = useSelector((state) => {
    return state.games.totalCount
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  useEffect(()=>{
    if(selectedSort !== null){
      getAllGames(search)
    }
  }, [page, selectedSort, showArchive])

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getAllGames()
    }else{
      setSearch(search)
      getAllGames(search)
    }
  }

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
      <ToastContainer />
      <Navigation type='Games' filterAction={getAllGames} filters={filters} search={search} setSearch={onSearch} archive={showArchive} setArchive={setShowArchive} data={sortList} setPage={setPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue} />
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head}>

        <div className={styles.wrapper}>
          {/* Games Table View */}
          {showSkeleton && games?.length === 0 && 
            <EmptyStateSkeleton />
          }
          {showSkeleton && games?.length > 0 && 
            <SkeletonTable rows={games.length} column={4} controls={2} type={['recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[36, 100, 100, 36]} colHeight={[36, 15, 25, 36]} />
          }
          {!showSkeleton && !isFiltered && games?.length === 0 &&
            <EmptyStates access={userAccess} route={`/create-game/${appid}`} type="Game" />
          }
          {!showSkeleton && !isFiltered && games?.length > 0 &&
            <GamesTableLayout access={userAccess} items={games} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (games?.length > 0 ?
              <GamesTableLayout access={userAccess} items={games} title="Last edited" />
            :
              <div className={styles.message}>No Result Found</div>
            )
          }
        </div>

        {games.length > 0 &&
          <div className={styles.pagination}>
            <Stack spacing={2}>
              <Pagination page={page} renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '&.MuiPaginationItem-root': {
                      minWidth: '28px',
                      height: '28px',
                      fontSize: '12px'
                    },
                    '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    }
                  }}/>
              )} siblingCount={1} count={Math.ceil(totalGames/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
        }

      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about games <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/games" target="_blank">Click Here</a></p>
      </div>
    </>
  )
}

export default Games