import React from "react";
import styles from './pageNotFound.module.sass'

const PageNotFound = () => {

    return(
        <>
            
        </>
    )

}

export default PageNotFound