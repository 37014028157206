import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './addRewardModal.module.sass'
import Icon from '../../Icon'
import Form from '../../Form'
import TextInput from '../../TextInput'
import Dropdown from '../../Dropdown'
import ItemsTableLayout from './ModalTable'
import CurrencyTableLayout from './ModalTable'
import BundleTableLayout from './ModalTable'
import PMTableLayout from './ModalTable'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllItemAction } from '../../../redux/action/builds/economy/items/itemAction'
import { getAllCurrencyAction } from '../../../redux/action/builds/economy/currency/currencyAction'
import {ReactComponent as Previous} from '../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { Button } from 'bootstrap'
import axios from 'axios';
import bundlePlaceHolder from '../../../media/images/placeholders/Bundles.png'
import PMPlaceHolder from '../../../media/images/placeholders/Progression_Marker.png'
import currencyPlaceHolder from '../../../media/images/placeholders/Currency.png'
import itemPlaceHolder from '../../../media/images/placeholders/Items.png'
import cloneDeep from 'lodash/cloneDeep';
import { BASE_URL } from '../../../resources/APIEndpoints'

const typesOption = ['Types']
const subTypesOption = ['Sub Types']
const tagsOption = ['Tags']
const sorting = ["Items", "Currency", "Bundles", "Progression Marker"];
 
const AddRewardModal = ({categoryIndex,amountKey, className, type, onClose,value, setValue, currency, setCurrency, item, setItem, bundles, setBundle, PM, setPM}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()

    const [tabIndex, setTabIndex] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState([])
    const [tmpValue,setTmpValue]=useState(cloneDeep(value));
    const  Sorting = () => {
        if(type == 'Bundle'){
            setSort(["Items", "Currency", "Bundles"])
        }else{
            setSort(["Items", "Currency", "Bundles", "Progression Marker"])
        }  
    }

    useEffect(()=>{
        Sorting()
    }, [type])

    const [searchValue,setSearchValue]=useState('')
    const [searchPage, setSearchPage] = useState(1)
    const [itemsSearchCount,setItemsSearchCount]=useState(null)
    const [bundleSearchCount,setBundleSearchCount]=useState(null)
    const [currencySearchCount,setCurrencySearchCount]=useState(null)
    const [pmSearchCount,setPmSearchCount]=useState(null)

    /////////
        const [pageCurrency, currencyPage] = useState(1)
        const [pageItem, itemPage] = useState(1)
        const [pageBundle, bundlePage] = useState(1)
        const [pagePM, PMPage] = useState(1)
        const [currencyCount, setCurrencyLimit] = useState(8)
        const [itemCount, setItemLimit] = useState(8)
        const [bundleCount, setBundleLimit] = useState(8)
        const [PMCount, setPMLimit] = useState(8)

        const [totalCurrency, setTotalCurrency] = useState(null)
        const [totalItems, setTotalItems] = useState(null)
        const [totalBundles, setTotalBundles] = useState(null)
        const [totalPM, setTotalPM] = useState(null)
    
    const searchReward = () => {
        // if(searchValue == ''){
        //     // setCalled(prev=>{return !prev})
        // }else
        {
            let search = {
                projectId : appid,
                search : searchValue,
                offset: ((searchPage-1)*itemCount),
                limit: itemCount
            }
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/inventory/search`, search, headers)
            .then(res=>{
                if(res?.data?.data?.currencies && res?.data?.data?.currencies.length > 0){
                    const updatedArray = res?.data?.data?.currencies && res?.data?.data?.currencies.map((ele) => {
                        const matchObj = value.find(element => element.currencyId == ele.id)
                        if(matchObj){
                            return {
                                ...ele,
                                amount : matchObj.amount,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                amount : null,
                                status : false
                            }
                        }
                    })
                    setCurrency&&setCurrency(updatedArray)
                    setCurrencySearchCount(res?.data?.data?.currenciesSearchCount)
                    setTotalCurrency(res?.data?.data?.currenciesTotalCount)
                }
                else{
                    setCurrency&&setCurrency([])
                    setCurrencySearchCount(res?.data?.data?.currenciesSearchCount)
                }
                if(res?.data?.data?.inventoryItems && res?.data?.data?.inventoryItems.length > 0){
                    const updatedArray = res?.data?.data?.inventoryItems && res?.data?.data?.inventoryItems.map(ele => {
                        const matchObj = value.find(element => element.itemId == ele.id)
                        if(matchObj){
                            return {
                                ...ele,
                                amount : matchObj.amount,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                amount : null,
                                status : false
                            }
                        }
                    })
                    setItem&&setItem(updatedArray)
                    setItemsSearchCount(res?.data?.data?.itemsSearchCount)
                    setTotalItems(res?.data?.data?.itemsTotalCount)
                }
                else{
                    setItem&&setItem([])
                    setItemsSearchCount(res?.data?.data?.itemsSearchCount)
                }
                if(res?.data?.data?.inventoryBundles && res?.data?.data?.inventoryBundles.length > 0){
                    const updatedArray = res?.data?.data?.inventoryBundles && res?.data?.data?.inventoryBundles.map(ele => {
                        const matchObj = value.find(element => element.bundleId == ele.id)
                        if(matchObj){
                            return {
                                ...ele,
                                amount : matchObj.amount,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                amount : null,
                                status : false
                            }
                        }
                    })
                    setBundle&&setBundle(updatedArray)
                    setBundleSearchCount(res?.data?.data?.bundlesSearchCount)
                    setTotalBundles(res?.data?.data?.bundlesTotalCount)
                }
                else{
                    setBundle&&setBundle([])
                    setBundleSearchCount(res?.data?.data?.bundlesSearchCount)
                }
                if(res?.data?.data?.progressionMarkers && res?.data?.data?.progressionMarkers.length > 0){
                    const updatedArray = res?.data?.data?.progressionMarkers && res?.data?.data?.progressionMarkers.map(ele => {
                        const matchObj = value.find(element => element.progressionMarkerId == ele.id)
                        if(matchObj){
                            return {
                                ...ele,
                                amount : matchObj.amount,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                amount : null,
                                status : false
                            }
                        }
                    })
                    setPM&&setPM(updatedArray)
                    setPmSearchCount(res?.data?.data?.progressionMarkersSearchCount)
                    setTotalPM(res?.data?.data?.progressionMarkersTotalCount)
                }
                else{
                    setPM&&setPM([])
                    setPmSearchCount(res?.data?.data?.progressionMarkersSearchCount)
                }
                // const rewardList = [...currencies, ...items, ...bundles, ...pm]
            })
        }
    }


    const setStatusOptions = (optArr,valArr) => {
        if (!optArr||!valArr) {
            return []
        }
        if (valArr.length==0) {
            optArr.forEach(option => {
                option.status=false
            });
            return optArr
        }
        // const cntMap = new Map(valArr.map(item => [item.id, item]));
        // optArr.forEach(option => {
        //     const cntItem = cntMap.get(option.id);
        //     if (cntItem) {
        //         option.status = true;
        //             if (amountKey) {
        //                 option[amountKey] = cntItem[amountKey]
        //             } else {
        //                 option.amount = cntItem.amount
        //             }
        //     } else {
        //         option.status = false;
        //     }
        // });
        optArr = optArr && optArr.map(option => {
            let matchObj = valArr.find(val => (val.uuid ? val.uuid === option.id : val.id === option.id) && val.archive === false)
            if(matchObj){
                return{
                    ...option,
                    status: true,
                    quantity: matchObj.quantity
                }
            }else{
                return{
                    ...option,
                    status: false,
                    quantity: null
                }
            }
        })
        return optArr;
    }

    const handleCurrencyChange = (event, value)  => {
        // if (searchValue=='') {
        //     currencyPage(value)
        //     setCurrencySearchCount(null)
        // }
        // else{
            setSearchPage(value)
        // }
    }

    const handleItemChange = (event, value)  => {
        // if (searchValue=='') {
        //     itemPage(value)
        //     setItemsSearchCount(null)
        // }
        // else{
            setSearchPage(value)
        // }
    }

    const handleBundleChange = (event, value)  => {
        // if (searchValue=='') {
        //     bundlePage(value)
        //     setBundleSearchCount(null)
        // }
        // else{
            setSearchPage(value)
        // }
    }

    const handlePMChange = (event, value)  => {
        // if (searchValue=='') {
        //     PMPage(value)
        //     setPmSearchCount(null)
        // }
        // else{
            setSearchPage(value)
        // }
    }

    useEffect(()=>{
        setSearchPage(1)
    },[activeIndex,searchValue])

    // useEffect(()=>{
    //     searchReward()
    // },[])

    useEffect(()=>{
        searchReward()
    },[searchValue,searchPage,pageItem,pageBundle,pageCurrency,pagePM])

    const save = () => {
        setValue(tmpValue)
        onClose()
    }

    const closeModal = () => {
        onClose();
    }

    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>{type == 'Bundle' ? 'Add Content' : 'Add Rewards'}</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.modalContent}>
                                <div className={styles.modalSearch}>
                                    <Form className={styles.form} placeholder="Search" type="text" name="search" icon="search" onChange={(e)=>{setSearchValue(e?.target?.value)}}/>
                                </div>
                                <div className={styles.contentBody}>
                                    <div className={styles.toggle}>
                                        {sort && sort.map((x, index) => (
                                            <button className={type == 'Bundle'? cn(styles.link, { [styles.active]: index === activeIndex}) : cn(styles.links, { [styles.active]: index === activeIndex})} onClick={() => setActiveIndex(index)} key={index}>
                                                <p>{x}</p>
                                            </button>
                                        ))}
                                    </div>
                                    {(type == 'Bundle' || type == 'Task') &&
                                        <>
                                            <div className={styles.wrap}>
                                                {activeIndex === 0 && 
                                                    <>
                                                        <ItemsTableLayout amountKey={amountKey} value={tmpValue} categoryIndex={categoryIndex} type='Item' items={setStatusOptions(item,tmpValue[categoryIndex].rewardDetails.items)} setData={setItem} setValue={setTmpValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                        '&.MuiPaginationItem-root': {
                                                                          minWidth: '28px',
                                                                          height: '28px',
                                                                          fontSize: '12px'
                                                                        },
                                                                        '&.Mui-selected': {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        },
                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        }
                                                                    }}
                                                                />
                                                                )} page={searchPage} siblingCount={1} count={Math.ceil((searchValue !== '')?(itemsSearchCount/itemCount):(totalItems/itemCount))} color="primary" onChange={handleItemChange} />
                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className={styles.wrap}>
                                                {activeIndex === 1 && 
                                                    <>
                                                        <CurrencyTableLayout amountKey={amountKey} value={tmpValue} categoryIndex={categoryIndex} type='Currency' items={setStatusOptions(currency,tmpValue[categoryIndex].rewardDetails.currencies)} setData={setCurrency} setValue={setTmpValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                        '&.MuiPaginationItem-root': {
                                                                          minWidth: '28px',
                                                                          height: '28px',
                                                                          fontSize: '12px'
                                                                        },
                                                                        '&.Mui-selected': {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        },
                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        }
                                                                      }}
                                                                />
                                                                )} page={searchPage} siblingCount={1} count={Math.ceil((searchValue !== '')?(currencySearchCount/currencyCount):(totalCurrency/currencyCount))} color="primary" onChange={handleCurrencyChange} />
                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                    
                                            <div className={styles.wrap}>
                                                {activeIndex === 2 &&
                                                    <>
                                                        <BundleTableLayout amountKey={amountKey} value={tmpValue} categoryIndex={categoryIndex} type='Bundles' items={setStatusOptions(bundles,tmpValue[categoryIndex].rewardDetails.bundles)} setData={setBundle} setValue={setTmpValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            {/* <div className={styles.icons}>
                                                                <button disabled={bundleCount <= 1 ? true : false}>
                                                                    <Previous className={styles.previous} disabled={true} onClick={onPreviousBundle}/>
                                                                </button>
                                                                <button disabled={bundles.length < 10 ? true : false}>
                                                                    <Next className={styles.next} onClick={onNextBundle}/>
                                                                </button>
                                                            </div> */}
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                        '&.MuiPaginationItem-root': {
                                                                          minWidth: '28px',
                                                                          height: '28px',
                                                                          fontSize: '12px'
                                                                        },
                                                                        '&.Mui-selected': {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        },
                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        }
                                                                    }}
                                                                />
                                                                )} page={searchPage} siblingCount={1} count={Math.ceil((searchValue !== '')?(bundleSearchCount/bundleCount):(totalBundles/bundleCount))} color="primary" onChange={handleBundleChange} />
                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                    {type == 'Task' &&
                                        <>
                                            <div className={styles.wrap}>
                                                {activeIndex === 3 &&
                                                    <>
                                                        <PMTableLayout amountKey={amountKey} value={tmpValue} categoryIndex={categoryIndex} type='Progression Marker' items={setStatusOptions(PM,tmpValue[categoryIndex].rewardDetails.progressionMarkers)} setData={setPM} setValue={setTmpValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            {/* <div className={styles.icons}>
                                                                <button disabled={PMCount <= 1 ? true : false}>
                                                                    <Previous className={styles.previous} disabled={true} onClick={onPreviousPM}/>
                                                                </button>
                                                                <button disabled={PM.length < 10 ? true : false}>
                                                                    <Next className={styles.next} onClick={onNextPM}/>
                                                                </button>
                                                            </div> */}
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                        '&.MuiPaginationItem-root': {
                                                                          minWidth: '28px',
                                                                          height: '28px',
                                                                          fontSize: '12px'
                                                                        },
                                                                        '&.Mui-selected': {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        },
                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        }
                                                                    }}
                                                                />
                                                                )} page={searchPage} siblingCount={1} count={Math.ceil((searchValue !== '')?(pmSearchCount/PMCount):(totalPM/PMCount))} color="primary" onChange={handlePMChange} />
                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.button}>
                    <button onClick={save} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Save</button>
                    <button onClick={closeModal} className={cn("button-white-small")}>Cancel</button>
                </div>
            </div>
        </>
    )
}

export default AddRewardModal