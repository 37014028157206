import React, {useEffect, useState} from 'react'
import axios from 'axios';
import customAxios from '../../../../../../utils/interceptor';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import styles from './progressionMarkerInfo.module.sass'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../../../components/Card';
import TextInput from '../../../../../../components/TextInput';
import Dropdown from '../../../../../../components/Dropdown';
import TextArea from '../../../../../../components/TextArea';
import Icon from '../../../../../../components/Icon';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import { useDispatch, useSelector } from 'react-redux';
import { createProgressionMarkerAction, editProgressionMarker, editProgressionMarkerAction, getAllProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import { WithContext as ReactTags } from "react-tag-input";
import removeIcon from '../../../../../../media/images/icons/remove.png'
import Tooltip from '../../../../../../components/Tooltip';
import { BASE_URL, GET_PROGRESSION_MARKER } from '../../../../../../resources/APIEndpoints';
import ProgressionMarker from '../../../../../../media/images/placeholders/Progression_Marker.png'
import { useRef } from 'react';
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import Toast from '../../../../../../components/Toast';
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../../components/MetaInput';
import _ from 'lodash'
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const ProgressionMarkerInfo = ({className, onClose,isChanged, setIsChanged}) => {

    const {appid, id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()

    const placeholder = ProgressionMarker
    const[{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 

    const [displayName, setDisplayName] = useState()
    const [markerIcon, setMarkerIcon] = useState(null)
    //const [changedIcon, setChangedIcon] = useState("")
    const [markerId, setMarkerId] = useState()
    const [markerDescription, setMarkerDescription] = useState()
    const [tags, setTags] = useState([]);
    const [metaData, setMetaData] = useState([{key : '' , value : null}])
    const [checkObject, setCheckObject] = useState()
        const getProgressionMarkerById = async() => {
        let getPM = {
            projectId : appid,
            ids : [id],
            offset : 0,
            limit : 1
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_MARKER, getPM, headers)
        if(res){
            setCheckObject(res.data.data.progressionMarkers[0])
            setMarkerIcon(res.data.data.progressionMarkers[0].iconUrl)
            setLogo({src: (res.data.data.progressionMarkers[0].iconUrl != null ? res.data.data.progressionMarkers[0].iconUrl :  ProgressionMarker) , alt: 'Upload an Image'})
            setDisplayName(res.data.data.progressionMarkers[0].name)
            setMarkerId(res.data.data.progressionMarkers[0].progressionMarkerId)
            setMarkerDescription(res.data.data.progressionMarkers[0].description)
            res.data.data.progressionMarkers[0].tagsMapping && res.data.data.progressionMarkers[0].tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });
            // let meta = []
            // res.data.data.progressionMarkers[0].meta && Object.keys(res.data.data.progressionMarkers[0].meta).forEach(e => {
            //     meta.push({key : e, value : res.data.data.progressionMarkers[0].meta[e]})
            // });
            // setMetaData(meta)
            let meta = []
            if(res.data.data.progressionMarkers[0].meta === null || JSON.stringify(res.data.data.progressionMarkers[0].meta) === '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                res.data.data.progressionMarkers[0].meta && Object.keys(res.data.data.progressionMarkers[0].meta).forEach(e => {
                    meta.push({key : e, value : res.data.data.progressionMarkers[0].meta[e]})
                });
                setMetaData(meta)
            }
            
        }
    }

    useEffect(()=>{
        getProgressionMarkerById()
    }, [])

    const onDisplayName= (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    }

    const onMarkerId = (e) => {
        if(e.target.value == ''){
            setMarkerId('')
        }else{
            errors.idError = ''
            setMarkerId(e.target.value)
        }

    }

    const onMarkerDescription= (e) => {
        setMarkerDescription(e.target.value)
    }

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        //setChangedIcon(res.data.getUrl)
        setMarkerIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
 toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setLogo({src: placeholder , alt: 'Upload an Image'})
        setMarkerIcon(null)
        ref.current.value = ''
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name && data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('pmName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.progressionMarkerId && data.progressionMarkerId == '') {
            error.idError = 'PM ID is required';
            isValid = false;
            if(data.name && data.name != ''){
                const element = document.getElementById('pmId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const compareMeta = (meta1, meta2) => {
        const keys1 = Object.keys(meta1);
        const keys2 = Object.keys(meta2);
        // If the number of keys or their values differ, return the whole meta2
        if (keys1.length !== keys2.length || !_.isEqual(meta1, meta2)) {
            return meta2; // Return the whole meta2 object
        }
        // Return null if there are no changes
        return null;
    }

    const filterChanged = (obj1, obj2) => {
        return _.reduce(obj2, (result, value, key) => {
            // Always include 'id' and 'uuid' keys
            if (key === 'id' || key === 'projectId') {
              result[key] = value;
            }
            // Special case: compare 'tags' in obj2 with 'tagsMapping' in obj1
            else if (key === 'tags') {
                const tagsFromMapping = obj1['tagsMapping'] && obj1['tagsMapping'].map(mapping => mapping.tag.name);
                if (!_.isEqual(tagsFromMapping, value)) {
                  result[key] = value; // map tags changes to 'tagsMapping'
                }
            }
            // Compare nested objects (like 'meta')
            else if (key === 'meta' && _.isObject(value) && _.isObject(obj1[key])) {
                const metaChanges = compareMeta(obj1.meta, obj2.meta);
                if (metaChanges) {
                    result.meta = metaChanges; // Include the whole new meta if there are changes
                }
            }
            // Normal comparison: add the key if values differ
            else if (!_.isEqual(obj1[key], value)) {
              result[key] = value;
            }
            return result;
        }, {})
    }
    const db = getFirestore(app);
    
    const save = async() => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })
        let progressionMaraker = {
            projectId : appid,
            id : parseInt(id),
            iconUrl : markerIcon,
            name : displayName,
            description : markerDescription,
            progressionMarkerId : markerId,
            meta : finalMetaData,
            tags : itemTags
        }
        let PM = await filterChanged(checkObject, progressionMaraker)
        if(validation(PM)){
            dispatch(editProgressionMarkerAction(PM, navigate, appid, setDisable))
            // Access the response data (e.g., game ID)
                 
            setIsChanged(false);
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                app_id:appid,
                resource: "PROGRESSION MARKER",
                resource_id: id, // Use the ID from response
                description: `Progression Marker '${displayName}' updated successfully.`,
                quantity: 1,
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
            console.log("Activity logged successfully.");
        
            setIsChanged(false)
        }
    }

    const cancel = () => {
        navigate(`/progression-marker/${appid}`)
        setIsChanged(false)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [disable, setDisable] =  useState(false)
    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    const setchange = () => {
        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })

        if (checkObject&&
            (displayName==checkObject.name)&&
            (markerId==checkObject.progressionMarkerId)&&
            (markerIcon==checkObject.iconUrl)&&
            (((markerDescription==undefined||markerDescription==''||markerDescription==null)&& checkObject.description==null)||markerDescription==checkObject.description)&&
            // ((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }


    useEffect(()=>{
        setchange()
    },[displayName,markerIcon,markerId,markerDescription,tags,metaData])

    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-blue">
                    <div className={styles.description}>
                        <div className={styles.preview}>
                            <div className={styles.previewImage}>
                                <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                            </div>
                            <div className={styles.previewInput}> 
                                <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                                <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                            </div>
                            {markerIcon != null &&
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                            }
                        </div>
                        <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={markerIcon} setIcon={setMarkerIcon} setValue={setLogo} onClose={onClose} />
                        <div className={styles.group}>
                            <TextInput id='pmName' className={styles.field} errorMessage={errors.nameError} value={displayName} tooltip={TooltipTitle.PMName} label="Display Name*" name="DisplayName" type="text" required onChange={(e)=>{e.target.value == '' ? setDisplayName('') : setDisplayName(e.target.value); errors.nameError = ''}}/>
                            <TextInput isId={true} id='pmId' className={styles.field} errorMessage={errors.idError} value={markerId} tooltip={TooltipTitle.PMID} label="Progression Marker ID*" name="progressionId" type="text" required onChange={(e)=>{e.target.value == '' ? setMarkerId('') : setMarkerId(e.target.value); errors.idError = ''}}/>
                        </div>
                        <TextArea tooltip={TooltipTitle.PMDescription} className={styles.field} rows={5} value={markerDescription} label="Progression Marker Description" onChange={(e)=>{onMarkerDescription(e)}}/>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                    <div className={styles.cardDescription}>
                        <TagInputWithDropdown tooltip={TooltipTitle.PMTags} tags={tags} setTags={setTags}/>
                        <div className={styles.createCurrency}>
                            <div className={styles.groupHeading}>
                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.PMMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                            </div>

                            {metaData && metaData.length > 0 &&
                                <div className={styles.groupMeta}>
                                    <div className={styles.field}>Key</div>
                                    <div className={styles.field}>Value</div>
                                </div>
                            }

                            <div className={styles.groupMeta}>
                                {metaData && metaData.map((e,i)=>{
                                        return(
                                            <>
                                                <TextInput className={styles.field} value={metaData[i].key} label="" name="key" type="text" required  onChange={(e)=>{onChangeKey(e,i)}}/>
                                                <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                                {/* <TextInput className={styles.field} value={metaData[i].value} label="" name="value" type="text" required onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                                <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><img className={styles.icon} src={removeIcon} alt="remove" /></span>
                                            </>
                                        )
                                })}
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
        </>
    )
}

export default ProgressionMarkerInfo