import React from 'react'
import styles from './emptyState.module.sass'
import { Link } from 'react-router-dom'
import Icon from '../Icon'
import addIcon from '../../media/images/icons/light.png'
import { ReactComponent as EmptyState } from "../../media/images/placeholders/EmptyState.svg";
import cn from "classnames";
import Control from '../../screens/Engage/Players/GetPlayers/Control'
import ControlMember from '../../screens/AppSettings/Members/GetMembers/Control'
import OrgControlMember from '../../screens/Organization/Settings/Members/Control'
import AppControl from '../../screens/Organization/Apps/GetApps/Control'

const EmptyStates = ({route, exportOption, memberAction, access, usedFor, type, apps, setApps, setAppName, setShowLoader, setLoaderType, action}) => {

  return (
    <>
      <div className={styles.emptyStateContainer}>
        <div className={styles.emptyStateContainerInner}>
          <div className={styles.emptyStateSvg}>
            <EmptyState />
          </div>

          <div className={styles.emptyStateContent}>
            <div className={styles.heading}>{type == 'Apps' ? 'Ready, Set, Mint!' : type == 'Currency' ? ' Ready, Set, Mint!' : type == 'Item' ? 'From Potions to Power-ups!' : type == 'Bundle' ? 'Pack More Fun with Bundles!' : type == 'PM' ? 'Epic Games, Epic Progress!' : type == 'PS' ? `Map the Hero's Journey` : type == 'Task' ? 'Achievement Basecamp!' : type == 'mission' ? 'Architect Thrilling Quests!' : type == 'Step Series' ? 'Design Sequential Milestones!' : type == 'Game' ? 'Unleash Worlds of Wonder!' : type == 'Match' ? 'Define Your Duels!' : type == 'Tournaments' ? 'Forge Competitive Arenas!' : type == 'Instant Battle' ? 'Fight on Demand!' : type == 'Player' ? `Players' Pavilion!` : type == 'Leaderboard' ? 'Ranking Mastery!' :type == 'Event' ? 'Kickstart Dynamic Gameplay' : type == 'Store' ? 'Welcome to Your Digital Marketplace!': (type == 'Members' || type == 'OrgMembers') ? 'Add Members to your project and Organisation' : type == 'Leaderboard Result' ? 'Top Performers' : type == 'Media' ? 'Manage files' : type == 'LiveOps' ? 'Plan Your Competitions!' : type == 'Competition Result' ? 'Victory Standings' : type === 'Documents' ? 'Manage Documents' : ''}</div>
            <div className={styles.content}>{type == 'Apps' ? 'Feels a little empty over here!' : type == 'Currency' ? 'Design and manage in-game currencies easily.' : type == 'Item' ? 'Create diverse game items and control their specifications.' : type == 'Bundle' ? 'Combine game items and currencies into bundles for special rewards.' : type == 'PM' ? `Easily define your game's progression markers` : type == 'PS' ? 'Craft a progression system that engages and rewards.' : type == 'Task' ? 'Set the Stage for Player Goals' :  type == 'Game' ? 'Configure settings for various game modes or mini-games.' : type == 'Match' ? 'Craft the perfect match settings for unforgettable player battles.' : type == 'Leaderboard' ? 'Build competitive rankings for to track player performance.' : type == 'Tournaments' ? 'Design tournaments backed by leaderboards for player showdowns.' : type == 'Instant Battle' ? 'Quick battles. Immediate rewards. Perfect for fast-paced action.' : type == 'Player' ? 'Manage profiles, inventories, wallets, and rewards, all in one place.' :  type == 'Leaderboard' ? 'Build competitive rankings for to track player performance.' :type == 'Event' ? 'Define events that map to in-game actions.':type == 'Store' ? 'Create a seamless shopping experience for your players.': (type == 'Members' || type == 'OrgMembers') ? 'Add and Manage your members.' : type == 'Leaderboard Result' ? 'See Who’s Leading the Game' : type == 'Media' ? 'Upload/Manage your images & videos in app' : type == 'mission' ? 'Bundle tasks into engaging cyclical achievements.' :  type == 'Step Series' ? 'Create a series of incremental challenges and reward progress at every step.' : type == 'LiveOps' ? 'Schedule and manage in-game competitions.' : type == 'Competition Result' ? 'Discover Top Performers' : type === 'Documents' ? 'Manage your documents in app' : ''}</div>
          </div>
          {!access &&
            <>
              {(type !== 'Leaderboard Result' && type != 'Competition Result' && type !== 'Media') &&
                <div className={styles.emptyStateButtonGroup}>
                  {(type != 'Player' && type != 'Members' && type != 'OrgMembers' && type != 'Apps') ?
                    <div className={styles.createButton}>
                      <Link to={route} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>{type == 'Currency' ? 'Create Currency' : type == 'Item' ? 'Create Item' : type == 'Bundle' ? 'Create Bundle' : type == 'PM' ? 'Create Progression Marker' : type == 'PS' ? 'Create Progression System' : type == 'Task' ? 'Create Task' : (type == 'Step Series') ? 'Create Step Series' : type == 'Store' ? 'Create Store': type == 'mission' ? 'Create Mission' : type == 'Game' ? 'Create Game' : type == 'Match' ? 'Create Match' : type == 'Leaderboard' ? 'Create Leaderboard' : type == 'Tournaments' ? 'Create Tournament' : type == 'Instant Battle' ? 'Create Instant Battle'  : type=='LiveOps'?'Schedule Competition': type == 'Event' ? 'Create Event': type === 'Documents' ? 'Create Document' : ''}</Link>
                    </div>
                  : ''}
                  {type == 'Player' && <Control type='medium' action={action}/> }
                  {type == 'Apps' && <AppControl type='medium' setAppName={setAppName} setShowLoader={setShowLoader} setLoaderType={setLoaderType}/> }
                  {type == 'Members' && <ControlMember memberAction={memberAction} usedFor={usedFor} type='medium' action={`Create Player`}/> }
                  {type == 'OrgMembers' && <OrgControlMember memberAction={memberAction} usedFor={usedFor} type='medium' apps={apps} setApps={setApps}/> }
                  {exportOption && exportOption}
                </div>
              }
            </>
          }
        </div>
      </div>
    </>
  )
}

export default EmptyStates