import React, { useEffect, useState } from 'react'
import styles from './login.module.sass'
import TextInput from '../../../../components/TextInput'
import Icon from '../../../../components/Icon'
import {useDispatch} from 'react-redux'
import moment from 'moment-timezone';
import {ToastContainer} from 'react-toastify';
import {useNavigate} from 'react-router-dom'
import './style.css'
import {ReactComponent as Check} from '../../../../media/images/icons/Vector 408 (Stroke).svg'
import Dropdown from '../../../../components/Dropdown'
import {ReactComponent as Internet} from '../../../../media/images/icons/internetBlack.svg'
import newTimezones from '../../../../components/CustomTimeZones'

const SignIn = ({onSave, user, setUser, props}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [rules, setRules] = useState({lengthRule: null, capitalRule: null, smallRule: null, numberRule: null, specialRule: null})

    const style = {
        width: 560,
        height: 608,
    }

    function validateEmailAddress(emailAddress) {
        if(emailAddress !== null){
            let atSymbol = emailAddress.indexOf("@");
            let dotSymbol = emailAddress.lastIndexOf(".");
            let spaceSymbol = emailAddress.indexOf(" ");
            if ((atSymbol != -1) &&
                (atSymbol != 0) &&
                (dotSymbol != -1) &&
                (dotSymbol != 0) &&
                (dotSymbol > atSymbol + 1) &&
                (emailAddress.length > dotSymbol + 1) &&
                (spaceSymbol == -1)) {
                return true;
            } else {
                return false;
            }
        }
        else{
            return false
        }
        
    }

    const validateContact = (mobile) => {
        const mobileNumberRegex = /^[0-9]{10}$/;
        if (!mobileNumberRegex.test(mobile)) {
            return false
        } else {
            return true
        }
    }

    const checkRule = (password) => {
        const hasMinLength = password.length >= 8;
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Adjust the special characters if needed
        const hasNumber = /\d/.test(password);
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);

        return hasMinLength && hasSpecialChar && hasNumber && hasUppercase && hasLowercase
    }
  
    const onChangeUser = (e) => {
        const {name, value} = e.target
        setUser((user) => ({...user, [name]: value}))
        if(name === 'password'){
            if(e.target.value != '' || e.target.value != null){
                errors.passwordErr = ''
            }
            setRules(validatePassword(e.target.value))
        }
    }

    const validatePassword = (password) => {
        const rules = {
            lengthRule: password.length >= 8,
            capitalRule: /[A-Z]/.test(password),
            smallRule: /[a-z]/.test(password),
            numberRule: /\d/.test(password),
            specialRule: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        }
        return rules
    }

    const [errors, setErrors] = useState({passwordErr : '', email:'', contact: ''})

    const validation = (data) => {
        const error = {}
        let isValid = true
        if(data.password == null || data.password == ''){
            error.passwordErr = 'Password is required'
            isValid = false
        }
        if(data.firstName == null || data.firstName == ''){
            error.firstName = 'First Name is required'
            isValid = false
        }
        if(data.lastName == null || data.lastName == ''){
            error.lastName = 'Last Name is required'
            isValid = false
        }
        if(!validateEmailAddress(data.email)){
            error.email = 'Please enter a valid email address.'
            isValid = false
        }
        if(!validateContact(data.contactNo)){
            error.contact = 'Please enter a valid Contant No.'
            isValid = false
        }
        if(data.email == null || data.email == ''){
            error.email = 'Email is required'
            isValid = false
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const next = () => {
        if(validation(user)){
            if(checkRule(user.password)){
                localStorage.setItem('userName', JSON.stringify(user.firstName + " " + user.lastName))
                onSave(prevState => prevState + 1)   
            }
        }
    }

    return (
        <>
            <ToastContainer/>
            <div className={styles.personalInfo}>
                <div className={styles.formContainer}>
                    <div className={styles.form}>
                        <div className={styles.formHeader}>
                            <div className={styles.formTitle}>Begin your<br/> Specter Journey<span
                                className={styles.blueText}>!</span></div>
                            <div className={styles.formContent}>A Few Quick Details to Begin</div>
                        </div>
                        <div className={styles.formBody}>
                            <div className={styles.group}>
                                <TextInput position="bottom" errorMessage={errors.firstName} className={styles.fields} type='text' name='firstName' placeHolder='First Name' value={user.firstName} 
                                onChange={(e) => {onChangeUser(e)}} currency={<Icon name="profile-circle" size="20"/>}/>
                                <TextInput position="bottom" errorMessage={errors.lastName} className={styles.fields} type='text' name='lastName' placeHolder='Last Name' value={user.lastName} 
                                onChange={(e) => {onChangeUser(e)}} currency={<Icon name="profile-circle" size="20"/>}/>
                            </div>
                            <div className={styles.single}>
                                <TextInput position="bottom" errorMessage={errors.email} style='verify' verify={<Check/>} className={styles.fields} type='email' name='email'  value={user.email} placeHolder='Email' disabled={true}
                                onChange={(e) => {onChangeUser(e)}} currency={<Icon name="mail" size="20"/>}/>
                            </div>
                            <div className={styles.single}>
                                <TextInput position="bottom" errorMessage={errors.contact} className={styles.fields} type='text' name='contactNo' value={user.contactNo} placeHolder='Mobile No'
                                onChange={(e) => {onChangeUser(e)}} currency={<Icon name="mobile" size="20"/>}/>
                            </div>
                            {!user.googleId && (
                                <>
                                    <div className={styles.single}>
                                        <TextInput position="bottom" errorMessage={errors.passwordErr} className={styles.fields} type='password' name='password' placeHolder='Enter Your Password' value={user.password} 
                                        onChange={(e) => {onChangeUser(e)}} currency={<Icon name="lock" size="20"/>}/>
                                    </div>
                                    <div className={styles.rules}>
                                        <div className={styles.passwordRule}>
                                            <div className={styles.heading}>
                                                <span>Password must contain: </span>
                                            </div>
                                            <div className={styles.ruleGroup}>
                                                <div className={styles.rule}>
                                                    {rules.lengthRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.lengthRule != null && !rules.lengthRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.lengthRule != null && rules.lengthRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least 8 characters</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.smallRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.smallRule != null && !rules.smallRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.smallRule != null && rules.smallRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one lowercase letter (a-z)</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.capitalRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.capitalRule != null && !rules.capitalRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.capitalRule != null && rules.capitalRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one uppercase letter (A-Z)</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.numberRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.numberRule != null && !rules.numberRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.numberRule != null && rules.numberRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one number (0-9)</span>
                                                </div>
                                                <div className={styles.ruleFull}>
                                                    {rules.specialRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.specialRule != null && !rules.specialRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.specialRule != null && rules.specialRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one special character (! @ # $ % ^ & )</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={styles.formFooter}>
                            <button className={styles.Next} onClick={next}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignIn