import { GET_PLANS, GET_PLANS_SUCCESS, GET_PLANS_ERROR, GET_INVOICE, GET_INVOICE_SUCCESS, GET_INVOICE_ERROR, GET_ADD_ONS, GET_ADD_ONS_SUCCESS, GET_ADD_ONS_ERROR, BUY_ADD_ONS, BUY_ADD_ONS_SUCCESS, BUY_ADD_ONS_ERROR, BUY_NEW_PLAN, BUY_NEW_PLAN_SUCCESS, BUY_NEW_PLAN_ERROR, VERIFY_BILLING_SUCCESS, VERIFY_BILLING_ERROR, GET_MY_PLAN_SUCCESS, GET_MY_PLAN_ERROR, GET_MY_PAYMENT_SUCCESS, GET_MY_PAYMENT_ERROR, GET_MY_PLAN, GET_MY_PAYMENT, GET_PRICE_BOOK_SUCCESS, GET_PRICE_BOOK_ERROR } from "../../../actionTypes/actionTypes";
import BillingServices from "../../../../services/organisationServices/billingServices/billingServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";
import { getOrgProfileAction } from "../ProfileAction/profileAction";

export const getPlansAction = () => {
    return async (dispatch) => {
        dispatch(getPlans(null, GET_PLANS))
        await BillingServices.getPlans()
        .then(res=>{
            dispatch(getPlans(res.data.data, GET_PLANS_SUCCESS))
        }).catch(err => {
            dispatch(getPlans(err.response, GET_PLANS_ERROR))
        })
    }
}

export const getPriceBookAction = (body) => {
    return async (dispatch) => {
        await BillingServices.getPlanBook(body)
        .then(res => {
            dispatch(getPlanBook(res.data.data.pricebooks ? res.data.data.pricebooks : res.data.data.pricebook, GET_PRICE_BOOK_SUCCESS))
        }).catch(err => {
            dispatch(getPlanBook(err, GET_PRICE_BOOK_ERROR))
        })
    }
}

export const getAddOnsAction = () => {
    return async (dispatch) => {
        dispatch(getAddOns(null, GET_ADD_ONS))
        await BillingServices.getAddOns()
        .then(res=>{
            dispatch(getAddOns(res.data.data, GET_ADD_ONS_SUCCESS))
        }).catch(err => {
            dispatch(getAddOns(err.response, GET_ADD_ONS_ERROR))
        })
    }
}

export const getInvoiceAction = (body) => {
    return async (dispatch) => {
        dispatch(getInvoice(null, GET_INVOICE))
        await BillingServices.getInvoice(body)
        .then(res=>{
            dispatch(getInvoice(res.data.data, GET_INVOICE_SUCCESS))
        }).catch(err => {
            dispatch(getInvoice(err.response, GET_INVOICE_ERROR))
        })
    }
}

export const verifyBillingAction = (body, Navigate, isGST) => {
    return async (dispatch) => {
        await BillingServices.verifyBilling()
        .then(res=>{
            dispatch(verifyBilling(res.data.data, VERIFY_BILLING_SUCCESS))
            if(JSON.stringify(res.data.data.billingAddress) === JSON.stringify({})){
                Navigate(`/billing-details`, {state: {plan: body}})
            }else{
                let address = res.data.data.billingAddress
                if((address.street && address.country && address.state && address.city && address.zip)){
                    if(!address.gstNo && isGST){
                        Navigate(`/billing-details`, {state: {plan: body}})
                    }else{
                        dispatch(buyNewPlanAction(body))
                    }
                }else{
                    Navigate(`/billing-details`, {state: {plan: body}}) 
                } 
            }
        }).catch(err => {
            dispatch(verifyBilling(err.response, VERIFY_BILLING_ERROR))
        })
    }
}

export const buyAddOnsAction = (body) => {
    return async (dispatch) => {
        await BillingServices.buyAddOns(body)
        .then(res=>{
            dispatch(buyAddOns(res.data, BUY_ADD_ONS_SUCCESS))
        }).catch(err => {
            dispatch(buyAddOns(err.response, BUY_ADD_ONS_ERROR))
        })
    }
}

export const buyNewPlanAction = (body) => {
    return async (dispatch) => {
        await BillingServices.buyNewPlan(body)
        .then(res=>{
            window.location.href = res.data.data
            dispatch(buyNewPlan(res.data, BUY_NEW_PLAN_SUCCESS))
        }).catch(err => {
            dispatch(buyNewPlan(err.response, BUY_NEW_PLAN_ERROR))
        })
    }
}

export const updateOrganisationAction = (body, plan, setDisable) => {
    return async (dispatch) => {
        await BillingServices.updateOrganisation(body)
        .then(res => {
            if(plan){
                setTimeout(() => {
                    dispatch(buyNewPlanAction(plan))
                }, 1000)
            }
            if(setDisable){
                dispatch(getOrgProfileAction({}))
                setDisable(true)
            }
        }).catch(err => {
            toast.error(<Toast type='Error' messages={err.response.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
        })
    }
}

export const getMyPlanAction = (body) => {
    return async (dispatch) => {
        dispatch(getMyPlan(null, GET_MY_PLAN))
        await BillingServices.getMyPlan(body)
        .then(res => {
            dispatch(getMyPlan(res.data.data, GET_MY_PLAN_SUCCESS))
        }).catch(err => {
            dispatch(getMyPlan(err, GET_MY_PLAN_ERROR))
            toast.error(<Toast type='Error' messages={err.response.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
        })
    }
}

export const getAllPaymentAction = (body) => {
    return async (dispatch) => {
        dispatch(getPayment(null, GET_MY_PAYMENT))
        await BillingServices.getPayment(body)
        .then(res => {
            dispatch(getPayment(res.data.data, GET_MY_PAYMENT_SUCCESS))
        }).catch(err => {
            dispatch(getPayment(err, GET_MY_PAYMENT_ERROR))
            toast.error(<Toast type='Error' messages={err.response.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
        })
    }
}

export const updateCardDetailsAction = (body, navigate) => {
    return async (dispatch) => {
        await BillingServices.updateCardDetails(body)
        .then(res => {
            window.location.href = res.data.data
            //dispatch(updateCardDetails(res.data.data))
        }).catch(err => {
            //dispatch(updateCardDetails(err.response))
        })
    }
}

export const downloadInvoiceAction = (body) => {
    return async (dispatch) => {
        const response = await BillingServices.downloadInvoice(body)
        return response?.data
    }
}

export const getPlans = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getPayment = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getAddOns = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getInvoice = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const verifyBilling = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const buyAddOns = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const buyNewPlan = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getMyPlan = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getPlanBook = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const updateCardDetails = (data) => {
    return {
        payload : data
    }
}

export const downloadInvoice = (data) => {
    return {
        payload : data
    }
}