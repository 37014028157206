import React, {useState, useRef, useEffect} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import styles from './resumeNpauseModal.module.sass'
import { useNavigate,useParams } from 'react-router-dom'
import { getAllMetaDataAction } from '../../../redux/action/common/commonAction'
import { pausenplayLiveOpsAction } from '../../../redux/action/engage/LiveOps/LiveOpsAction'
import app from "../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const ResumenPauseScheduleModal = ({className, status, name, type, as, id, scheduleId, instanceId, liveOps, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams();

  const db = getFirestore(app);

  const Stop = async () => {
    let stopLiveOps = {
      scheduledId : scheduleId,
      status : (status === 'active' ? 'paused' : status === 'paused' ? 'active' : 'paused')
    }
    try {
      await dispatch(pausenplayLiveOpsAction(stopLiveOps, type, as, appid, onClose, navigate,id))

          const user = JSON.parse(localStorage.getItem('user'))
          const memberID = user?.id
          const memberName = user?.name
          const orgId = (localStorage.getItem('orgId'))

          // Log activity in Firestore
          const activityLog = {
              timestamp: new Date().toISOString(),
              action_type: "CREATE",
              app_id:appid,
              resource: type.toUpperCase(),
              // resource_id: IdFromResponse, // Use the ID from response
              description: `${type} '${name}' ${(status === 'active' ? 'paused' : status === 'paused' ? 'resumed' : 'paused')} successfully.`,
              quantity: 1,
operation_status: "SUCCESS",
              activity_feed: true,
              priority: "LOW",
              performed_by_id: memberID,
              organization_id: orgId,
              performed_by:memberName,
              request_json:JSON.stringify(stopLiveOps),
          };
          await addDoc(collection(db, "activityFeed"), activityLog);
          console.log("Activity logged successfully.");
      
    } catch (error) {
        console.error("Error whie logging activity:", error);
    }
    
  }

  return (
        <div className={styles.scheduleModal}>
          <div className={styles.scheduleContainer}>
            <div className={styles.modalHeader}>
              <div className={styles.modalHeading}>
                  <span>{status === 'active' ? 'Pause' : status === 'paused' ? 'Resume' : ''} {type}</span>
                </div>
            </div>

            <div className={styles.modalBody}>
              {type === 'Leaderboard' &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to {status === 'active' ? 'pause' : status === 'paused' ? 'resume' : ''} the leaderboard <span className={styles.name}>{name}</span>?</span>
                  {status === 'active' && <span className={styles.warningContent}>&nbsp;No new scores will be accepted until you resume. This applies to all instances of the current and recurring leaderboards.</span>}
                  {status === 'paused' && <span className={styles.warningContent}>&nbsp;New scores will now be accepted. This applies to all instances of the current and recurring leaderboards.</span>}
                </>
              }
              {(type === 'Tournament' || type === 'Instant Battle') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to {status === 'active' ? 'pause' : status === 'paused' ? 'resume' : ''} the competition <span className={styles.name}>{name}</span>?</span>
                  {status === 'active' && <span className={styles.warningContent}>&nbsp;No new entries will be accepted until you resume. This applies to all instances of the current and recurring competitions.</span>}
                  {status === 'paused' && <span className={styles.warningContent}>&nbsp;New entries will now be accepted. This applies to all instances of the current and recurring competitions.</span>}
                </>
              }
            </div>

            <div className={styles.modalFooter}>
              <button className={styles.buttonDanger} onClick={Stop}>Confirm</button>
              <button className={styles.buttonSecondary} onClick={onClose}>Cancel</button>
            </div>

          </div>
        </div>
  )
}

export default ResumenPauseScheduleModal