import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../Checkbox";
import Row from "./Row";
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'

const Table = ({ items, access, title, type, setValue }) => {

  const desiredLength = 10;
  items = items.concat(Array(desiredLength - items.length).fill({})).slice(0, desiredLength)

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Icon</div>
          <div className={styles.col}>{type == 'modal' ? "Name" : "Display Name"}</div>
          <div className={styles.col}>Price</div>
          <div className={styles.col}>Item Type</div>
          <div className={styles.col}></div>
        </div>
        {items && items.map((x, index) => (
          <Row access={access} setValue={setValue} type={type} item={x} key={index} index={index} />))
        }
      </div>
    </div>
  );
};

export default Table;
