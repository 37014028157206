import React, { useState } from "react";
import cn from "classnames";
import Icon from "../../../../../Icon";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import { downloadInvoiceAction } from "../../../../../../redux/action/organisation/Billing/billingAction";
import { useDispatch } from "react-redux";

const Control = ({className, id, appid, status, name, item}) => {

  const dispatch = useDispatch()

  const downloadInvoice = async(item) => {
    try {
      let binaryData = await dispatch(downloadInvoiceAction(item.invoiceId))
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Invoice_${item.invoiceNumber}(${item.invoiceDate}).pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove()
    }catch (err) {
      console.log(err)
    } finally{
      console.log('downloaded')
    }
  }

  const actions = [
    {
      icon: 'download'
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions && actions.map((x, index) => {
            return(
              <Link className={styles.button} key={index} onClick={()=> downloadInvoice(item)}>
                <Icon name={x.icon} size='20' />
              </Link>
            )
          }
        )}
      </div>
    </>
  );
};

export default Control;
