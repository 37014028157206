import React, { useState, useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./multiselect.module.sass";
import Tooltip from "../../Tooltip";
import Icon from "../../Icon";
import CustomTooltip from '../../CustomTooltips/ReusableTooltip'
import Tooltips from "../../Tooltip";
import { width } from "@mui/system";

const Dropdown = ({className, disabled, errorMessage, classDropdownHead, classDropdownLabel, classCheckboxTick, value, setValue, placeholder, options, label, tooltip, place, small, upBody}) => {

  const [visible, setVisible] = useState(false);
  const [filterText, setFilterText] = useState('');

  const outClick = () => {
    setVisible(false)
    setFilterText('')
  }
  const handleClick = (selectedOption) => {
    if(value.some(e => e.name === selectedOption.name)){
      setValue(value.filter((values) => values.name !== selectedOption.name))
    }else{
      setValue([...value, selectedOption]);
    }
    setFilterText('')
  };

  const removeOption = (selectedOption) => {
    if (value.includes(selectedOption)) {
      setValue(value.filter((values) => values !== selectedOption));
    } 
    else if (selectedOption=="Global") {
      setValue([])
    }
    else {
      setValue([...value, selectedOption]);
    }
  }

  const [selectAll, setSelectAll] = useState(true)

  const toggleSelectAll = () => {
    if(selectAll == true){
      setSelectAll(false)
      setValue([])
    }else{
      setSelectAll(true)
      setValue(options && options.map((e, i) => ({id : e.id, name : e.name})))
    }
  }

  const onDropDownClick = (event) => {
    setVisible(true)
      // if(document.getElementById('inputFocus')){
      //     document.getElementById('inputFocus').focus()
      // }
  }

  useEffect(() => {
    if(value.length < 249){
      setSelectAll(false)
    }else{
      setSelectAll(true)
    }
  }, [value])

  return (
    <div className={styles.main}>
      {label && (
        <div className={styles.groupLabel}>
          <div className={cn(styles.label, classDropdownLabel)}>
            {label}
            {tooltip && (
              <Tooltips className={styles.tooltip} title={tooltip} icon="info" place="right" />
            )}
          </div>
          <div className={styles.error}>
            {(value && value.length == 0) && errorMessage}
          </div>
        </div>
      )}
      <OutsideClickHandler onOutsideClick={() => outClick()}>
      <div className={cn(styles.dropdown, className,{ [styles.small]: small },{ [styles.active]: visible}, { [styles.disabled]: disabled})}>
        <div className={(errorMessage && value && value.length == 0) ? cn(styles.head, styles.head_error, classDropdownHead) : cn(styles.head, classDropdownHead)} onClick={(e) => onDropDownClick(e)}>
          <div className={styles.selection}>
              {value.length > 0 ?
                <>
                {value.length==options.length?
                   <div className={styles.valueContainer}>
                    <span className={styles.pills}>Global<span className={styles.remove} onClick={()=>{removeOption("Global")}}><Icon name='close' size='12' /></span></span>
                  </div>
                  :
                  <>
                  <div className={styles.valueContainer}>
                    {value && value.slice(0, 5).map((e,i) => {
                      return(
                        <span className={styles.pills}>{e.name}<span className={styles.remove} onClick={!disabled ? ()=>{removeOption(e)} : null}><Icon name='close' size='12' /></span></span>
                      )
                    })}
                  </div>
                  <CustomTooltip text="Locations" data={value}><span className={styles.tooltipValues}>{value.length > 5 ? <span>&nbsp;&#43;&nbsp;{value.length-5}</span>  : ''}</span></CustomTooltip>
                  </>
                }
                  
                  
                  <input id="inputFocus" type="text" style={{height:"30px"}} className={styles.locInput} value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                </>
              : 
              <div className={styles.valueContainer} style={{width:"100%"}}>
                <input id="inputFocus" type="text" className={styles.locInput} style={{height:"30px",width:"100%"}} placeholder={placeholder} value={filterText} onChange={(e) => setFilterText(e.target.value)} />
              </div>
              }
            </div>
            
        </div>
        {!disabled &&
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          <div className={cn(styles.option)}>
            <label className={cn(styles.checkbox)} onChange={toggleSelectAll}>
                <input className={styles.input} type="checkbox" checked={selectAll} />
                <span className={styles.inner}>
                    <span className={cn(styles.tick, classCheckboxTick)}></span>
                    <span className={styles.optionLabel}>Select All</span>
                </span>
            </label>
          </div>
          {options && options.filter(option => option.name&& option.name.toLowerCase().includes(filterText.toLowerCase())).map((x, index) => (
            <div className={cn(styles.option)} key={index}>
                <label className={cn(styles.checkbox)} onChange={() => handleClick(x, index)} checked={value.some(e => e.name === x.name)}>
                    <input className={styles.input} type="checkbox" checked={value.some(e => e.name === x.name)}/>
                    <span className={styles.inner}>
                        <span className={cn(styles.tick, classCheckboxTick)}></span>
                        <span className={styles.optionLabel}>{x.name}</span>
                    </span>
                </label>
            </div>
          ))}
        </div>
        }
      </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
