import React, {useState, useRef} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import styles from './addCategoryModal.module.sass'
import TextInput from '../../../TextInput'
import {createScheduleLiveOpsAction,createLiveOpsCategoryAction} from '../../../../redux/action/engage/LiveOps/LiveOpsAction'
import ColorRadio from '../../../../components/ColorRadio'
import { useNavigate,useParams } from 'react-router-dom'
import app from "../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const AddAppModal = ({className, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams();

  const [appName, setAppName] = useState()

  const [radioColor, setRadioColor] = useState()

  const colorOption=["#EFEFEF","#FFBC99","#CABDFF","#B1E5FC","#B5E4CA","#FFD88D","#FFB7F5","#FF8D8D","#8DA0FF"]

  const onChangeAppName = (e) => {
    setAppName(e.target.value)
  }
  const db = getFirestore(app);

  const save = async() => {
      let createLiveOpsCategory = {
        name: appName,
        colorCode:radioColor,
        projectId:appid,
      }
      try {
        await dispatch(createLiveOpsCategoryAction(createLiveOpsCategory, navigate, appid))

            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "CREATE",
                app_id:appid,
                resource: "LIVE_OPS",
                // resource_id: IdFromResponse, // Use the ID from response
                description: `Live Ops Category '${appName}' created successfully.`,
                quantity: 1,
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(createLiveOpsCategory),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
            console.log("Activity logged successfully.");
        
      } catch (error) {
          console.error("Error while logging activity:", error);
      }
        onClose()
  }

  return (
    <div className={styles.addAppModal}>
      <div className={styles.addAppModalBody}>
        <div className={styles.modalHeading}>
            <div className={styles.block}></div><span>Create Category</span>
        </div>
        <div>
              <TextInput type='text' label='Name' placeholder='Enter Category Name' onChange={(e)=>{onChangeAppName(e)}}/>
          </div>
      </div>
      <div className={styles.group}>
      {colorOption.map((color, index) => {
          return (
              <div key={index} className={styles.variants}>
                  <ColorRadio colorCode={color} id={index} name="color" value={radioColor==color} onChange={()=>{setRadioColor(color)}}/>
              </div>
          );
      })}
          
      </div>

      <div className={styles.btns}>
        <button className={styles.save} onClick={save}>Save</button>
      </div>
    </div>
  )
}

export default AddAppModal