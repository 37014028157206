import React, { useContext, useEffect, useState, useRef} from "react";
import { NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./activity.module.sass";
import Icon from "../../Icon";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "../../../redux/action/organisation/ProfileAction/profileAction";
import GlobalStateContext from "../../Context/GlobalStates/GlobalStateContext";
import { collection, getDocs, where, query, orderBy, limit, startAfter, getFirestore } from "firebase/firestore"; // Import Firestore functions
import app from "../../../firebase.js"

const Activity = ({ className }) => {

    const [visibleHelp, setVisibleHelp] = useState(false);

    /////////////////
        const [activities, setActivities] = useState([]);
        const [loading, setLoading] = useState(false);
        const db = getFirestore(app);

        const navigate =useNavigate()
        const { appid } = useParams();
      
        useEffect(() => {
          fetchActivities();
        }, [visibleHelp]);
      
        const fetchActivities = async () => {
          if (loading) return;
          setLoading(true);
      
          try {
            const q = query(
              collection(db, "activityFeed"),
              where("activity_feed", "==", true), // Only include documents where activity_feed is true
              where("app_id", "==", appid),
              orderBy("timestamp", "desc"),
              limit(4)
            );
      
            const querySnapshot =appid&& await getDocs(q);
            const newActivities = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
      
            setActivities((prevActivities) => {
              const prevActivityIds = new Set(prevActivities.map((activity) => activity.id));
              const uniqueActivities = newActivities.filter((activity) => !prevActivityIds.has(activity.id));
              return [...prevActivities, ...uniqueActivities];
            });
          } catch (error) {
            console.error("Error fetching activities: ", error);
          } finally {
            setLoading(false);
          }
        };


    return (
        <>
        <OutsideClickHandler onOutsideClick={() => setVisibleHelp(false)}>
            <div className={cn(styles.user, className, {[styles.active]: visibleHelp})}>
                <button className={styles.headHelp} onClick={() => setVisibleHelp(!visibleHelp)}>
                    <Icon name="activity" size="16"/>
                </button>
                <div className={styles.body}>
                <div className={styles.activityTable}>
                    <p style={{fontSize:"20px",fontWeight:"600"}}>Activity</p>
                    <ul className={styles.activityList}>
                        {activities?.length>0? activities.map((activity) => (
                        <li key={activity.id} className={styles.activityItem}>
                            {/* Replace with your custom SVG icons */}
                            <span className={styles.iconWrapper}>
                            <Icon
                                name={activity?.action_type=== "UPDATE" ? "edit" :activity?.action_type== "ARCHIVE"? "trash":activity?.action_type=== "CREATE"?"file-add": "activity"}
                                size="24"
                            />
                            </span>
                            <div className={styles.activityContent}>
                            <div className={styles.performedBy}>
                                {activity.performed_by}
                                <div className={styles.timestamp}>
                                <div style={{fontSize:"10px",textAlign:"end"}}>{activity?.timestamp && new Date(activity.timestamp).toLocaleDateString()}</div>
                                <div>{activity?.timestamp&&new Date(activity.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</div>
                                </div>
                            </div>
                            <div className={styles.description}>{activity.description}</div>
                            </div>
                        </li>
                        )):
                        <p style={{ color: "#777", fontWeight: "600", textAlign:"center"}}>No Activities Available</p>
                        }
                    </ul>
                    {activities?.length>0&&<div className={styles.cardSave}>
                        <button className={styles.Save} onClick={()=>navigate(`/activityFeed/${appid}`)}>See all</button>
                    </div>}
                </div>
                </div>
                </div>
        </OutsideClickHandler>
        </>
    );
};

export default Activity;
