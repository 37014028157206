import React, { useContext, useEffect, useRef, useState } from 'react'
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import styles from './profile.module.sass'
import moment from 'moment'
import Icon from '../../../../../../components/Icon'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import Toast from '../../../../../../components/Toast'
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar'
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction'
import { ReactComponent as AddButtonIcon } from '../../../../../../media/images/icons/add_button.svg'
import ProfilePlaceholder from '../../../../../../media/images/placeholders/Profile.png'
import SearchDropdown from '../../../../../../components/Dropdown/SearchDropdown'
import {ReactComponent as Internet} from '../../../../../../media/images/icons/internet.svg'
import { getAllMetaDataAction, getRegionsAction } from '../../../../../../redux/action/common/commonAction'
import { editOrgProfileAction } from '../../../../../../redux/action/organisation/ProfileAction/profileAction'
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext'
import { BASE_URL } from '../../../../../../resources/APIEndpoints'
import { updateOrganisationAction } from '../../../../../../redux/action/organisation/Billing/billingAction'

const Address = ({data, locationOption}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const globalStates = useContext(GlobalStateContext)

    const [disableField, setDisableField] = useState(true)
    const [orgAddress, setOrgAddress] = useState({street: null, country: null, state: null, city: null, zip: null, gstNo: null})
    const [location, setLocation] = useState('')
    const [locationId, setLocationId] = useState(null)
    const [regionOption, setRegionOptions] = useState([])
    const [region, setRegion] = useState('')
    const [regionId, setRegionId] = useState(null)

    const configureAddress = () => {
        if(data && data?.billingAddress){
            let address = data?.billingAddress
            setOrgAddress(org => ({...org, street: (address?.street ? address?.street : ''), country: (address?.country ? address?.country : ''), state: (address?.state ? address?.state : ''), city: (address?.city ? address?.city : ''), zip: (address?.zip ? address?.zip : ''), gstNo: (data?.gstNo ? data?.gstNo  : '')}))
            setLocation(address?.country ? address?.country : '')
            setRegion(address?.state ? address?.state : '')
        }
    }

    const onChangeBillingAddress = (e) => {
        const {name, value} = e.target
        setOrgAddress(org => ({...org, [name]: value}))
    }

    const onChangeLocation = (e) => {
        console.log('On Change Location Called')
        setOrgAddress(org => ({...org, country: e, state: '', city: ''}))
        setRegion('')
        setRegionId(null)
    }

    const onChangeRegion = (e) => {
        console.log('On Change State Called')
        setOrgAddress(org => ({...org, state: e}))
    }

    const getRegions = () => {
        let country = {
            country : location,
            offset : 0,
            limit : 50
        }
        dispatch(getRegionsAction(country))
    }

    let regionOptions = useSelector((state) => {
        return state.common.region
    })

    const cancelChanges = () => {
        setDisableField(true)
    }

    useEffect(() => {
        configureAddress()
    }, [data])

    useEffect(() => {
        if(location !== null && location !== ''){
            getRegions()
            onChangeLocation(location)
        }
    }, [locationId])

    useEffect(() => {
        if(region !== null && region !== ''){
            onChangeRegion(region)
        }
    }, [regionId])

    const save = () => {
        let address = Object.fromEntries(Object.entries(orgAddress).filter(([_, v]) => v != null && v !== ''))
        dispatch(updateOrganisationAction(orgAddress, null, setDisableField))
    }

    return (
        <>
            <ToastContainer />
            <div className={styles.wrapper}>
            <Card title="Address" classTitle="title-purple" head={disableField && globalStates?.user.type === 'admin' && <div className={styles.icon} onClick={() => setDisableField(!disableField)}><span className={styles.edit}><Icon name='edit' size='24' /></span></div>}>
                <div className={styles.overview}>
                    <div className={styles.group}>
                        <div className={styles.field}>
                            <SearchDropdown disabled={disableField ? true : false} placeholder='Country' value={location} setValue={setLocation} setId={setLocationId} options={locationOption} />
                        </div>
                        <TextInput disabled={disableField ? true : false} className={styles.field} position={`bottom`} type='text' name='zip' placeHolder='Zipcode' value={orgAddress.zip} onChange={(e) => onChangeBillingAddress(e)} />
                    </div>
                    <div className={styles.group}>
                        <TextInput disabled={disableField ? true : false} placeHolder='Address 1' className={styles.block} name='street' position={`bottom`} value={orgAddress.street} onChange={(e) => onChangeBillingAddress(e)}/>
                    </div>
                    <div className={styles.group}>
                        <TextInput disabled={disableField ? true : false} placeHolder='Address 2' className={styles.block} name='street' position={`bottom`} value={orgAddress.street} onChange={(e) => onChangeBillingAddress(e)} />
                    </div>
                    <div className={styles.group}>
                        <div className={styles.field}>
                            <SearchDropdown disabled={disableField ? true : false} placeholder='State' value={region} setValue={setRegion} setId={setRegionId} options={regionOptions}/>
                        </div>  
                        <TextInput disabled={disableField ? true : false} className={styles.field} position={`bottom`} type='text' name='city' placeHolder='City' value={orgAddress.city} onChange={(e) => onChangeBillingAddress(e)}/>
                    </div>
                    <div className={styles.group}>
                        <TextInput disabled={disableField ? true : false} placeHolder='GST No' position={`bottom`} className={styles.block} name='gstNo' value={orgAddress.gstNo} onChange={(e) => onChangeBillingAddress(e)}/>
                    </div>

                    {!disableField &&
                        <div className={styles.group}>
                            <div className={styles.cardAction}>
                                <div className={styles.cardSave}>
                                    <button className={styles.Save} onClick={save}>Save</button>
                                </div>
                                <div className={styles.cardCancel}>
                                    <button className={styles.Cancel} onClick={cancelChanges}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Card>
            </div>
        </>
    )
}

export default Address