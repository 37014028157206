// import {createStore, applyMiddleware, compose} from 'redux'
// import thunk from 'redux-thunk'
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import rootReducer from '../reducer/rootreducer/rootReducer'

// const initialState = {};
// const middleWare = [thunk];

// const persistConfig = {
//     key: 'persist-key',
//     version : 1,
//     storage
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
// const store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleWare)))
// const persistor = persistStore(store)

// export default store
// export {persistor}

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import rootSaga from '../../sagas/rootSaga/rootSaga'; // Import your root saga
import rootReducer from '../reducer/rootreducer/rootReducer'

// Configure redux-persist for state persistence
const persistConfig = {
  key: 'root',
  storage,
};

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Compose enhancers for middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Apply both thunk and saga middleware
const middlewares = [thunk, sagaMiddleware];

// Create the store with the persisted reducer and middlewares
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

// Run the saga middleware
sagaMiddleware.run(rootSaga);

// Persistor for redux-persist
const persistor = persistStore(store);

export { store, persistor };