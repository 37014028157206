import React, {useState, useEffect} from "react";
import cn from "classnames";
import styles from './navHeader.module.sass'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "../Icon";
import EnvironmentSwitchTooltip from "../CustomTooltips/EnvironmentSwitchTooltip";
import { ReactComponent as  ServerIcon} from "../../media/images/icons/server_icon.svg";
import ProfilePlaceHolder from '../../media/images/placeholders/Profile.png'
import { useSelector } from "react-redux";
import { ReactComponent as  Home} from "../../media/images/icons/home.svg";

const NavHeader = ({className, wide, onOpen, type, isChanged, setIsChanged, loaderType, setLoaderType, appName, setAppName, showLoader, setShowLoader}) => {
    
    const {appid} = useParams()
    const Navigate = useNavigate();
    const { pathname } = useLocation();

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [lnk, setLnk] = useState()
  
    const handleClick = () => {
      onOpen();
      setVisible(false);
    };
  
    const navigation = [
      {
        title: "Currencies",
        url: `/currency/${appid}`,
      },
      {
        title: "Items",
        url: `/item/${appid}`,
      },
      {
        title: "Bundles",
        url: `/bundle/${appid}`,
      },
      {
        title: "Stores",
        url: `/store/${appid}`,
      }
    ]
  
    const progressionNavigation = [
      {
        title: "Progression Markers",
        url: `/progression-marker/${appid}`,
      },
      {
        title: "Progression Systems",
        url: `/progression-system/${appid}`,
      },
    ]
  
    const engageNavigation = [
      {
        title: "Tasks",
        url: `/task/${appid}`,
      },
      {
        title: "Missions",
        url: `/mission/${appid}`,
      },
      {
        title: "Step Series",
        url: `/step-series/${appid}`,
      }
    ]
  
    const competitionNavigation = [
      {
        title: 'Tournaments',
        url : `/competition-tournament/${appid}`
      },
      {
        title : 'Instant-Battles',
        url : `/competition-instant-battle/${appid}`
      }
    ]
    
    const appSettingsNavigation = [
      {
        title : 'App Information',
        url : `/app-information/${appid}`
      },
      {
        title: "Media",
        url: `/app-media/${appid}`,
      },
      {
        title : 'Members',
        url : `/app-members/${appid}`
      },
      {
        title : 'API',
        url : `/app-api/${appid}`
      }
    ]
  
    const LiveOpsNavigation = [
      {
        title : 'Competitions',
        url : `/competition-schedule/${appid}`
      },
      {
        title : 'Calendar',
        url : `/calendar/${appid}`
      }
    ]
  
    const ProfileSettingsNavigation = [
      {
        title : 'Organization',
        url : `/settings-organization-profile`
      },
      {
        title : 'Member',
        url : `/settings-member-profile`
      }
    ]
  
    const NavTo = (x) => {
      if (isChanged==true) {
        setLnk(x)
        setVisibleModal(true);
      } else {
        Navigate(x)
      }
    }

    const [environmentOption, setEnvironmentOption] = useState([{id : 1, name : 'Production', active: (localStorage.getItem('environment') =='Production' ? true :false) },{id : 2, name : 'Quality Assurance', active: (localStorage.getItem('environment') =='Staging' ? true :false)}, {id : 3, name : 'Development', active: (localStorage.getItem('environment') =='Development' ? true :false)}])
    const [environment, setEnvironment] = useState()

    const loaderConfigure = (loaderType) => {
      setLoaderType(loaderType)
      setShowLoader(true)
      setTimeout(() => {
          setShowLoader(false)
      }, 4000)
    }
  
    const getLocalStorageData = () => {
      const env = localStorage.getItem('environment')
      setEnvironment(env == 'Staging' ? 'Quality Assurance' : env)
    }

    useEffect(()=>{
      getLocalStorageData()
    }, [])

    const resetEnvironment = () => {
      localStorage.setItem('environment', 'Development')
      loaderConfigure(5)
    }

    const refreshPage = () => {
      localStorage.setItem('environment', "Development")
      loaderConfigure(4)
    }

    const [organisation, setOrganisation] = useState({name : '', iconUrl : ''})

    let OrgProfile = useSelector((state) => {
      return state.organisation.orgProfile
    })

    const configOrganisation = () => {
      if(OrgProfile){
        setOrganisation(org => ({...org, name : OrgProfile.name, iconUrl : OrgProfile.defaultLogoUrl}))
      }
    }
  
    useEffect(()=>{
      configOrganisation()
    }, [OrgProfile])

    return (
        <>
            <header className={cn(styles.header, className, {[styles.wide]: wide})}>
                <div className={styles.organization}>
                  <div className={styles.organizationLogo}>
                    {organisation.iconUrl !== null && <img src={organisation.iconUrl} alt="orgLogo" />}
                    {organisation.iconUrl === null && <div className={styles.backGround}><div className={styles.txt}>P</div></div>}
                  </div>
                  <div className={styles.organizationName}>
                    <h5 className={styles.orgText}>
                      {organisation.name}
                    </h5>
                  </div>
                </div>
                {window.location.pathname.includes('/home') &&
                  <>
                    <div className={styles.breadcrumbs}>
                      <div className={styles.icon}><Home /></div>
                      <div className={styles.title}>Home</div>
                    </div>
                  </>
                }
                {((type === 'App' || type === 'AppSettings') && !window.location.pathname.includes('/home')) &&
                  <>
                    <div className={styles.environmentWrapper}>
                      <EnvironmentSwitchTooltip type='detail' setLoaderType={setLoaderType} showLoader={showLoader} setShowLoader={setShowLoader} appName={appName} data={environmentOption} environment={environment}>
                        <div className={styles.environment}>
                          <ServerIcon className={environment == 'Production' ? styles.productionLogo : environment == 'Quality Assurance' ? styles.stagingLogo : styles.developLogo} />
                        </div>
                        <div className={styles.appDetail}>
                            <p className={styles.appName}>{appName}</p>
                            <p className={styles.env}>{environment}</p>
                        </div>
                        <div className={styles.downArrow}>
                            <Icon name='arrow-down' size='12' />
                        </div>
                      </EnvironmentSwitchTooltip>
                      <div className={styles.setting} onClick={() => Navigate(`/app-information/${appid}`)}>
                        <Icon name='setting' size='16' />
                      </div>
                    </div>
                  </>
              }
            </header> 
        </>
    );
}

export default NavHeader