import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import cn from "classnames";
import Card from "../../../components/Card";
import styles from './media.module.sass'
import FileUpload from '../../../components/File/FileUpload'
import { useParams } from "react-router-dom";
import axios from "axios";
import Toast from "../../../components/Toast";
import { BASE_URL, GET_MEDIA } from "../../../resources/APIEndpoints";
import FileCardComponent from '../../../components/AppSettingComponent/MediaNewFrame'
import { useDispatch, useSelector } from "react-redux";
import { addMediaAction, getAllMediaAction,deleteMediaAction } from "../../../redux/action/AppSettings/Media/mediaAction";
import EmptyStates from "../../../components/EmptyStates";
import Loader from "../../../components/Loader";
import GlobalStateContext from "../../../components/Context/GlobalStates/GlobalStateContext";
import { useContext } from "react";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Form from '../../../components/Form';
import {ReactComponent as Folder} from '../../../media/images/icons/folder 2.svg'
import Icon from "../../../components/Icon"
import {ReactComponent as ArchiveSvg} from '../../../media/images/icons/archive.svg'
import { Link } from "react-router-dom";
import Modal from "../../../components/Modals/ModalComponent";
import DeleteMediaModal from "../../../components/AppSettingComponent/MediaNewFrame/DeleteMediaModal"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import app from "../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const Media = ({className}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const {folderName} = useParams()
    const Tabs = ["Images", "Videos"]
    const [activeIndex, setActiveIndex] = useState(0)

    const [contents, setContents] = useState([])

    const db = getFirestore(app);

    const getMedia = () => {
        let media = {
            projectId : appid,
            filters : {
                "categories" : [folderName],
                // "extensions" : ["jpeg"]
            },
            // typeId : activeIndex,
            // category : folderName,
            offset: ((page-1)*limit),
            limit: limit,
        }
        dispatch(getAllMediaAction(media))
    }

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(12)
    const [count, setCount] = useState()

    const [search,setSearch]=useState('')

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)
    const [visibleModal, setVisibleModal] = useState(false);
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    useEffect(() => {
      getUserAccess()
    }, [])

    useEffect(() => {
        onSearch()
    }, [page,activeIndex,search])

    let media = useSelector((state) => {
        return state.media.media
    })
    
    let isLoading = useSelector((state) => {
        return state.media.isLoading
    })

    const [showLoader, setShowLoader] = useState(false)

    const handlePageChange = (event, value)  => {
        setPage(value)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const save = async() => {
        let fileContent = [...contents]
        const forEachPromise = new Promise((resolve) => {
            setShowLoader(true)
            fileContent && fileContent.forEach(async(e, i) => {
                const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${e.contentType}`)
                let fileUrl = res.data.getUrl
                fileContent[i].url = fileUrl
                var config = {method: 'put', url: res.data.postUrl,data : e.data}
                axios(config)
                .then(function (response) {
                    e.target.value = ''
                })
                .catch(function (error) {

                })
                // If it's the last iteration, resolve the Promise
                if(i === fileContent.length - 1) {
                    resolve();
                }
            })   
        });
        
        await forEachPromise;

        let files = {
            projectId : appid,
            contents : fileContent && fileContent.map(obj => {const { data, ...rest } = obj; return rest;})
        }
        
        try {
            await dispatch(addMediaAction(files, setContents, appid,folderName))
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "CREATE",
                app_id:appid,
                resource: "MEDIA",
                // resource_id: appid, // Use the ID from response
                description: `Media added successfully in ${capitalizeFirstLetter(folderName)}.`,
                quantity: 1,
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(files),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
            console.log("Activity logged successfully.");
        } catch (error) {
            
        }
    }

    const Cancel = () => {
        setContents([])
    }
    
    const getSearchMedia = async(search) => {
        let getMediaData = {
            search : search,
            projectId : appid,
            // category : folderName,
            filters : {
                "categories" : [folderName],
                // "extensions" : ["jpeg"]
            },
            offset: ((page-1)*limit),
            limit: limit
            // typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    const onSearch = () => {
        if(search == ''){
            getMedia()
        }else{
            getSearchMedia(search)
        }
      }

    const folders =[
        {
            name:"",
            link:`/media/folders/icons/${appid}`,
            title:"Icons"
        },
        {
            name:"",
            link:`/media/folders/assets/${appid}`,
            title:"Assets"
        },
        {
            name:"",
            link:`/media/folders/videos/${appid}`,
            title:"Videos"
        },
        {
            name:"",
            link:`/media/folders/bundles/${appid}`,
            title:"Bundles"
        },
        {
            name:"",
            link:`/media/folders/misc/${appid}`,
            title:"Misc"
        }
    ]



    //////file upload code-------

    const [files , setFiles] = useState(null)

    let usedFor = 'media'
    let setSidebar = false
        
    const onUploadProgress = (progressEvent) => {
        for(var i = 1 ; i <= document.getElementsByClassName('progress-bar-images').length; i++){
            const {loaded, total} = progressEvent;
            let percentage = Math.floor((loaded*100)/total)
            if(document.getElementById(`successfull${i}`)){
                document.getElementById(`successfull${i}`).style.width = `${percentage}%`
                if(percentage == 100){
                    document.getElementById(`percentage${i}`).style.display = 'none'
                    document.getElementById(`view${i}`).style.display = 'block'
                }
            }
        }
    }
    
    const onFileUpload = async(e) => {
        if(usedFor == 'media'){
            let fileContent = []
            for(var i = 0; i< e.target.files.length; i++){
                let fileData = e.target.files[i]
                let fileName = fileData.name
                let fileType = fileData.type
                let fileSize = fileData.size/1024
                let file_binary = await convertbase64(fileData)
                fileContent.push({contentType : `${fileType}`, fileSize : fileSize,category : folderName, fileName : fileName, url : null, data : convertDataURIToBinary(file_binary)})
            }
            const forEachPromise = new Promise((resolve) => {
                const token = localStorage.getItem('token')
                const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
                fileContent && fileContent.forEach(async(e, i) => {
                    const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${e.contentType}&projectId=${appid}&category=${folderName}&fileName=${e.fileName}`,headers)
                    let fileUrl = res.data.getUrl
                    fileContent[i].url = fileUrl
                    var config = {headers : {'Content-Type' : `${e.contentType}`}, method: 'put', url: res.data.postUrl,data : e.data}
                    axios(config)
                    .then(function (response) {
                        e.target.value = ''
                    })
                    .catch(function (error) {

                    })
                    // If it's the last iteration, resolve the Promise
                    if(i === fileContent.length - 1) {
                        resolve();
                    }
                })   
            });
            console.log("data",fileContent)
            await forEachPromise;

            let files = {
                projectId : appid,
                contents : fileContent && fileContent.map(obj => {const { data, ...rest } = obj; return rest;})
            }
            
            try {
                await dispatch(addMediaAction(files, setFiles, appid, folderName))
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))
    
                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "CREATE",
                    app_id:appid,
                    resource: "MEDIA",
                    // resource_id: appid, // Use the ID from response
                    description: `Media added successfully in ${capitalizeFirstLetter(folderName)}.`,
                    quantity: 1,
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(files),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
                console.log("Activity logged successfully.");
            } catch (error) {
                
            }
        }else if(usedFor == 'multiUpload'){
            let screenshotHolder = []
            for(var i = 0; i< e.target.files.length; i++){
                screenshotHolder.push(e.target.files[i])
            }        
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            screenshotHolder && screenshotHolder.forEach(async (element, index) => {
                const file_binary = await convertbase64(element)
                const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${element.type}&projectId=${appid}&category=${folderName}&fileName=${element.name}`,headers)
                let imageUrl = res.data.getUrl
                //setValue(prevState => [...prevState, imageUrl])
                var config = {headers : {'Content-Type' : `${element.type}`}, method: 'put',url: res.data.postUrl,data :convertDataURIToBinary(file_binary), onUploadProgress}
                axios(config)
                .then(async function (response) {
                    if(setSidebar){
                        let uploadLogo = {
                            projectId : appid,
                            contents : [{
                                contentType : element.type,
                                fileName : element.name,
                                url : res.data.getUrl,
                                category : folderName,
                                fileSize : element.size/1024
                            }]
                        }
                        
                        try {
                            await dispatch(addMediaAction(uploadLogo, null, appid,folderName))
                            const user = JSON.parse(localStorage.getItem('user'))
                            const memberID = user?.id
                            const memberName = user?.name
                            const orgId = (localStorage.getItem('orgId'))
                
                            // Log activity in Firestore
                            const activityLog = {
                                timestamp: new Date().toISOString(),
                                action_type: "CREATE",
                                app_id:appid,
                                resource: "MEDIA",
                                // resource_id: appid, // Use the ID from response
                                description: `Media added successfully in ${capitalizeFirstLetter(folderName)}.`,
                                quantity: 1,
operation_status: "SUCCESS",
                                activity_feed: true,
                                priority: "LOW",
                                performed_by_id: memberID,
                                organization_id: orgId,
                                performed_by:memberName,
                                request_json:JSON.stringify(uploadLogo),
                            };
                            await addDoc(collection(db, "activityFeed"), activityLog);
                            console.log("Activity logged successfully.");
                        } catch (error) {
                            
                        }
                    }
                    toast.success(<Toast type='Success' messages={`Screenshot ${index+1} Uploaded Successfully`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                })
                .catch(function (error) {
                    
                });
            });
        }
        else{
            if(e.target.files[0]) {
                // setFile({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
            } 
            var file = e.target.files[0];   
            const file_binary = await convertbase64(file)
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}&projectId=${appid}&category=${folderName}&fileName=${file.name}`,headers)
            // setIcon(res.data.getUrl)
            var config = {
                headers : {'Content-Type' : `${file.type}`},
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            axios(config)
            .then(async function (response) {
                if(setSidebar){
                    let uploadLogo = {
                        projectId : appid,
                        contents : [{
                            contentType : file.type,
                            fileName : file.name,
                            category : folderName,
                            url : res.data.getUrl,
                            fileSize : file.size/1024
                        }]
                    }
                    try {
                        await dispatch(addMediaAction(uploadLogo, null, appid,folderName))
                        const user = JSON.parse(localStorage.getItem('user'))
                        const memberID = user?.id
                        const memberName = user?.name
                        const orgId = (localStorage.getItem('orgId'))
            
                        // Log activity in Firestore
                        const activityLog = {
                            timestamp: new Date().toISOString(),
                            action_type: "CREATE",
                            app_id:appid,
                            resource: "MEDIA",
                            // resource_id: appid, // Use the ID from response
                            description: `Media added successfully in ${capitalizeFirstLetter(folderName)}.`,
                            quantity: 1,
operation_status: "SUCCESS",
                            activity_feed: true,
                            priority: "LOW",
                            performed_by_id: memberID,
                            organization_id: orgId,
                            performed_by:memberName,
                            request_json:JSON.stringify(uploadLogo),
                        };
                        await addDoc(collection(db, "activityFeed"), activityLog);
                        console.log("Activity logged successfully.");
                    } catch (error) {
                        
                    }
                    setSidebar(false)
                }
                toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            })
            .catch(function (error) {
                toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            });
        }
    }
    
    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
                  
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
            
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }



// Function to fetch and delete all media items
const deleteAllMedia = async () => {
    let pageCount =  Math.ceil(media?.totalCount/limit);
    let allItems = [];

    const token = localStorage.getItem('token');
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}


    for (let i = 0; i < pageCount; i++) {
        let media = {
            projectId: appid,
            filters: {
                "categories": [folderName],
            },
            offset: i * limit,
            limit: limit,
        };

        try {
            let res = await axios.post(BASE_URL + GET_MEDIA, media,headers);
            if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                res.data.data.mediaDetails.map((x)=>{
                    allItems.push(x.id);
                })
            }
        } catch (error) {
            console.error("Error fetching media:", error);
            toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
            return;
        }
    }

        let deleteMediaData = {
            projectId: appid,
            ids: allItems,
        };
        try {
            await dispatch(deleteMediaAction(deleteMediaData, appid, folderName));
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "ARCHIVE",
                app_id:appid,
                resource: "MEDIA",
                // resource_id: appid, // Use the ID from response
                description: `Media archived successfully from ${capitalizeFirstLetter(folderName)}.`,
                quantity: 1,
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(deleteMediaData),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
            console.log("Activity logged successfully.");
        } catch (error) {
            console.error("Error deleting media:", error);
            toast.error(<Toast type='Error' messages={`Failed to delete all media`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
        }
    

    toast.success(<Toast type='Success' messages='All media deleted successfully!' />, {
        position: 'top-right',
        icon: false,
        hideProgressBar: true,
        autoClose: 2000,
        style: { background: '#DEF1E5' }
    });
};



const downloadSingle = async (url,name) => {
    await fetch(url, {
    method: 'GET',
    responseType: 'arraybuffer'
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      name,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
  };




  const exportMedia = async () => {
    const pageCount = Math.ceil( media?.totalCount / limit);
    let exportedData = [];

    const token = localStorage.getItem('token');
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}

    for (let i = 0; i < pageCount; i++) {
        let media = {
            projectId: appid,
            filters: {
                "categories": [folderName],
            },
            offset: i * limit,
            limit: limit,
        };

        try {
            let res = await axios.post(BASE_URL + GET_MEDIA, media,  headers );
            if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                exportedData.push(...res.data.data.mediaDetails);
            }
        } catch (error) {
            console.error("Error fetching media:", error);
            toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
        }
    }

    // Create a new JSZip instance
    const zip = new JSZip();
    const folder = zip.folder(`${folderName}`);

    // Add images to the zip
    const filePromises = exportedData.map(async (file) => {
        if (!file.url) {
            console.error("File URL is undefined:", file);
            toast.error(<Toast type='Error' messages={`File URL is undefined: ${file.name}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
            return;
        }

        try {
            const decodedURL = decodeURIComponent(file.url);
            const response = await axios.get(decodedURL, { responseType: 'blob', headers: { 'Cache-Control': 'no-cache' } });
            if (response.status !== 200) {
                console.error(`Failed to fetch ${decodedURL}`, response.status, response.statusText);
                throw new Error(`Failed to fetch ${decodedURL}`);
            }
            const blob = response.data;
            const fileType = response.headers['content-type'];
            folder.file(file.name, blob, { binary: true });
        } catch (error) {
            console.error("Error fetching file:", error);
            toast.error(<Toast type='Error' messages={`Failed to fetch file: ${file.name}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
        }
    });

    await Promise.all(filePromises);

    // Generate the zip and trigger download
    zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `${folderName}.zip`);
    });
};



    ///////-------


    return(
        <>
            <ToastContainer />
            <Card className={styles.card} classCardHead={styles.head} title={folderName?`${folderName.slice(0,1).toLocaleUpperCase()}${folderName.slice(1,undefined)}`:`${folderName}`} classTitle={cn("title-blue", styles.title)} 
            // head={<div style={{width:"220px",marginLeft:"auto"}}><Form className={styles.form} placeholder="Search Images" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/></div>}
            head={
                    <div className={styles.cardAction}>
                        <Link to={`/app-media/${appid}`} className={styles.cardCancel}>
                            <button className={styles.Cancel} onClick={()=>{}}><Icon size={20} name='arrow-left' className={styles.icon} /><span style={{marginLeft:"4px"}}>Back</span></button>
                        </Link>
                        <label for={`files`} className={styles.cardSave}>
                            <label for={`files`} className={styles.Save} onClick={()=>{}}><Icon size={20} fill="#fff" name='upload' className={styles.icon} /><span style={{marginLeft:"4px"}}>Upload</span></label>
                            <input disabled={userAccess} multiple id={`files`} accept="image/*, video/*" className={cn(styles.input, {[styles.disabled] : userAccess})} type="file" onChange={(e)=>{onFileUpload(e)}}/>
                        </label>
                    </div>
                }
            >
                <div style={{height:"1px",background:"#EFEFEF",margin:"12px"}}></div>
                <div style={{marginBottom:"8px",display:"flex"}}>
                <div className={styles.filters}>
                    <div className={styles.buttons}>
                        <Icon name='filter' size='20' className={styles.icon} />
                        <span className={styles.buttonText}>Filter</span>
                    </div>
                    {/* <div className={styles.buttons}>
                        <ArchiveSvg className={styles.icon} />
                        <span className={styles.buttonText}>Show Archived</span>
                    </div> */}
                    <div className={styles.buttons} onClick={()=>exportMedia()}>
                        <Icon name='download' className={styles.icon} />
                        <span className={styles.buttonText}>Download All</span>
                    </div>
                    <div className={styles.buttons} onClick={()=>setVisibleModal(true)}>
                        <Icon name='trash' className={styles.icon} />
                        <span className={styles.buttonText}>Delete All</span>
                    </div>
                </div>
                    <div style={{width:"220px",marginLeft:"auto"}}><Form className={styles.form} placeholder="Search Images" type="text" name="search" icon="search" onChange={(e) => {setSearch(e.target.value)}}/></div>
                </div>
                <div className={styles.wrapper}>
                    {/* <div className={styles.fileUpload}>
                        <FileUpload disabled={userAccess} id={`files`} usedFor='media' label='' title={`Click or drop image/video`} value={contents} setValue={setContents} activeIndex={activeIndex}/>
                    </div> */}

                    {/* <div style={{marginTop:"16px"}}>
                        <Form className={styles.form} placeholder="Search Images" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
                    </div> */}

                    {media?.mediaDetails && media?.totalCount == 0 &&
                        // (activeIndex == 0 ?
                            <>
                                <EmptyStates type='Media'/>
                            </>
                        // : activeIndex == 1 ? 
                        //     <>
                        //         <EmptyStates type='Media'/>
                        //     </> 
                        // : '')
                    }

                    {media?.mediaDetails && media?.mediaDetails.length > 0 && isLoading == false &&
                        <div className={styles.filePreview}>
                            <div className={styles.fileWrapper}>
                                {media?.mediaDetails && media?.mediaDetails.map((x, index) => (
                                    <FileCardComponent access={userAccess} type={x.type == 'image' ? 'Images' :x.type == 'video' ? 'Videos':'others'} className={styles.product} item={x} key={index} released />
                                ))}
                            </div>
                        </div>
                    }
                    <Modal visible={visibleModal} onClose={() => {setVisibleModal(false)}}>
                        <DeleteMediaModal onProceed={()=>{deleteAllMedia()}} onClose={() => {setVisibleModal(false)}}/>
                    </Modal>
            {!isLoading && media?.mediaDetails&& media?.mediaDetails?.length > 0 &&
              <div className={styles.pagination}>
                <Stack spacing={2}>
                  <Pagination renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '&.MuiPaginationItem-root': {
                          minWidth: '28px',
                          height: '28px',
                          fontSize: '12px'
                        },
                        '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} page={page} count={Math.ceil(media?.totalCount/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            }
                </div> 
            </Card>    
        </>
    )
}

export default Media