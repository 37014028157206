import React, { useState, useRef, useEffect, useContext } from 'react'
import '../querybuilder.scss'
import cn from 'classnames'
import axios from 'axios'
import customAxios from '../../../../../../utils/interceptor'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {QueryBuilder, add, ActionWithRulesProps, Group, ActionElement, Rule} from 'react-querybuilder'
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import Schedule from '../Schedule'
import TextArea from '../../../../../../components/TextArea'
import Dropdown from '../../../../../../components/Dropdown';
import Radio from '../../../../../../components/Radio';
import Tooltip from "../../../../../../components/Tooltip";
import Checkbox from "../../../../../../components/Checkbox"
import CommonDropdown from '../../../../../../components/MultiselectDropDown/CommonDropdown'
import ContentTable from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/ContentTableLayout'
import Control from '../Control'
import styles from './taskInformation.module.sass'
import CustomCombinatorSelector from '../CustomQueryBuilder/CustomCombinatorSelector' 
import CustomFieldSelector from '../CustomQueryBuilder/CustomFieldSelector'
import CustomOperatorSelector from '../CustomQueryBuilder/CustomOperatorSelector'
import CustomValueEditor from '../CustomQueryBuilder/CustomValueEditor';
import {getCustomEventAction, getDefaultEventAction} from '../../../../../../redux/action/builds/events/eventActions'
import {editTaskAction, getAllTaskAction} from '../../../../../../redux/action/engage/Achievement/Task/TaskAction'
import Task from '../../../../../../media/images/placeholders/TAsk.png'
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddRuleIcon} from '../../../../../../media/images/icons/Rule_Add_SVG.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import { BASE_URL, GET_PROGRESSION_SYSTEM, GET_TASK } from '../../../../../../resources/APIEndpoints';
import { getAllCurrencyAction } from '../../../../../../redux/action/builds/economy/currency/currencyAction';
import { getAllItemAction } from '../../../../../../redux/action/builds/economy/items/itemAction';
import { getAllBundlesAction } from '../../../../../../redux/action/builds/economy/bundles/bundleAction';
import { getAllProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
// Static Data
import Toast from '../../../../../../components/Toast';
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle'
import JSONMetaDataValueInput from '../../../../../../components/MetaInput'
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext'
import Icon from '../../../../../../components/Icon'
import Switch from '../../../../../../components/Switch'
import _ from 'lodash'

const TaskInformation = ({className, onClose, setIsChanged}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const globalStates = useContext(GlobalStateContext)
    const {appid} = useParams()
    const {id} = useParams()
    const ref = useRef()

    // Task Informations
    const placeholder = Task
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [disable, setDisable] =  useState(false)
    const [iconUrl, setIconUrl] = useState()
    const [name, setName] = useState()
    const [taskId, setTaskId] = useState()
    const [description, setDescription] = useState()

    // Rewards
    const [content, setContent] = useState([])
    const [isLink, setIsLink] = useState(false)
    const [isLinkRewards, setIsLinkRewards] = useState(false)
    const [linkRewards, setLinkRewards] = useState([])

    // Access and Eligibility
    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])
    const [lockPSErr, setLockPSErr] = useState(false)
    const [displayId, setDisplayId] = useState(null)
    const [called, setCalled] = useState(false)

    const [userAccess, setUserAccess] = useState(false)
    const [editAccess, setEditAccess] = useState(true)

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setEditAccess(globalStates.access)
            setUserAccess(false)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
                setEditAccess(globalStates.access)
            }
        }else{

        }
    }

    useEffect(() => {
        getUserAccess()
    }, [globalStates.access])

    // const getUserAccess = () => {
    //     if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
    //         let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
    //         if(appPermission?.length > 0){
    //             setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
    //             globalStates.setAccess(true)
    //         }
    //     }
    // }
    
    // useEffect(() => {
    //     getUserAccess()
    // }, [])

    // const onToggleAccess = () => {
    //     if(!userAccess){
    //         setEditAccess(!editAccess)
    //         globalStates.setAccess(false)
    //     }
    // }

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setIconUrl(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setIconUrl(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onChangeName = (e) => {
        setName(e.target.value)
    }

    const onChangeId = (e) => {
        setTaskId(e.target.value)
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    // Add Rewards

    const [allCurrency, setAllCurrency] = useState([])
    const [currencyPage, setCurrencyPage] = useState(1)
    const [currencyLimit, setCurrencyLimit] = useState(10)
    
    const getAllCurrencyData = () => {
        let getAllCurrency = {
            projectId : appid,
            offset: ((currencyPage-1)*currencyLimit),
            limit: currencyLimit
        }
        dispatch(getAllCurrencyAction(getAllCurrency))
    }

    useEffect(() => {
        getAllCurrencyData()
    }, [currencyPage])

    const customizeCurrencyData = () => {
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = content.find(element => element.currencyId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllCurrency(updatedArray)
        }
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    useEffect(()=>{
        customizeCurrencyData()
    }, [currenciesData, called])

    const [allItems, setAllItems] = useState([])
    const [itemPage, setItemPage] = useState(1)
    const [itemLimit, setItemLimit] = useState(10)
    
    const getAllItemsData = () => {
        let items = {
            projectId : appid,
            offset: ((itemPage-1)*itemLimit),
            limit: itemLimit
        }
        dispatch(getAllItemAction(items))
    }

    useEffect(() => {
        getAllItemsData()
    }, [itemPage])


    let itemsData = useSelector((state) => {
        return state.item.items
    })

    const customizeItemData = () => {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map(ele => {
                const matchObj = content.find(element => element.itemId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllItems(updatedArray)
        }
    }

    useEffect(() => {
        customizeItemData()
    }, [itemsData, called])

    const [getAllBundle, setAllBundle] = useState([])
    const [bundlePage, setBundlePage] = useState(1)
    const [bundleLimit, setBundleLimit] = useState(10)

    const getAllBundlesData = () => {
        let bundles = {
            projectId : appid,
            offset: ((bundlePage-1)*bundleLimit),
            limit: bundleLimit
        }
        dispatch(getAllBundlesAction(bundles))
    }

    let bundleData = useSelector((state) => {
        return state.bundles.bundles
    })

    useEffect(() => {
        getAllBundlesData()
    }, [bundlePage])

    const customizeBundleData = () => {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map(ele => {
                const matchObj = content.find(element => element.bundleId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllBundle(updatedArray)
        }
    }

    useEffect(() => {
        customizeBundleData()
    }, [bundleData, called])

    const [allPM, setAllPM] = useState([])
    const [pmPage, setPMPage] = useState(1)
    const [pmLimit, setPMLimit] = useState(10)

    const getAllPMData = () => {
        let progressionMarkers = {
            projectId : appid,
            ids : [],
            offset: ((pmPage-1)*pmLimit),
            limit: pmLimit
        }
        dispatch(getAllProgressionMarkerAction(progressionMarkers))
    }

    useEffect(() => {
        getAllPMData()
    }, [pmPage])

    let pmData = useSelector((state)=>{
        return state.progressionMarkers.progressionMarker
    })

    const customizePMData = () => {
        if(pmData && pmData.length > 0){
            const updatedArray = pmData && pmData.map(ele => {
                const matchObj = content.find(element => element.progressionMarkerId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllPM(updatedArray)
        }
    }

    useEffect(() => {
        customizePMData()
    }, [pmData, called])

    const storeContent = () => {
        sessionStorage.setItem('contents', JSON.stringify(content))
    }

    const storeLinkReward = () => {
        sessionStorage.setItem('link-rewards', JSON.stringify(linkRewards))
    }

    useEffect(()=>{

    }, [content, linkRewards])

    useEffect(()=> {
        getAllCurrencyData()
        getAllItemsData()
        getAllBundlesData()
        getAllPMData()
        storeContent()
        storeLinkReward()
        getTaskDetails()
        getPS()
        getAllEvents()
        setTags([])
    }, [])

    // Configure

        const [type, setType] = useState('')
        const [typeId, setTypeId] = useState('')
        const typesOption = [{id : 1, name : 'static'},{id : 2, name : 'daily'},{id : 3, name : 'weekly'}]
        const [frequency, setFrequency] = useState(false)
        const [claim, setClaim] = useState(false)
        const [reward, setReward] = useState(true)
        const [event, setEvent] = useState([])
        const [eventOptions, setEventOption] = useState([])
        const [eventId, setEventId] = useState('')
    
        const [fields, setFields] = useState([])

        const combinators = [{name : 'and', label : 'and'}, {name : 'or', label : 'or'}]
    
        const [query, setQuery] = useState({combinator : 'and', rules : [{field : '', value : {value : null, type : false, allTime : true, noOfRecords : null}, operator : ''}]})
    
        const onchangeQuery = (newQuery) => {
            setQuery(newQuery);
        };
    
        const buildClasses = {
            queryBuilder : "qb-container",
            ruleGroup : 'rule-group',
            rule : 'rule',
            removeRule : 'remove'
        }
    
        const [rule, setRule] = useState([])
        const [config, setConfig] = useState()
    
        const checkBooleanValue = (value) => {
            if (value === true || value === 'true') {
              return true;
            }
            return false;
        }

        const convertRule = (rules) => {
            var result = JSON.parse(JSON.stringify(rules))
            var configure = []
            function recurssive(result){
                for(var i = 0; i < result.length ; i++){
                    var current = result[i]
                    if(current.field != undefined){
                        configure.push({...(current.field.id && current.field.id != undefined && {parameterId : current.field.id}), parameterName : current.field.name, operator : current.operator, value : (current.field.dataType == 'integer' && current.value.value != null && current.value.value != '' ? parseInt(current.value.value) : current.field.dataType == 'integer' && (current.value.value == null || current.value.value == '') ? null : current.field.dataType == 'boolean' ? checkBooleanValue(current.value.value) : current.field.dataType == 'float' ? parseFloat(current.value.value) : current.value.value), incrementalType : (current.value.type == true ? 'cumulative' : 'one-shot'), noOfRecords : ((current.value.type == true && current.value.allTime == false && current.value.noOfRecords != null) ? parseInt(current.value.noOfRecords) : (current.value.type == true && current.value.allTime == true) ? 'all' : null), type : current.field.type, dataType: current.field.dataType})
                        current.fact = current.field.name;
                        if(current.field.dataType == 'string'){
                            current.value = current.value.value
                        }else if(current.field.dataType == 'integer'){
                            current.value = ((current.value.value != null && current.value.value != '') ? parseInt(current.value.value) : null)
                        }else if(current.field.dataType == 'boolean'){
                            const booleanValue = checkBooleanValue(current.value.value)
                            current.value = booleanValue
                            //current.value = (current.value.value === 'true')
                        }else if(current.field.dataType == 'float'){
                            current.value = parseFloat(current.value.value)
                        }else{
                            current.value = current.value.value
                        }
                    }
                    delete current.valueSource
                    delete current.field
                    delete current.id
            
                    if(current && (current.rules || current.all || current.any) && (current?.rules?.length || current?.all?.length || current?.any?.length) > 0){
                        if(current.combinator == 'and'){
                            current.all = current['rules']
                        }else{
                            current.any = current['rules']
                        }
                        delete current.fact
                        recurssive(current.rules)
                        delete current.rules
                        delete current.combinator
                        delete current.not
                    }
                }
            }
            recurssive(result.rules)
            setRule(result.rules)
            setConfig(configure)
            return { rulesData: result.rules,  configData : configure};
        }
    
        const [eventPage, setEventPage] = useState(1)
        const [eventLimit, setEventLimit] = useState(100)
        const [isCalled, setIsCalled] = useState(false)
    
        const getAllEvents = async() => {
            let getEvents = {
                projectId : appid,
                offset: ((eventPage-1)*eventLimit),
                limit: eventLimit
            }
    
            Promise.all([await dispatch(getCustomEventAction(getEvents)), await dispatch(getDefaultEventAction(getEvents))]).then(res =>{
                setIsCalled(true)
            })
            
        }
    
        let customeEvents = useSelector((state) => {
            return state.event.customEvents
        })
        
        let defaultEvents = useSelector((state) => {
            return state.event.defaultEvents
        })
    
        let totalCustomEvents = useSelector((state)=>{
            return state.event.totalCustomEventCount
        })
        
        let totalDefaultvents = useSelector((state)=>{
            return state.event.totalDefaultEventCount
        })
    
        const configureEvent = () => {
            if(isCalled){
                const customEventData = customeEvents.map(v => ({...v, type: 'custom'}))
                const defaultEventData = defaultEvents.map(v => ({...v, type: 'default'}))
                let events = [...customEventData, ...defaultEventData]
                let allEvents = []
                if(events.length > 0){
                    events && events.forEach((e,i) => {
                        allEvents.push(e)
                    })
                    setEventOption(eventOption => [...eventOption, ...allEvents])
                    setIsCalled(false)
                }
            }
        }
    
        useEffect(()=>{
            configureEvent()
        }, [defaultEvents, customeEvents])
    
    
        const handleAddGroup = () => {
            const newGroup = {
                id: "new_group_" + Math.random(),
                combinator : 'and',
                not : false,
                rules: [],
            };
            const updatedQuery = {
                ...query,
                rules: [...(query.rules || []), newGroup],
            };
          
            setQuery(updatedQuery);
        }
    
        const AddRuleButton = (ActionWithRulesProps) => {
            const onClickFirst = () => setQuery(add(query,{field: '', operator: '', value: {value : null, type : false, allTime : true, noOfRecords : null}}, ActionWithRulesProps.path))
            return (
            <>
                <button className={styles.ml} onClick={onClickFirst}><AddRuleIcon /></button>
            </>
            );
        };
    
        const addGroupButton = () => {
            return(
                <></>
            )
        } 

    // Access and Eligibility

    const [lockBy, setLockBy] = useState(false)

    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id}])
        })
    }

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }

    // Custom Data

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]); 
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };

    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', typeErr : '', claimErr : '', eventIdErr : '', operatorErr : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name && data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('taskName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.taskId && data.taskId == '') {
            error.idError = 'Task ID is required';
            isValid = false;
            if(data.name && data.name != ''){
                const element = document.getElementById('taskId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (((data.customEventId && data.customEventId == undefined) && ((data.defaultEventId && data.defaultEventId == '') || (data.defaultEventId && data.defaultEventId == null))) || ((data.customEventId && data.defaultEventId == undefined) && ((data.customEventId && data.customEventId == '') || (data.customEventId && data.customEventId == null)))) {
            error.eventIdErr = 'Event is required';
            isValid = false;
            if((data.name && data.name != '') && (data.taskId && data.taskId != '')){
                const element = document.getElementById('event');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const validateQuery = (rules) => {
        var result = JSON.parse(JSON.stringify(rules))
        let isValid = true
        if(result.rules?.length > 0  && result.rules[0]?.field != ''){
            function recurssive(result){
                for(var i = 0; i < result.length ; i++){
                    var current = result[i]
                    if(current.rules === undefined || current.rules === null){
                        if(current.field === '' || current.field === undefined || current.field === null){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Parameter cannot be empty.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                        }
                        if(current.operator === '' || current.operator === undefined || current.operator === null || current.operator === '=' ){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Operator cannot be empty.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                        }
                        if(current.value?.value === '' || current.value?.value === undefined || current.value?.value === null){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Value cannot be empty.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                        }
                    }
                    if(current.rules && current.rules?.length > 0){
                        recurssive(current.rules)
                    }
                }
            }
            recurssive(result.rules)
        }
        if(!isValid){
            const element = document.getElementById('query-builder');
            const offset = 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
        return isValid;
    }

    const compareRewardsUnlockDetail = (data, compareArray) => {
        const result = data && data.filter(obj1 => {
            const matchingObj = compareArray && compareArray.find(obj2 => obj2.levelSystemId === obj1.levelSystemId); 
            if (!matchingObj) {
                // Include the object if it doesn't exist in array2 and archive is false
                return !obj1.archive;
            }
            const isLevelChanged = obj1.level !== matchingObj.levelNo;
            const isArchiveChanged = obj1.archive !== matchingObj.archive;
            if (isLevelChanged || isArchiveChanged) {
                // Add updatedLevel only if level is changed
                obj1.updatedLevel = isLevelChanged ? obj1.level : null;
                obj1.level = matchingObj.levelNo;
                return true;
            }
            return false;
        })
        return result
    }

    const compareLinkRewards = (linkedRewardDetails, array) => {
        const result = array && array.filter(item => {
          // Find if the item exists in linkRewards by id
          const existingItem = linkedRewardDetails.find(lr => lr.id === item.id);
          // Check for new entries, changed quantity, or archive status
          if (!existingItem) {
            if (item.archive === undefined) {
                item.archive = false;
            }        
            return item.archive === false; // New entry
          }
          return (
            item.quantity !== existingItem.quantity ||  item.archive === true // Archive is true
          );
        }).map(({ id, ...rest }) => rest)
      
        return result;
    }

    const compareMeta = (meta1, meta2) => {
        const keys1 = Object.keys(meta1);
        const keys2 = Object.keys(meta2);
        // If the number of keys or their values differ, return the whole meta2
        if (keys1.length !== keys2.length || !_.isEqual(meta1, meta2)) {
            return meta2; // Return the whole meta2 object
        }
        // Return null if there are no changes
        return null;
    }

    const checkRewardsContent = (arr1, arr2) => {
        const updatedObjects = arr2 && arr2.filter((item2) => {
            const item1 = _.find(arr1, { id: (item2.itemId ? item2.itemId : item2.currencyId ? item2.currencyId : item2.bundleId ? item2.bundleId : item2.progressionMarkerId ? item2.progressionMarkerId : null) });
            if (!item1) return false; // Skip if there's no corresponding object in the first array
            if(item2.itemId){
                return (
                    item1.id !== item2.itemId ||
                    item1.archive !== item2.archive ||
                    item1.quantity !== item2.quantity
                )
            }

            if(item2.currencyId){
                return (
                    item1.id !== item2.currencyId ||
                    item1.archive !== item2.archive ||
                    item1.quantity !== item2.quantity
                )
            }

            if(item2.bundleId){
                return (
                    item1.id !== item2.bundleId ||
                    item1.archive !== item2.archive ||
                    item1.quantity !== item2.quantity
                )
            }
            if(item2.progressionMarkerId){
                return(
                    item1.id !== item2.progressionMarkerId ||
                    item1.archive !== item2.archive ||
                    item1.quantity !== item2.quantity
                )
            }
        
            return false; // Skip other price types if needed
        })

        // Step 2: Find newly added objects in the second array (not in the first array)
        const newObjects = arr2 && arr2.filter(item2 => !_.find(arr1, { id: (item2.itemId ? item2.itemId : item2.currencyId ? item2.currencyId : item2.bundleId ? item2.bundleId : item2.progressionMarkerId ? item2.progressionMarkerId : null) }));

        // Step 3: Combine the changed and newly added objects
        return [...updatedObjects, ...newObjects]
    }

    const filterChanged = (obj1, obj2) => {
        return _.reduce(obj2, (result, value, key) => {
          // Always include 'id' and 'uuid' keys
          if (key === 'id' || key === 'projectId') {
            result[key] = value;
          }
          else if(key === 'rewardDetails'){
            let rewards = [...obj1.bundles, ...obj1.currencies, ...obj1.items, ...obj1.progressionMarkers]
            const updatedContent = checkRewardsContent(rewards, obj2.rewardDetails)
            if(updatedContent?.length > 0){
                result[key] = updatedContent
            }
          }
          // Special case: compare 'tags' in obj2 with 'tagsMapping' in obj1
          else if (key === 'tags') {
            const tagsFromMapping = obj1['tagsMapping'] && obj1['tagsMapping'].map(mapping => mapping.tag.name);
            if (!_.isEqual(tagsFromMapping, value)) {
              result[key] = value; // map tags changes to 'tagsMapping'
            }
          }
          // Compare nested objects (like 'meta')
          else if (key === 'meta' && _.isObject(value) && _.isObject(obj1[key])) {
            const metaChanges = compareMeta(obj1.meta, obj2.meta);
            if (metaChanges) {
              result.meta = metaChanges; // Include the whole new meta if there are changes
            }
          }
          // Normal comparison: add the key if values differ
          else if (!_.isEqual(obj1[key], value)) {
            result[key] = value;
          }
          return result;
        }, {})
    }

    const submit = async(visible, setVisible) => {

        let unlockPS = []
        
        PS && PS.map((e,i) =>{
            unlockPS.push({archive: (e.archive ? e.archive : false), unlockItemId : null, levelSystemId : e.id, level : e.lockedLevel})
        })

        if(lockBy === false) {
            unlockPS && unlockPS.map((e)=>(e.archive = true))
        }

        let conevertRules;
        let conevertConfig

        if(query.rules && query.rules.length > 0 && query.rules[0].field != ''){
            const {rulesData, configData} = convertRule(query);
            conevertRules = rulesData
            conevertConfig = configData
        }

        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let eventTags = []
        tags && tags.forEach((e)=>{
            eventTags.push(e.text)
        })

        const contents = JSON.parse(sessionStorage.getItem('contents'))
        const addedContent = contents.map(({iconUrl, name, type, displayId, id, ...content}) => content)
        const contentTask = addedContent.filter(item => !item.archive)

        const rewards = JSON.parse(sessionStorage.getItem('link-rewards'))
        const addedRewards = rewards && rewards.map(({iconUrl, name, type, displayId, ...content}) => content)
        const updatedRewards = await compareLinkRewards(checkRewards, addedRewards)

        let lockByPSLevel = await compareRewardsUnlockDetail(unlockPS, checkObject.levelDetails)

        let createTask = {
            projectId : appid,
            id : id,
            name : name,
            // type : type,
            ...(eventType == 'custom' ? {customEventId : eventId, defaultEventId : null} : {customEventId : null, defaultEventId : eventId}),
            taskId : taskId,
            description : description,
            iconUrl : iconUrl,
            rewardClaim : (reward == false ? 'automatic' : 'on-claim'),
            isLockedByLevel : lockBy,
            // isRecurring : ((type == 'static' || type == 'Static') ? frequency : false),
            isRecurring : frequency,
            ...(lockByPSLevel?.length > 0 && {levelDetails : lockByPSLevel}),
            tags : eventTags,
            meta : finalMetaData,
            ...(lockByPSLevel?.length > 0 && {levelDetails : lockByPSLevel}),
            ...(query.rules && query.rules.length > 0 && conevertRules != undefined && {config : conevertConfig}),
            rewardDetails : contentTask,
            ...(updatedRewards?.length > 0 && {linkedRewardDetails: updatedRewards}),
            ...((query.combinator == 'and' && (query.rules && query.rules.length > 0 && conevertRules != undefined)) ? {businessLogic : {all : conevertRules}} : (query.combinator == 'or' && (query.rules && query.rules.length > 0 && conevertRules != undefined)) ? {businessLogic : {any : conevertRules}} : {})
        }
        let task = await filterChanged(checkObject, createTask)
        if(validation(task)){
            if(validateQuery(query)){
                if(visible){
                    dispatch(editTaskAction(task, navigate, appid, setDisable,visible, setVisible))
                    setIsChanged(false)
                }else{
                    dispatch(editTaskAction(task, navigate, appid, setDisable))
                    setIsChanged(false)
                }
            }
        }
    }

    const [eventType, setEventType] = useState()

    const cancel = () => {
        setEditAccess(true)
        globalStates.setAccess(true)
        // navigate(`/task/${appid}`)
        setIsChanged(false)
    }

    // Get Task API Call Data

    const [checkObject, setCheckObject] = useState()
    const [checkRewards, setCheckRewards] = useState([])

    const getTaskDetails = async() => {
        let getTaskDetails = {
            projectId : appid,
            ids : [id],
            offset : 0,
            limit : 1
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_TASK, getTaskDetails, headers)
        let taskDetails = res.data.data.tasks
        setCheckObject(taskDetails[0])
        if(taskDetails && taskDetails.length > 0){
            // Information Data
            setLogo({src: (taskDetails[0].iconUrl != null ? taskDetails[0].iconUrl : Task), alt: 'Upload an Image'});
            setIconUrl(taskDetails[0].iconUrl)
            setName(taskDetails[0].name)
            setTaskId(taskDetails[0].taskId)
            setDescription(taskDetails[0].description)
            // Add Reward
            // Add Reward
            let RewardsData = [...taskDetails[0].currencies,...taskDetails[0].items, ...taskDetails[0].bundles, ...taskDetails[0].progressionMarkers]
            let contentData = []
            RewardsData && RewardsData.forEach((e,i) =>{
                if((e.currencyId != undefined || e.currencyId != null)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, currencyId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.currencyId})
                    }
                }
                if((e.itemId != null || e.itemId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, itemId : e.id, quantity : e.quantity, type : (e.isConsumable == true ? 'Consumable' : 'Durable'), archive : false, displayId : e.itemId})
                    }
                }
                if((e.bundleId != null || e.bundleId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, bundleId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.bundleId})
                    }
                }
                if((e.progressionMarkerId != null || e.progressionMarkerId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, progressionMarkerId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.progressionMarkerId})
                    }
                }
            })
            setContent(contentData)
            sessionStorage.setItem('contents', JSON.stringify(contentData))
            let totalLinkRewards = []
            let linkRewardData = taskDetails[0].linkedRewardDetails          
            let updatedLinkReward = [...linkRewardData.items, ...linkRewardData.bundles, ...linkRewardData.currencies, ...linkRewardData.progressionMarkers]
            if(updatedLinkReward?.length > 0){
                setIsLink(true)
            }
            updatedLinkReward && updatedLinkReward.forEach((e,i) =>{
                if((e.currencyId != undefined || e.currencyId != null)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, currencyId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.currencyId})
                    }
                }
                if((e.itemId != null || e.itemId != undefined)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, itemId : e.id, quantity : e.quantity, type : (e.isConsumable == true ? 'Consumable' : 'Durable'), archive : false, displayId : e.itemId})
                    }
                }
                if((e.bundleId != null || e.bundleId != undefined)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, bundleId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.bundleId})
                    }
                }
                if((e.progressionMarkerId != null || e.progressionMarkerId != undefined)){
                    if(e.archive == false){
                        totalLinkRewards.push({id: e.id, iconUrl : e.iconUrl, name : e.name, progressionMarkerId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.progressionMarkerId})
                    }
                }
            })
            setIsLinkRewards(!taskDetails[0].isLinkedRewardSameAsGeneralRewards)
            setLinkRewards(totalLinkRewards)
            setCheckRewards(totalLinkRewards)
            sessionStorage.setItem('link-rewards', JSON.stringify(totalLinkRewards))
            if(taskDetails[0].levelDetails && taskDetails[0].levelDetails.length > 0){
                setLockBy(true)
                taskDetails[0].levelDetails && taskDetails[0].levelDetails.forEach((ele, i) => {
                    if(ele.id){
                        if (!ele.archive) {
                            setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.levelSystemId, name : ele.levelSystemName, lockedLevel: ele.levelNo, archive:ele.archive}])
                        }
                        else{
                            setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.levelSystemId, name : ele.levelSystemName, lockedLevel: null, archive:ele.archive}])
                        }
                        // setPS(prevState => [...prevState, {id : ele.id , id : ele.levelSystemId, name : ele.levelSystemName, lockedLevel: ele.levelNo}])
                    }
                })
            }else{
                setLockBy(false)
            }
            // Configure
            setEventId(taskDetails[0].defaultEventId != null ? taskDetails[0].defaultEventId : taskDetails[0].customEventId)
            setDisplayId(taskDetails[0].defaultEventId != null ? taskDetails[0].defaultEventId : taskDetails[0].customEvent.eventId)
            setEventType(taskDetails[0].defaultEventId != null ? 'default' : 'custom')
            setEvent(taskDetails[0].defaultEventId != null ? taskDetails[0].defaultEvent.name : taskDetails[0].customEvent.name)
            setFrequency(taskDetails[0].isRecurring != null ? taskDetails[0].isRecurring : false)
            setType(taskDetails[0].type)
            setReward(taskDetails[0].rewardClaim == 'automatic' ? false : true)
            setClaim(taskDetails[0].rewardClaim == 'automatic' ? false : true)
            setConfig(taskDetails[0].config)
            setFields(taskDetails[0].defaultEventId != null ? [...taskDetails[0].defaultEvent.defaultParameterDetails, ...taskDetails[0].defaultEvent.customParameterDetails, ...taskDetails[0].defaultEvent.optionalParameterDetails, ...taskDetails[0].defaultEvent.specterParameterDetails] : taskDetails[0].customEventId ? [...taskDetails[0].customEvent.customParameterDetails, ...taskDetails[0].customEvent.defaultParameterDetails] : [])
            if(taskDetails[0].businessLogic != undefined && taskDetails[0].businessLogic != null && taskDetails[0].businessLogic.all != undefined && taskDetails[0].businessLogic.any == undefined){
                setRule(taskDetails[0].businessLogic.all)
            }else if(taskDetails[0].businessLogic != undefined && taskDetails[0].businessLogic != null && taskDetails[0].businessLogic.all == undefined && taskDetails[0].businessLogic.any != undefined){
                setRule(taskDetails[0].businessLogic.any)
            }
            setRuleDetails(taskDetails[0].businessLogic)
            // Custom Data
            taskDetails[0].tagsMapping && taskDetails[0].tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });
            let meta = []
            if(taskDetails[0].meta == null || JSON.stringify(taskDetails[0].meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                taskDetails[0].meta && Object.keys(taskDetails[0].meta).forEach(e => {
                meta.push({key : e, value : taskDetails[0].meta[e]})
            });
                setMetaData(meta)
            }
            setCalled(true)
        }
    }

    const getRequiredField = () => {

        let selectedEvent = eventOptions && eventOptions.filter(event => {
            return event.id === eventId
        })

        if(selectedEvent && selectedEvent.length > 0){
            if(selectedEvent[0].type == 'custom'){
                let field = [...selectedEvent[0].customParameterDetails,...selectedEvent[0].defaultParameterDetails]
                let fieldOptions = []
                field && field.forEach((e,i) => {
                    fieldOptions.push({id : e.id, name : e.name, inputType : e.dataType.dataTypeName, type : e.type})
                })
                setFields(field)
            }else{
                let field = [...selectedEvent[0].customParameterDetails, ...selectedEvent[0].defaultParameterDetails, ...selectedEvent[0].specterParameterDetails, ...selectedEvent[0].optionalParameterDetails]
                let fieldOptions = []
                field && field.forEach((e,i) => {
                    fieldOptions.push({id : e.id, name : e.name, inputType : e.dataType.dataTypeName, type : e.type})
                })
                setFields(field)
            }
        }
    }

    useEffect(() => {
        getRequiredField()
    }, [eventOptions, eventId])

    const [ruleDetails, setRuleDetails] = useState(null)


    const saveDecision = () => {
        if(ruleDetails == null || ruleDetails == undefined){
                
        }else{
            let result
            if(ruleDetails.all != undefined && ruleDetails.any == undefined){
                result = JSON.parse(JSON.stringify(ruleDetails.all))
            }else if(ruleDetails.all == undefined && ruleDetails.any != undefined){
                result = JSON.parse(JSON.stringify(ruleDetails.any))
            }
            let x = 0;
            if(result != undefined){
                function recurssive(result){
                    for(var i=0 ; i< result.length ; i++){
                        var current = result[i]
                        if(current.fact != undefined){
                            current.field = {name : current['fact'], id : config[x].parameterId, type : config[x].type, dataType : config[x].dataType}
                            current.value = {id: x, value : current.value, type : (config[x].incrementalType == 'one-shot' ? false : true), allTime : ((config[x].incrementalType == 'cumulative' && config[x].noOfRecords != 'all') ? false : true), noOfRecords : ((config[x].incrementalType == 'cumulative' && config[x].noOfRecords != null && config[x].noOfRecords != 'all' ) ? config[x].noOfRecords : null)}
                            x++
                            delete current.fact
                        }
                        if((current.all || current.any || current.rules)){
                            if(current.all){
                                current.rules = current['all']
                                current.combinator = 'and'
                                recurssive(current.all)
                                delete current.all
                            }else{
                                current.rules = current['any']
                                current.combinator = 'or'
                                recurssive(current.any)
                                delete current.any
                            }
                        }
                    }
                }
                recurssive(result)
                let initialQuery
                if(ruleDetails.all != undefined && ruleDetails.any == undefined){
                    initialQuery = {
                        combinator : 'and',
                        rules : result
                    }
                }else if(ruleDetails.all == undefined && ruleDetails.any != undefined){
                    initialQuery = {
                        combinator : 'or',
                        rules : result
                    }
                }
                setQuery(initialQuery)
            }
        }
    }

    useEffect(()=>{
        saveDecision()
    }, [ruleDetails])

    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    function compareQueryConfigArrays(arr1, arr2) {
         
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)


        if((array1==null||array1==undefined)&&(array2==null||array2==undefined)){
            return true
        }
        else if((array1==null||array1==undefined)||(array2==null||array2==undefined)){
            return false
        }
        if (array1?.length !== array2?.length) {
            return false;
        }
        if((array1==null||array1==undefined)&&(array2==null||array2==undefined)){
            return true
        }
        else if((array1==null||array1==undefined)||(array2==null||array2==undefined)){
            return false
        }
        // Iterate over the arrays
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]?.dataType==array2[i]?.dataType)&&
                (array1[i]?.incrementalType==array2[i]?.incrementalType)&&
                (array1[i]?.operator==array2[i]?.operator)&&
                (array1[i]?.parameterId==array2[i]?.parameterId)&&
                (array1[i]?.parameterName==array2[i]?.parameterName)&&
                (parseInt(array1[i]?.noOfRecords)==parseInt(array2[i]?.noOfRecords)||(array2[i]?.noOfRecords==null && array1[i]?.noOfRecords==null))&&
                (array1[i]?.type==array2[i]?.type)&&
                (array1[i]?.value==array2[i]?.value)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareQueryBusinessLogicArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if((array1==null||array1==undefined)&&(array2==null||array2==undefined)){
            return true
        }
        else if((array1==null||array1==undefined)||(array2==null||array2==undefined)){
            return false
        }
        if (array1?.length !== array2?.length) {
            return false;
        }
        if((array1==null||array1==undefined)&&(array2==null||array2==undefined)){
            return true
        }
        else if((array1==null||array1==undefined)||(array2==null||array2==undefined)){
            return false
        }
        // Iterate over the arrays
        for (let i = 0; i < array1.length; i++) {
            if (array1[i]?.all) {
                if (array1[i]?.all) {
                    for (let n = 0; n < array1[i]?.all.length; n++) {
                        let all1 = array1[i]?.all[n];
                        let all2 = array2[i]?.all[n];
                        if (
                            (all1?.fact==all2?.fact)&&
                            (all1?.operator==all2?.operator)&&
                            (parseInt(all1?.value)==parseInt(all2?.value))
                        ) {
                            
                        } else {
                            return false;
                        }
                    }
                }
                else {
                    return false;
                }
                
            } else if (array1[i]?.any) {
                if (array2[i]?.any) {
                    for (let m = 0; m < array1[i]?.any.length; m++) {
                        const any1 = array1[i]?.any[m];
                        const any2 = array1[i]?.any[m];
                            if (
                            (any1?.fact==any2?.fact)&&
                            (any1?.operator==any2?.operator)&&
                            (parseInt(any1?.value)==parseInt(any2?.value))
                        ) {
                            
                        } else {
                            return false;
                        }
                    }
                }
                else {
                    return false;
                }

                
            }
            else {
                if (
                    (array1[i]?.fact==array2[i]?.fact)&&
                    (array1[i]?.operator==array2[i]?.operator)&&
                    (parseInt(array1[i]?.value)==parseInt(array2[i]?.value))
                ) {
                    
                } else {
                    return false;
                }

            }
        }

        return true;
    }

    function compareUnlockConditionsArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.lockedLevel)===array2[i]?.lockedLevel|| array2[i]?.lockedLevel==undefined|| array2[i]?.lockedLevel==null)&&
                (array1[i]?.unlockLevelSystemId===array2[i]?.unlockLevelSystemId || array2[i]?.unlockLevelSystemId==undefined|| array2[i]?.unlockLevelSystemId==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareContentArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false;
        }
    
        // Iterate over the arrays
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]?.id==array2[i]?.id || array2[i]?.id==undefined|| array2[i]?.id==null)&&
                parseInt(array1[i].quantity)===array2[i].quantity
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    const setchange = () => {
        
        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })
        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({id: e.dbID, unlockItemId : null, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })
        let lkBy 
        let ps=[]
        let contentCheck
        let cfg
        let bsnsLogic
        if((query?.rules?.length>0 && query?.rules[0].field != '')){
            const {rulesData, configData} = convertRule(query);
            cfg = configData
            if (query.combinator == 'and' && (query.rules && query.rules.length > 0 && query.rules[0].field != '')) {
                bsnsLogic = rulesData
            } else if (query.combinator == 'or' && (query.rules && query.rules.length > 0 && query.rules[0].field != '')) {
                bsnsLogic = rulesData
            }
        }
        
        

        let configg 
        let businessLogicc


        if (checkObject) {
            let RewardsData = [...checkObject?.currencies,...checkObject?.items, ...checkObject?.bundles, ...checkObject?.progressionMarkers]
            let contentData = []
            

            
            RewardsData && RewardsData.forEach((e,i) =>{
                if((e.currencyId != undefined || e.currencyId != null)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, currencyId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.currencyId})
                    }
                }
                if((e.itemId != null || e.itemId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, itemId : e.id, quantity : e.quantity, type : (e.isConsumable == true ? 'Consumable' : 'Durable'), archive : false, displayId : e.itemId})
                    }
                }
                if((e.bundleId != null || e.bundleId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, bundleId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.bundleId})
                    }
                }
                if((e.progressionMarkerId != null || e.progressionMarkerId != undefined)){
                    if(e.archive == false){
                        contentData.push({id: e.id, iconUrl : e.iconUrl, name : e.name, progressionMarkerId : e.id, quantity : e.quantity, type : null, archive : false, displayId : e.progressionMarkerId})
                    }
                }
            })
            contentCheck = checkObject && compareContentArrays(content, contentData)
            
            if(checkObject.levelDetails && checkObject.levelDetails.length > 0){
                lkBy=true
                checkObject.levelDetails && checkObject.levelDetails.forEach((ele, i) => {
                    if(ele.id){
                        ps.push({id : ele.id , id : ele.levelSystemId, name : ele.levelSystemName, lockedLevel: ele.levelNo})
                    }
                })
            }else{
                lkBy=false
            }
            
            configg=checkObject?.config
            if(checkObject.businessLogic != undefined && checkObject.businessLogic != null && checkObject.businessLogic.all != undefined && checkObject.businessLogic.any == undefined){
                businessLogicc=checkObject.businessLogic.all
            }else if(checkObject.businessLogic != undefined && checkObject.businessLogic != null && checkObject.businessLogic.all == undefined && checkObject.businessLogic.any != undefined){
                businessLogicc=checkObject.businessLogic.any
            }
        }
        let psCheck=checkObject && ((checkObject.levelDetails&& compareUnlockConditionsArrays(unlockPS, ps))||!ps)
        let cnfgCheck=checkObject&& compareQueryConfigArrays(cfg, configg)
        let bsnsCheck=checkObject && compareQueryBusinessLogicArrays(bsnsLogic, businessLogicc)

        if (checkObject&&
            (iconUrl==checkObject.iconUrl)&&
            (name===checkObject.name)&&
            (taskId===checkObject.taskId)&&
            (description==checkObject.description||(checkObject.description==null&&description==''))&&
            (contentCheck)&&
            (type==checkObject.type)&&
            (frequency==checkObject.isRecurring)&&
            (eventId==(checkObject.defaultEventId != null ? checkObject.defaultEventId : checkObject.customEventId))&&
            (reward==checkObject.rewardClaim == 'automatic' ? false : true)&&
            (lockBy==((checkObject.levelDetails && checkObject.levelDetails.length > 0)?true:false))&&
            (psCheck)&&
            (cnfgCheck)&&
            // (bsnsCheck)&&
            // (query?.combinator=='and'&&query?.rules?.length==1&&(query?.rules[0]?.value?.value==null||query?.rules[0]?.value?.value=='')&&query?.rules[0]?.operator=='')&&
            // ((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject?.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }
    
    useEffect(()=>{
        setchange()
    },[iconUrl,name,taskId,description,content,type,frequency,eventId,reward,PS,fields,query,eventType,displayId,tags,metaData])

    const onChangeLink = (e) => {
        if(e.target.checked){
            setIsLink(true)
            setLinkRewards(checkRewards)
            sessionStorage.setItem('link-rewards',  JSON.stringify(checkRewards))
        }else{
            let rewards = JSON.parse(sessionStorage.getItem('link-rewards'))
            let allRewards = rewards.map(e => ({...e, archive: true}))
            setIsLink(false)
            setIsLinkRewards(isLinkRewards)
            setLinkRewards(allRewards)
            sessionStorage.setItem('link-rewards',  JSON.stringify(allRewards))
        }
    }

    const onChangeLinkRewards = (e) => {
        if(isLinkRewards){
            let rewards = JSON.parse(sessionStorage.getItem('link-rewards'))
            let allRewards = [...content, ...rewards.map(e => ({...e, archive: true}))]
            setIsLinkRewards(false)
            setLinkRewards(allRewards)
            sessionStorage.setItem('link-rewards',  JSON.stringify(allRewards))
        }else{
            setIsLinkRewards(true)
            setLinkRewards(checkRewards)
            sessionStorage.setItem('link-rewards',  JSON.stringify(checkRewards))
        }
    }

    const configureRewards = () => {
        if(isLink){
            if(!isLinkRewards){
                setLinkRewards(content)
                sessionStorage.setItem('link-rewards',  JSON.stringify(content))
            }
        }else{
            setLinkRewards([])
            sessionStorage.setItem('link-rewards',  JSON.stringify([]))
        }
    }

    useEffect(() => {
        configureRewards()
    }, [content])

    return(
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>   
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                            {!editAccess && <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        {iconUrl != null && !editAccess && 
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        }
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={iconUrl} setIcon={setIconUrl} setValue={setLogo} onClose={onClose} />
                    <div className={styles.infoGroup}>
                        <div className={styles.group}>
                            <TextInput disabled={editAccess} tooltip={TooltipTitle.TaskName} id='taskName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required value={name} onChange={(e)=>{e.target.value == '' ? setName('') : setName(e.target.value); errors.nameError = ''}} />
                            <TextInput disabled={editAccess} tooltip={TooltipTitle.TaskId} id='taskId' className={styles.field} errorMessage={errors.idError} label="Task ID*" name="itemId" type="text" required value={taskId} onChange={(e)=>{e.target.value == '' ? setTaskId('') : setTaskId(e.target.value); errors.idError = ''}} />
                        </div>

                        <div className={styles.textArea}>
                            <TextArea disabled={editAccess} tooltip={TooltipTitle.TaskDescription} className={styles.field} rows={5} label="Task Description" value={description} onChange={(e)=>{setDescription(e.target.value)}} />
                        </div>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Add Rewards" classTitle="title-red"
                head={
                    <div className={styles.accessEligibility}>
                        <div className={styles.groupHeading}>
                            <Control pageItem={itemPage} pageCurrnecy={currencyPage} pageBundle={bundlePage} pagePM={pmPage} type='Task' currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} itemPage={setItemPage} bundles={getAllBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} PM={allPM} setPM={setAllPM} PMCount={pmLimit} PMPage={setPMPage} value={content} setValue={setContent} setCalled={setCalled} width='medium'/>
                        </div>
                    </div>
                }
            >
            {content && content.length > 0 &&
                <div className={styles.cardDescription}>
                    
                        <>
                            <div className={styles.accessEligibility}>
                                <div className={styles.content}>
                                    <div className={styles.wrapper}>
                                        <ContentTable access={editAccess} items={content} setValue={setContent} allCurrency={allCurrency} setAllCurrency={setAllCurrency} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle} allPM={allPM} setAllPM={setAllPM} />
                                    </div>
                                </div>
                            </div>
                        </>
                </div>
            }
            </Card>
            
            <Card className={cn(styles.card, className)} title="Link Rewards" classTitle="title-red"
            head={
                <>
                    <div className={styles.selection}>
                        <Switch value={isLink} onChange={(e) => {onChangeLink(e)}}/>
                    </div>
                </>
            }>
                {isLink &&
                    <div className={styles.cardDescription}>
                        <div className={styles.accessEligibility}>
                                <div  className={styles.group}>
                                    <TextInput className={styles.field} label="Link Reward Type" name="linkeward" type="text" required disabled value='User Referral' />
                                    <div className={styles.fieldRadio}>
                                        <div className={styles.radioLabel}>
                                            <span>Is link rewards same as task rewards?</span>
                                        </div>
                                        <div className={styles.variants}>
                                            <Radio className={styles.radioConfig} value={!isLinkRewards} name="isLinkRewards" onChange={(e) => {onChangeLinkRewards(e)}} content="Yes" />
                                            <Radio className={styles.radioConfig} value={isLinkRewards} name="isLinkRewards" onChange={(e) => {onChangeLinkRewards(e)}} content="No" />
                                        </div>
                                    </div>
                                </div>
                            {isLinkRewards &&
                                <Control usedFor='Link Rewards' type='Task' pageItem={itemPage} pageCurrnecy={currencyPage} pageBundle={bundlePage} pagePM={pmPage} currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} itemPage={setItemPage} bundles={getAllBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} PM={allPM} setPM={setAllPM} PMCount={pmLimit} PMPage={setPMPage} value={linkRewards} setValue={setLinkRewards} setCalled={setCalled} width='medium'/>
                            } 
                        </div>
                        {linkRewards && linkRewards?.length > 0 &&
                            <div className={styles.accessEligibility}>
                                <div className={styles.content}>
                                    <div className={styles.wrapper}>
                                        <ContentTable usedFor='Link Rewards' disable={!isLinkRewards} items={linkRewards} setValue={setLinkRewards} allCurrency={allCurrency} setAllCurrency={setAllCurrency} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle} allPM={allPM} setAllPM={setAllPM}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </Card>

            <Card className={cn(styles.card, className)} title="Configure" classTitle="title-green">
                <div className={styles.cardDescription}>

                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Task Frequency</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={editAccess} className={styles.radioConfig} value={!frequency} name="frequency" onChange={() => setFrequency(false)} content="One Time" />
                                    <Radio disabled={editAccess} className={styles.radioConfig} value={frequency} name="frequency" onChange={() => setFrequency(true)} content="Every Time" />
                                </div>
                            </div>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Grant Reward<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskGrant} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={true} className={styles.radioConfig} value={!reward} name="reward" onChange={() => setReward(false)} content="Server Side" />
                                    <Radio disabled={editAccess} className={styles.radioConfig} value={reward} name="reward" onChange={() => setReward(true)} content="Client Side" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.field}>
                                <Dropdown disabled={editAccess} setIsCalled={setIsCalled} eventPage={eventPage} setEventPage={setEventPage} eventLimit={eventLimit} limit={totalCustomEvents+totalDefaultvents} tooltip={TooltipTitle.TaskEvent} id='event' label='Event Name*' errorMessage={errors.eventIdErr} placeholder='Select Event' options={eventOptions} value={event} setValue={setEvent} setDisplayId={setDisplayId} setField={setFields} setId={setEventId} setType={setEventType}/>
                            </div>
                            <TextInput tooltip={TooltipTitle.TaskEventId} className={styles.field} disabled value={displayId} label="Event ID" name="itemId" type="text" />
                        </div>
                    </div>

                    <div className={styles.configure}>
                        <span className={styles.params}>Parameter Type<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskEventRuleEngine} icon="info"/></span><span className={styles.addGroup} onClick={handleAddGroup}><AddMetaIcon /></span></span>
                    </div>
                    <div className={styles.configure} id='query-builder'>
                        <QueryBuilder controlClassnames={buildClasses} combinators={combinators} query={query} fields={fields} onQueryChange={onchangeQuery} controlElements={{combinatorSelector : (props) => (<CustomCombinatorSelector {...props} />), fieldSelector : (props) => (<CustomFieldSelector {...props} />), operatorSelector : (props) => (<CustomOperatorSelector {...props} />)  ,valueEditor : CustomValueEditor, addRuleAction : AddRuleButton , addGroupAction : props => (props.level === 0 ? <addGroupButton {...props} /> : null)}}/>
                    </div>

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by Level<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={editAccess} className={styles.radio} value={lockBy} name="lockBy" onChange={() => setLockBy(true)} content="Yes" />
                                    <Radio disabled={editAccess} className={styles.radio} value={!lockBy} name="lockBy" onChange={() => setLockBy(false)} content="No" />
                                </div>
                            </div>
                            {lockBy &&
                                <div className={styles.field}>
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskPSLevel} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown disabled={editAccess} placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    
                    {lockBy &&
                        <div className={styles.accessEligibility}>
                            <div className={styles.group}>
                                {PS && PS.map((element, index) =>{
                                    return(
                                        <>
                                            <TextInput disabled={editAccess} tooltip={TooltipTitle.TaskPSLevel} className={styles.field} label={`${element.name}`} currency='Level' style='grey' value={element.lockedLevel} type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e) => {onChangeLevel(e.target.value, index)}}/>
                                            {/* <div className={styles.field}>
                                                <LevelDropdown key={index} label={`${element.name}`} name={`Level${index}`} placeholder='Select Level' options={element.levelSystemLevelMapping} value={PS} setValue={setPS} index={index}/>
                                            </div> */}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
            <div className={styles.cardDescription}>
                    <div className={styles.customData}>
                        
                    <TagInputWithDropdown disabled={editAccess} tooltip={TooltipTitle.TaskTags} tags={tags} setTags={setTags}/>

                        <div className={styles.groupHeading}>
                            <span className={styles.spanTitle}>Meta Data<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskMetaData} icon="info"/></span></span>{!editAccess && <span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>}
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput disabled={editAccess} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                        <JSONMetaDataValueInput disabled={editAccess} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {!editAccess && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>}
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            {!editAccess &&
            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={()=>{submit(false, null , null)}}>Save</button>
                    </div>
                    <div className={styles.cardSave}>
                        {checkObject && <Schedule liveOpsStatus={checkObject.status} id={id} as={`saveNschedule`} type='Task' saveTask={submit} name={name}/>}
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
            }
        </>
    )

}

export default TaskInformation