import React, { useEffect, useState, useRef, useContext } from "react";
import cn from "classnames";
import Toast from "../../../../../../components/Toast";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import styles from "./currencyinfo.module.sass";
import Card from "../../../../../../components/Card";
import Icon from "../../../../../../components/Icon";
import TextInput from "../../../../../../components/TextInput";
import TextArea from "../../../../../../components/TextArea";
import { editCurrecyAction, getAllCurrencyAction, getCurrencyByIdAction } from "../../../../../../redux/action/builds/economy/currency/currencyAction";
import { useDispatch, useSelector, connect } from "react-redux";
import axios from "axios";
import customAxios from "../../../../../../utils/interceptor";
import { BASE_URL, GET_CURRENCY } from "../../../../../../resources/APIEndpoints";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import addIcon from '../../../../../../media/images/icons/add.png'
import { WithContext as ReactTags } from "react-tag-input";
import Tooltip from "../../../../../../components/Tooltip";
import {ReactComponent as Remove} from '../../../../../../media/images/icons/remove.svg'
import Currency from '../../../../../../media/images/placeholders/Currency.png'
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from "../../../../../../components/FileUploadSidebar";
import { getAllMediaAction } from "../../../../../../redux/action/AppSettings/Media/mediaAction";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../components/MetaInput";
import GlobalStateContext from "../../../../../../components/Context/GlobalStates/GlobalStateContext";
import _ from 'lodash'
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const CurrencyInformation = ({className, isChanged,setIsChanged, onClose}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const {appid} = useParams()
    const globalStates = useContext(GlobalStateContext)
    const {id} = location.state
    const ref = useRef()
    const [checkObject, setCheckObject] = useState()
    const placeholder = Currency
    const[{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [currencyIcon, setCurrencyIcon] = useState(null)
    //const [changedIcon, setChangedIcon] = useState("")
    const [displayName, setDisplayName] = useState('');
    const [currencyId, setCurrencyId] = useState('')
    const [currencyCode, setCurrencyCode] = useState();
    const [currencyType, setCurrencyType] = useState('');
    const [currencyDescription, setCurrencyDescription] = useState()
    const [tags, setTags] = useState([]);
    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const getCurrecyById = async() => {

        let currencyById = {
            projectId : appid,
            ids : [id]
        }

        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_CURRENCY, currencyById, headers)
        let itemId = res.data.data.currenciesDetails[0]
        setCheckObject(itemId)
        if(res){
            setLogo({src: (res.data.data.currenciesDetails[0].iconUrl != null ? res.data.data.currenciesDetails[0].iconUrl : Currency), alt: 'Upload an Image'})
            setCurrencyIcon(res.data.data.currenciesDetails[0].iconUrl)
            setDisplayName(res.data.data.currenciesDetails[0].name)
            setCurrencyId(res.data.data.currenciesDetails[0].currencyId)
            setCurrencyCode(res.data.data.currenciesDetails[0].code)
            setCurrencyType(res.data.data.currenciesDetails[0].type)
            setCurrencyDescription(res.data.data.currenciesDetails[0].description)
            res.data.data.currenciesDetails[0].tagsMapping && res.data.data.currenciesDetails[0].tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });
            let meta = []
            if(res.data.data.currenciesDetails[0].meta == null || JSON.stringify(res.data.data.currenciesDetails[0].meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                res.data.data.currenciesDetails[0].meta && Object.keys(res.data.data.currenciesDetails[0].meta).forEach(e => {
                    meta.push({key : e, value : res.data.data.currenciesDetails[0].meta[e]})
                })
                setMetaData(meta)
            }

        }
    }

    useEffect(()=>{
        getCurrecyById()
    }, [])

    const onDisplayName = (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    } 

    const onCurrencyCode = (e) => {
        setCurrencyCode(e.target.value)
    }

    const onCurrencyId = (e) => {
        if(e.target.value == ''){
            setCurrencyId('')
        }else{
            errors.idError = ''
            setCurrencyId(e.target.value)
        }
    }

    const onCurrencyType = (e) => {
        setCurrencyType(e.target.value) 
    }

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]),alt: e.target.files[0].name});    
        }      
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setCurrencyIcon(res.data.getUrl)
        //setChangedIcon(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setCurrencyIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
        //setChangedIcon('')
    }

    const cancel = () => {
        navigate(`/currency/${appid}`)
        setIsChanged(false)
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', typeError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if(data.name && data.name == '') {
            error.nameError = 'Display name is required';
            isValid = false;
            const element = document.getElementById('currencyName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if(data.currencyId && data.currencyId == '') {
          error.idError = 'Currency ID is required';
          isValid = false;
          if(data.name && data.name != ''){
            const element = document.getElementById('currencyId');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }

        if(data.type && data.type == ''){
            error.typeError = 'Currency Type is required'
            isValid = false
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const compareMeta = (meta1, meta2) => {
        const keys1 = Object.keys(meta1);
        const keys2 = Object.keys(meta2);
        // If the number of keys or their values differ, return the whole meta2
        if (keys1.length !== keys2.length || !_.isEqual(meta1, meta2)) {
            return meta2; // Return the whole meta2 object
        }
        // Return null if there are no changes
        return null;
    }

    const filterChanged = (obj1, obj2) => {
        return _.reduce(obj2, (result, value, key) => {
            // Always include 'id' and 'uuid' keys
            if (key === 'id' || key === 'projectId') {
              result[key] = value;
            }
            // Special case: compare 'tags' in obj2 with 'tagsMapping' in obj1
            else if (key === 'tags') {
                const tagsFromMapping = obj1['tagsMapping'] && obj1['tagsMapping'].map(mapping => mapping.tag.name);
                if (!_.isEqual(tagsFromMapping, value)) {
                  result[key] = value; // map tags changes to 'tagsMapping'
                }
            }
            // Compare nested objects (like 'meta')
            else if (key === 'meta' && _.isObject(value) && _.isObject(obj1[key])) {
                const metaChanges = compareMeta(obj1.meta, obj2.meta);
                if (metaChanges) {
                    result.meta = metaChanges; // Include the whole new meta if there are changes
                }
            }
            // Normal comparison: add the key if values differ
            else if (!_.isEqual(obj1[key], value)) {
              result[key] = value;
            }
            return result;
        }, {})
    }

    const db = getFirestore(app);

    const submit = async() => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })
        const currencyData = {
            projectId : appid,
            id : id,
            iconUrl : currencyIcon,
            name : displayName,
            currencyId : currencyId,
            code : currencyCode,
            type : currencyType,
            description : currencyDescription,
            meta : finalMetaData,
            tags : itemTags,
        }
        let currency = await filterChanged(checkObject, currencyData)
        if(validation(currency)){
            try {
                dispatch(editCurrecyAction(currency, navigate, appid, setDisable))
                setIsChanged(false);
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "UPDATE",
                    app_id:appid,
                    resource: "CURRENCY",
                    resource_id: id, // Use the ID from response
                    description: `Currency '${displayName}' updated successfully.`,
                    quantity: 1,
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(currency),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
                console.log("Activity logged successfully.");
            
                setIsChanged(false)
            } catch (error) {
                
            }
            setIsChanged(false)
        }
    }

    const onTagInput = () => {
        if(document.getElementById('currencyTagInput')){
            document.getElementById('currencyTagInput').focus()
        }
    }

    const [disable, setDisable] =  useState(false)
    const [visibleHelp, setVisibleHelp] = useState(false)

    const setchange = () => {

        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

    if (checkObject&&
        (currencyIcon==checkObject.iconUrl)&&
        (currencyId==checkObject.currencyId)&&
        (displayName==checkObject.name)&&
        ((currencyCode===checkObject.code)||((checkObject.code==null||checkObject.code==undefined)&&currencyCode==""))&&
        (currencyType==checkObject.type)&&
        ((currencyDescription===checkObject.description)||((checkObject.description==null||checkObject.description==undefined)&&currencyDescription==""))&&
        // ((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
        (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| ((checkObject.tagsMapping==null|| checkObject.tagsMapping.length==0)&& itemTags.length==0))
        ) {
            setIsChanged(false)
        }
        else {
        setIsChanged(true)
    }
    }
    
    useEffect(()=>{
        setchange()
    }, [currencyIcon,currencyId,displayName,currencyCode,currencyType,currencyDescription,tags, metaData])
    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    return (
        <>
        
            <Card className={cn(styles.card, className)} title="Currency Information" classTitle="title-green">
                <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {currencyIcon != null &&
                        <div className={styles.previewCancel}>
                            <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                        </div>
                        }
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={currencyIcon} setIcon={setCurrencyIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput tooltip={TooltipTitle.CurrencyName} id='currencyName' className={styles.field} errorMessage={errors.nameError} value={displayName} label="Display Name*" name="DisplayName" type="text" required onChange={(e)=>{onDisplayName(e)}}/>
                        <TextInput isId={true} tooltip={TooltipTitle.CurrencyId} id='currencyId' className={styles.field} errorMessage={errors.idError} value={currencyId} label="Currency ID*" name="CurrencyId" type="text" required onChange={(e)=>{onCurrencyId(e)}}/>
                    </div>
                    <div className={styles.group}>
                        <TextInput tooltip={TooltipTitle.CurrencyCode} className={styles.field} defaultValue={currencyCode} label="Currency Code" name="CurrencyCode" type="text" required onChange={(e)=>{onCurrencyCode(e)}} />
                        <TextInput tooltip={TooltipTitle.CurrencyType} disabled className={styles.field} defaultValue={currencyType == 'virtual' ? 'Virtual Currency' : currencyType == 'real' ? 'RMG' : ''} label="Currency Type*" name="Currency Type" type="text" required onChange={(e)=>{onCurrencyType(e)}}/>
                    </div>
                    <TextArea tooltip={TooltipTitle.CurrencyDescription} className={styles.field} value={currencyDescription} rows={5} label="Currency Description" onChange={(e)=>{setCurrencyDescription(e.target.value)}}/>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <TagInputWithDropdown tooltip={TooltipTitle.CurrencyTags} tags={tags} setTags={setTags}/>
                    <div className={styles.editCurrency}>

                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.CurrencyMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><img src={addIcon} alt="add" /></span>
                        </div>
                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                    return(
                                        <>
                                            <TextInput key={i} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                            <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                            {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e, i)}}/> */}
                                            <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon} /></span>
                                        </>
                                    )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={submit}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default CurrencyInformation