import React, {useEffect, useState, useRef, useContext} from 'react'
import cn from 'classnames'
import customAxios from '../../../utils/interceptor';
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { toast } from 'react-toastify';
import Toast from '../../../components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/Card'
import TextInput from '../../../components/TextInput';
import TextArea from '../../../components/TextArea';
import Tooltip from '../../../components/Tooltip';
import Control from './Control';
import GenreDropdown from '../../../components/MultiselectDropDown/GenreDropdown'
import SearchDropdown from '../../../components/Dropdown/SearchDropdown'
import PlatformDropdown from '../../../components/MultiselectDropDown/PlatformDropdown'
import LocationDropdown from '../../../components/MultiselectDropDown/LocationDropdown'
import Switch from '../../../components/Switch';
import { BASE_URL, GET_APPS } from '../../../resources/APIEndpoints';
import { editAppAction, getAppDetailsAction } from '../../../redux/action/apps/appsAction';
import Icon from '../../../components/Icon';
import styles from './test.module.sass'

import App from '../../../media/images/placeholders/App.png'
import FileImages from '../../../components/File/Images';
import FileVideos from '../../../components/File/Videos';
import { ReactComponent as AddButtonIcon } from '../../../media/images/icons/add_button.svg'
import { ReactComponent as UploadFile } from '../../../media/images/icons/upload_file.svg';
import {ReactComponent as Remove} from '../../../media/images/icons/remove.svg'
import {ReactComponent as AddMetaIcon} from '../../../media/images/icons/add_meta.svg'
import {ReactComponent as ImagePlaceholder} from '../../../media/images/icons/image_placeholder.svg'
import {ReactComponent as VideoPlaceholder} from '../../../media/images/icons/video_placeholder.svg'
import Dropdown from '../../../components/Dropdown';
import TagInputWithDropdown from "../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../components/FileUploadSidebar'
import { getAllMediaAction, addMediaAction} from '../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../components/MetaInput';
import GlobalStateContext from '../../../components/Context/GlobalStates/GlobalStateContext';
import Loader from '../../../components/Loaders/LoaderProgress';
import app from "../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const gernePlaceHolder = ["Select Genre"]

const AppInfo = ({ className, onClose, setIsChanged}) => {

    const { appid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logoRef = useRef()
    const screenshotRef = useRef()
    const videoRef = useRef()
    const tagRef = useRef()

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
            }
        }
    }
    
    useEffect(() => {
        getUserAccess()
    }, [])

    const placeholder = App
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [appIcon, setAppIcon] = useState(null)
    const [appName, setAppName] = useState("");
    const [appGerne, setAppGerne] = useState([])
    const [appDescription, setAppDescription] = useState()
    const [platform, setPlatform] = useState([])
    const [locations, setLocations] = useState([])
    const [appHowTo, setAppHowTo] = useState("")
    const [screenshot, setScreenshot] = useState([])
    const [video, setVideo] = useState([])
    const [isScreenShot, setIsScreenShot] = useState(false);
    const [isVideo, setIsVideo] = useState(false)
    const [tags, setTags] = useState([]);
    const [catIndex,setCatIndex]= useState(null)
    
    const [genreOption, setGenreOption] = useState([])
    const [platformOption, setPlatformOption] = useState([])
    const [locationOption, setLocationOption] = useState([])

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setAppIcon(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data : convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setAppIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    const onAppName = (e) => {
        if(e.target.value == '' || e.target.value == null){
            setAppName('')
        }else{
            errors.nameErr = ''
            setAppName(e.target.value)
        }
    }

    const onAppGerne = (e) => {
        setAppGerne(e.target.value)
    }

    const onAppDescription = (e) => {
        setAppDescription(e.target.value)        
    }

    const uploadFile = async(e) => {
        try{
            var file = e.target.files[0];   
            const file_binary = await convertbase64(file)
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=octet-stream`)
            var config = {
                headers : {'Content-Type' : `${file.type}`},
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            let response = await axios(config)
            if(response){
                toast.success('File Uploaded Successfully')
                if(response.status == 200){
                    return res.data.getUrl
                }
            }
            return false
        }catch(err){
            return false
        }
    }

    const removeFile = (index) => {
        let platformList = [...platform]
        platformList[index].fileName = ''
        platformList[index].assetBundleUrl = ''
        setPlatform(platformList)
    }

    const onChangePlatform = async(e, index, type, id) => {
        let platformList = [...platform]
        if(type == 'file'){
            let res = await uploadFile(e)
            platformList[index][e.target.name] = res
            platformList[index]['fileName'] = e.target.files[0].name
            setPlatform(platformList)
        }else{
            if(/^\d*\.?\d\d*\.?\d*$/.test(e.target.value)){
                platformList[index][e.target.name] = e.target.value
                setPlatform(platformList)
            }if(e.target.value == ''){
                platformList[index][e.target.name] = ''
                setPlatform(platformList)
            }
        }
    }




    const onSelectedCategoryLogo = async(e,i,x) =>{
        const tempCategory=[...platform]
        console.log("platformCategory",tempCategory,x,i)
        tempCategory[i].assetBundleUrl=e
        tempCategory[i]['fileName'] = x.name
        setPlatform(tempCategory)
    }

    const uploadCategoryLogo = async(e,i) =>{
        var file1= null
        file1 = e.target.files[0];
        const file_binary1 = await convertbase64(file1)
        const res = await axios.get(`https://admin.staging.specterapp.xyz/v1/admin/upload-url?contentType=${file1.type}&projectId=${appid}&category=${`bundles`}&fileName=${e.target.files[0].name}`)

        const tempCategory=[...platform]
        tempCategory[i].assetBundleUrl=res.data.getUrl;
        tempCategory[i]['fileName'] = e.target.files[0].name
        e.target.value=null
        setPlatform(tempCategory);
        var config1 = {
            headers : {'Content-Type' : `${file1.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary1),
        }
        axios(config1)
        .then(function (response) {
            let setSidebar=true
            if(setSidebar){
                let uploadLogo = {
                    projectId : appid,
                    contents : [{
                        contentType : file1.type,
                        fileName : file1.name,
                        category : "bundles",
                        url : res.data.getUrl,
                        fileSize : file1.size/1024
                    }]
                }
                dispatch(addMediaAction(uploadLogo, null, appid,'bundles',false))
                // setSidebar(false)
            }
            // toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }






    const onRemove = (selectedList, removedItem) => {
        setPlatform(oldValues => {return oldValues.filter(platform => platform.name !== removedItem.name)})
    }

    const onAppHowTo = (e) => {
        setAppHowTo(e.target.value)
    }

    const removeScreenShots = (index) => {
        let screenshotList = [...screenshot]
        let screenshotFields = screenshotList.filter((e,i,c)=>{
            return i != index
        })
        screenshotRef.current.value = ''
        setScreenshot(screenshotFields)
    }

    const removeVideos = (index) => {
        let videoList = [...video]
        let videoFields = videoList.filter((e,i,c)=>{
            return i != index
        })
        videoRef.current.value = ''
        setVideo(videoFields)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleInputChange = (e) => {

    }

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [checkObject,setCheckObject]= useState()
    const getAppDetail = async() => {
        let getAppDetail = {
            ids : [appid]
        }
        dispatch(getAppDetailsAction(getAppDetail))
        // const token = localStorage.getItem('token')
        // const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        // let res = await customAxios.post(BASE_URL + GET_APPS, getAppDetail, headers)   
    }

    let appDetail = useSelector((state) => {
        return state.apps.appDetails
    })

    let isLoading = useSelector((state) => {
        return state.apps.isLoading
    })

    const configureAppDetail = () => {
        console.log(appDetail, 'AD')
        if(appDetail && appDetail?.length > 0){
            let app = appDetail[0]
            localStorage.setItem('App', app.name)
            localStorage.setItem('AppIcon', app.iconUrl)
            setCheckObject(app)
            setAppName(app.name)
            setLogo({src: (app.iconUrl != null ? app.iconUrl : App), alt: 'Upload an Image'}); 
            setAppIcon(app.iconUrl)
            setCategory(app.projectCategoryMaster != null ? app.projectCategoryMaster.categoryName : '')
            setCategoryId(app.projectCategoryMaster != null ? app.projectCategoryMaster.id : null)
            setAppDescription(app.description)
            setAppHowTo(app.howTo)
            setIsScreenShot(app.screenshotUrls == null ? false : true)
            setIsVideo(app.videoUrls == null ? false : true)
            setScreenshot(app.screenshotUrls != null ? app.screenshotUrls : [])
            setVideo(app.videoUrls != null ? app.videoUrls : [])
            
            app.genres && app.genres.forEach((e,i) => {
                setAppGerne(prevState => [...prevState, {id : e.id, name : (e.genreName[0].toUpperCase() + e.genreName.slice(1))}])
            })
            
            app.projectPlatformDetails && app.projectPlatformDetails.forEach((e,i) => {
                setPlatform(prevState => [...prevState, {platformId : e.platformId, name : (e.platformId == 1 ? 'Android' : 'iOS'), fileName : e.assetName, assetBundleUrl : e.assetBundleUrl, assetBundleVersion : e.assetBundleVersion, minimumAppVersion : e.minimumAppVersion}])
            })

            
            app.countries && app.countries.forEach((e,i)=>{
                setLocations(prevState => [...prevState, {id : e.id, name : e.name}])
            })

            app.tagsMapping && app.tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });

            let meta = []
            if(app.meta == null || JSON.stringify(app.meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                app.meta && Object.keys(app.meta).forEach(e => {
                    meta.push({key : e, value : app.meta[e]})
                })
                setMetaData(meta)
            }
        }
    }

    useEffect(() => {
        configureAppDetail()
    }, [appDetail])

    const getOptionalData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setLocationOption(res.data.data.countryMaster)

        let Platforms = []
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.map((e, i) =>{
            Platforms.push({platformId : e.id, name : (e.platformName == 'android' ? "Android" : 'iOS'), fileName : '', assetBundleUrl : '', assetBundleVersion : '', minimumAppVersion : ''})
        })
        setPlatformOption(Platforms)
        let genre = []
        res.data.data.gameGenerMaster && res.data.data.gameGenerMaster.forEach((e,i) => {
            genre.push({id : e.id, name : (e.genreName[0].toUpperCase() + e.genreName.slice(1))})
        })
        setGenreOption(genre)

        setCategoriesOption(res.data.data.projectCategoryMasterIds && res.data.data.projectCategoryMasterIds.map(e => ({ id : e.id, name : e.categoryName})))
    }

    useEffect(()=> {
        setTags([])
        setLocations([])
        setPlatform([])
        setMetaData([])
        setAppGerne([])
        setAppHowTo([])
        setScreenshot([])
        setVideo([])
        getAppDetail()
        getOptionalData()
    }, [appid])

    const [errors, setErrors] = useState({nameErr : '', categoryIdErr : ''})

    const validation = (data) => {
      let error = {}
      let isValid = true
      if(data.name == '' || data.name == null){
        error.nameErr = 'Name is required'
        isValid = false
        const element = document.getElementById('appName');
        const offset = 140;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({top: offsetPosition, behavior: 'smooth'});
      }
  
      if(data.projectCategoryMasterId == '' || data.projectCategoryMasterId == null){
        error.categoryIdErr = 'Category is required'
        isValid = false
        if(data.name != '' && data.name != null){
            const element = document.getElementById('appCategory');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      }
  
      setErrors(error)
      if(isValid){
        return true
      }
  
    }

    const db = getFirestore(app);

    const saveApp = async () => {

        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let finalTag = []
        tags && tags.forEach((e)=>{finalTag.push(e.text)})

        let countryId = []
        locations && locations.filter((e,i) => countryId.push(e.id))

        let appGerneId = []
        appGerne && appGerne.filter((e,i) => appGerneId.push(e.id))

        let appsDetail = {
            id : appid,
            name : appName,
            iconUrl : appIcon,
            genreIds : appGerneId,
            description : appDescription,
            platformDetails : platform.map(({name, ...rest}) => {return {assetName:rest.fileName,...rest};}),
            countryIds : countryId,
            screenshotUrls : screenshot,
            videoUrls : video,
            howTo : appHowTo,
            tags : finalTag,
            meta : finalMetaData,
            projectCategoryMasterId : categoryId
        }
        if(validation(appsDetail)){
            
            try {
                await dispatch(editAppAction(appsDetail, appid, setDisable))
                // Access the response data (e.g., game ID) 
                setIsChanged(false);
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "UPDATE",
                    app_id:appid,
                    resource: "APP",
                    resource_id: appid, // Use the ID from response
                    description: `App information for '${appName}' updated successfully.`,
                    quantity: 1,
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(appsDetail),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
                console.log("Activity logged successfully.");
            
                setIsChanged(false)
            } catch (error) {
                
            }
            setIsChanged(false)
        }

    }

    const [disable, setDisable] =  useState(false)

    const [category, setCategory] = useState()
    const [categoryId, setCategoryId] = useState()
    const [categoriesOption, setCategoriesOption] = useState([])

    const [visibleHelp, setVisibleHelp] = useState(false)
    const [visibleVideo, setVisibleVideo] = useState(false)
    const [visibleImage, setVisibleImage] = useState(false)

    const [visibleCategorySidebar, setVisibleCategorySidebar] = useState(false)


    const [media, setMedia] = useState([])
    const [type, setType] = useState(0)

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            // category: 'icons',
            filters : {
                "categories" : 'icons',
                // "extensions" : ["jpeg"]
            },
            typeId : type
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [type])

    useEffect(() => {
        configureMedia()
    }, [files])

    function compareGenreArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.id)===array2[i]?.id|| array2[i]?.id==undefined|| array2[i]?.id==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareScreenshotArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length==0 &&(array2==null || array2==undefined)) {
            return true
        }
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]==array2[i]|| ((array2[i]==undefined|| array2[i]==null)&&array1.length==0))
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function comparePlatformArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.platformId)===array2[i]?.platformId|| array2[i]?.platformId==undefined|| array2[i]?.platformId==null)&&
                (array1[i].assetBundleUrl ==array2[i]?.assetBundleUrl)&&
                (array1[i].assetBundleVersion ==array2[i]?.assetBundleVersion)&&
                (array1[i].minimumGameVersion ==array2[i]?.minimumGameVersion)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareLocationArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.id)===array2[i]?.id|| array2[i]?.id==undefined|| array2[i]?.id==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }
    
    const setchange = () => {
        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })
         
        let locationcheck = checkObject&& ((checkObject.countries&& compareLocationArrays(locations, checkObject.countries))||!checkObject?.countries)
        let platformcheck = checkObject&& ((checkObject.projectPlatformDetails&& comparePlatformArrays(platform, checkObject.projectPlatformDetails))||!checkObject?.projectPlatformDetails)
        let genrecheck = checkObject&& ((checkObject.genres&& compareGenreArrays(appGerne, checkObject.genres))||!checkObject?.genres)
        let screenshotCheck = checkObject&& (( compareScreenshotArrays(screenshot, checkObject.screenshotUrls)))
        let videoCheck = checkObject&& (( compareScreenshotArrays(video, checkObject.videoUrls)))
        if (checkObject&&
            (appIcon==checkObject.iconUrl)&&
            (appName===checkObject.name)&&
            (categoryId===checkObject.projectCategoryMasterId)&&
            (appDescription==checkObject.description||(checkObject.description==null&&appDescription==''))&&
            (appHowTo==checkObject.howTo||(checkObject.howTo==null&&appHowTo==''))&&
            (locationcheck)&&
            (platformcheck)&&
            (genrecheck)&&
            (screenshotCheck)&&
            (videoCheck)&&
            //((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject?.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }
    // const [query, setQuery] = useState({combinator : 'and', rules : [{field : '', value : {value : null, type : false, allTime : true, noOfRecords : null}, operator : ''}]})
    useEffect(()=>{
        setchange()
    },[appIcon,appName,categoryId,appDescription,appHowTo,appGerne,platform,locations,screenshot,video,isScreenShot,isVideo,tags,metaData])

    // Loader
    if(isLoading){
        return(
        <>
            <div className={styles.loader}>
                <Loader />
            </div>
        </>
        )
    }

    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
            <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                            {!userAccess && <label className={styles.button} onClick={() => {setVisibleHelp(true); setType(0)}}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        {appIcon != null && !userAccess ?
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={appIcon} setIcon={setAppIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput disabled={userAccess} id='appName' tooltip={TooltipTitle.Appname} errorMessage={errors.nameErr} className={styles.field} value={appName} label="Name*" name="Name" type="text" required onChange={(e)=>{onAppName(e)}}/>
                        <div className={styles.field}>
                            <GenreDropdown disabled={userAccess} label="Select Genre" tooltip={TooltipTitle.AppGenre} placeholder="Select Genre" options={genreOption} value={appGerne} setValue={setAppGerne}/>
                        </div>
                    </div>

                    <div className={styles.group}>
                        <div className={styles.field}>
                            <SearchDropdown disabled={userAccess} tooltip={TooltipTitle.AppCategory}  id='appCategory' errorMessage={errors.categoryIdErr}  options={categoriesOption} value={category} setValue={setCategory} setId={setCategoryId} label="Select Category*" placeholder='Select Category' name="Category" required onChange={(e)=>{onAppName(e)}}/>
                        </div>
                    </div>

                    <TextArea disabled={userAccess} className={styles.field} tooltip={TooltipTitle.AppDescription} value={appDescription != null ? appDescription : ''} rows={5} label="Description" onChange={(e)=>{onAppDescription(e)}}/>
                </div>
            </Card>
            
            <Card className={cn(styles.card, className)} title="Technical Details" classTitle="title-red">
                <div className={styles.cardDescription}>   
                    <div className={styles.appInformation}>
                        <div className={styles.appInformation}>
                            <PlatformDropdown disabled={userAccess} tooltip={TooltipTitle.AppPlatform} label="Select Platform" placeholder="Choose Game Platform" options={platformOption} value={platform} setValue={setPlatform} />
                        </div>
                    </div>
                    <FileUploadSidebar type={"store"} catIndex={catIndex} customUploadFunction={uploadCategoryLogo} customSelectMediaFunction={onSelectedCategoryLogo} media={media} setMedia={setMedia} types='Images' id={`asset${'-'}`} multiple={false} visible={visibleCategorySidebar} folderName='bundles' setVisible={setVisibleCategorySidebar} onClose={onClose} />

                    <div className={styles.appInformation}>
                        {platform && platform.map((e,i)=>{
                            return(
                                <>
                                    <div key={e.name} className={styles.platformContainer}>
                                        <div className={styles.platformHeading}>
                                            <span>{e.name == 'android' || e.name == "Android" ? "Android" : "iOS"}</span>
                                        </div>
                                        <div className={styles.groupByThree}>
                                            <div className={styles.field}>
                                                <div className={styles.bundleLabel}><span>Asset Bundle</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.AppAssetBundle} icon="info"/></span></div>
                                                {e.fileName == '' &&
                                                    <>
                                                        {/* <input disabled={userAccess} type="file" id={`files-${i}`} className='file-input' name='assetBundleUrl' onChange={(evt)=>{onChangePlatform(evt, i, 'file', e.platformId)}}/> */}
                                                        <label for={`files-${i}`} className={styles.uploadBundleButton} onClick={() => {setCatIndex(i);setVisibleCategorySidebar(true)}}><span className={styles.label}>Upload Bundle</span><span className={styles.icon}><UploadFile /></span></label>
                                                    </>
                                                }
                                                {e.fileName != '' &&
                                                    <>
                                                        <label for={`files-${i}`} className={styles.uploadedFile}>
                                                            {/* <input disabled={userAccess} type="file" id={`files-${i}`} name='assetBundleUrl' className='file-input' onChange={(evt)=>{onChangePlatform(evt, i, 'file', e.platformId)}}/> */}
                                                            <label for={`files-${i}`} className={styles.fileInput}><span className={styles.label} title={e.fileName} onClick={() => {setCatIndex(i);setVisibleCategorySidebar(true)}}>{e.fileName}</span></label>
                                                            <label className={styles.fileRemove}><span className={styles.icon} onClick={()=>{removeFile(i)}}><Icon className={styles.close} name='close-circle' size='24'/></span></label>
                                                        </label>
                                                    </>
                                                }
                                                </div>
                                                <TextInput disabled={userAccess} key={i} className={styles.field} tooltip={TooltipTitle.AppAssetBundleVerion} label="Asset Bundle Version" name="assetBundleVersion" type="text" value={e.assetBundleVersion} onChange={(evt)=>{onChangePlatform(evt,i)}}/>
                                                <TextInput disabled={userAccess} key={i} className={styles.field} tooltip={TooltipTitle.AppMinimumVersion} label="Minimum App Version" name="minimumAppVersion" type="text" value={e.minimumAppVersion} onChange={(evt)=>{onChangePlatform(evt,i)}} />
                                                {!userAccess && <span className={styles.remove} onClick={()=>{onRemove(platform, e)}}><Remove className={styles.icon}/></span>}
                                            </div>
                                            {e.assetBundleUrl != '' ? 
                                                <div className={styles.platformUrl}>
                                                    <a className={styles.bundleUrl} href={e.assetBundleUrl}>Download file</a>
                                                </div>
                                            : ''}
                                        </div>
                                    </>
                                )
                            })}
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Location" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.appInformation}>
                        <div className={styles.appInformation}>
                            <LocationDropdown disabled={userAccess} tooltip={TooltipTitle.AppLocation} label="Choose Location" placeholder="Choose Location" options={locationOption} value={locations} setValue={setLocations} />
                        </div>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Extra" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.appInformation}>
                        <div className={styles.screenshot}>
                            <div className={styles.line}>
                                <div className={styles.info}>
                                    Add Screenshots<span><Tooltip className={styles.tooltip} title={TooltipTitle.AppScreenshot} icon="info"/></span>
                                </div>
                                <Switch className={styles.switch} value={isScreenShot} onChange={() => setIsScreenShot(!isScreenShot)} />
                            </div>
                            {isScreenShot ?
                                <div onClick={() => {setVisibleImage(true); setType(0)}}>
                                    <FileImages type="image" className={styles.field} title="Click or drop image" label="Cover images" setValue={setScreenshot} value={screenshot} data={screenshotRef}/>
                                </div>
                            : ''} 
                            <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`screenshot`} multiple={true} visible={visibleImage} setVisible={setVisibleImage} icon={screenshot} setIcon={setScreenshot} value={screenshot} setValue={setScreenshot} onClose={onClose} />
                            {isScreenShot && screenshot &&
                                <>
                                    <div className={styles.screenshotPreview}>
                                        <div className={styles.previewRow}>
                                            {screenshot && screenshot.map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.previewCol}>
                                                            <div className={styles.previewContainer}>
                                                                <div className={styles.imgPreview}>
                                                                    <ImagePlaceholder />
                                                                </div>
                                                                <div className={styles.previewContent}>
                                                                    <div className={styles.screenshotTitle}>{`screenshot - ${i+1}`}</div>
                                                                    <div className={styles.precentageContainer}>
                                                                        <div id={`percentage${i+1}`} className={styles.percentage}>
                                                                            <div id ={`successfull${i+1}`} className={styles.successfull}></div>
                                                                        </div>
                                                                        <div className='progress-bar-images'></div>
                                                                        <div id={`view${i+1}`} className={styles.view}><Control type='Images' src={e} /></div>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.close} onClick={(e)=>{removeScreenShots(i)}}>
                                                                    <Icon name="close" size="14"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                        </div> 

                        <div className={styles.screenshot}>
                            <div className={styles.line}>
                                <div className={styles.info}>
                                    Add Video<span><Tooltip className={styles.tooltip} title={TooltipTitle.AppVideos} icon="info"/></span>
                                </div>
                                <Switch className={styles.switch} value={isVideo} onChange={() => setIsVideo(!isVideo)} />
                            </div>
                            {isVideo ?
                                <div onClick={() => {setVisibleVideo(true); setType(1)}}>
                                    <FileVideos type="video" className={styles.field} title="Click or drop videos" label="Cover videos" setValues={setVideo} value={video} data={videoRef}/>
                                </div>
                            : ''} 
                            <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`videos`} multiple={true} visible={visibleVideo} setVisible={setVisibleVideo} icon={video} setIcon={setVideo} value={video} setValue={setVideo} onClose={onClose} />
                            {isVideo && video &&
                                <>
                                    <div className={styles.screenshotPreview}>
                                        <div className={styles.previewRow}>
                                            {video && video.map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.previewCol}>
                                                            <div className={styles.previewContainer}>
                                                                <div className={styles.imgPreview}>
                                                                    <VideoPlaceholder />
                                                                </div>
                                                                <div className={styles.previewContent}>
                                                                    <div className={styles.screenshotTitle}>{`video - ${i+1}`}</div>
                                                                    <div className={styles.precentageContainer}>
                                                                        <div id={`percentage_video${i+1}`} className={styles.percentage}>
                                                                            <div id = {`successfull_video${i+1}`} className={styles.successfull}></div>
                                                                        </div>
                                                                        <div className='progress-bar-videos'></div>
                                                                        <div id={`view_video${i+1}`} className={styles.view}><Control type='Videos' src={e} /></div>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.close} onClick={(e)=>{removeVideos(i)}}>
                                                                    <Icon name="close" size="14" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                        </div> 

                        <div className={className.video}>
                            <TextArea disabled={userAccess} tooltip={TooltipTitle.AppHowTo} rows={5} label="How-to" value={appHowTo} onChange={(e)=>{onAppHowTo(e)}} />
                        </div>      
                    </div>
                </div>
            </Card>
            
            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.AppTags} tags={tags} setTags={setTags}/>

                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.AppMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput disabled={userAccess} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e,i)}}/>
                                        <JSONMetaDataValueInput disabled={userAccess} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                        {!userAccess && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon}/></span>}
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={saveApp}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default AppInfo