import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import Icon from "../../../../../../components/Icon";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import {ReactComponent as Arrow} from '../../../../../../media/images/icons/Union.svg'
import AddContentModal from "../../../../../../components/Modals/Build/Economy/Bundle/AddContent";
import MatchFilterModal from "../../../../../../components/Filters/Build/Matches"
import Sort from '../../../../../../components/Sort'

const Control = ({className, setPage, value, setValue, data, sort, setSort, sortIndex, setSortIndex, selected, setSelected, filters, action, type, width}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <div className={cn(styles.control, className)}>
        <div className={cn(styles.buttons, className, { [styles.active]: (filters.gameId.length + filters.matchFormat.length + filters.matchOutcome.length + filters.leaderboardRanking.length > 0) })} onClick={() => {setVisibleModal(true)}}>
            <Icon name='filter' size='20' className={styles.icon} />
            <span className={styles.buttonText}>Filter {(filters.gameId.length + filters.matchFormat.length + filters.matchOutcome.length + filters.leaderboardRanking.length > 0) && <span className={styles.countPill}>{filters.gameId.length + filters.matchFormat.length + filters.matchOutcome.length + filters.leaderboardRanking.length}</span>}</span>
        </div>
        {/* <Sort setPage={setPage} value={value} setValue={setValue} data={data} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selected} setSelected={setSelected} sort={sort} setSort={setSort}>
          <div className={cn(styles.buttons, className, { [styles.active]: (value?.name !== null)})}>
            <span className={styles.sortIcon}>
              <Arrow className={cn(styles.up, { [styles.active]: (value?.value !== null && value?.value === 0)})}/>
              <Arrow className={cn(styles.down, { [styles.active]: (value?.value !== null && value?.value === 1)})}/>
            </span>
            <span className={styles.buttonText}>
              {value?.name ? (value?.name === 'updated_at' ? 'Updated At' : value?.name === 'created_at' ? 'Created At' : value?.name == 'name' ? 'Match Name' : '') : 'Sort'}
            </span>
          </div>
        </Sort> */}
      </div>
      <Modal heading={true} width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <MatchFilterModal action={action} filters={filters} onClose={() => setVisibleModal(false)} />
      </Modal>
    </>
  );
};

export default Control;
