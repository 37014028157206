import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./dropdown.module.sass";
import Tooltip from "../../Tooltip";
import { ReactComponent as AddIconSvg } from "../../../media/images/icons/radio button/element/checked.svg";
import ConfirmModal from "../../../components/Modals/Build/Economy/Currency/ConfirmModal";
import Modal from "../../../components/Modals/ModalComponent";
import Tooltips from "../../Tooltip";

const Dropdown = ({ className, disabled, id, groupBy, error, errorMessage, classLabel, classDropdownHead, type, classDropdownLabel, placeholder, value, setValue, setId, setType, setDisplayId, setField, options, label, small, upBody, tooltip, place }) => {

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [filterText, setFilterText] = useState(value||'');

    const navigate = useNavigate();
    const { appid } = useParams();

    function titleCase(str) {
        if (str) {
            var splitStr = str.split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }

    const ModalClose = () => {
        setVisibleModal(false);
        window.scrollTo(0, 0);
        if (type === "progression marker") {
            setTimeout(() => {
                navigate(`/create-progression-marker/${appid}`);
            }, 100);
        }
        if (type === "game") {
            setTimeout(() => {
                navigate(`/create-game/${appid}`);
            }, 100);
        }
        if (type === "competition") {
            setTimeout(() => {
                navigate(`/competition-tournament/${appid}`);
            }, 100);
        }
    };

    const handleClick = (values) => {
        setValue(values.name);
        setId(values.id);
        if (error) {
            error(false);
        }
        if (setDisplayId) {
            if (values.type === 'custom') {
                setDisplayId(values.eventId);
            } else {
                setDisplayId(values.id);
            }
        }
        if (setField) {
            let field = values.customParameterDetails ? [...values.customParameterDetails] : [];
            if (values.defaultParameterDetails) {
                field = [...field, ...values.defaultParameterDetails];
            }
            if (values.specterParameterDetails) {
                field = [...field, ...values.specterParameterDetails];
            }
            setField(field.map(e => ({
                id: e.id,
                name: e.name,
                inputType: e.dataType.dataTypeName,
                type: e.type
            })));
        }
        if (setType) {
            setType(values.type);
        }
        setVisible(false);
        setFilterText(value||'')
    };

    useEffect(() => {
        // const container = document.getElementById(id);
        // if (container) {
        //     const containerWidth = container.offsetWidth;
        //     const contentWidth = container.scrollWidth;
        // }
        setFilterText(value||'')
    }, [value, id]);

    const filteredOptions = options&&options.filter(option =>
        option?.name?.toLowerCase()?.includes(filterText?.toLowerCase())
    );

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "30px",
        fontSize: "12px",
        fontWeight: "inherit",
        width: "100%", // Ensure it takes the full width
    };
    
    return (
        <div id={id} className={groupBy === 1 ? styles.groupDropdown : groupBy === 2 ? styles.groupByTwoDropdown : groupBy === 3 ? styles.groupByThreeDropdown : groupBy === 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(value||''); }}>
                {label && (
                    <div className={styles.groupLabel}>
                        <div className={cn(styles.label, classDropdownLabel)}>
                            {label}
                            {tooltip && (
                                <Tooltips
                                    className={styles.tooltip}
                                    title={tooltip}
                                    icon="info"
                                    place="right"
                                />
                            )}
                        </div>
                        <div className={styles.error}>
                            {(value === null || value === undefined || value === '') && errorMessage}
                        </div>
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
                    <div className={(errorMessage && (value === null || value === undefined || value === '')) ? cn(styles.head, styles.head_error, classDropdownHead) : cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.placeholder}>
                            <input
                                type="text"
                                style={inputStyle}
                                placeholder={placeholder}
                                value={filterText}
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && visible && (
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {filteredOptions.length > 0 ? filteredOptions.map && filteredOptions.map((x, index) => (
                                <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.name === value })} key={index}>
                                    {x.name != null ? x.name : x.levelName}
                                </div>
                            )) : (
                                <div style={{ textAlign: "center" }} className={styles.option}>
                                    <div>No <span style={{ color: "#33383F" }}>'{titleCase(type)}'</span> available</div>
                                    <div>Click on <span style={{ color: "#33383F" }}>'+'</span> to create</div>
                                </div>
                            )}
                            <div className={styles.fxd} onClick={() => { setVisibleModal(true); }}>
                                <div className={styles.divider}></div>
                                <div className={styles.add}>
                                    <Link onClick={() => { setVisibleModal(true); }} className={styles.button}>
                                        <AddIconSvg />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                    <ConfirmModal onProceed={ModalClose} onClose={() => setVisibleModal(false)} type={titleCase(type)} />
                </Modal>
            </OutsideClickHandler>
        </div>
    );
};

export default Dropdown;
