import React, { useEffect, useState } from "react";
import styles from './secondaryHeader.module.sass'
import cn from "classnames";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "../../Form";
import ToggleArchive from "./Archive";
import Sorting from "./Sort";
import addIcon from '../../../media/images/icons/light.png'
import DropdownButton from "./DropdownButton";
import FilterGame from '../../../screens/Builds/Games/GetGames/Filters/Control'
import FilterMatch from '../../../screens/Builds/Matches/GetMatches/Filters/Control'
import FilterCurrency from '../../../screens/Builds/Economy/Currency/GetCurrency/Filters/Control'
import FilterItem from '../../../screens/Builds/Economy/Items/GetItems/Filters/Control'
import FilterBundle from '../../../screens/Builds/Economy/Bundles/GetBundles/Filters/Control'
import FilterStore from '../../../screens/Builds/Economy/Store/GetStores/Filters/Control'
import FilterPM from '../../../screens/Builds/Progression/ProgressionMarker/GetProgressionMarker/Filters/Control'
import FilterPS from '../../../screens/Builds/Progression/ProgressionSystem/GetProgressionSystem/Filters/Control'
import FilterTask from '../../../screens/Engage/Achievement/Tasks/GetTask/Filters/Control'
import FilterSS from '../../../screens/Engage/Achievement/Missions/GetMission/Filters/Control'
import FilterMission from '../../../screens/Engage/Achievement/StepSeries/GetStepSeries/Filters/Control'
import FilterLeaderboard from '../../../screens/Engage/Leaderboard/GetLeaderboard/Filters/Control'
import FilterTournament from '../../../screens/Engage/Competitions/GetCompetition/GetCompetitionTournament/Filters/Control'
import FilterIB from '../../../screens/Engage/Competitions/GetCompetition/GetCompetitionInstantBattle/Filters/Control'

const SecondaryNavigation = ({className, filters, filterAction, type, search, setSearch, archive, setArchive, setPage, data, sortIndex, setSortIndex, selected, setSelected, sort, setSort, value, setValue, exportOption, exportAction}) => {

    const Navigate = useNavigate()
    const { pathname } = useLocation()
    const {appid} = useParams()

    const [navLinks, setNavLinks] = useState([])
    let links = []

    const configureLinks = (type) => {
        switch(type){
            case "Games" : 
                links = [
                    {
                        title: "Games",
                        url: `/games/${appid}`,
                    }
                ]
                break;
            case "Matches" : 
                links = [
                    {
                        title: "Matches",
                        url: `/matches/${appid}`,
                    }
                ]
                break;
            case "Currency" :
            case 'Items' :
            case 'Bundles' :
            case 'Stores' :
                links = [
                    {
                        title: "Currencies",
                        url: `/currency/${appid}`,
                    },
                    {
                        title: "Items",
                        url: `/item/${appid}`,
                    },
                    {
                        title: "Bundles",
                        url: `/bundle/${appid}`,
                    },
                    {
                        title: "Stores",
                        url: `/store/${appid}`,
                    }
                ];
                break;
            case 'PM' :
            case 'PS' :
                links = [
                    {
                      title: "Progression Markers",
                      url: `/progression-marker/${appid}`,
                    },
                    {
                      title: "Progression Systems",
                      url: `/progression-system/${appid}`,
                    },
                ];
                break;
            case "Default Events" : 
            case "Custom Events" : 
                links = [
                    {
                        title: "Default Events",
                        url: `/default-events/${appid}`,
                    },
                    {
                        title: "Custom Events",
                        url: `/custom-events/${appid}`,
                    }
                ]
                break;
            case "Players" : 
                links = [
                    {
                        title: "Players",
                        url: `/players/${appid}`,
                    }
                ]
                break;
            case 'Task' :
            case 'Mission' :
            case 'SS' :
                links = [
                    {
                      title: "Tasks",
                      url: `/task/${appid}`,
                    },
                    {
                      title: "Missions",
                      url: `/mission/${appid}`,
                    },
                    {
                      title: "Step Series",
                      url: `/step-series/${appid}`,
                    }
                ]
                break;
            case 'Tournament' :
            case 'IB' :
                links = [
                    {
                      title: 'Tournaments',
                      url : `/competition-tournament/${appid}`
                    },
                    {
                      title : 'Instant-Battles',
                      url : `/competition-instant-battle/${appid}`
                    }
                ]
                break;
            case "Leaderboard" : 
                links = [
                    {
                    title: 'Leaderboard',
                    url : `/leaderboard/${appid}`
                  }
                ]
                break;
            default :
            links = []
        }
        setNavLinks(links)
    }

    useEffect(() => {
        configureLinks(type)
    }, [])

    return(
        <>
            <div className={styles.secondaryNavigation}>

                <div className={styles.secondaryContainer}>
                    <div className={styles.navigation}>
                        <div className={styles.wrapper}>
                            <div className={styles.list}>
                                {navLinks && navLinks.map((x,index)=>{
                                    return(
                                        <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})} onClick={()=> Navigate(x?.url)} key={index}>
                                            {x.title}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={styles.actionDropdown}>
                                {type !== 'Default Events' &&
                                    <DropdownButton exportAction={exportAction} exportOption={exportOption} toggle={(type === 'Currency' || type === 'Items' || type === 'Bundles' || type === 'Stores') ? true : false}>
                                        <Link to={type === 'Games' ? `/create-game/${appid}` : type === 'Matches' ? `/create-match/${appid}` : type === 'Currency' ? `/create-currency/${appid}` : type === 'Items' ? `/create-item/${appid}` : type === 'Bundles' ? `/create-bundle/${appid}` : type === 'Stores' ? `/create-store/${appid}` : type === 'PM' ? `/create-progression-marker/${appid}` : type === 'PS' ? `/create-progression-system/${appid}` : type === 'Custom Events' ? `/create-events/${appid}` : type === 'Task' ? `/create-task/${appid}` : type === 'Mission' ? `/create-mission/${appid}` : type === 'SS' ? `/create-step-series/${appid}` : type === 'Tournament' ? `/create-competition-tournament/${appid}` : type === 'IB' ? `/create-competition-instant-battle/${appid}` : type === 'Leaderboard' ? `/create-leaderboard/${appid}` : null} className={cn(styles.button, {[styles.dropdown] : (type === 'Currency' || type === 'Items' || type === 'Bundles' || type === 'Stores') ? true : false})}>
                                            <div className={styles.add}>
                                                <img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon' />
                                            </div>
                                            <div className={styles.title}>Create</div>
                                        </Link>
                                    </DropdownButton>
                                }
                                {/* <Link to={type === 'Games' ? `/create-game/${appid}` : type === 'Matches' ? `/create-match/${appid}` : type === 'Currency' ? `/create-currency/${appid}` : type === 'Items' ? `/create-item/${appid}` : type === 'Bundles' ? `/create-bundle/${appid}` : type === 'Stores' ? `/create-store/${appid}` : type === 'PM' ? `/create-progression-marker/${appid}` : type === 'PS' ? `/create-progression-system/${appid}` : type === 'Task' ? `/create-task/${appid}` : type === 'Mission' ? `/create-mission/${appid}` : type === 'SS' ? `/create-step-series/${appid}` : type === 'Tournament' ? `/create-competition-tournament/${appid}` : type === 'IB' ? `/create-competition-instant-battle/${appid}` : type === 'Leaderboard' ? `/create-leaderboard/${appid}` : null} className={cn("button-tiny", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={16} height={16}/>Create</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.options}>
                        <div className={styles.wrapper}>
                            {(type === 'Games' || type === 'Matches' || type === 'Currency' || type === 'Items' || type === 'Bundles' || type === 'Stores' || type === 'PM' || type === 'PS') &&
                                <div className={styles.archive}>
                                    <ToggleArchive archive={archive} setArchive={setArchive} />
                                </div>
                            }
                            <div className={styles.filtersNsort}>
                                {filters &&
                                    <div className={styles.filter}>
                                        {type === 'Games' && <><FilterGame filters={filters} action={filterAction}/></>}
                                        {type === 'Matches' && <><FilterMatch filters={filters} action={filterAction}/></>}
                                        {type === 'Currency' && <><FilterCurrency filters={filters} action={filterAction}/></>}
                                        {type === 'Items' && <><FilterItem filters={filters} action={filterAction}/></>}
                                        {type === 'Bundles' && <><FilterBundle filters={filters} action={filterAction}/></>}
                                        {type === 'Stores' && <><FilterStore filters={filters} action={filterAction}/></>}
                                        {type === 'PM' && <><FilterPM filters={filters} action={filterAction}/></>}
                                        {type === 'PS' && <><FilterPS filters={filters} action={filterAction}/></>}
                                        {type === 'Task' && <><FilterTask filters={filters} action={filterAction}/></>}
                                        {type === 'Mission' && <><FilterMission filters={filters} action={filterAction}/></>}
                                        {type === 'SS' && <><FilterSS filters={filters} action={filterAction}/></>}
                                        {type === 'Tournament' && <><FilterTournament filters={filters} action={filterAction}/></>}
                                        {type === 'IB' && <><FilterIB filters={filters} action={filterAction}/></>}
                                        {type === 'Leaderboard' && <><FilterLeaderboard filters={filters} action={filterAction}/></>}
                                    </div>
                                }
                                <div className={styles.sort}>
                                    <Sorting setPage={setPage} value={value} setValue={setValue} data={data} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selected} setSelected={setSelected} sort={sort} setSort={setSort} />
                                </div>
                            </div>
                            <div className={styles.search}>
                                <Form size='sm' className={styles.form} placeholder={`Search ${type}`} type="text" name="search" icon="search" value={search} onChange={(e) => {setSearch(e.target.value)}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecondaryNavigation