import React, { useContext, useEffect, useRef, useState } from 'react'
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import styles from './profile.module.sass'
import moment from 'moment'
import Icon from '../../../../../../components/Icon'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import Toast from '../../../../../../components/Toast'
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar'
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction'
import { ReactComponent as AddButtonIcon } from '../../../../../../media/images/icons/add_button.svg'
import ProfilePlaceholder from '../../../../../../media/images/placeholders/Profile.png'
import SearchDropdown from '../../../../../../components/Dropdown/SearchDropdown'
import {ReactComponent as Internet} from '../../../../../../media/images/icons/internet.svg'
import { getAllMetaDataAction } from '../../../../../../redux/action/common/commonAction'
import { editOrgProfileAction } from '../../../../../../redux/action/organisation/ProfileAction/profileAction'
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext'
import { BASE_URL } from '../../../../../../resources/APIEndpoints'
import app from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const Profile = ({data, onClose, setAppName, locationOption}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const logoRef = useRef()
    const globalStates = useContext(GlobalStateContext)

    const placeholder = ProfilePlaceholder
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setOrgProfile(org => ({...org, defaultLogoUrl : res.data.getUrl}))
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));     
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
        // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setOrgProfile(user => ({...user, defaultLogoUrl : null}))
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    useEffect(() => {
        configureOrgProfile()
    }, [data])
    
    const [orgProfile, setOrgProfile] = useState({defaultLogoUrl : null, name : '', email : '', id : ''})
    const [location, setLocation] = useState('')
    const [locationId, setLocationId] = useState(null)

    const configureOrgProfile = () => {
        if(data){
            setLogo({src: (data && data.defaultLogoUrl != null && data.defaultLogoUrl != '' ? data.defaultLogoUrl : ProfilePlaceholder), alt: 'Upload an Image'}); 
            setOrgProfile(org => ({...org, defaultLogoUrl : (data && data.defaultLogoUrl != null && data.defaultLogoUrl != '' ? data.defaultLogoUrl : null)}))
            setOrgProfile(org => ({...org, name : (data && data.name != null && data.name != '' ? data.name : '')}))
            setOrgProfile(org => ({...org, email : (data && data.email != null && data.email != '' ? data.email : '')}))
            setOrgProfile(org => ({...org, location : (data && data.location != null && data.location != '' ? data.location : '')}))
            setOrgProfile(org => ({...org, id : (data && data.id != null && data.id != '' ? data.id : '')}))
            setLocation(data && data.location != null && data.location != '' ? data.location : null)
        }
    }

    const db = getFirestore(app);

    const save = async () => {
        let orgDetails = {
            name : orgProfile.name,
            defaultLogoUrl : orgProfile.defaultLogoUrl,
            email : orgProfile.email,
            location : location
        }
        try {
            await dispatch(editOrgProfileAction(orgDetails, setAppName, setDisableField))
            // Access the response data (e.g., game ID) 
            // setIsChanged(false);
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                // app_id:appid,
                resource: "ORGANIZATION",
                // resource_id: id, // Use the ID from response
                description: `Organization Profile '${orgProfile.name}' updated successfully.`,
                quantity: 1,
operation_status: "SUCCESS",
                activity_feed:  false,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(orgDetails),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
            console.log("Activity logged successfully.");
        
            // setIsChanged(false)
        } catch (error) {
            
        }
    }

    const [disableField, setDisableField] = useState(true)

    const cancelChanges = () => {
        configureOrgProfile()
        setDisableField(true)
    }
    
    return (
        <>
            <ToastContainer />
            <div className={styles.wrapper}>
            <Card title="Overview" classTitle="title-purple" head={disableField && globalStates?.user.type === 'admin' && <div className={styles.icon} onClick={() => setDisableField(!disableField)}><span className={styles.edit}><Icon name='edit' size='24' /></span></div>}>
                <div className={styles.overview}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input disabled={disableField ? true : false}  id={`icon`} type="file" className='file-input' accept="image/jpeg, image/png, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                            {!disableField && <label for='icon' className={styles.button}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        {(!disableField && orgProfile != null && orgProfile.defaultLogoUrl != null) ?
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    
                    <div className={styles.group}>
                        <TextInput disabled={disableField ? true : false} className={styles.field} value={orgProfile && orgProfile.name != null && orgProfile.name != '' ? orgProfile.name : ''} label='Name' name="name" type="text" onChange={(e) => setOrgProfile(org => ({...org, name : e.target.value}))}/>
                        <TextInput disabled={disableField ? true : false}  className={styles.field} value={orgProfile && orgProfile.email != null && orgProfile.email != '' ? orgProfile.email : ''} label='Email' name='email' type='text' onChange={(e) => setOrgProfile(org => ({...org, email : e.target.value}))}/>
                    </div>

                    <div className={styles.group}>
                        <div className={styles.field} ><SearchDropdown disabled={disableField ? true : false}  currency={<Internet />} label='Location' placeholder='Where is your Organization based?' options={locationOption} value={location} setValue={setLocation} setId={setLocationId}/></div>
                        <TextInput disabled  className={styles.field} value={orgProfile && orgProfile.id != null && orgProfile.id != '' ? orgProfile.id : ''} label='Organization ID' name='orgId' type='text'/>
                    </div>

                    {!disableField &&
                    <div className={styles.group}>
                        <div className={styles.cardAction}>
                            <div className={styles.cardSave}>
                                <button className={styles.Save} onClick={save}>Save</button>
                            </div>
                             <div className={styles.cardCancel}>
                                <button className={styles.Cancel} onClick={cancelChanges}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </Card>
            </div>
        </>
    )
}

export default Profile