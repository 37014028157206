import { GET_DEFAULT_EVENTS, GET_CUSTOM_EVENTS, ADD_CUSTOM_EVENTS, EDIT_DEFAULT_EVENTS, EDIT_CUSTOM_EVENTS, FILTER_DEFAULT_SUCCESS, FILTER_CUSTOM_SUCCESS, GET_CUSTOM_EVENTS_SUCCESS, GET_CUSTOM_EVENTS_ERROR, GET_DEFAULT_EVENTS_SUCCESS, GET_DEFAULT_EVENTS_ERROR, FILTER_DEFAULT_EVENT_SUCCESS, FILTER_DEFAULT_EVENT_ERROR, FILTER_CUSTOM_EVENT_SUCCESS, FILTER_CUSTOM_EVENT_ERROR } from "../../../actionTypes/actionTypes";
import eventServices from "../../../../services/buildServices/eventServices/eventServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";

export const getDefaultEventAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getDefaultEvent(null, null, GET_DEFAULT_EVENTS))
        await eventServices.getDefaultEvent(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getDefaultEvent(res.data.data.appEventDetails, res.data.data.totalCount, GET_DEFAULT_EVENTS_SUCCESS))
        }).catch(err => {
            dispatch(getDefaultEvent(null, null, GET_DEFAULT_EVENTS_ERROR))
        })
    }
}

export const getCustomEventAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getCustomEvent(null, null, GET_CUSTOM_EVENTS))
        await eventServices.getCustomEvent(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getCustomEvent(res.data.data.appEventDetails, res.data.data.totalCount, GET_CUSTOM_EVENTS_SUCCESS))
        }).catch(err => {
            dispatch(getCustomEvent(null, null, GET_CUSTOM_EVENTS_ERROR))
        })
    }
}

export const filterDefaultEventsAction = (body, showSkeleton) => {
    return async (dispatch) => {
        await eventServices.getDefaultEvent(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(fileterEvent(res.data.data.appEventDetails, res.data.data.searchCount, FILTER_DEFAULT_EVENT_SUCCESS))
        }).catch(err => {
            dispatch(fileterEvent(null, null, FILTER_DEFAULT_EVENT_ERROR))
        })
    }
}

export const filterCustomEventsAction = (body, showSkeleton) => {
    return async (dispatch) => {
        await eventServices.getCustomEvent(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(fileterEvent(res.data.data.appEventDetails, res.data.data.searchCount, FILTER_CUSTOM_EVENT_SUCCESS))
        }).catch(err => {
            dispatch(fileterEvent(null, null, FILTER_CUSTOM_EVENT_ERROR))
        })
    }
}

// export const createCustomEventAction = (body, navigate, appid, setDisable) => {
//     return async (dispatch) => {
//         setDisable(true)
//         await eventServices.createCustomEvent(body)
//         .then(res=>{
//             toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
//             setTimeout(()=>{
//                 setDisable(false)
//                 navigate(`/events/${appid}`)
//             }, 2000)
//         }).catch(error => {
//             setDisable(false)
//             error.response.data.errors && error.response.data.errors.map((err,i)=>{
//                     toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
//                 })
//         })
//     }
// }
export const createCustomEventAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true);
        try {
            // Send request to create a custom event
            const res = await eventServices.createCustomEvent(body);

            // Dispatching success action (if needed)
            // dispatch(addCustomEvent(res.data.data, ADD_CUSTOM_EVENT_SUCCESS));

            // Show success message
            toast.success(
                <Toast type="Success" messages={res.data.message} />,
                {
                    position: 'top-right',
                    icon: false,
                    hideProgressBar: true,
                    autoClose: 2000,
                    style: { background: '#DEF1E5' },
                }
            );

            // Navigate after a short delay
            setTimeout(() => {
                setDisable(false);
                navigate(`/events/${appid}`);
            }, 2000);

            return res; // Return the response to be used by the caller

        } catch (error) {
            setDisable(false);

            // Display error messages, if any
            if (error.response?.data?.errors) {
                error.response.data.errors.forEach((err) => {
                    toast.error(
                        <Toast type="Error" messages={err.message} />,
                        {
                            position: 'top-right',
                            icon: false,
                            hideProgressBar: true,
                            autoClose: 2000,
                            style: { background: '#FAE8E7' },
                        }
                    );
                });
            }

            throw error; // Re-throw the error to be caught by the caller
        }
    };
};


export const editDefaultEventAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await eventServices.editDefaultEvent(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/default-events/${appid}`)
            }, 2000)
            return res;
        }).catch(error => {
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
                throw error;
        })
    }
}

export const editCustomEventAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await eventServices.editCustomEvent(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/custom-events/${appid}`)
            }, 1500)
            return res;
        }).catch(error => {
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
                throw error;
        })
    }
}

export const getDefaultEvent = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getCustomEvent = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const createCustomEvent = (data) => {
    return {
        type : ADD_CUSTOM_EVENTS,
        payload : data
    }
}

export const editDefaultEvent = (data) => {
    return {
        type : EDIT_DEFAULT_EVENTS,
        payload : data
    }
}

export const editCustomEvent = (data) => {
    return {
        type : EDIT_CUSTOM_EVENTS,
        payload : data
    }
}

export const fileterEvent = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}