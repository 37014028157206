import { START_API_CALLS, API_CALL_SUCCESS, API_CALL_FAILURE, RESET_SAGA_STATE } from '../../actions/currency/bulkFileUploadAction';

const initialState = {
  loading: false,
  completedCalls: 0,
  failedCalls: 0,
  totalCalls: 0,
  error: null,
};

export const currencyUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_API_CALLS:
      return {
        ...state,
        completedCalls: 0,
        loading: true,
        error: null
      };
    case API_CALL_SUCCESS:
      return {
        ...state,
        totalCalls: action.totalLength,
        completedCalls: state.completedCalls + 1
      };
    case API_CALL_FAILURE:
      return {
        ...state,
        totalCalls: action.totalLength,
        failedCalls: state.failedCalls + 1,
        error: action.error
      };
    case RESET_SAGA_STATE: {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
};
