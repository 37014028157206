import React, {useState, useEffect} from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../../components/Radio'
import styles from './wallets.module.sass'
import OverviewTableLayout from '../../../../../components/EngageComponents/PlayersComponent/walletOverviewTable'
import TransactionHistoryTableLayout from '../../../../../components/EngageComponents/PlayersComponent/transactionHistory'
import Control from './Control'
import { getPlayerDetailAction } from '../../../../../redux/action/engage/Players/PlayersAction'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';

const Wallets = ({activeIndex, access}) => {

  const {appid} = useParams()
  const {id} = useParams()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const getPlayerDetails = async() => {
    let playerDetail = {
        entities : {
            value : 'wallets',
            offset: ((page-1)*limit),
            limit: limit
        },
        projectId : appid,
        ids : [id]
    }
    await dispatch(getPlayerDetailAction(playerDetail, activeIndex))
  }

  const onPrevious = () => {
    setPage(prevPage => prevPage - 1)
  }

  const onNext = () => {
    setPage(nextPage => nextPage + 1)
  }

  useEffect(()=>{
      getPlayerDetails()
  }, [activeIndex, page])


  const playerWalletOverview = useSelector((state) => {
      return state.players.walletOverview
  })

  const playerWalletHistory = useSelector((state) => {
      return state.players.walletHistory
  })

  let totalCount = useSelector((state) => {
    return state.players.totalWalletCount
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
  }

  return (
    <>
        <Card title="Overview" classTitle="title-purple">
          {playerWalletOverview && playerWalletOverview.length > 0 ?
            <OverviewTableLayout items={playerWalletOverview} /> 
          : <div className={styles.warning}>No Data Available</div>}

        </Card>
        <Card title="Transaction History" classTitle="title-purple">
          <div>
            <Control access={access} activeIndex={activeIndex}/>
          </div>
          <div className={styles.tableWrapper}>
            <div className={styles.group}>
              {playerWalletHistory && playerWalletHistory.length > 0 ?
                <>
                  <TransactionHistoryTableLayout items={playerWalletHistory}/>
                  <div className={styles.pagination}>
                    <Stack spacing={2}>
                      <Pagination renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            sx={{
                              '&.MuiPaginationItem-root': {
                                minWidth: '28px',
                                height: '28px',
                                fontSize: '12px'
                              },
                              '&.Mui-selected': {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              },
                              '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              }
                            }}
                        />
                      )} siblingCount={1} count={Math.ceil(totalCount/limit)} color="primary" onChange={handlePageChange} />
                    </Stack>
                      {/* <div className={styles.icons}>
                        <button disabled={page <= 1 ? true : false}>
                          <Previous className={styles.previous} disabled={true} onClick={onPrevious}/>
                        </button>
                        <button disabled={playerWalletHistory.length < 10 ? true : false}>
                          <Next className={styles.next} onClick={onNext}/>
                        </button>
                      </div> */}
                  </div>
                </>
              : <div className={styles.warning}>No Data Available</div>}
            </div>
          </div>
        </Card> 
    </>
  )
}

export default Wallets