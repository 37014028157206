import React, { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import axios from "axios";
import OutsideClickHandler from "react-outside-click-handler";
import {  useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import Modal from "../Modals/ModalComponent";
import styles from "./TagInputDropdown.module.sass";
import { toast, ToastContainer } from 'react-toastify';
import Tooltip from "../Tooltip";
import {ReactComponent as AddMetaIcon} from '../../media/images/icons/add_meta.svg'
import { BASE_URL, GET_TAGS} from "../../resources/APIEndpoints";
import TextInput from "../TextInput";
import Icon from "../Icon";
import { getTagsAction, updateTagsAction } from '../../redux/action/common/commonAction';

const TagInputWithDropdown = ({id, tags, setTags, tooltip, disabled}) => {

const [visibleModal, setVisibleModal] = useState(false);
const [dropdownVisible, setDropdownVisible] = useState(false);
const [filterText, setFilterText] = useState('');
const [projectName, setProjectName] = useState("");


const onTagInput = (event) => {
  setDropdownVisible(true)
    if(id){
      if(document.getElementById(id)){
        document.getElementById(id).focus()
      }
    }else{
      if(document.getElementById('tagsInput')){
        document.getElementById('tagsInput').focus()
      }
    }
}
const KeyCodes = { comma: 188, enter: 13 };

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const {appid} = useParams()

const [tmpTag, setTmpTag] = useState([]);

const tagOpt= useSelector((state=>{
  return state.common.tags
}))

const configureTags = () =>{
    tagOpt?.tagDetails&&setTmpTag(tagOpt.tagDetails)
    tagOpt.projectDetails&&setProjectName(tagOpt.projectDetails.name)
}

const getTags = async() => {
  let items = {
      projectId : appid
  }
  dispatch(getTagsAction(items))
}

 const dispatch = useDispatch()

const onSave = async() => {
  
  let tagData=[]
  let rmtag=[]
    tmpTag.filter((e,i)=>e.type===0||e.type===1||e.type===2).map((e,i)=>{
      if (e.type==1) {
        tagData.push({"name":e.name, "type":e.type})
      }
      if (e.type==0) {
          setTags(tags=>tags.filter((x,i)=>(x.text!=e.name)))
          tagData.push({"name":e.name, "type":e.type, "id":e.id})
        }
      if (e.type==2) {
        let temporary=[...tags]
          temporary.map((x,i)=>{
          if (x?.text==e.iState) {
            temporary[i].text=e.name
          }
        })
        setTags(temporary)
        tagData.push({"name":e.name, "type":e.type, "id":e.id})
      }
    })

    let data={
    "projectId":appid,
    "tagDetails":tagData
    }
    dispatch(updateTagsAction(data))
    setVisibleModal(false)
    setTimeout(() => {
      getTags()
    }, 200);
}

const handleDelete = (i) => {
  setTags(tags.filter((tag, index) => index !== i));
};

const handleClick = (tag) => {
  tag.text=tag.name
  if(!tags.some(e => e.text === tag.text)){
    handleAddition(tag);
  }
  else{
    setTags(tags.filter((tg, index) => tg.text !== tag.text));
  }
};

const handleInputBlur = (tag) => {
  // tag=''
  // setFilterText('')
};

const handleInputChange = (tag) => {
  setFilterText(tag)
};

const outClick = () => {
  setDropdownVisible(false)
  setFilterText('')
}
const addTags = () => {
  let values = [...tmpTag]
  values.push({"name": "","type": 1})
  setTmpTag(values)
}
const onChangeValue = (e,i) => {
  let values = [...tmpTag]
  values[i].name = e.target.value
  setTmpTag(values)
}

const trash = (i) => {
  let values = [...tmpTag]
  values[i].type = 0
  setTmpTag(values)
}
const edit = (i) => {
  let values = [...tmpTag]
  values[i].iState = values[i].name
  values[i].type = 2
  setTmpTag(values)
}

const handleAddition = (tag) => {
  if (!tags.some(e => e.text == tag.text)) {
    if(tags.length < 10){
      setTags([...tags, tag]); 
    }else{
        toast.warning("Only 10 tags are allowed")
    }
  }
};

const handleDrag = (tag, currPos, newPos) => {
  const newTags = [...tags].slice();
  newTags.splice(currPos, 1);
  newTags.splice(newPos, 0, tag);

  setTags(newTags);
};

const handleTagClick = (index, event) => {
  event.stopPropagation();
};

const onClearAll = () => {
  setTags([]);
};
const onTagUpdate = (i, newTag) => {
  const updatedTags = tags.slice();
  updatedTags.splice(i, 1, newTag);
  setTags(updatedTags);
};

useEffect(()=>{
  // setTmpTag(suggestions)
  getTags()
},[])

useEffect(()=>{
  configureTags()
},[tagOpt])

  return (
    <>
    <div className={styles.multigroup}>
    <div className={styles.multiselect}>
    <div className={styles.selectLabel}>
        <span>Tags<span><Tooltip className={styles.tooltip} title={tooltip} icon="info"/></span> <button className={styles.manage} onClick={disabled ? null : () => setVisibleModal(true)}>Manage Tags</button></span>
        <span className={styles.tagsWarning}><span className={styles.tagBold}>{tags.length}</span>/10 Tags</span>
    </div>
    <OutsideClickHandler onOutsideClick={() => outClick()}>
      <div className={cn(styles.tagInput, {[styles.disabled] : disabled})} onClick={disabled ? null : onTagInput}>
          <ReactTags id={id ? id : 'tagsInput'} inputProps={{disabled : disabled}} handleDelete={disabled ? undefined : handleDelete} handleAddition={(e)=>{handleAddition(e);outClick()}} handleDrag={handleDrag} delimiters={delimiters}
            handleTagClick={handleTagClick} onClearAll={onClearAll} onTagUpdate={onTagUpdate} placeholder="Enter tags to describe your item"
            minQueryLength={2} maxLength={20} autofocus={false} allowDeleteFromEmptyInput={true} autocomplete={true}
            readOnly={false} allowUnique={false} allowDragDrop={true} inline={true} inputFieldPosition="inline"
            allowAdditionFromPaste={true} editable={!disabled} clearAll={true} tags={tags} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur}/>
            <div className={cn(styles.dropdown,{ [styles.small]: true },{ [styles.active]: dropdownVisible})}>
            <div className={cn(styles.body)}>
            {dropdownVisible && (
            <div className={styles.dropdown}>
              {(tmpTag.filter(option => option.name!==""&&option.name.toLowerCase().includes(filterText.toLowerCase())).length>0)?tmpTag.filter(option => option.name!==""&&option.name.toLowerCase().includes(filterText.toLowerCase())).map((x, index) => (
                <div className={cn(styles.option)} key={index}>
                  <label className={cn(styles.checkbox)} onChange={() => handleClick(x)}>
                    <input className={styles.input} type="checkbox" checked={tags.some(e => e.text === x.name)}/>
                    <span className={styles.inner}>
                      <span className={cn(styles.tick)}></span>
                      <span className={styles.optionLabel}>{x.name}</span>
                    </span>
                  </label>
                </div>
              )):<div style={{textAlign:"center", margin:"120px"}}>
                <span>No Results Found</span>
                <p style={{
                  fontSize: "12px",
                  color: "#6f767e"}}
                >
                  Press 'Enter' to add a new tag to the list
                </p>
                </div>}
            </div>
          )}
          </div>
          </div>
      </div>
    </OutsideClickHandler>

    </div>
</div>
<Modal heading={false} visible={visibleModal} onClose={() => setVisibleModal(false)}>
    <div className={styles.modalHeading}>
      <div className={styles.block}></div><span>Manage Tags - {projectName}</span>
    </div>

    <div style={{margin:"-16px 0 16px 0",fontSize:"12px",color:"#6f767e"}}>
    Tags added here are available across the entire project. Deleting a tag will remove it from all instances within the project.
    </div>

    <span>No. of Tags:  {tmpTag.filter((e,i)=>e.type!==0).length}</span>

    <div style={{margin:"24px 0"}}>
      <span>Tags </span><span className={styles.addIcon} onClick={addTags}><AddMetaIcon /></span>
    </div>
  <div className={styles.scrollable}>
    {tmpTag && tmpTag.map((e,i)=>{
      return(
        <>
        { e.type!==0 &&
          <div className={styles.tagGroup}>
          <TextInput disabled={!e.type>=1} className={styles.inputTag} label="" name="value" type="text" required  value={(e.name != '' || e.name != undefined || e.name != null) ? e.name : ''} onChange={(e)=>{onChangeValue(e, i)}}/>
          <div className={cn(styles.control)}>
          <span className={styles.button} onClick={()=>{edit(i)}}><Icon name="edit" size="20" /></span>
          <span className={styles.button} onClick={()=>{trash(i)}}><Icon name="trash" size="20" /></span>
          </div></div>
          }
        </>
      )
    })}
  </div>
  <div className={styles.cardAction} >
    <div className={styles.cardSave}>
      <button disabled={false} onClick={onSave} className={styles.Save}>Save</button>
    </div>
    <div className={styles.cardCancel}>
      <button onClick={() => setVisibleModal(false)} className={styles.Cancel}>Cancel</button>
    </div>
  </div>
    
</Modal>
</>
  );
};

export default TagInputWithDropdown;
